import React, { Fragment, useEffect, useState, useRef } from 'react';
import './chapters.scss';
import '../myLearning.scss';
import Slider from "react-slick";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { BubbleLoader } from '../../../Loader/loader';
import ProgressBar from '../../../utils/progressBar';
import { getSubjectChapters, getSubjectBrief, viewAnalytic } from '../../../Redux/ActionCreators/myLearningAction';
import { showSideBar } from '../../../Redux/ActionCreators/manageContentAction';
import SubjectBrief from './subjectBrief';
import { SampleNextArrow, SamplePrevArrow, convertMillisToHrsMins } from '../../../utils/miscelleneous';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
// import Certificate from '../../../../utils/certificate';
import { getMentorList } from '../../../Redux/ActionCreators/mentorConnectAction';
import html2pdf from 'html2pdf.js';


export default function Chapters() {
    const [isOpen, setIsOpen] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [type, setType] = useState('');

    const location = useLocation();
    const sliderRef = useRef(null);
    const navigation = useNavigationType();
    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(showSideBar());
        dispatch(getSubjectChapters(location.state.id));
        dispatch(getMentorList('', ''));
    }, [])
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mentors, subjectChapters, subjectDescription, isChapterLoading, subject, skillDetails } = useSelector(state => state.myLearning);
    const { mentorList } = useSelector(state => state.mentorConnect);
    const openCourseDetail = () => {
        dispatch(getSubjectBrief(location.state.id));
        setIsOpen(true);
    }

    useEffect(() => {
        if (navigation === 'POP') {
            console.log('back btn clicked1')
            const local_data = JSON.parse(localStorage.getItem('time'));
            local_data.viewed = false;
            const update = local_data;
            if (update && update?.topic_id && update?.resource_id && update?.subject_id && update?.chapter_id && update?.type && update?.time_spent) {
                dispatch(viewAnalytic(update));
            }
        }
    }, [navigation])

    const goToTopics = () => {
        navigate(window.location.pathname === '/my-learning/chapters' ? `/my-learning/topics` : '/my-subjects/topics', {
            state: {
                id: subjectChapters[0]?._id,
                chapter: subjectChapters[0]?.name,
                subject: location.state.title,
                subjectId: location.state.id,
                type: location.state.type,
                detail: subjectChapters[0],
                content: location.state.content
            }
        })
    }

    const openModal = (type) => {
        setType(type);
        if (detail.analytic) {
            downloadcertificatePdf()
        } else {
            setIsAlert(true);
        }
    }

    const downloadcertificatePdf = () => {
        const input = document.getElementById('cert');
        // const element = document.getElementById('report');
        var opt = {
            format: 'A4',
            orientation: 'landscape', // Can also be 'landscape'

            margin: [0.5, 0.05, 0.5, 0.05],
            filename: `certificate.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 1,
                useCORS: true, // Enable this for cross-origin images
                logging: true
            },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // New Promise-based usage:
        html2pdf().from(input).set(opt).save();
    }



    const navigate = useNavigate();
    const { detail, title, content } = location.state
    const settings1 = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }

        ]
    };

    return (
        <Fragment>
            <div className='chapter-module-holder'>
                {window.innerWidth > 767 ?
                    <div className="bc-nav ml-2 mt-2" >
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {/* <li className="breadcrumb-item"> <img src='/images/home.svg' alt='' /></li> */}
                                <li className="breadcrumb-item cursor-pointer" onClick={() => navigate(-1)} >{window.location.pathname === '/my-learning/chapters' ? t('My Learning') : t('My Subjects')}</li>
                                <li className="breadcrumb-item  b-active">{location.state.title}</li>
                            </ol>
                        </nav>
                    </div> :
                    <img src='/images/back.svg' alt='' onClick={() => navigate(-1)} />
                }
                {
                    location.state.type === 'default' ?
                        < div className='course-description'>

                            <div className='chapter-desc'>
                                <div className=''>
                                    <h3>
                                        {title}
                                    </h3>&nbsp;&nbsp;
                                    <p className=''>
                                        <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} top={-18} completed={detail.analytic || 0} />
                                    </p>
                                </div>
                                <div className='sub-description elipsis-4'>
                                    {detail?.description}
                                </div>
                                <div className='chapter-count'>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/video-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Videos')}</h6>
                                            <h5>{detail?.videos}</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/article-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Articles')}</h6>
                                            <h5>{detail?.articles}</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/quiz-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Quizzes')}</h6>
                                            <h5>{detail?.quizzes || 0}</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/document-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Documents')}</h6>
                                            <h5>{detail?.documents}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='action-btn'>
                                    <button onClick={goToTopics}>{t('Start learning')}</button>

                                    <h5 onClick={() => openCourseDetail()}>{t('View Course Details')}</h5>
                                </div>
                            </div>
                            <div className='chapter-img'>
                                <img src={detail?.image} alt='' />
                            </div>
                        </div> :
                        <div className='chapter-description'>
                            <div className='chapter-img'>
                                <img src={detail?.image} alt='' />
                            </div>
                            <div className='chapter-desc'>
                                <div className=''>
                                    <h3>
                                        {location.state.title}
                                    </h3>&nbsp;&nbsp;
                                    <p className=''>
                                        <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} top={-19} completed={detail?.analytic || 0} />
                                    </p>

                                </div>
                                <div className='sub-description'>
                                    {detail?.description}
                                </div>
                                <div className='chapter-count'>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/video-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Videos')}</h6>
                                            <h5>{detail?.videos}</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/article-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Games')}</h6>
                                            <h5>{detail?.articles}</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/quiz-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Quizzes')}</h6>
                                            <h5>{!content ? (detail?.quizzes || 0) : 0}</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/document-icon.svg' alt='' />
                                        <div className='mx-2'>
                                            <h6>{t('Documents')}</h6>
                                            <h5>{detail?.documents}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }


                <div className='chapters-holder'>
                    <h4>{t('Chapters')}
                        {subjectChapters?.length > 0 ? <aside onClick={goToTopics}>{t('View all')}</aside> : null}
                    </h4>
                    {subjectChapters?.length > 0 &&
                        <div className={'bubble-card mt-3'} style={{ marginLeft: -5 }}>
                            <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap mr-2'} style={{ overflowY: window.innerWidth > 767 ? '' : 'hidden' }}>
                                {
                                    window.innerWidth > 767 ?
                                        <div className=''>
                                            <Slider {...settings1} ref={c => (sliderRef)}>
                                                {isChapterLoading ?
                                                    <BubbleLoader /> :
                                                    subjectChapters?.map((el) => {
                                                        return (
                                                            <div className='card' style={{ width: '20.5%', marginRight: 10 }}>
                                                                <Link to={window.location.pathname === '/my-learning/chapters' ? `/my-learning/topics` : `/my-subjects/topics`}
                                                                    state={{
                                                                        id: el._id,
                                                                        chapter: el.name,
                                                                        subject: location.state.title,
                                                                        subjectId: location.state.id,
                                                                        type: location.state.type,
                                                                        detail: el,
                                                                        content: content
                                                                    }}
                                                                >
                                                                    <img className="card-img-top" src={el.image} style={{ height: 118 }} alt="Card" />
                                                                    {el.articles + el.videos + el.documents + el.quizzes === 0 ? null :
                                                                        <div className='time'>
                                                                            <img src='images/duration.svg' alt='' />&nbsp;
                                                                            {convertMillisToHrsMins((el.duration), t)}
                                                                        </div>
                                                                    }
                                                                    <div className="card-body p-0">
                                                                        <div style={{ padding: 7, paddingTop: 5, paddingRight: 7, }}>
                                                                            <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                                <h5 className='elipsis-2 w-96' >
                                                                                    {el.name || el.title}
                                                                                </h5>
                                                                                <aside className='d-flex mt-1' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}>{el.articles + el.videos + el.documents + el.quizzes} {t('Items')}</aside>
                                                                            </div>
                                                                            <div className='mt-3'>
                                                                                <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el?.analytic || 0}
                                                                                />
                                                                            </div>
                                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: -5 }}></div>
                                                                            <button>{t('Start learning')}</button>
                                                                        </div>
                                                                    </div>
                                                                </Link>

                                                            </div>

                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                        :
                                        subjectChapters !== undefined && subjectChapters.map((el, index) => {
                                            return (
                                                <div key={index} className='card' style={{ width: 230, marginRight: 10 }}>
                                                    <Link to={window.location.pathname === '/my-learning/chapters' ? `/my-learning/topics` : `/my-subjects/topics`}
                                                        state={{
                                                            id: el._id,
                                                            chapter: el.name,
                                                            subject: location.state.title,
                                                            subjectId: location.state.id,
                                                            type: location.state.type,
                                                            detail: el,
                                                            content: content
                                                        }}
                                                    >
                                                        <img className="card-img-top" src={el.image} alt="Card" height='96px' />
                                                        <div className="card-body p-0">
                                                            <div style={{ padding: 7, paddingTop: 0, paddingRight: 7, }}>
                                                                <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                    <h5 className='elipsis-1' >
                                                                        {el.name}
                                                                    </h5>
                                                                    <aside className='d-flex mt-1' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}>{el.articles + el.videos + el.quizzes + el.documents} Items</aside>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el.analytic || 0}
                                                                    />
                                                                </div>
                                                                <div style={{ borderTop: '1px solid #ddd', marginTop: -5 }}></div>
                                                                <button>{t('Start learning')}</button>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    {/* <p><img src='/images/small-subscriber-icon.svg' alt='' /> &nbsp;126 subscribers</p> */}

                                                </div>
                                            )
                                        })

                                }

                            </div>
                        </div>
                    }
                </div>
                {
                    location.state.type === 'default' ?
                        <div className='test-holder1'>
                            <div className='test-card' onClick={() => navigate('/practice-tests')} style={{ backgroundImage: `url(/images/trophy.png)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: '160px 165px' }}>
                                <h4>{t('Take a test')}</h4>
                                <p>{t('Level up on the above skills and collect up to 50 Engagement points and get closer to your database.')}</p>
                                <button>{t('Start Test')}</button>
                            </div>
                            <div className='test-card' onClick={() => openModal('certificate')} style={{ backgroundImage: `url(/images/certificate.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: '160px 165px' }}>
                                <h4>{t('Get Certificate')}</h4>
                                <p>{t('Accelerate your career or validate your knowledge—get certified effortlessly.')}</p>
                                <button >{t('Download Certificate')}</button>
                            </div>
                            <div className='test-card' onClick={() => openModal('visit')} style={{ backgroundImage: `url(/images/visit.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: '160px 165px' }}>
                                <h4>{t('Book a Campus visit')}</h4>
                                <p>{t('Schedule a personalized tour for an inside look at your future educational journey!')}</p>
                                <button >{t('Book Now')}</button>
                            </div>

                        </div>
                        :
                        <div className='test-holder' onClick={() => navigate('/practice-tests')}>
                            <h4>{t('Take a test')}</h4>
                            <p>{t('Level up on the above skills and collect up to 50 Engagement points and get closer to your database.')}</p>
                            <button>{t('Start Test')}</button>
                        </div>

                }
                <div className='mentors-list'>
                    <h4 style={{ marginLeft: -8 }}>{t('Teachers')}</h4>
                    <div className={window.innerWidth > 767 ? 'row mt-2' : 'row flex-nowrap mr-2 overflow-auto'} >
                        {
                            mentorList && mentorList.slice(0, 5)?.map((el) => {
                                return (
                                    <div className='card' style={{ width: window.innerWidth > 767 ? '18.5%' : 230, marginRight: 10 }} >
                                        {
                                            el.profile_image !== null ?
                                                <img className="card-img-top" src={el.profile_image} style={{ height: 150 }} alt="Card" /> :
                                                <div className='intial-holder'>
                                                    <div className='initial'>
                                                        {el.initial}
                                                    </div>
                                                </div>
                                        }

                                        <div className="card-body text-center">
                                            <h5>{el.name}</h5>
                                            <p>{el.subjects[0]?.name}</p>
                                            <img src='/images/message.svg' alt='' />
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </div >


            <SubjectBrief
                subjectDescription={subjectDescription}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
            />

            {/* Create custom test modal */}
            <Modal
                show={isAlert}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"alert-modal"}
            >
                <Modal.Body>

                    <div className='text-center'>
                        {
                            type === 'certificate' ?
                                <p>Please complete atleast 75% of the course and take a test to download the certificate</p> :
                                <p>Please complete atleast 75% of the course and take a test to book a campus visit</p>
                        }
                    </div>

                    <div className='form-group text-center mt-3'>
                        <button className='position-relative' onClick={() => setIsAlert(false)}>
                            Ok
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
            {/* <div style={{ zIndex: -1, position: 'fixed' }} >
                <Certificate
                    course={title}
                    certificateData={null}
                />
            </div> */}
        </Fragment>

    )
}
