/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import "./manageResource.scss";
import Switch from "react-switch";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
    hideSideBar, getResourcesList, googleLinkPreview, googleLinkPreviewSetNull,
    saveArticle, reorderTopicsResources, reorderdTopicsResources, getTopicListOfSubject, updateVideoorArticles, updateResourceStatus, resetResourceLoading,
    getQuizQuestion, addParams,
} from "../../../Redux/ActionCreators/manageContentAction";
import Modal from "react-bootstrap/Modal";
import PreviewLink from "../Manage-Add-Content/previewLink";
import $ from 'jquery'
import { TopicLoader } from "../../../Loader/loader";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { minutesToMilliseconds, msTominutes } from "../../../utils/miscelleneous";
import ViewResource from "../../../utils/viewResource";


function ManageResources() {
    const [chapterId, setChapterId] = useState('');
    const [topicId, setTopicId] = useState('');
    const [title, setTitle] = useState('');
    const [topic, setTopic] = useState('');
    const [isAddArticle, setIsAddArticle] = useState(false);
    const [edit, setEdit] = useState(false);
    const [articleLink, setArticleLink] = useState('');
    const [duration, setDuration] = useState('2');
    const [description, setDescription] = useState('');
    const [source, setSource] = useState('');
    const [articleLinkError, setArticleLinkError] = useState('');
    const [durationError, setDurationError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [sourceError, setSourceError] = useState('');
    const [isLink, setIsLink] = useState(false);
    const [id, setId] = useState('');
    const [isView, setIsView] = useState(false);
    const [resource, setResource] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigation = useNavigationType();
    const { isResourceLoading, resourceList, previewData, topicsListOfSubject } = useSelector(state => state.manageContent);

    useEffect(() => {
        const firstRender = async () => {
            window.scrollTo(0, 0);
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            };
            await dispatch(hideSideBar());
            if (navigation === 'POP') {
                location.state.chapterId = localStorage.getItem('chapterId');
                location.state.id = localStorage.getItem('topicId');
                location.state.topic = localStorage.getItem('topic');
                location.state.chapter = localStorage.getItem('title');

                setChapterId(localStorage.getItem('chapterId'));
                setTopicId(localStorage.getItem('topicId'));
                setTitle(localStorage.getItem('title'));
                setTopic(localStorage.getItem('topic'));
                await dispatch(addParams(localStorage.getItem('chapterId'), localStorage.getItem('title'), localStorage.getItem('topic'), localStorage.getItem('topicId')));
                await dispatch(resetResourceLoading());
                await dispatch(getResourcesList(localStorage.getItem('topicId')));
            } else {
                setChapterId(location.state.chapterId);
                setTopicId(location.state.id);
                setTitle(location.state.chapter);
                setTopic(location.state.topic);
                await dispatch(addParams(location.state.chapterId, location.state.chapter, location.state.topic, location.state.id));
                await dispatch(resetResourceLoading());
                await dispatch(getResourcesList(location.state.id));
            }


            dispatch(getTopicListOfSubject(location.state.subjectId));
        }
        firstRender();
    }, [])

    const handleVideo = (type) => {
        navigate("/manage-content/topic/add-resources", {
            state: {
                title: title,
                topic: topic,
                type: type,
                chapterId: chapterId,
                topicId: topicId,
                subject: location.state.subject,
                subjectId: location.state.subjectId,
                edit: false,
            },
        });
    };




    const handleChange = (topicId, id, checked) => {
        let obj = {
            topic_id: topicId,
            r_id: id,
            checked: !checked
        }
        dispatch(updateResourceStatus(obj));
    }

    const addArticle = () => {
        setIsAddArticle(true);
        setEdit(false);
    };

    const handleModal = async () => {
        setIsAddArticle(false);
        setArticleLink('');
        setDuration('');
        await dispatch(googleLinkPreviewSetNull());
    };

    const handleChangeArticle = async (evt) => {
        var regex =
            /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
        var Extension = evt.target.value
            .substring(evt.target.value.lastIndexOf(".") + 1)
            .toLowerCase();
        if (!regex.test(evt.target.value)) {
            setArticleLinkError("Please enter valid link")
            dispatch(googleLinkPreviewSetNull());
            return false;
        }
        else if (Extension === 'pdf') {
            setArticleLinkError("Please enter article link")
        }
        else {
            await setArticleLink(evt.target.value);
            const res = await dispatch(googleLinkPreview(evt.target.value));
            await setArticleLinkError('');
            if (res?.type === 'GET_PREVIEW') {
                setDescription(res?.payload?.description ? res.payload?.description : '')
                setSource(res?.payload?.site_name ? res.payload?.site_name : '');
            }

        }
    };

    const changeDuration = (val) => {
        setDuration(val);
        setDurationError('');
    }

    const changeSource = (val) => {
        setSource(val);
        setSourceError('');
    }

    const changeDescription = (val) => {
        setDescription(val);
        setDescriptionError('');
    }


    const articleLinkASave = async () => {
        if (articleLink === "" || null) {
            setArticleLinkError(`Link can't be empty`);
        }
        else if (!previewData) {
            setArticleLinkError("Please enter the link");
        }
        else if (source === '') {
            setSourceError('Please enter source');
        }
        else if (description === '') {
            setSourceError('Please enter description');
        }
        else {
            let data = {
                topic_id: topicId,
                description: previewData?.description || description,
                title: previewData?.title,
                image: previewData?.image,
                url: previewData?.url,
                type: 'Article',
                duration: Number(minutesToMilliseconds(duration)),
                source: source
            }
            const res = await dispatch(saveArticle(data));
            if (res?.type === 'ADD_RESOURCE') {
                dispatch(googleLinkPreviewSetNull());
                handleModal();
            }
        }
    };

    const editResources = async (details) => {
        if (details?.type === "Article") {

            const res = await dispatch(googleLinkPreview(details?.url));
            await setArticleLinkError('');
            if (res?.type === 'GET_PREVIEW') {
                setDescription(res?.payload?.description ? res.payload?.description : '')
                setSource(res?.payload?.site_name ? res.payload?.site_name : '');
            }
            setIsLink(details.source === 'Baamboozle' ? true : false)
            setIsAddArticle(true);
            setEdit(true);
            setDuration(details?.duration ? msTominutes(details?.duration) : 2);
            setArticleLink(details?.url);
            setId(details?._id);

        } else {
            if (details.type === 'Quiz') {
                dispatch(getQuizQuestion(details?._id));
            }
            navigate("/manage-content/topic/add-resources", {
                state: {
                    title: location.state.chapter,
                    topic: location.state.topic,
                    type: details.type,
                    details: details,
                    chapterId: chapterId,
                    topicId: location.state.id,
                    subject: location.state.subject,
                    subjectId: location.state.subjectId,
                    edit: true,
                }
            });

        }
    };

    const updateArticle = async () => {
        if (articleLink === "" || null) {
            setArticleLinkError("Link cannot be empty");
        }
        else if (!previewData) {
            setArticleLinkError("Please enter the link");
        }
        else if (source === '') {
            setSourceError('Please enter source');
        }
        else if (description === '') {
            setSourceError('Please enter description');
        }
        else {
            var data = new FormData();
            data.append("r_id", id);
            data.append("description", previewData.description);
            data.append("title", previewData.title);
            data.append("image", previewData.image);
            data.append("type", "Article");
            data.append("url", previewData.url);
            data.append("duration", Number(minutesToMilliseconds(duration)));
            data.append("source", source);
            await dispatch(updateVideoorArticles(data));
            await dispatch(googleLinkPreviewSetNull());
            setIsAddArticle(false);
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return
        const data = await dispatch(reorderTopicsResources(result));
        const resources = Array.from(resourceList)
        const [reorderedSubtopicResource] = resources.splice(data.payload.source.index, 1)
        resources.splice(data.payload.destination.index, 0, reorderedSubtopicResource)
        resources.forEach((el, i) => {
            if (el.index !== i + 1) {
                el.index = i + 1
            }
        })
        await dispatch(reorderdTopicsResources(topicId, resources.map(el => [{ _id: el._id, index: el.index }][0])));
    }

    const openView = (el) => {
        setIsView(true);
        setResource(el);
        $("body").css("overflow", "hidden");
    }

    const closeDetails = () => {
        setIsView(false);
        setResource('');
        $("body").css("overflow", "visible");
    }

    const goToSubTopicsContent = async (chapterid, topicId, topic, chapter) => {
        console.log(chapterid, topicId, topic, chapter)
        setChapterId(chapterid);
        setTopicId(topicId);
        setTitle(chapter);
        setTopic(topic)
        await dispatch(resetResourceLoading());
        await dispatch(addParams(chapterid, chapter, topic, topicId));
        await dispatch(getResourcesList(topicId))
        await dispatch(hideSideBar());
    }



    const backtoBubble = () => {
        navigate(-1);
        dispatch(hideSideBar());
    };

    return (
        <Fragment>

            <div className="subtopics-module">

                <div className='collapse-topics' style={{ background: '#fff' }} >
                    <h1>{location.state.subject}</h1>
                    <div id="accordion" className="accordion">
                        {
                            topicsListOfSubject !== undefined && topicsListOfSubject?.map((items, index) => {

                                return (
                                    <div className="card mb-0" >
                                        <div className={items.topics?.length === 0 ? 'card-header collapsed display' : 'card-header collapsed'} data-toggle="collapse" href={`#${index}`}>
                                            <a className="card-title" >
                                                <div style={{ marginLeft: 30 }} className='d-flex justify-content-start align-items-center' onClick={() => goToSubTopicsContent(items._id, items.topics[0]?._id, items.topics[0]?.name, items.name)}>
                                                    <p className="elipsis-1">{items?.name}</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div id={index} className={chapterId === items._id ? 'card-body collapse show' : 'card-body collapse'} data-parent={`#accordion`} >
                                            <ul className='content-holder' >
                                                {
                                                    items.topics.sort((a, b) => (a.index - b.index)).map((el, i) => {
                                                        return (
                                                            <li key={el._id} className="resource " style={{ background: topic === el.name ? '#EDF3F3' : '' }}>
                                                                <div className="col-md-12 col-8 recent-title"   >
                                                                    <div onClick={() => goToSubTopicsContent(items._id, el._id, el.name, items.name)} >
                                                                        <h1 className="elipsis-2">{el?.name}</h1>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="manage-subtopics add-content-wrapper p-0" style={{ width: '70%', marginLeft: 20 }}
                >
                    <div className="d-flex" style={{ fontSize: 20, cursor: "pointer" }}>
                        <p style={{ marginTop: 5 }} onClick={() => backtoBubble()}>
                            <img src="/images/back.svg" alt="" width="20px" />
                        </p>
                        &nbsp;&nbsp;
                        <h1>{title}</h1>
                    </div>

                    <div className='subject-header'>
                        <ul className='subject-btn'>
                            <li><button onClick={() => handleVideo("Video")}><img src='/images/video-icon-white.svg' alt='' /> &nbsp; Add Video</button></li>
                            <li><button onClick={() => {
                                addArticle()
                                setIsLink(false)
                            }}><img src='/images/article-icon-white.svg' alt='' /> &nbsp; Add Article</button></li>
                            <li><button onClick={() => handleVideo("Document")}><img src='/images/document-icon-white.png' alt='' /> &nbsp; Add Document</button></li>
                            <li><button onClick={() => handleVideo("Quiz")}><img src='/images/quiz-icon-white.png' alt='' /> &nbsp; Add Quiz</button></li>
                            <li><button onClick={() => {
                                addArticle()
                                setIsLink(true)
                            }}><img src='/images/quiz-icon-white.png' alt='' /> &nbsp; Add Link</button></li>
                        </ul>

                        <div className='content-heading'>
                            <ul>
                                <li>
                                    <div>No.</div>
                                    <div>Item</div>
                                    <div>Item Type</div>
                                    <div>Status</div>
                                    <div style={{ width: '18%' }}>Actions</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId={chapterId}>
                                {
                                    (provided) => (
                                        <ul className='subject-list' {...provided.droppableProps} ref={provided.innerRef} >
                                            {isResourceLoading ?
                                                <TopicLoader />
                                                :
                                                resourceList?.length > 0 ?
                                                    resourceList?.map((el, index) => {
                                                        return (
                                                            <Draggable key={el._id} draggableId={el._id} index={index} >
                                                                {
                                                                    (provided) => (
                                                                        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                            <div className='subject-num' >
                                                                                <img src='/images/drag.svg' alt='' />
                                                                                <p>{index + 1}</p>
                                                                            </div>
                                                                            <div className='subject-name ml-0' style={{ width: '40%' }} >
                                                                                <div className="d-flex align-items-center">
                                                                                    <article style={{ width: '56px' }}>
                                                                                        <img
                                                                                            src={el.type === 'Quiz' ? '/images/quiz.svg' : el.type === 'Document' ? (!el.image ? '/images/error-image.jpg' : el.image) : el.image !== null ? el.image : '/images/error-image.jpg'}
                                                                                            alt=""
                                                                                            className="rounded"
                                                                                            width="56px "
                                                                                            height="32px"
                                                                                            onError={(e) => {
                                                                                                if (e.target.src !== "/images/error-image.jpg") {
                                                                                                    e.target.onerror = null;
                                                                                                    e.target.src = "/images/error-image.jpg";
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                    </article> &nbsp;
                                                                                    <p style={{ marginLeft: "17px", lineHeight: "normal" }}>
                                                                                        {el.title}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='text-center ' style={{ width: '18%', cursor: 'default' }} >
                                                                                <h3 className='d-flex justify-content-start align-items-center'>
                                                                                    {el.type === "Video" ?
                                                                                        <img
                                                                                            src="/images/video-icon.svg" alt='' /> : el.type === "Article" ?
                                                                                            <img
                                                                                                src="/images/article-icon.svg" alt='' /> : el.type === "Quiz" ? <img
                                                                                                    src="/images/quiz-icon.svg" alt='' /> : el.type === "Document" ? <img
                                                                                                        src="/images/document-icon.svg" alt='' /> : null}
                                                                                    &nbsp; &nbsp;
                                                                                    {el.source === 'Baamboozle' ? 'Game' : el.type}</h3>
                                                                            </div>

                                                                            <div className='text-center'>
                                                                                <p> <Switch onChange={() => handleChange(topicId, el._id, el.active)}
                                                                                    width={30}
                                                                                    height={16}
                                                                                    uncheckedIcon={false}
                                                                                    checkedIcon={false}
                                                                                    onColor='#00B24B'
                                                                                    checked={el.active} /><br />
                                                                                    {el.active ? 'Active' : 'Inactive'}
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-center'>
                                                                                {el.type === "Quiz" ? null : <img src="/images/view_grey.svg" alt="" width='25px' onClick={() => openView(el)} />}
                                                                                <img src="/images/edit.svg" onClick={() => editResources(el)} alt="" />
                                                                                {/* <img src='/images/delete.svg' alt='' />&nbsp;&nbsp;
                                         <img src='/images/menu.svg' alt='' /> */}
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                            </Draggable>
                                                        )
                                                    })
                                                    : <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                        <p>You have not added any resources  yet.</p>
                                                    </div>

                                            }
                                        </ul>
                                    )}
                            </Droppable>
                        </DragDropContext>
                    }

                </div>
                <div className="resource-details" style={{ display: isView ? 'block' : 'none', }}>
                    <ViewResource
                        activeFeedData={resource}
                        closeNotes={closeDetails}
                    />
                </div>
                <Modal
                    show={isAddArticle}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    // onHide={handleModal}
                    dialogClassName={"add-topic-modal"}
                >
                    <div className="modal-header" style={{ background: "#1D8BCB" }}>
                        <h5 className="modal-title">
                            {edit ? (isLink ? 'Edit Game Link' : 'Edit Article') : (isLink ? 'Add Link' : 'Add Article')}
                            <br />
                        </h5>

                        <div className="sticky-close" style={{ top: 14 }} onClick={() => handleModal()}>
                            <img src="/images/close-white.svg" width="15px" alt="" />
                        </div>
                    </div>
                    <Modal.Body style={{ padding: "22px 40px 22px 40px" }}>
                        <div>
                            <label>{isLink ? 'Link' : 'Article Link'}*</label>
                            <input
                                className="form-control mt-2"
                                value={articleLink}
                                onChange={(e) => handleChangeArticle(e)}
                            />
                            {previewData ? (
                                <PreviewLink previewData={previewData} />
                            ) : null}
                        </div>
                        <small>{articleLinkError}</small>
                        {previewData?.description === '' &&
                            <div className="mt-3">
                                <label>Description*</label>
                                <input
                                    type="number"
                                    className="form-control mt-2"
                                    value={description}
                                    onChange={(e) => changeDescription(e.target.value)}
                                />
                            </div>
                        }
                        <small>{descriptionError}</small>
                        <div className="mt-3">
                            <label>Duration(in mins)*</label>
                            <input
                                type="number"
                                className="form-control mt-2"
                                value={duration}
                                onChange={(e) => changeDuration(e.target.value)}
                            />
                        </div>
                        <small>{durationError}</small>
                        <div className="mt-3">
                            <label>Source*</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                value={source}
                                onChange={(e) => changeSource(e.target.value)}
                            />
                        </div>
                        <small>{sourceError}</small>
                        <div className="save-btn">
                            {
                                edit ?
                                    <button onClick={updateArticle} > Save</button> :
                                    <button onClick={articleLinkASave} >Save </button>
                            }

                            <button onClick={handleModal}>Cancel</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    );
}

export default (ManageResources);
