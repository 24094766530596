/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import "./../manageContent.scss";
import {
    addQuizQuestions,
    selectOrRemoveQuestions,
    getQuestionFromSubject,
    reorederSubTopicsProps
} from "../../../Redux/ActionCreators/manageContentAction";
import Select, { components } from 'react-select';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import Notespopup from '../../My-notes/notespopup';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function QuizQuestions(props) {
    const [query, setQuery] = useState('');
    const [subject, setSubject] = useState('');
    const [subjectSelect, setSubjectSelect] = useState('');
    const [selectType, setSelectType] = useState('');
    const [isView, setIsView] = useState(false);
    const [resource, setResource] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const { subjectSelect, selectType } = props
        setSubject(props.subject);
        setSubjectSelect(subjectSelect);
        setSelectType(selectType);
        props.selectType &&
            dispatch(getQuestionFromSubject(props.subjectSelect, props.selectType));
    }, [])

    const { questionList, questionListForQuiz } = useSelector(state => state.manageContent)



    const onQuestionSelect = (e, question) => {
        if (e.target.checked === true) {
            dispatch(addQuizQuestions(question));
        } else {
            dispatch(selectOrRemoveQuestions(question._id));
        }
    };

    const getSubject = (e) => {
        setSubject(e);
        setSubjectSelect(e.value);
        dispatch(getQuestionFromSubject(e.value, selectType));

    }

    const serachQuestions = (e) => {
        setQuery(e.target.value);
        // if (e.target.value === "" || null || undefined) {
        //     props.getQuestionFilterList(e.target.value, subjectSelect, selectType);
        // } else {
        //     props.getQuestionFilterList(e.target.value, subjectSelect, selectType);
        // }
    };





    const viewQuestion = async (type, id) => {
        await props.getQuizQuestion(id);
        setIsView(true);
        setResource({ ...props.questionData, type1: 'Question' });
        $("body").css("overflow", "hidden");
    }

    const closeDetails = () => {
        setIsView(false);
        setResource('');
        $("body").css("overflow", "visible");
    }

    const handleDragEnd = async (result) => {
        if (!result.destination) return
        await dispatch(reorederSubTopicsProps(result));
    }



    const { type } = props;
    const updatedquestionList = questionList && questionList.map((question) => {
        if (questionListForQuiz?.findIndex((element) => element._id === question._id) !== -1) {
            return {
                ...question,
                checked: true,
            };
        }
        return { ...question, checked: false };
    });
    return (
        <Fragment>



            <div
                style={{ marginLeft: 20 }}
                className={
                    type === "selectQuestion"
                        ? (window.location.pathname === '/manage-content' ? "manage-content-holder  selected-question" : "manage-content-holder selected-ques")
                        : "manage-content-holder"
                }
            >
                <Fragment>
                    <div className='ques-header'>
                        {
                            type === "selectQuestion" ? (
                                <div className="select" style={{ fontSize: 20, cursor: "pointer" }}>
                                    <div className="d-flex align-items-center justify-content-between select-qes">
                                        <div className="d-flex">
                                            <p style={{ marginTop: 5 }} onClick={() => props.backToMain()}>
                                                <img src="/images/back.svg" alt="" width="20px" />
                                            </p>
                                            &nbsp; &nbsp;
                                            <h1 style={{ color: '#222222' }}>Select question</h1>
                                        </div>
                                        <button className="ml-auto" onClick={() => props.backToMain()}>
                                            Done <span class="arrow-right"></span>
                                        </button>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="content-header" style={{ marginTop: 2, padding: 10 }}>
                        <div className="content-heading">
                            {type === "selectQuestion" ? (
                                <div className="d-flex align-items-center search-input">
                                    <div className='position-relative'>
                                        <div class="symbol">
                                            <img src="/images/search.svg" alt="" width='14px' />
                                        </div>
                                        <input
                                            style={{ height: 40 }}
                                            className='form-control'
                                            autoComplete="off"
                                            placeholder="Search for a question or tags"
                                            spellCheck="false"
                                            onChange={(e) => serachQuestions(e)}
                                            type="text"
                                        />
                                    </div>
                                    &nbsp;&nbsp;
                                    <div style={{ width: '30%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={props.selectFromSubject}
                                            onChange={getSubject}
                                            placeholder='Select Subject'
                                            value={subject}
                                        />
                                    </div>
                                    <div className="ms-auto">
                                        <h2 >{questionListForQuiz?.length}</h2>
                                        <span style={{ fontSize: 12, color: '#9B9AA3', marginLeft: -10 }}>Questions Selected</span>
                                    </div>
                                </div>
                            ) : null}

                            <ul className="select-qes-table">
                                <li>
                                    <div className="first-row">
                                        {" "}
                                        {type === "selectQuestion" ? "Select" : "Question #"}
                                    </div>
                                    <div style={{ width: "20%" }}>Type</div>
                                    <div style={{ width: "45%" }}>Title</div>
                                    <div style={{ width: "20%" }}>Chapter</div>
                                    <div style={{ width: '20%' }}>Actions</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* {questionList !== undefined && questionList !== null ? ( */}
                    <ul className="program-list select-qes-table">
                        {type !== "selectQuestion" &&
                            questionListForQuiz &&
                            questionListForQuiz.length <= 0 ? (
                            <div className="empty-list">
                                <img src="/images/empty.png" alt="" />
                                <p>You have not added any Questions yet.</p>
                            </div>
                        ) : type !== "selectQuestion" && questionListForQuiz ? (
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId={'222'}>
                                    {(provided) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                            {questionListForQuiz.map((el, index) => {
                                                return (
                                                    <Draggable key={el._id} draggableId={el._id} index={index} >
                                                        {(provided) => (
                                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >

                                                                <div className="program-name first-row">
                                                                    {type === "selectQuestion" ? (
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                onClick={(e) => {
                                                                                    onQuestionSelect(e, el);
                                                                                }}
                                                                                style={{ width: 20, height: 20 }}
                                                                            />
                                                                        </td>
                                                                    ) : (
                                                                        <p><img src='/images/drag.svg' alt="" /></p>
                                                                    )}
                                                                </div>

                                                                <div
                                                                    className="program-name text-center text-capitalize"
                                                                    style={{ width: "17%" }}
                                                                >
                                                                    <p className='ml-0'>{el.type}</p>
                                                                </div>

                                                                <div style={{ width: "45%" }}>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.description }} />
                                                                </div>
                                                                <div className="text-center" style={{ width: "18%" }}>
                                                                    <p>{el.chapter_name}</p>
                                                                </div>
                                                                {type === "selectQuestion" ?
                                                                    <div
                                                                        style={{ width: "20%" }}
                                                                        className="text-center"
                                                                        onClick={() => viewQuestion('Question', el._id)}
                                                                    >
                                                                        <img src="/images/view_grey.svg" alt="" width='25px' />
                                                                        &nbsp;&nbsp;
                                                                    </div>
                                                                    : (
                                                                        <div style={{ width: "18%" }}
                                                                            className="text-center"
                                                                            onClick={() => dispatch(selectOrRemoveQuestions(el._id))}
                                                                        >
                                                                            <img src="/images/delete.svg" alt="" />
                                                                            &nbsp;&nbsp;
                                                                        </div>
                                                                    )}
                                                            </li>)
                                                        }
                                                    </Draggable>
                                                )
                                            })
                                            }
                                        </ul>
                                    )
                                    }
                                </Droppable>
                            </DragDropContext>
                        ) : updatedquestionList && updatedquestionList.length ? (
                            updatedquestionList !== undefined &&
                            updatedquestionList.map((el, index) => {
                                return (
                                    <li>
                                        <div className="program-name ">
                                            {type === "selectQuestion" ? (
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={el.checked ? true : false}
                                                        onChange={(e) => {
                                                            onQuestionSelect(e, el);
                                                        }}
                                                        style={{ width: 20, height: 20 }}
                                                    />
                                                </td>
                                            ) : (
                                                <p>{index + 1}</p>
                                            )}
                                        </div>

                                        <div
                                            className="program-name text-center text-uppercase"
                                            style={{ width: "17%" }}
                                        >
                                            <p className='ml-0'>{el.type}</p>
                                        </div>


                                        <div style={{ width: "45%" }}>
                                            <p dangerouslySetInnerHTML={{ __html: el.description }} />
                                        </div>
                                        <div className="text-center" style={{ width: "18%" }}>
                                            <p>{el.chapter_name}</p>
                                        </div>
                                        {type === "selectQuestion" ?
                                            <div style={{ width: '18%' }}
                                                className="text-center"
                                                onClick={() => viewQuestion('Question', el._id)}
                                            >
                                                <img src="/images/view_grey.svg" alt="" width='25px' />
                                                &nbsp;&nbsp;
                                            </div>
                                            : (
                                                <div className="text-center">
                                                    <img src="/images/delete.svg" alt="" />
                                                    &nbsp;&nbsp;
                                                </div>
                                            )}
                                    </li>
                                );
                            })
                        ) : (
                            <div className="empty-list">
                                <img src="/images/empty.png" alt="" />
                                <p>You have not added any Questions yet.</p>
                            </div>
                        )}

                    </ul>
                </Fragment>
            </div>
            {/* <div className="feeds-details" style={{ display: isView ? 'block' : 'none', }}>
                <Notespopup
                    activeFeedData={resource}
                    closeNotes={closeDetails}
                />
            </div> */}
        </Fragment>
    );
}


export default (QuizQuestions);
