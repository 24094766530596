import React, { useEffect, useState } from 'react'
import './users.scss';
import { Fragment } from 'react';
import Select, { components } from 'react-select';
import { generate_year_range } from '../../utils/miscelleneous';
import states from './state.json';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};
var yearsStart

export default function ViewUser(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [student_Id, setStudentId] = useState('');
    const [email, setEmail] = useState('');
    const [classs, setClass] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [edit, setEdit] = useState(true);
    const [paid, setPaid] = useState('');
    const [tId, setTid] = useState('');
    const [id, setId] = useState('');
    const [payStatus, setPayStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [section, setSection] = useState('');
    const [status, setStatus] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [studentIdError, setStudentIdError] = useState('');
    const [affiliationError, setAffiliationError] = useState('');
    const max_length = 50
    const payStatusOption = [{ label: 'Paid', value: true }, { label: 'Unpaid', value: false }];
    const [chars_left, setCharLeft] = useState(50);
    const [chars_left1, setCharLeft1] = useState(50);
    const [chars_left2, setCharLeft2] = useState(50);
    const [chars_left3, setCharLeft3] = useState(50);


    useEffect(() => {
        const { student } = props
        console.log(props.classes)
        if (student !== undefined) {
            setId(student?._id);
            setFirstName(student?.firstname);
            setLastName(student?.lastname);
            setEmail(student?.email);
            setStudentId(student?.id_num);
            setClass({ label: `Class ${student.class}`, value: student?.class })
            setSection({ label: student?.section_name, value: student?.section_id })
            setAffiliation({ label: student?.affiliation?.name, value: student?.affiliation?._id })
            props.getAffliations();
            props.getSectionFromClass(student.class, student?.affiliation?._id);
            setCharLeft(max_length - student?.firstname?.length);
            setCharLeft1(max_length - student?.lastname?.length);
            setCharLeft2(student?.id_num !== null ? max_length - student.id_num?.length : 50);
            setCharLeft3(max_length - student?.email?.length);
            setStatus({ value: student.active, label: student.active ? 'Active' : 'Inactive' });
            setEdit(props.edit);

        }
    }, [])

    const onChangeStudent = (e) => {
        changeChar(e.target.name, e.target.value);
        if (e.target.name === 'firstname') {
            setFirstName(e.target.value);
            setFirstNameError('')
        }
        else if (e.target.name === 'lastname') {
            setLastName(e.target.value);
            setLastNameError('');
        }
        else if (e.target.name === 'student_Id') {
            setStudentId(e.target.value);
            setStudentIdError('');
        }
    }

    const changeChar = (name, val) => {
        const charCount = val.length;
        const maxChar = max_length;
        const charLength = maxChar - charCount;
        if (name === 'firstname') {
            setCharLeft(charLength);
        }
        else if (name === 'lastname') {
            setCharLeft1(charLength);
        }
        else if (name === 'student_Id') {
            setCharLeft2(charLength);
        }
        else if (name === 'email') {
            setCharLeft3(charLength);
        }
    }



    const getViewMode = () => {
        setEdit(true);
    }




    const selectAffiliation = (e) => {
        setAffiliation(e);
        setAffiliationError('');
        props.getClasses(e?.value);
        setClass('');
        setSection('');
    }

    const selectClass = (e) => {
        console.log(e)
        setClass(e);
        setSection('');
        props.getSectionFromClass(e.class, e.affiliation_id)
    }


    const getSection = (e) => {
        setSection(e);

    }

    const getStatus = (e) => {
        setStatus(e);
    }

    const getPayStatus = (e) => {
        setPayStatus(e);
        setPaid(e.value)
    }


    const editStudent = async () => {
        if (firstName === "" || null) {
            setFirstNameError('Please enter firstName');
        }
        else if (lastName === "" || null) {
            setLastNameError('Please enter lastName');
        }
        else if (student_Id === "" || null) {
            setStudentIdError('Please enter studentId');
        }
        else if (affiliation === "" || null) {
            setAffiliationError('Please select affiliation');
        }

        else {
            let obj = {
                _id: id,
                firstname: firstName,
                lastname: lastName,
                student_id: student_Id,
                section_id: section?.value,
                affiliation_id: affiliation?.value,
                class: classs?.label.split(' ')[1],
                status: status?.value
            }
            await props.editStudent(obj);
            setEdit(true);
            setFirstName(firstName);
            setLastName(lastName);
            setStudentId(student_Id);
            setSection(section);
            setPaid(paid);
            setStartDate(startDate);
            setEndDate(endDate);
            setTid(tId);
            setAmount(amount);
        }
    }

    yearsStart = generate_year_range(1970, 2022);

    return (
        <div className='view-form'>
            {
                props.type === 'student' ?
                    <div className='add-student-form'>
                        <div className='student-header' style={{ width: edit ? '100%' : '55%' }}>
                            {!edit ? <img onClick={props.goToUsers} src='/images/back1.svg' alt='' /> : <img onClick={edit ? props.goToUsers : getViewMode} src='/images/back1.svg' alt='' />}
                            <p>{edit ? 'View Student' : 'Edit Student'}</p>
                            {
                                edit ?
                                    <h3 className='cursor-pointer' onClick={() => setEdit(false)}><img src='/images/edit-layout-icon.svg' alt='' />&nbsp; Edit</h3>
                                    : null
                            }
                        </div>
                        <div className='form-inputs'>
                            <div className='row mt-1'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Student First Name*
                                            {edit ? null : <aside className='float-right'>{chars_left}</aside>}
                                        </label>
                                        <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} disabled={edit} name='firstname' value={firstName} onChange={(e) => onChangeStudent(e)} />
                                        <span style={{ color: 'red', fontSize: 14 }}>{firstNameError}</span>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Student Last Name*
                                            {edit ? null : <aside className='float-right'>{chars_left1}</aside>}
                                        </label>
                                        <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} disabled={edit} maxLength='50' name='lastname' value={lastName} onChange={(e) => onChangeStudent(e)} />
                                        <span style={{ color: 'red', fontSize: 14 }}>{lastNameError}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Student Id*
                                            {edit ? null : <aside className='float-right'>{chars_left2}</aside>}
                                        </label>
                                        <input className='form-control mt-2' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} disabled={edit} maxLength='50' name='student_Id' value={student_Id} onChange={(e) => onChangeStudent(e)} />
                                        <span style={{ color: 'red', fontSize: 14 }}>{studentIdError}</span>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Student Email*
                                            {edit ? null : <aside className='float-right'>{chars_left3}</aside>}
                                        </label>
                                        <input className='form-control mt-2' disabled={true} maxLength='50' name='email' value={email} style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} onChange={(e) => onChangeStudent(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Affiliation*
                                        </label>
                                        {edit ?
                                            <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} value={affiliation?.label} disabled readOnly />
                                            :
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={props.affliations?.map(el => [{ value: el._id, label: el.name }][0])}
                                                onChange={(e) => selectAffiliation(e)}
                                                value={affiliation}
                                                placeholder='Select Affiliations'
                                            />}
                                        <span>{affiliationError}</span>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Class*
                                        </label>
                                        {
                                            edit ?
                                                <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} value={classs?.label} disabled readOnly />
                                                : <Select
                                                    isDisabled={!affiliation}
                                                    components={{ DropdownIndicator }}
                                                    placeholder=''
                                                    onChange={selectClass}
                                                    options={props.classes?.filter(el => el.sections?.length)?.map(el => [{ ...el, label: el.label, value: el._id }][0])}
                                                    value={classs}
                                                />
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className='row mt-1'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Section*
                                        </label>
                                        {
                                            edit ?
                                                <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} value={section?.label} disabled readOnly />
                                                : <Select
                                                    isDisabled={!classs}
                                                    components={{ DropdownIndicator }}
                                                    placeholder=''
                                                    onChange={getSection}
                                                    options={props.sections}
                                                    value={section}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Status*
                                        </label>
                                        {
                                            edit ?
                                                <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} disabled value={status?.label} readOnly />
                                                : <Select
                                                    components={{ DropdownIndicator }}
                                                    placeholder=''
                                                    onChange={getStatus}
                                                    options={props.statusOption}
                                                    value={status}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                localStorage.getItem('role').includes('master') ?
                                    <div className='payment-history'>
                                        <h1>Subscription Details</h1>
                                        <div className='row mt-1'>
                                            <div className='col-lg-5'>
                                                <div className='form-group mt-2'>
                                                    <label>Payment Status*
                                                    </label>
                                                    {
                                                        edit ?
                                                            <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} disabled value={paid ? 'Paid' : 'Unpaid'} readOnly />
                                                            :
                                                            <Select
                                                                components={{ DropdownIndicator }}
                                                                placeholder=''
                                                                onChange={getPayStatus}
                                                                options={payStatusOption}
                                                                value={payStatus}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    : null
                            }
                            {
                                !edit ?
                                    <Fragment>
                                        <hr style={{ borderTop: '1px solid #DDDDE2' }} />
                                        <div className='save-btn'>
                                            <button onClick={editStudent}>Save</button>
                                            <button onClick={props.goToUsers}>Cancel</button>
                                        </div>
                                    </Fragment>
                                    : null
                            }

                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}




