import { getUniqueObjects } from "../../utils/miscelleneous";
import {
    RESET_CLASSROOMS, GET_OTHER_SUBJECT, GET_OTHER_SKILLS, GET_SKILL_DETAILS, GET_SKILL_PODS, GET_COURSES, RESET_TOPIC_LOADING, GET_POD_RESOURCES, RESET_CONTENT_LOADING, GET_RESOURCE_FROM_CONTENT,
    Skill_Analytic_Capture, LIKE_CONTENT_RESOURCE, GET_CONTENT_NOTE, SAVE_CONTENT_NOTE, BACK_TO_TOPIC,
    GET_BOOKMARKED_SKILL, TOGGLE_BOOKMARK, BOOKMARK_DONE, SEARCH_SKILLS,
    GET_TEST_QUESTIONS, GET_TEST_QUESTION, SELECT_ANSWER, TEST_SUBMIT, CLOSE_TEST, GET_CERT_DATA
} from "../Types/types";




let intialState = {
    isSubjectLoading: true,
    isBubbleLoading: true,
    isLoading: true,
    isShow: false,
    otherSubjects: [],
    otherSkills: [],
    note: null,

    qIndex: 0,
    filterPrevResources: [],
    resourceIndex: [],
    isQuizComplete: false,
    remainQuestion: [],
    isSkillLoading: true,
    bookmarked: false,
    isTest: false,
    courseList: []
}


export default function contentLibraryReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_CLASSROOMS:
            stateCopy.isSkillLoading = true;
            stateCopy.otherSubjects = [];
            stateCopy.isSubjectLoading = true;
            return stateCopy;
        case GET_OTHER_SUBJECT:
            stateCopy.otherSubjects = action.payload?.length ? [...stateCopy.otherSubjects, action.payload] : stateCopy.otherSubjects;
            stateCopy.isSkillLoading = true;
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case GET_OTHER_SKILLS:
            stateCopy.otherSkills = action.payload.map(item => ({ ...item, total: item.articles + item.videos })).filter(el => el.total !== 0);
            stateCopy.otherSkillsCopy = action.payload.map(item => ({ ...item, total: item.articles + item.videos })).filter(el => el.total !== 0);
            stateCopy.isSkillLoading = false;
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case SEARCH_SKILLS:
            if (action.payload === '') {
                stateCopy.otherSkills = stateCopy.otherSkillsCopy;
            } else {
                const filter = stateCopy.otherSkillsCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.otherSkills = filter;
            }
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case GET_SKILL_DETAILS:
            stateCopy.skillDetails = action.payload;
            return stateCopy;
        case GET_SKILL_PODS:
            stateCopy.skillPods = action.payload;
            stateCopy.isBubbleLoading = false;
            return stateCopy;
        case GET_COURSES:
            stateCopy.courseList = action.payload?.filter(el => el.language === 'English');
            stateCopy.isBubbleLoading = false;
            return stateCopy;
        case RESET_TOPIC_LOADING:
            stateCopy.resources = [];
            stateCopy.topicLoading = true;
            return stateCopy;
        case GET_POD_RESOURCES:
            var sortArr = action.payload.data.resources.sort((a, b) => { return a.index - b.index });
            var uniquesTopics = getUniqueObjects(sortArr.map((el) => ({ subtopic: el.subtopic, subtopic_index: el.subtopic_index, subtopic_id: el.subtopic_id, complete: false })), 'subtopic').sort((a, b) => (a.subtopic_index - b.subtopic_index));
            const content = uniquesTopics.map(data => { return action.payload.data.resources.sort((a, b) => (a.index - b.index)).filter(el => { return data.subtopic === el.subtopic }) });
            stateCopy.topics = getComplete(uniquesTopics, content);
            stateCopy.resourceIndex = [].concat.apply([], content);
            stateCopy.resourcesCopy = stateCopy.resources;
            stateCopy.resources = content;
            stateCopy.skillPods.forEach(el => {
                if (el._id === action.payload.id) {
                    el.subtopics = stateCopy.resources;
                } else {
                    el.subtopics = []
                }
            })
            stateCopy.isShow = false;
            stateCopy.topicLoading = false;
            return stateCopy;

        case GET_RESOURCE_FROM_CONTENT:
            stateCopy.individualResource = action.payload;
            // stateCopy.getIndividualResource = action.payload;
            // var found1 = stateCopy.filterPrevResources.find(o => o._id === action.payload._id);
            // if (found1 === undefined) {
            //     stateCopy.filterPrevResources = [...stateCopy.filterPrevResources, stateCopy.getIndividualResource];
            // }
            stateCopy.isShow = true;
            // if (action.payload.type === 'Quiz') {
            //     stateCopy.questions = action.payload.questions;
            //     stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            // }
            stateCopy.showQuiz = false;
            stateCopy.isLoadingResource = false;
            return stateCopy;
        case Skill_Analytic_Capture:
            stateCopy.resources.forEach(arr => arr.forEach(item => item._id === action.payload.resource_id && (item.viewed = action.payload.viewed)));
            return stateCopy;
        case LIKE_CONTENT_RESOURCE:
            stateCopy.individualResource.like = action.payload.like;
            return stateCopy;
        case GET_CONTENT_NOTE:
            if (action.payload.data !== null) {
                stateCopy.note = action.payload.data.text;
            }
            else {
                stateCopy.note = null;
            }
            return stateCopy;
        case SAVE_CONTENT_NOTE:
            stateCopy.note = action.payload.text;
            return stateCopy;
        case RESET_CONTENT_LOADING:
            stateCopy.individualResource = {};
            stateCopy.questions = [];
            stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            stateCopy.showQuiz = false;
            stateCopy.isLoadingResource = true;
            return stateCopy;
        case BACK_TO_TOPIC:
            stateCopy.isShow = false;
            return stateCopy;
        case GET_BOOKMARKED_SKILL:
            stateCopy.bookmarkedSkill = action.payload.skills.map(item => ({ ...item, total: item.articles + item.videos })).filter(el => el.total !== 0);
            stateCopy.bookmarkedSubject = action.payload.subjects;
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case TOGGLE_BOOKMARK:
            if (action.payload.bookmark) {
                action.payload.aff_id &&
                    stateCopy.otherSubjects.map((el, i) => {
                        if (el[0].aff_id === action.payload.aff_id) {
                            el.forEach(it => {
                                if (it._id === action.payload.id) {
                                    it.bookmarked = false
                                }
                            })
                        }
                    });
                stateCopy.otherSkills.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.bookmarked = false;
                    }
                })
            }
            else {
                action.payload.aff_id &&
                    stateCopy.otherSubjects.map((el, i) => {
                        if (el[0].aff_id === action.payload.aff_id) {
                            el.forEach(it => {
                                if (it._id === action.payload.id) {
                                    it.bookmarked = true
                                }
                            })
                        }
                    });
                stateCopy.otherSkills.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.bookmarked = true;
                    }
                })
            }
            return stateCopy;
        case BOOKMARK_DONE:
            if (action.payload) {
                stateCopy.bookmarked = false;
            }
            else {
                stateCopy.bookmarked = true;
            }
            return stateCopy;
        case GET_TEST_QUESTIONS:
            stateCopy.isTest = true;
            stateCopy.individualResource = { ...action.payload, time: action.payload.time / 60 };
            stateCopy.questions = action.payload.data.map(el => ({ ...el, answer: null }));
            stateCopy.question = null;
            return stateCopy;
        case GET_TEST_QUESTION:
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            return stateCopy;
        case SELECT_ANSWER:
            stateCopy.question.answer = action.payload.aid;
            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    options: answer.options,
                    answer: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case TEST_SUBMIT:
            stateCopy.isQuizComplete = false;
            stateCopy.score = action.payload.score;
            const questions1 = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    options: answer.options,
                    answer: null
                }
            })
            stateCopy.questions = questions1;
            return stateCopy;
        case CLOSE_TEST:
            stateCopy.isTest = false
            return stateCopy;
        case GET_CERT_DATA:
            stateCopy.certificateData = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}

var temp

const getComplete = (topics, content) => {
    for (var i = 0; i < topics.length; i++) {
        var contentIndex = content[i];
        for (var j = 0; j < contentIndex.length; j++) {
            temp = true;
            if (topics[i].topic === contentIndex[j].topic) {
                if (!contentIndex[j].viewed) {
                    temp = false;
                    break;
                }
            }
        }
        topics[i].complete = temp
    }
    return topics
}


