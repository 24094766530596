import React from 'react';
import Modal from 'react-bootstrap/Modal';


export default function ConfirmModal({ isConfirm, confirmDelete, closeModal, type }) {
    return (
        <div>
            {/* ########## Complete delete modal ############### */}
            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4 text-center">
                    <h1 className='mb-2'>{`Are you sure you want to delete this ${type}?`}</h1>
                    <div className="save-btn mt-4">
                        <button onClick={confirmDelete}>Yes</button> &nbsp;&nbsp;
                        <button onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
