import React, { useEffect, useState } from 'react';
import './mentorConnect';
import Select, { components } from 'react-select';
import Snackbar from '@mui/material/Snackbar';
import { getMentorList, getFacultySubjects, addQuery, refreshAttachment } from '../../Redux/ActionCreators/mentorConnectAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function AddQuery(props) {
    const [addLink, setAddLink] = useState(false);
    const [subject, setSubject] = useState('');
    const [search, setSearch] = useState('');
    const [faculty, setFaculty] = useState('');
    const [facultyId, setFacultyId] = useState('');
    const [facultyError, setFacultyError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [category, setCategory] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [question, setQuestion] = useState('');
    const [questionError, setQuestionError] = useState('');

    const [successEnable, setSuccessEnable] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { catagories, selectFacultyOptions, facultySubjects, attachment, query } = useSelector(state => state.mentorConnect);

    useEffect(() => {
        const firstRender = async () => {
            const { details, empty } = location.state
            if (!empty) {
                if (details !== undefined) {
                    setFaculty({ value: details._id, label: details.name, profile_image: details?.profile_image, color: details?.color, initial: details?.initial });
                    setFacultyId(details?._id);
                }
                await dispatch(getFacultySubjects(details._id));
            } else {
                setFaculty('');
                // await dispatch(getMentorList(subject, search));
            }
        }

        firstRender();

    }, [])


    const goBack = () => {
        navigate(-1);
        // props.refreshAttachment();
    }

    const getCatagory = (e) => {
        setCategory(e)
        setCategoryError('');
    }

    const selectFaculty = async (e) => {
        setFaculty(e);
        setFacultyId(e.value);
        setSubject('');
        setFacultyError('')
        await dispatch(getFacultySubjects(e.value));
    }

    const selectSubject = (e) => {
        setSubject(e);
        setSubjectError('')
    }

    const changeQuestion = (val) => {
        setQuestion(val);
        setQuestionError('');
    }

    const saveQuery = async () => {
        if (category === '' || null) {
            setCategoryError('Please select category')
        }
        else if (faculty === '' || null) {
            setFacultyError('Please select faculty')
        }
        else if (subject === '' || null) {
            setSubjectError('Please select subject')
        }
        else if (question.trim('') === '' || null) {
            setQuestionError(`Question can't empty`)
        }
        else {
            let obj = {
                category: category?.value,
                faculty_id: facultyId,
                subject_id: subject?.value,
                attachments: [],
                question: question
            }
            const res = await dispatch(addQuery(obj));
            if (res?.type === 'ADD_QUERY') {
                goBack();
            }
        }
    }


    const openSnackbar = () => {
        setSuccessEnable(true);
        setSuccessMessageText('Not allowed more than one file');
    }

    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setSuccessMessageText('')
    }

    return (
        <div className='add-query-form'>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successEnable}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />
            <div className='query-form'>
                <div className='query-header'>
                    <img src='/images/back1.svg' onClick={goBack} alt='' />
                    <p>New Query</p>
                </div>
                <p className='mandatory'>All fields marked * are mandatory</p>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='form-group mt-2'>
                            <label className='mb-2'>Question Category*
                            </label>
                            <Select
                                components={{ DropdownIndicator }}
                                options={catagories}
                                placeholder='Select Question Category'
                                value={category}
                                onChange={getCatagory}
                            />
                            <small>{categoryError}</small>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group mt-2'>
                            <label className='mb-2'>Faculty*
                            </label>
                            <Select
                                components={{ DropdownIndicator }}
                                options={selectFacultyOptions}
                                placeholder=''
                                value={faculty}
                                onChange={selectFaculty}
                                isDisabled={location.state.empty ? false : true}
                                formatOptionLabel={el => (
                                    <div className="faculty-option d-flex align-items-center">
                                        {
                                            (el.profile_image && el.profile_image !== null) ?
                                                <img src={el.profile_image} alt="faculty" width='20px' height='20px' className='rounded-circle' />
                                                : <div className='intial' style={{ background: el.color }}>{el.initial}
                                                </div>
                                        }
                                        &nbsp;&nbsp;<span>{el.label}</span>
                                    </div>
                                )}
                            />
                            <small>{facultyError}</small>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group mt-2'>
                            <label className='mb-2'>Subject*
                            </label>
                            <Select
                                components={{ DropdownIndicator }}
                                options={facultySubjects?.map(el => [{ label: el.name, value: el._id }][0])}
                                placeholder='Select Subject'
                                value={subject}
                                onChange={selectSubject}
                            />
                            <small>{subjectError}</small>
                        </div>
                    </div>
                </div>
                <div className='form-group mt-3'>
                    <label className='mb-2'>Question Title*
                        {/* <aside className='float-right'>300</aside> */}
                    </label>
                    <textarea
                        className='form-control'
                        rows='4'
                        cols='4'
                        // maxLength='300'
                        onChange={(e) => changeQuestion(e.target.value)}
                    />
                    <small>{questionError}</small>
                </div>
                <div className='mt-4'>
                    {/* <ul style={{ listStyle: 'none' }}>
                        <li className='position-relative mt-1 d-flex align-items-center' >
                            <img onClick={() => props.attachment && props.attachment.length >= 1 ? openSnackbar() : setAddLink(true)} src='/images/attachment.png' alt='' /> &nbsp;<p onClick={() => props.attachment && props.attachment.length >= 1 ? openSnackbar() : setAddLink(true)} style={{ color: '#F5821F', fontWeight: 'bold', fontSize: 14, cursor: 'pointer' }}>Attach a file</p>(Optional)
                        </li>
                    </ul> */}
                    <ul className="resource-holder">
                        {
                            props.attachment !== undefined && props.attachment.map((el, index) => {
                                var Extension = el
                                    .substring(el.lastIndexOf(".") + 1)
                                    .toLowerCase();
                                return (
                                    <li className="resource border-bottom" >
                                        <div className="col-md-1 col-1 pl-0 resource-image" >
                                            <img src={Extension === 'pdf' ? '/images/file.png' : (Extension === 'png' || Extension === 'jpg' || Extension === 'jpeg') ? '/images/png-placeholder.png' : '/images/default.png'} alt='' />
                                        </div>
                                        <div className="col-md-9 col-9 recent-title">
                                            <div>
                                                {/* <h1>{el.title}</h1> */}
                                                <p className='d-flex justify-content-start align-items-center mt-1 elipsis'>
                                                    {el}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-1 d-flex justify-content-end align-items-center cursor-pointer" style={{ marginLeft: window.innerWidth > 767 ? '' : 40 }} onClick={() => window.open(el, '_blank')}>
                                            <img src='/images/view_grey.svg' alt='' />
                                        </div>
                                    </li>
                                )
                            })

                        }
                    </ul>
                </div>
                <button className='submit-btn mt-3' onClick={saveQuery}>Submit</button>
            </div>
            {/* ######################################### Add Link modal ########################################### */}
            {/* <UploadFile
                addLink={addLink}
                handleModal={() => setAddLink(false)}
                uploadFile={uploadFile}
            /> */}
        </div>
    )
}

export default (AddQuery);