/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import './achievers.scss';
import Modal from 'react-bootstrap/Modal';
import EllipsisText from "react-ellipsis-text";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { convertDate, reverseArr } from '../../utils/miscelleneous'
import Drawer from '@mui/material/Drawer';
import { getSchoolAchiever, getSectionAchiever, getClassAchiever, apperciationSent, getMonthlyGraph, getSubjectGraph } from '../../Redux/ActionCreators/achieverAction';
import { CardLoader, BigCardLoader, TopicLoader } from '../../Loader/loader';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from '../../Redux/ActionCreators/profileAction';


function Achievers() {
    const [weekly, setWeekly] = useState(true)
    const [details, setDetails] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [activeTab, setActiveTab] = useState('overall');
    const [activeBtn, setActiveBtn] = useState('weekly');
    const [cardLoader, setCardloader] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();
    const { schoolAchievers, sectionAchievers, classAchievers, months, contentCounts, consumed, subject, isListLoading, isCardLoading, complete } = useSelector(state => state.achiever);
    const { profileDetails } = useSelector(state => state.profile);
    const arr = [{ id: 0, title: 'school' }, { id: 1, title: 'class' }, { id: 2, title: 'section' },];

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const getData = async () => {
            await dispatch(getProfileDetails());
            if (activeTab === 'section') {
                const res = await dispatch(getSectionAchiever(weekly, 'section'));
                if (res?.type === 'GET_SECTION_ACHIEVERS') {
                    const sectionAchiver = res?.payload[0]
                    setDetails(res?.payload[0])
                    if (sectionAchiver) {
                        await dispatch(getMonthlyGraph(sectionAchiver?.user_id));
                        await dispatch(getSubjectGraph(sectionAchiver?.user_id));
                    }
                }
                await setCardloader(false)
            }
            else if (activeTab === 'class') {
                const res = await dispatch(getClassAchiever(weekly, 'class'));
                if (res?.type === 'GET_CLASS_ACHIEVERS') {
                    const classAchiever = res?.payload[0]
                    setDetails(res?.payload[0])
                    console.log(classAchievers)
                    if (classAchiever !== null) {
                        await dispatch(getMonthlyGraph(classAchiever?.user_id));
                        await dispatch(getSubjectGraph(classAchiever?.user_id));
                    }
                }
                setCardloader(false);
            } else if (activeTab === 'school') {
                const res = await dispatch(getSchoolAchiever(weekly, 'school'));
                if (res?.type === 'GET_SCHOOL_ACHIEVERS') {
                    const schoolAchiver = res?.payload[0]
                    setDetails(res?.payload[0])
                    if (schoolAchiver) {
                        await dispatch(getMonthlyGraph(schoolAchiver?.user_id));
                        await dispatch(getSubjectGraph(schoolAchiver?.user_id));
                    }
                }
                await setCardloader(false)
            }
            else {
                dispatch(getSchoolAchiever(weekly, 'school'));
                dispatch(getClassAchiever(weekly, 'class'));
                dispatch(getSectionAchiever(weekly, 'section'));
            }
        }

        getData();
    }, [activeTab, activeBtn, weekly])


    const onTabChange = (e) => {
        setActiveTab(e)
        setCardloader(true);

    }



    const onBtnChange = async (e) => {
        setActiveBtn(e);
        setWeekly(e === 'weekly' ? true : false);
    }

    const sendApperciation = async (weekly, type, id, name) => {
        console.log(type)
        let obj = {
            weekly,
            type,
            to: id
        }
        await dispatch(apperciationSent(obj, name));

    }

    const getIntial = (name) => {
        let f = name.split(' ')[0]
        let l = name.split(' ')[1]
        return f.split('')[0] + l.split('')[0]
    }

    const openDetails = (el) => {
        setIsOpen(true);
        setDetails(el);
        dispatch(getMonthlyGraph(el.user_id));
        dispatch(getSubjectGraph(el.user_id))
    }


    const snackClosed = () => {
        setSuccessMessage(false);
        setSuccessMessageText('');
    }

    const monthlyWise = {
        chart: {
            type: 'column',
            height: 190
        },
        legend: {
            enabled: false
        },
        tooltips: {
            enabled: false
        },
        title: {
            text: ''
        },
        colors: ['#5EB5E7', '#00B24B', '#ECC30B', '#FF5A5F'],
        xAxis: {
            categories: months !== undefined && months,
            type: 'Months'
        },
        yAxis: {
            type: 'Scores',
            min: 0,
            max: 100,
            tickInterval: 20,
            labels: {
                format: '{value}%'
            },
            title: {
                text: ''
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                animation: false,
                enableMouseTracking: false,
                hover: {
                    enabled: false
                }

            }
        },
        tooltip: {
            enabled: false
        },
        series: consumed !== undefined && consumed
    }

    const subjectWiseGraph = {
        chart: {
            type: 'bar',
            height: 300
        },
        title: {
            text: ''
        },
        colors: ['#FF5A5F', '#ECC30B', '#00B24B', '#5EB5E7'],
        xAxis: {
            categories: subject !== undefined && subject,
            labels: {
                align: 'left',
                reserveSpace: true,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    color: '#696873',
                    align: 'left'
                }
            }
        },
        yAxis: {
            visible: true,
            title: false,
            labels: {
                enabled: false
            },
            gridLineColor: 'transparent',
            stackLabels: {
                enabled: true,
                formatter: function () {
                    var val = this.total;
                    if (val > 0) {
                        return val;
                    }
                    return '';
                },
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || '#166B9C'
                }
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0.01
            },
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },

            series: {
                animation: false,
                enableMouseTracking: false,
                stacking: 'normal',
                pointWidth: 15,
                dataLabels: {
                    enabled: false,
                    crop: false,
                    overflow: false
                },
                hover: {
                    enabled: false
                }

            },

        },
        series: reverseArr(contentCounts !== undefined && contentCounts)
    }

    const match = window.matchMedia("(min-width: 768px) and (max-width: 1068px)");
    return (
        <div className='achievers-holder'>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMessage}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />

            <Modal
                show={isReady}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"ready-modal"}
            >
                <div className='p-4 text-center'>
                    <h1>You can achieve the praise and recognition for all your efforts. <br /> Data for the Achievers section will be available after 30 days.</h1>
                    <button onClick={() => setIsReady(false)}>Ok</button>
                </div>
            </Modal>
            <div>
                <h3>Achievers</h3>
                <p className='acheiver-text'>Achievers are ranked by engagement scores. Select card to view student dashboard</p>
                {
                    (!classAchievers?.length && !sectionAchievers?.length) ? null :
                        <div className='d-flex justify-content-between mt-1 flex-wrap' style={{ width: window.innerWidth > 767 ? '485px' : '' }}>
                            <p className='acheiver-text' style={{ fontWeight: 'bold' }}> Data Updated on : {convertDate(sectionAchievers[0]?.createdAt)}</p>
                            <p className='acheiver-text' style={{ fontWeight: 'bold' }} >Next update : Next monday</p>
                        </div>

                }
            </div>
            {
                (!classAchievers?.length && !sectionAchievers?.length && !schoolAchievers?.length) ?
                    <div className="empty-list mt-2" >
                        <img src="/images/empty.png" alt="" />
                        <p>Achievers data is not available right now. This data gets updated every week.
                            <br />Please come back later!</p>

                    </div>
                    :
                    <Fragment>

                        <div className='achievers-tab'>
                            <ul>
                                <li className={activeTab === 'overall' ? "active-tab" : null}
                                    onClick={() => onTabChange('overall')}>Overall View</li>
                                <li className={activeTab === 'school' ? "active-tab" : null}
                                    onClick={() => onTabChange('school')}>My School</li>
                                <li className={activeTab === 'class' ? "active-tab" : null}
                                    onClick={() => onTabChange('class')}>My Class</li>
                                <li className={activeTab === 'section' ? "active-tab" : null}
                                    onClick={() => onTabChange('section')}>My Section</li>

                            </ul>
                        </div>
                        <div className='achievers-btns'>
                            {
                                profileDetails !== undefined && window.innerWidth > 767 ?
                                    (activeTab === 'class' ?
                                        <h4>Class {profileDetails?.class}</h4> :
                                        activeTab === 'section' ?
                                            <h4>Section {profileDetails?.section?.name}</h4> :

                                            null
                                    )
                                    : null
                            }
                            <button className={activeBtn === 'weekly' ? "active-btn" : null} onClick={() => onBtnChange('weekly')}>Last Week</button> &nbsp; &nbsp;
                            <button className={activeBtn === 'all' ? "active-btn" : null} onClick={() => onBtnChange('all')}>All Time</button>
                        </div>
                        {
                            activeTab === 'overall' ?
                                <div className={window.innerWidth > 767 ? 'achiever-overall row' : 'achiever-overall row flex-nowrap overflow-auto'}>
                                    {(complete || isCardLoading) ?
                                        <BigCardLoader /> :
                                        arr.map((el, index) => {
                                            return (
                                                <div className='col-md-4 col-12 d-flex flex-column card-deck' style={{ marginRight: window.innerWidth > 767 ? 0 : -28 }}>
                                                    <div class="card " style={{ width: '100%', background: '#166B9C' }}>
                                                        <div class="card-body pt-2">
                                                            {
                                                                <h4 class="card-title">{el.title === 'school' ? 'My School' : el.title === 'section' ? 'My Section' : el.title === 'class' ? 'My Class' : null}<br /> </h4>
                                                            }
                                                            <div className='container text-center'>
                                                                {
                                                                    index === 0 ?
                                                                        <div className='row align-items-end justify-content-center'>
                                                                            {
                                                                                schoolAchievers !== undefined && schoolAchievers.slice(0, 3).map((section, i) => {
                                                                                    return (
                                                                                        <div className='col-md-4 col-4 d-flex justify-content-center align-items-center flex-column' style={{ order: i === 0 ? 2 : i === 1 ? 1 : 3 }}>
                                                                                            <div className='achievers-initial' style={{ background: section.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                {section.profile_image !== null ? (
                                                                                                    <img class=" img-fluid" src={section.profile_image} alt="card" />
                                                                                                ) : section.initial !== null ? (
                                                                                                    <div style={{ color: '#fff' }} >{section.initial}</div>
                                                                                                ) : (
                                                                                                    getIntial(section.name)
                                                                                                )}

                                                                                            </div>
                                                                                            {section.rank === 1 ?
                                                                                                <div className='under' >
                                                                                                    <img src='/images/highlighted.png' alt='' />
                                                                                                </div> : null
                                                                                            }
                                                                                            <div className='achievers-name'>
                                                                                                <EllipsisText text={section.name} length={18} />
                                                                                            </div>
                                                                                            <div className='achievers-rank' style={{ height: section.rank === 1 ? 160 : 127 }}>
                                                                                                <div>
                                                                                                    {
                                                                                                        section.rank === 1 ?
                                                                                                            <img src='/images/medal1.svg' alt='' />
                                                                                                            : section.rank === 2 ?
                                                                                                                <img src='/images/medal2.svg' alt='' /> :
                                                                                                                <img src='/images/medal3.svg' alt='' />
                                                                                                    }
                                                                                                </div>
                                                                                                <p>
                                                                                                    {section.score}
                                                                                                </p>

                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div> :
                                                                        index === 1 ?
                                                                            <div className='row align-items-end justify-content-center'>
                                                                                {
                                                                                    classAchievers !== undefined && classAchievers.slice(0, 3).map((section, i) => {
                                                                                        return (
                                                                                            <div className='col-md-4 col-4 d-flex justify-content-center align-items-center flex-column' style={{ order: i === 0 ? 2 : i === 1 ? 1 : 3 }}>
                                                                                                <div className='achievers-initial' style={{ background: section.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                    {section.profile_image !== null ? (
                                                                                                        <img class=" img-fluid" src={section.profile_image} alt="card" />
                                                                                                    ) : section.initial !== null ? (
                                                                                                        <div style={{ color: '#fff' }}>{section.initial}</div>
                                                                                                    ) : (
                                                                                                        getIntial(section.name)
                                                                                                    )}
                                                                                                </div>
                                                                                                {section.rank === 1 ?
                                                                                                    <div className='under'>
                                                                                                        <img src='/images/highlighted.png' alt='' />
                                                                                                    </div> : null
                                                                                                }
                                                                                                <div className='achievers-name'>
                                                                                                    <EllipsisText text={section.name} length={18} />
                                                                                                </div>
                                                                                                <div className='achievers-rank' style={{ height: section.rank === 1 ? 160 : 127 }}>
                                                                                                    <div>
                                                                                                        {
                                                                                                            section.rank === 1 ?
                                                                                                                <img src='/images/medal1.svg' alt='' />
                                                                                                                : section.rank === 2 ?
                                                                                                                    <img src='/images/medal2.svg' alt='' /> :
                                                                                                                    <img src='/images/medal3.svg' alt='' />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <p>
                                                                                                        {section.score}
                                                                                                    </p>

                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>

                                                                            :
                                                                            index === 2 ?
                                                                                <div className='row align-items-end justify-content-center'>
                                                                                    {
                                                                                        sectionAchievers !== undefined && sectionAchievers.slice(0, 3).map((section, i) => {
                                                                                            return (
                                                                                                <div className='col-md-4 col-4 d-flex justify-content-center align-items-center flex-column' style={{ order: i === 0 ? 2 : i === 1 ? 1 : 3 }}>
                                                                                                    <div className='achievers-initial' style={{ background: section.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                        {section.profile_image !== null ? (
                                                                                                            <img class=" img-fluid" src={section.profile_image} alt="card" />
                                                                                                        ) : section.initial !== null ? (
                                                                                                            <div style={{ color: '#fff' }} >{section.initial}</div>
                                                                                                        ) : (
                                                                                                            getIntial(section.name)
                                                                                                        )}

                                                                                                    </div>
                                                                                                    {section.rank === 1 ?
                                                                                                        <div className='under' >
                                                                                                            <img src='/images/highlighted.png' alt='' />
                                                                                                        </div> : null
                                                                                                    }
                                                                                                    <div className='achievers-name'>
                                                                                                        <EllipsisText text={section.name} length={18} />
                                                                                                    </div>
                                                                                                    <div className='achievers-rank' style={{ height: section.rank === 1 ? 160 : 127 }}>
                                                                                                        <div>
                                                                                                            {
                                                                                                                section.rank === 1 ?
                                                                                                                    <img src='/images/medal1.svg' alt='' />
                                                                                                                    : section.rank === 2 ?
                                                                                                                        <img src='/images/medal2.svg' alt='' /> :
                                                                                                                        <img src='/images/medal3.svg' alt='' />
                                                                                                            }
                                                                                                        </div>
                                                                                                        <p>
                                                                                                            {section.score}
                                                                                                        </p>

                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div> :
                                                                                null
                                                                }

                                                                <hr style={{ borderTop: '1px solid #008080', margin: '0.5rem -5px 1rem -5px' }} />
                                                            </div>
                                                            <div className='you'>
                                                                <p>You</p>
                                                            </div>
                                                            <div className='achievers-score'>
                                                                {profileDetails !== undefined && <div className='rank'>
                                                                    <h4>{
                                                                        activeBtn === 'weekly' ?
                                                                            (el.title === 'school' ?
                                                                                profileDetails.school_rank_week :
                                                                                el.title === 'section' ?
                                                                                    profileDetails.section_rank_week :
                                                                                    el.title === 'class' ?
                                                                                        profileDetails.class_rank_week :
                                                                                        profileDetails.center_rank) :
                                                                            (el.title === 'school' ?
                                                                                profileDetails.school_rank_all :
                                                                                el.title === 'section' ?
                                                                                    profileDetails.section_rank_all :
                                                                                    el.title === 'class' ?
                                                                                        profileDetails.class_rank_all :
                                                                                        profileDetails.center_rank)
                                                                    }
                                                                    </h4>
                                                                    <p>Rank</p>
                                                                </div>}&nbsp; &nbsp;
                                                                {profileDetails !== undefined && <div className='rank'>
                                                                    <h4>{profileDetails?.score}</h4>
                                                                    <p>Score</p>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='text-center cursor-pointer' onClick={() => onTabChange(el.title)}>View Details</p>
                                                </div>
                                            )
                                        })
                                    }

                                </div> :
                                activeTab === 'section' || activeTab === 'class' || activeTab === 'school' ?
                                    <Fragment>
                                        {
                                            window.innerWidth < 767 ?
                                                (activeTab === 'section' ?
                                                    <div className='section-details'>
                                                        <h4>My Section</h4>
                                                        {profileDetails !== undefined && <p>{profileDetails?.class} (Section {profileDetails?.section?.name})</p>}
                                                    </div> :
                                                    activeTab === 'class' ?
                                                        <div className='section-details'>
                                                            <h4>My Class</h4>
                                                        </div> :
                                                        activeTab === 'school' ?
                                                            <div className='section-details'>
                                                                <h4>My School</h4>
                                                            </div>
                                                            : null
                                                )
                                                : null
                                        }
                                        {
                                            cardLoader ?
                                                <CardLoader /> :
                                                <div className='achievers-section'>

                                                    <div className='achievers-section-card'>
                                                        <div className={window.innerWidth > 767 ? 'row justify-content-start align-items-end ml-0' : 'row justify-content-start align-items-end flex-nowrap overflow-auto overflowY-hidden ml-0'}>
                                                            {
                                                                isCardLoading ?
                                                                    <CardLoader /> :
                                                                    (activeTab === 'section' ?
                                                                        sectionAchievers !== undefined && sectionAchievers.slice(0, 3).map((el, index) => {
                                                                            return (
                                                                                <div class="card" style={{ height: window.innerWidth > 767 && index === 1 ? 201 : window.innerWidth > 767 && index === 2 ? 192 : 212 }} >
                                                                                    <a className='card-overlay' onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        openDetails(el)
                                                                                    }}>

                                                                                    </a>
                                                                                    <div class="card-body text-center">
                                                                                        <p>
                                                                                            <div className='initial' style={{ background: el.profile_image !== null ? '' : '#F5821F' }}>

                                                                                                {el.profile_image ? (
                                                                                                    <img class=" img-fluid" src={el.profile_image} alt="card" />
                                                                                                ) : el.initial !== null ? (
                                                                                                    <div style={{ color: '#fff' }}>{el.initial}</div>
                                                                                                ) : (
                                                                                                    getIntial(el.name)
                                                                                                )}
                                                                                            </div>
                                                                                        </p>
                                                                                        <div className='medal1'>
                                                                                            {
                                                                                                index === 0 ?
                                                                                                    <img src='/images/medal11.svg' alt='' /> : index === 1 ?
                                                                                                        <img src='/images/medal22.svg' alt='' /> : <img src='/images/medal33.svg' alt='' />
                                                                                            }
                                                                                        </div>
                                                                                        <div className='under'>
                                                                                            <img src='/images/highlighted1.png' alt='' />
                                                                                        </div>
                                                                                        <h4 className="card-title"> {el.name && <EllipsisText text={el.name} length={15} />} <br /><p style={{ visibility: 'hidden' }}>MBA Finance</p></h4>
                                                                                        <div className='d-flex align-items-baseline justify-content-start' style={{ marginTop: index === 0 ? 18 : index === 2 ? -8 : 0, }}>
                                                                                            <h5 style={{ marginTop: index === 0 ? -23 : index === 2 ? -20 : -20, }}>{el.score}</h5> &nbsp; &nbsp;
                                                                                            <div style={{ display: 'inline-block' }}>
                                                                                                {
                                                                                                    localStorage.getItem('loginEmail') === el.email ?
                                                                                                        <h6 >
                                                                                                            <img src='/images/clap-white.svg' alt='' />
                                                                                                        </h6>
                                                                                                        :
                                                                                                        <h6 style={{ background: el.appreciation ? '#00B24B' : '#F5821F' }} onClick={() => el.appreciation ? '' : sendApperciation(weekly, activeTab, el.user_id, el.name)}>
                                                                                                            <img src='/images/clap-white.svg' alt='' />
                                                                                                        </h6>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        : activeTab === 'class' ?
                                                                            classAchievers !== undefined && classAchievers.slice(0, 3).map((el, index) => {
                                                                                return (
                                                                                    <div class="card" style={{ height: window.innerWidth > 767 && index === 1 ? 201 : window.innerWidth > 767 && index === 2 ? 192 : 212 }} >
                                                                                        <a className='card-overlay' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            openDetails(el)
                                                                                        }}>

                                                                                        </a>
                                                                                        <div class="card-body text-center">
                                                                                            <p>
                                                                                                <div className='initial' style={{ background: el.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                    {el.profile_image ? (
                                                                                                        <img class=" img-fluid" src={el.profile_image} alt="card" />
                                                                                                    ) : el.initial !== null ? (
                                                                                                        <div style={{ color: '#fff' }}>{el.initial}</div>
                                                                                                    ) : (
                                                                                                        getIntial(el.name)
                                                                                                    )}
                                                                                                </div>
                                                                                            </p>
                                                                                            <div className='medal1'>
                                                                                                {
                                                                                                    index === 0 ?
                                                                                                        <img src='/images/medal11.svg' alt='' /> : index === 1 ?
                                                                                                            <img src='/images/medal22.svg' alt='' /> : <img src='/images/medal33.svg' alt='' />
                                                                                                }
                                                                                            </div>
                                                                                            <div className='under'>
                                                                                                <img src='/images/highlighted1.png' alt='' />
                                                                                            </div>
                                                                                            <h4 class="card-title">{el.name !== undefined && <EllipsisText text={el.name} length={15} />} <br /><p style={{ visibility: 'hidden' }}>MBA Finance</p></h4>
                                                                                            <div className='d-flex align-items-baseline justify-content-start' style={{ marginTop: index === 0 ? 18 : index === 2 ? -8 : 0, }}>
                                                                                                <h5 style={{ marginTop: index === 0 ? -23 : index === 2 ? -20 : -20, }}>{el.score}</h5> &nbsp; &nbsp;
                                                                                                <div style={{ display: 'inline-block' }}>
                                                                                                    {
                                                                                                        localStorage.getItem('loginEmail') === el.email ?
                                                                                                            <h6 >
                                                                                                                <img src='/images/clap-white.svg' alt='' />
                                                                                                            </h6>
                                                                                                            :
                                                                                                            <h6 style={{ background: el.appreciation ? '#00B24B' : '#F5821F' }} onClick={() => el.appreciation ? '' : sendApperciation(weekly, activeTab, el.user_id, el.name)}>
                                                                                                                <img src='/images/clap-white.svg' alt='' />
                                                                                                            </h6>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            activeTab === 'school' ?
                                                                                schoolAchievers !== undefined && schoolAchievers.slice(0, 3).map((el, index) => {
                                                                                    return (
                                                                                        <div class="card" style={{ height: window.innerWidth > 767 && index === 1 ? 201 : window.innerWidth > 767 && index === 2 ? 192 : 212 }} >
                                                                                            <a className='card-overlay' onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                openDetails(el)
                                                                                            }}>

                                                                                            </a>
                                                                                            <div class="card-body text-center">
                                                                                                <p>
                                                                                                    <div className='initial' style={{ background: el.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                        {el.profile_image ? (
                                                                                                            <img class=" img-fluid" src={el.profile_image} alt="card" />
                                                                                                        ) : el.initial !== null ? (
                                                                                                            <div style={{ color: '#fff' }}>{el.initial}</div>
                                                                                                        ) : (
                                                                                                            getIntial(el.name)
                                                                                                        )}
                                                                                                    </div>
                                                                                                </p>
                                                                                                <div className='medal1'>
                                                                                                    {
                                                                                                        index === 0 ?
                                                                                                            <img src='/images/medal11.svg' alt='' /> : index === 1 ?
                                                                                                                <img src='/images/medal22.svg' alt='' /> : <img src='/images/medal33.svg' alt='' />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='under'>
                                                                                                    <img src='/images/highlighted1.png' alt='' />
                                                                                                </div>
                                                                                                <h4 class="card-title">{el.name !== undefined && <EllipsisText text={el.name} length={15} />} <br /><p style={{ visibility: 'hidden' }}>MBA Finance</p></h4>
                                                                                                <div className='d-flex align-items-baseline justify-content-start' style={{ marginTop: index === 0 ? 18 : index === 2 ? -8 : 0, }}>
                                                                                                    <h5 style={{ marginTop: index === 0 ? -23 : index === 2 ? -20 : 0, }}>{el.score}</h5> &nbsp; &nbsp;
                                                                                                    <div style={{ display: 'inline-block' }}>
                                                                                                        {
                                                                                                            localStorage.getItem('loginEmail') === el.email ?
                                                                                                                <h6 >
                                                                                                                    <img src='/images/clap-white.svg' alt='' />
                                                                                                                </h6>
                                                                                                                :
                                                                                                                <h6 style={{ background: el.appreciation ? '#00B24B' : '#F5821F' }} onClick={() => el.appreciation ? '' : sendApperciation(weekly, activeTab, el.user_id, el.name)}>
                                                                                                                    <img src='/images/clap-white.svg' alt='' />
                                                                                                                </h6>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                null
                                                                    )
                                                            }
                                                        </div>
                                                        <div className='user-lists'>
                                                            {isListLoading ?
                                                                <TopicLoader /> :

                                                                <ul>
                                                                    {
                                                                        activeTab === 'section' ?
                                                                            sectionAchievers !== undefined && sectionAchievers.slice(3, 10).map((el, index) => {
                                                                                return (
                                                                                    <li>
                                                                                        <a className='user-overlay' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            openDetails(el)
                                                                                        }}>

                                                                                        </a>
                                                                                        <div className='rank-no'>
                                                                                            {el.rank}
                                                                                        </div>
                                                                                        <div className='ranker-name'>
                                                                                            <div className='initial' style={{ background: el.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                {el.profile_image ? (
                                                                                                    <img class=" img-fluid" src={el.profile_image} alt="card" />
                                                                                                ) : el.initial !== null ? (
                                                                                                    <div style={{ color: '#fff' }}>{el.initial}</div>
                                                                                                ) : (
                                                                                                    getIntial(el.name)
                                                                                                )}
                                                                                            </div>&nbsp;&nbsp;
                                                                                            <div className='name'>
                                                                                                <h1 className='text-capitalize'>{el.name}</h1>
                                                                                                {/* <p>B E Computer Science</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='ranker-score'>
                                                                                            {el.score}
                                                                                        </div>
                                                                                        {localStorage.getItem('loginEmail') === el.email ?
                                                                                            <div className='clap' >
                                                                                                <img src='/images/clap.svg' alt='' />
                                                                                            </div> :
                                                                                            (el.appreciation ?
                                                                                                <div className='clap' >
                                                                                                    <img src='/images/clap-active.svg' alt='' />
                                                                                                </div>
                                                                                                :
                                                                                                <div className='clap' onClick={() => sendApperciation(weekly, activeTab, el.user_id, el.name)}>
                                                                                                    <img src='/images/clap.svg' alt='' />
                                                                                                </div>)

                                                                                        }
                                                                                    </li>
                                                                                )
                                                                            })
                                                                            :
                                                                            activeTab === 'class' ?
                                                                                classAchievers !== undefined && classAchievers.slice(3, 10).map((el, index) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <a className='user-overlay' onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                openDetails(el)
                                                                                            }}>

                                                                                            </a>
                                                                                            <div className='rank-no'>
                                                                                                {el.rank}
                                                                                            </div>
                                                                                            <div className='ranker-name'>
                                                                                                <div className='initial' style={{ background: el.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                    {el.profile_image ? (
                                                                                                        <img class=" img-fluid" src={el.profile_image} alt="card" />
                                                                                                    ) : el.initial !== null ? (
                                                                                                        <div style={{ color: '#fff' }} >{el.initial}</div>
                                                                                                    ) : (
                                                                                                        getIntial(el.name)
                                                                                                    )}
                                                                                                </div>&nbsp;&nbsp;
                                                                                                <div className='name'>
                                                                                                    <h1 className='text-capitalize'>{el.name}</h1>
                                                                                                    {/* <p>B E Computer Science</p> */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='ranker-score'>
                                                                                                {el.score}
                                                                                            </div>
                                                                                            {localStorage.getItem('loginEmail') === el.email ?
                                                                                                <div className='clap' >
                                                                                                    <img src='/images/clap.svg' alt='' />
                                                                                                </div> :
                                                                                                (el.appreciation ?
                                                                                                    <div className='clap' >
                                                                                                        <img src='/images/clap-active.svg' alt='' />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className='clap' onClick={() => sendApperciation(weekly, activeTab, el.user_id, el.name)}>
                                                                                                        <img src='/images/clap.svg' alt='' />
                                                                                                    </div>)

                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                }) :
                                                                                activeTab === 'school' ?
                                                                                    schoolAchievers !== undefined && schoolAchievers.slice(3, 10).map((el, index) => {
                                                                                        return (
                                                                                            <li>
                                                                                                <a className='user-overlay' onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    openDetails(el)
                                                                                                }}>

                                                                                                </a>
                                                                                                <div className='rank-no'>
                                                                                                    {el.rank}
                                                                                                </div>
                                                                                                <div className='ranker-name'>
                                                                                                    <div className='initial' style={{ background: el.profile_image !== null ? '' : '#F5821F' }}>
                                                                                                        {el.profile_image ? (
                                                                                                            <img class=" img-fluid" src={el.profile_image} alt="card" />
                                                                                                        ) : el.initial !== null ? (
                                                                                                            <div style={{ color: '#fff' }} >{el.initial}</div>
                                                                                                        ) : (
                                                                                                            getIntial(el.name)
                                                                                                        )}
                                                                                                    </div>&nbsp;&nbsp;
                                                                                                    <div className='name'>
                                                                                                        <h1 className='text-capitalize'>{el.name}</h1>
                                                                                                        {/* <p>B E Computer Science</p> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='ranker-score'>
                                                                                                    {el.score}
                                                                                                </div>
                                                                                                {localStorage.getItem('loginEmail') === el.email ?
                                                                                                    <div className='clap' >
                                                                                                        <img src='/images/clap.svg' alt='' />
                                                                                                    </div> :
                                                                                                    (el.appreciation ?
                                                                                                        <div className='clap' >
                                                                                                            <img src='/images/clap-active.svg' alt='' />
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className='clap' onClick={() => sendApperciation(weekly, activeTab, el.user_id, el.name)}>
                                                                                                            <img src='/images/clap.svg' alt='' />
                                                                                                        </div>)

                                                                                                }
                                                                                            </li>
                                                                                        )
                                                                                    }) :
                                                                                    null
                                                                    }
                                                                </ul>

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='achievers-section-right'>
                                                        {details !== undefined &&
                                                            <div className='right-part'>
                                                                <h1>{weekly ? 'Engagement Dashboard' : 'Engagement Dashboard'}</h1>
                                                                <div className='ranker-details'>
                                                                    <div className='row'>
                                                                        <div className='col-md-5 col-5'>
                                                                            <div className='initial' style={{ background: details?.profile_image !== null ? '' : '#F5821F' }}>
                                                                                {details?.profile_image ? (
                                                                                    <img class=" img-fluid" src={details?.profile_image} alt="card" />
                                                                                ) : details.initial !== null ? (
                                                                                    <div style={{ color: '#fff' }}>{details?.initial}</div>
                                                                                ) : (
                                                                                    getIntial(details?.name)
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-md-7 col-7'>
                                                                            <h4>{details?.name}</h4>
                                                                            {/* <p>MBA Finance</p> */}
                                                                            <div className='d-flex'>
                                                                                <div className='right-part-rank'>
                                                                                    <small>{details?.rank} <p>Rank</p></small>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div className='right-part-rank'>
                                                                                    <small>{details?.score}<p>Score</p></small>
                                                                                </div>
                                                                            </div>
                                                                            {/* <h2>View all time stats</h2> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='subject-wise-graph'>
                                                                    <h1>Subject wise content consumption</h1>
                                                                    <p>Count of items consumed</p>
                                                                    <div className='legend-box'>
                                                                        <div className='legend'>
                                                                            <div className='legend-circle' style={{ background: '#5EB5E7' }}></div>&nbsp; Article
                                                                        </div>
                                                                        <div className='legend'>
                                                                            <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; Video
                                                                        </div>
                                                                        <div className='legend'>
                                                                            <div className='legend-circle' style={{ background: '#ECC30B' }}></div>&nbsp; Quiz
                                                                        </div>
                                                                        <div className='legend'>
                                                                            <div className='legend-circle' style={{ background: '#FF5A5F' }}></div>&nbsp; Document
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        contentCounts !== undefined && contentCounts.every(element => element.data === '' || null) ?
                                                                            <h4 className="engagement-update" >
                                                                                <img src='/images/alert-icon.svg' alt='' width='30px' /><br />
                                                                                Start learning. Your data will be available soon.</h4> : null
                                                                    }
                                                                    <div className='subject-chart'>
                                                                        <HighchartsReact
                                                                            highcharts={Highcharts}
                                                                            options={subjectWiseGraph}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='month-wise-graph'>
                                                                    <h1>Monthly content consumption</h1>
                                                                    <p>Percentage distribution of items consumed</p>

                                                                    {
                                                                        consumed !== undefined && consumed.every(element => element.data === '' || null) ?
                                                                            <h4 className="engagement-update" >
                                                                                <img src='/images/alert-icon.svg' alt='' width='30px' /><br />
                                                                                Start learning. Your data will be available soon.</h4> :
                                                                            null
                                                                    }
                                                                    <div className='subject-chart mt-2'>
                                                                        {consumed !== undefined && consumed.every(element => element.data === '' || null) ?
                                                                            null :
                                                                            <HighchartsReact
                                                                                highcharts={Highcharts}
                                                                                options={monthlyWise}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                        }
                                    </Fragment>
                                    : null
                        }
                        {
                            (match?.matches || window.innerWidth < 767) && details !== undefined && ['bottom'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Drawer
                                        anchor={anchor}
                                        open={isOpen}
                                        className='mobile-drawer1'
                                    >
                                        <div className='right-part'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h1>{weekly ? 'Weekly Dashboard' : 'All Time Dashboard'}</h1>
                                                <div className='float-right' onClick={() => setIsOpen(false)}>
                                                    <img src='/images/close1.svg' alt='' />
                                                </div>
                                            </div>

                                            <div className='ranker-details'>
                                                <div className='row'>
                                                    <div className='col-md-5 col-5'>
                                                        <div className='initial' style={{ background: details?.profile_image !== null ? '' : '#F5821F' }}>
                                                            {details?.profile_image ? (
                                                                <img class=" img-fluid" src={details?.profile_image} alt="card" />
                                                            ) : details?.initial !== null ? (
                                                                <div style={{ color: '#fff' }}>{details?.initial}</div>
                                                            ) : (
                                                                getIntial(details?.name)
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className='col-md-7 col-7'>
                                                        <h4>{details?.name}</h4>
                                                        <p style={{ visibility: 'hidden' }}>MBA Finance</p>
                                                        <div className='d-flex'>
                                                            <div className='right-part-rank'>
                                                                <small>{details?.rank}<p>Rank</p></small>
                                                            </div>
                                                            &nbsp;
                                                            <div className='right-part-rank'>
                                                                <small>{details?.score}<p>Score</p></small>
                                                            </div>
                                                        </div>
                                                        {/* <h2>View all time stats</h2> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='subject-wise-graph'>
                                                <h1>Subject wise content consumption</h1>
                                                <p>Count of items consumed</p>
                                                <div className='legend-box'>
                                                    <div className='legend'>
                                                        <div className='legend-circle' style={{ background: '#5EB5E7' }}></div>&nbsp; Article
                                                    </div>
                                                    <div className='legend'>
                                                        <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; Video
                                                    </div>
                                                    <div className='legend'>
                                                        <div className='legend-circle' style={{ background: '#ECC30B' }}></div>&nbsp; Quiz
                                                    </div>
                                                    <div className='legend'>
                                                        <div className='legend-circle' style={{ background: '#FF5A5F' }}></div>&nbsp; Document
                                                    </div>
                                                </div>
                                                {
                                                    contentCounts !== undefined && contentCounts.every(element => element.data === '' || null) ?
                                                        <h4 className="engagement-update" >
                                                            <img src='/images/alert-icon.svg' alt='' width='30px' /><br />
                                                            Start learning. Your data will be available soon.</h4> : null
                                                }
                                                <div className='subject-chart'>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={subjectWiseGraph}
                                                    />
                                                </div>
                                            </div>
                                            <div className='month-wise-graph'>
                                                <h1>Monthly content consumption</h1>
                                                <p>Percentage distribution of items consumed</p>
                                                {
                                                    consumed !== undefined && consumed.every(element => element.data === '' || null) ?
                                                        <h4 className="engagement-update" >
                                                            <img src='/images/alert-icon.svg' alt='' width='30px' /><br />
                                                            Start learning. Your data will be available soon.</h4> :
                                                        null
                                                }
                                                <div className='subject-chart mt-2'>
                                                    {consumed !== undefined && consumed.every(element => element.data === '' || null) ?
                                                        null :
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={monthlyWise}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Drawer>
                                </React.Fragment>))
                        }
                    </Fragment>
            }
        </div>
    )
}



export default Achievers;

