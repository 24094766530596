import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import { BallBeat } from '../../../Loader/loader';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getAffliations } from '../../../Redux/ActionCreators/manageContentAction';


export default function AddSubjectModal(props) {
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [subId, setSubId] = useState('');
    const [imageUrl, setImageUrl] = useState('/images/empty-image.svg');
    const [imageName, setImageName] = useState('');
    const [image, setImage] = useState('');
    const [active, setActive] = useState('');
    const [chars_left, setCharLeft] = useState(75);
    const [update, setUpdate] = useState(false);
    const [nameError, setNameError] = useState('');
    const [imgError, setImgError] = useState('');
    const [descError, setDescError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [affliation, setAffliation] = useState('');
    const [affliationError, setAffliationError] = useState('');
    const [classError, setClassError] = useState('');
    const [classs, setClass] = useState('');

    const maxChar = 75;
    const affliations = useSelector(state => state.manageContent.affliations);
    const classes = useSelector(state => state.auth.classes);
    const error = useSelector(state => state.error);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAffliations());
        if (props.items !== null) {
            const { items } = props
            if (items !== undefined) {
                setName(items.name);
                setDescription(!items?.description ? '' : items?.description);
                setImageUrl(items.image === null ? '' : items.image);
                setImage(items.image === null ? '' : items.image);
                setSubId(items?._id);
                setActive(items.active)
                setAffliation(items?.affiliation?._id);
                setClass(items.class)
                setCharLeft(maxChar - items.name?.length);
            }

        }
        else {
            setImageUrl('/images/empty-image.svg');
        }
    }, [])



    const changeName = (val) => {
        const charCount = val.length;
        const charLength = maxChar - charCount;
        setName(val);
        setNameError('');
        setCharLeft(charLength);
    }

    const changeAffliation = (val) => {
        setAffliation(val);
        setAffliationError('');
    }

    const changeClass = (val) => {
        setClass(val);
        setClassError('');
    }

    const changeDescription = (val) => {
        setDescription(val);
        setDescError('');
    }


    const changeImage = (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB");
        }
        else {
            const file = e.target.files[0]
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageUrl(reader.result)
            }
            if (file) {
                reader.readAsDataURL(file);
                setImageName(file.name);
                setImage(file);
                setImgError('');
                setUpdate(true);
            }
            else {
                setImageUrl('');
                setImageName('');
                setUpdate(false);
            }
        }
    }

    const saveSubject = async () => {
        if (name === '') {
            setNameError('Please enter Subject name');
        }
        else if (description === '') {
            setDescError('Please enter description');
        }
        else if (affliation === '') {
            setAffliationError('Please Select Affliation');
        }
        else if (classs === '') {
            setClassError('Please Select Class');
        }
        else if (image === '') {
            setImgError('Please upload image for subject');
        }

        else {
            const obj = {
                name: name,
                description: description,
                image: image,
                class: classs,
                affiliation_id: affliation
            }

            setIsLoading(true);
            await props.savedSubject(obj);
            if (error.successEnable) {
                setImageName('');
                setImage('');
                setName('');
                setDescription('');
                setUpdate(false);
                setImageUrl('/images/empty-image.svg');
            }
            setIsLoading(false);

        }

    }

    const editSubject = async () => {
        if (name === '') {
            setNameError('Please enter Subject name');
        }
        else if (description === '') {
            setDescError('Please enter description');
        }
        else if (image === '') {
            setImgError('Please upload image for subject');
        }
        else {
            var obj = {}

            if (update) {
                obj =
                {
                    _id: subId,
                    name: name,
                    image: image,
                    description: description,
                    class: classs,
                    affiliation_id: affliation,
                    active: active
                }
            }
            else {
                obj = {
                    _id: subId,
                    name: name,
                    description: description,
                    active: active,
                    class: classs,
                    affiliation_id: affliation
                }
            }

            setIsLoading(true);
            await props.updateSubject(obj);
            if (error.successEnable) {
                setImageName('');
                setImage('');
                setName('');
                setUpdate(false);
                setImageUrl('/images/empty-image.svg');
            }
            setIsLoading(false);

        }
    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    const { edit } = props
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <div class="modal-header" style={{ background: '#1D8BCB' }}>
                <h5 class="modal-title ">{edit ? 'Edit Subject' : 'Add Subject'}</h5>

                <div className="sticky-close" onClick={props.handleModal}>
                    <img src="/images/close-white.svg" width='15px' alt='' /></div>

            </div>
            <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>
                <div>
                    <label>Subject Name*
                        <aside className='float-right'>{chars_left}</aside>
                    </label>
                    <input className='form-control mt-2' value={name} maxLength={maxChar} onChange={(e) => changeName(e.target.value)} />
                </div>
                <small>{nameError}</small>
                <div className='mt-3'>
                    <label>Subject Description*
                    </label>
                    <textarea className='form-control mt-2' onChange={(e) => changeDescription(e.target.value)} value={description} />
                </div>
                <small>{descError}</small>
                <div className='d-flex'>
                    <div style={{ width: '50%' }}>
                        <div className='mt-3'>
                            <label>Affiliation*
                            </label>
                            <select className='form-control mt-2' value={affliation} onChange={(e) => changeAffliation(e.target.value)}>
                                <option hidden></option>
                                {
                                    affliations && affliations?.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <small>{affliationError}</small>
                    </div>&nbsp;&nbsp;
                    <div style={{ width: '50%' }}>
                        <div className='mt-3'>
                            <label>Class*
                            </label>
                            <select className='form-control mt-2' value={classs} onChange={(e) => changeClass(e.target.value)}>
                                <option hidden></option>
                                {
                                    classes && classes?.map(el => {
                                        return (
                                            <option key={el.value} value={el.value}>{el.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <small>{classError}</small>

                    </div>
                </div>
                {
                    edit ?
                        <div className='mt-4'>
                            <label>Subject Id*
                            </label>
                            <input className='form-control mt-2' value={subId} disabled />
                        </div>
                        : null
                }

                <div className='mt-3'>
                    <label>Subject Cover Image*</label>
                    <div className='d-flex align-items-center'>
                        {
                            image ?
                                <div className='program-image'>
                                    <img src={imageUrl} alt='' width='139px' height='78px' />
                                </div> :
                                <div className='program-image'>
                                    <img src={imageUrl} alt='' />
                                </div>
                        }
                        <div className='d-flex flex-column  position-relative mt-3'>
                            {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                            <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small>{imageName}</small>
                                <input type='file' className='file-upload'
                                    name="selectedFile" accept="image/png, image/jpeg" onChange={changeImage} />
                            </span>
                        </div>
                    </div>
                </div>
                <small>{imgError}</small>
                <div className='save-btn'>
                    {
                        edit ?
                            <button disabled={isLoading ? true : false} onClick={editSubject}>
                                {isLoading ? <div className='ballbeat'><BallBeat color="#fff" />
                                </div> : 'Save'}
                            </button>
                            :
                            <button disabled={isLoading ? true : false} onClick={saveSubject}>
                                {isLoading ? <div className='ballbeat'><BallBeat color="#fff" />
                                </div> : 'Save'}
                            </button>
                    }
                    <button onClick={props.handleModal}>Cancel</button>
                </div>
            </Modal.Body>
        </div>
    )
}

