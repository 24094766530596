import { AUTO_LOGIN, GET_LOGO, LOGIN_USER, AUTO_ADMIN_LOGIN } from '../Types/types';
import i18n from '../../i18n';

let intialState1 = {
    isAuthorized: false,
    user: localStorage.getItem('profileDetails'),
    succesMessage: false,
    classes: [{ label: 'Class 1', value: 1 }, { label: 'Class 2', value: 2 }, { label: 'Class 3', value: 3 }, { label: 'Class 4', value: 4 }, { label: 'Class 5', value: 5 }, { label: 'Class 6', value: 6 }, { label: 'Class 7', value: 7 }, { label: 'Class 8', value: 8 }, { label: 'Class 9', value: 9 }, { label: 'Class 10', value: 10 }, { label: 'Class 11', value: 11 }, { label: 'Class 12', value: 12 }]
}

export default function authReducer(state = intialState1, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_LOGO:
            const obj = {
                "name": "Master Admin",
                "logo": "https://elite-logos.s3.amazonaws.com/Edsanta%20Education_1655277805123.jpeg",
                "colors": {
                    "sidebarBgColor": "#333269",
                    "btnColor": "#b0bc00",
                    "textColor": "#fff",
                    "_id": "664f348c85d6a712effda082"
                },
                "images": [
                    "https://testservercertificates.s3.amazonaws.com/banner1_1716969811360.jpg",
                    "https://testservercertificates.s3.amazonaws.com/banner33_1716969811360.jpg",
                    "https://testservercertificates.s3.amazonaws.com/banner22_1716969811361.jpg"
                ],
                "quotes": [
                    "Empowering every child to learn and succeed!",
                    "Free Learning Platform",
                    "Technical Skills Development"
                ],
                "institution_id": {
                    "_id": "653ca33f99672747c86b56e2",
                    "name": "NHPS Institute",
                    "logo": "https://testservercertificates.s3.amazonaws.com/logo3_1716970019926.png",
                    "language": [
                        "English",
                        "Tamil"
                    ]
                }
            }
            stateCopy.logoDetails = ((window.location.origin === 'https://nhs-master-dev-ui.elitelearning.io') || (window.location.origin === 'https://schools-master.elitelearning.io') || (window.location.origin === 'http://localhost:2000')) ? { ...action.payload, ...obj } : action.payload;
            localStorage.setItem('logo', action.payload.logo);
            localStorage.setItem('school_id', action.payload._id);
            (localStorage.getItem('role') !== 'super-admin' && localStorage.getItem('role') !== 'master-admin') && localStorage.setItem('affiliations', JSON.stringify(action.payload.affiliation_id));
            localStorage.setItem('theme', JSON.stringify(action.payload.colors || { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' }));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
            // stateCopy.logoDetails = obj;
            return stateCopy;
        case LOGIN_USER:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.data.token);
            localStorage.setItem('loginEmail', action.payload.data.email);
            localStorage.setItem('name', action.payload.data.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.data));
            localStorage.setItem('role', action.payload.data.role);
            localStorage.setItem('i18nextLang', action.payload.lang ? action.payload.lang : action.payload.data.language);
            action.payload.lang ? i18n.changeLanguage(action.payload.lang) : i18n.changeLanguage(action.payload.data.language);
            stateCopy.user = action.payload.data;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        case AUTO_LOGIN:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            // localStorage.setItem('i18nextLang', action.payload.lang ? action.payload.lang : action.payload.data.language);
            // action.payload.lang ? i18n.changeLanguage(action.payload.lang) : i18n.changeLanguage(action.payload.data.language);
            stateCopy.user = action.payload;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        case AUTO_ADMIN_LOGIN:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            stateCopy.user = action.payload;
            window.location.href = '/reports'
            // window.location.reload();
            return stateCopy;
        case 'Logout_Done':
            stateCopy.user = null;
            return stateCopy;
        default:
            return stateCopy;
    }
}
