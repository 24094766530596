/* eslint-disable no-unused-vars */
import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../utils/request';
import {
   BACK_TO_ACCOUNT, EDIT_ACCOUNT, GET_ACCOUNT_DETAILS, GET_INSTITUTION,
   GET_ALL_INSTITUTION, ADD_INSTITUTE, UPDATE_INST_STATUS, EDIT_INSTITUTE, GET_SCHOOL_LIST, ADD_SCHOOL, UPDATE_SCHOOL_STATUS, UPDATE_SCHOOL, DELETE_STATE_OR_SCHOOL,
   GET_CLASS_LIST, CHANGE_SECTION, UPDATE_CLASSES,
   GET_FACULTY_LIST, ADD_USER,
   IS_ADD_SCHOOL, RESET_LIST, GET_COLORS, APPLY_THEME,
} from '../Types/types';
import { returnErrors } from './errorAction';




export const resetLoading = () => async (dispatch) => {
   return dispatch({
      type: RESET_LIST
   })
}

export const getInstitutions = () => async (dispatch) => {
   try {
      const response = await getApiCall(`utility/institutions`);
      return dispatch({ type: GET_INSTITUTION, payload: response.data.data });
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const getAllInstitutes = () => async (dispatch) => {
   try {
      const response = await getApiCall(`master-admin/institution/list`);
      return dispatch({ type: GET_ALL_INSTITUTION, payload: response.data.data });
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};


export const addInstitute = (obj) => async (dispatch) => {
   try {
      const response = await postApiCall(`master-admin/institution/create`, obj);
      if (response.data.success) {
         returnErrors(dispatch, 'Institution added', response?.status);
         return dispatch({ type: ADD_INSTITUTE, payload: response.data.data });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const updateInstStatus = (obj, checked) => async (dispatch) => {
   console.log(checked)
   try {
      const response = await putApiCall(`master-admin/institution/toggle`, obj);
      if (response.data.success) {
         returnErrors(dispatch, checked ? 'Institution activated' : 'Institution deactivated', response?.status);
         dispatch({ type: UPDATE_INST_STATUS, payload: { obj, checked } });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const updateInst = (obj) => async (dispatch) => {
   try {
      const response = await putApiCall(`master-admin/institution/update`, obj);
      if (response.data.success) {
         returnErrors(dispatch, 'Institution updated', response?.status);
         return dispatch({ type: EDIT_INSTITUTE, payload: response.data.data });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const getSchools = () => async (dispatch) => {
   try {
      const response = localStorage.getItem('role') === 'master-admin' ? await getApiCall(`master-admin/school`) : await getApiCall(`super-admin/school`);
      return dispatch({ type: GET_SCHOOL_LIST, payload: response.data.data });
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const addSchool = (obj) => async (dispatch) => {
   try {
      const response = localStorage.getItem('role') === 'master-admin' ? await postApiCall(`master-admin/school`, obj) : await postApiCall(`super-admin/school`, obj);
      if (response.data.success) {
         returnErrors(dispatch, 'School added', response?.status);
         return dispatch({ type: ADD_SCHOOL, payload: response.data.data });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const updateSchoolStatus = (obj, checked) => async (dispatch) => {
   try {
      const response = localStorage.getItem('role') === 'master-admin' ? await putApiCall(`master-admin/school/toggle`, obj) : await putApiCall(`super-admin/school/toggle`, obj);
      if (response.data.success) {
         returnErrors(dispatch, checked ? 'School activated' : 'School deactivated', response?.status);
         dispatch({ type: UPDATE_SCHOOL_STATUS, payload: { obj, checked } });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};


export const updateSchool = (obj) => async (dispatch) => {
   try {
      const response = localStorage.getItem('role') === 'master-admin' ? await putApiCall(`master-admin/school`, obj) : await putApiCall(`super-admin/school`, obj);
      if (response.data.success) {
         returnErrors(dispatch, 'School updated', response?.status);
         return dispatch({ type: UPDATE_SCHOOL, payload: response.data.data });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};
export const confirmDelete = (id, type) => async (dispatch) => {
   let obj = type === 'state' ? { state_id: id } : { school_id: id }
   try {
      let response
      if (type === 'institute') {
         response = await deleteApiCall(`super-admin/state`, obj);
      } else {
         response = localStorage.getItem('role') === 'master-admin' ? await deleteApiCall(`master-admin/school`, obj) : await deleteApiCall(`super-admin/school`, obj);
      }
      if (response.data.success) {
         returnErrors(dispatch, type === 'state' ? 'State deleted' : 'School Deleted', response?.status);
         dispatch({ type: DELETE_STATE_OR_SCHOOL, payload: { id, type } });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

//Classes 

export const getClasses = (id) => async (dispatch) => {
   try {
      const response = await getApiCall(`admin/school/classes?school_id=${localStorage.getItem('school_id')}&affiliation_id=${id ? id : ''}`);
      return dispatch({ type: GET_CLASS_LIST, payload: response.data.data });
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const changeSections = (section, detail) => async (dispatch) => {
   return dispatch({ type: CHANGE_SECTION, payload: { section, detail } })
}

export const updateClasses = (obj) => async (dispatch) => {
   try {
      const response = await putApiCall(`admin/school/classes`, obj);
      if (response.data.success) {
         returnErrors(dispatch, 'Classes Updated', response?.status);
         return dispatch({ type: UPDATE_CLASSES });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

//Faculty

export const getFacultyList = () => async (dispatch) => {
   try {
      const response = await getApiCall(`superadmin/faculty`);
      return dispatch({ type: GET_FACULTY_LIST, payload: response.data.data });
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};

export const addFaculty = (obj) => async (dispatch) => {
   try {
      const response = await postApiCall(`superadmin/user`, obj);
      if (response.data.success) {
         returnErrors(dispatch, 'Faculty added', response?.status);
         return dispatch({ type: ADD_USER, payload: response.data.data });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};



export const savedAccount = (obj) => async (dispatch) => {
   let data = new FormData()
   data.append("type", obj.type)
   data.append("_id", obj._id)
   data.append("name", obj.name)
   obj.logo !== undefined && data.append("logo", obj.logo)
   try {
      const response = await putApiCall(`admin/account/update/profile`, data);
      if (response.data.success) {
         return dispatch({ type: EDIT_ACCOUNT });
      }
      else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }

   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
}

export const openAddCenter = () => async (dispatch) => {
   return dispatch({ type: IS_ADD_SCHOOL })
}

export const backToMain1 = () => async (dispatch) => {
   return dispatch({ type: BACK_TO_ACCOUNT })
}





export const getColorCodes = () => async (dispatch) => {
   try {
      const response = await getApiCall(`admin/colors`);
      dispatch({ type: GET_COLORS, payload: response.data.data.colors });

   } catch (error) {
      returnErrors(dispatch, error.response?.data.message, error.response?.status)
   }
}

export const applyTheme = (theme) => async (dispatch) => {
   try {
      const response = await putApiCall(`admin/colors`, theme);
      if (response.data.success) {
         dispatch({ type: APPLY_THEME, payload: theme })
         returnErrors(dispatch, 'Colors updated', '200');
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error.response?.data.message, error.response?.status)
   }

}


export const getRedirectUrl = (obj) => async (dispatch) => {
   try {
      const response = await postApiCall(`super-admin/school/generate/auto/login`, obj);
      console.log(response.data)
      if (response.data.success) {
         return dispatch({ type: 'GET_URL', payload: response.data.url });
      } else {
         returnErrors(dispatch, response?.data?.message, response?.status);
      }
   } catch (error) {
      returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
   }
};