/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import './adminRewards.scss';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import Modal from 'react-bootstrap/Modal'
import Snackbar from '@mui/material/Snackbar';
import { getRewards, savedReward, updateReward, handleStatus, getCountries, getProviders, savedProvider, updateProvider, handleProviderStatus, } from '../../Redux/ActionCreators/adminRewardsAction';
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function AdminRewards() {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('rewards');
    const [rewardDetail, setRewardDetails] = useState({
        title: '', provider_id: '', cost: '', value: '', description: ''
    });
    const [provider, setProvider] = useState(null);
    const [errors, setErrors] = useState({ title: '', provider_id: '', cost: '', value: '' });
    const [edit, setEdit] = useState(false);
    const [providerDetail, setProviderDetails] = useState({
        title: '', image: '', bgcolor: '', country_id: ''
    });
    const [country, setCountry] = useState(null);
    const [imageName, setImageName] = useState('');
    const [imageUrl, setImageUrl] = useState('/images/empty-image.svg');
    const [providerErrors, setProviderErrors] = useState({ title: '', image: '', bgcolor: '', country_id: '' });
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const dispatch = useDispatch();
    const { rewards, providers, countries } = useSelector(state => state.adminReward);

    useEffect(() => {
        dispatch(getRewards());
        dispatch(getCountries());
        dispatch(getProviders());
    }, [])


    const onTabChange = (e) => {
        setActiveTab(e);
        setIsOpen(false);
        setEdit(false);
    }

    const openAddForm = async (data, edit, type) => {
        setIsOpen(true);
        setEdit(edit);
        if (type === 'reward') {
            if (data !== null) {
                setProvider({ label: data.provider?.title, value: data?.provider?._id })
                setRewardDetails({ title: data.title, provider_id: data.provider?._id, cost: data.cost, description: data?.description, value: data.value, _id: data._id });
            } else {
                setProvider(null);
                setRewardDetails({ title: '', provider_id: '', cost: '', value: '', description: '' })
            }
        } else {
            if (data !== null) {
                setImageUrl(data?.image);
                setCountry({ label: data.country?.name, value: data?.country?._id });
                setProviderDetails({ title: data.title, image: data.image, bgcolor: data.bgcolor, country_id: data.country?._id, _id: data._id });
            } else {
                setCountry(null);
                setProviderDetails({ title: '', image: '', bgcolor: '', country_id: '' })
            }
        }
    }


    const onChangeProvider = (e) => {
        setProvider(e);
        setRewardDetails(prev => ({ ...prev, provider_id: e.value }));
        setErrors(prev => ({ ...prev, provider_id: '' }));
    }


    function rewardFormChange(e) {
        const { name, value } = e.target
        const newErrors = { ...errors };
        if (name === 'title' && value === '') {
            newErrors.title = 'Reward title is required';
        } else {
            newErrors.title = '';
        }
        if (name === 'cost' && value === '') {
            newErrors.cost = 'Cost is required';
        } else {
            newErrors.cost = '';
        }
        if (name === 'value' && value === '') {
            newErrors.value = 'Value is required';
        } else {
            newErrors.value = '';
        }
        setErrors(newErrors)
        setRewardDetails(prev => ({ ...prev, [name]: value }))
    }


    const handleCloseModal = () => {
        setIsOpen(false);
        setRewardDetails({
            title: '', provider_id: '', cost: '', value: '', description: ''
        });
        setImageUrl('/images/empty-image.svg');
        setImageName('');
        setProviderDetails({
            title: '', image: '', bgcolor: '', _id: ''
        });
    }

    const saveReward = () => {
        const errors = {}
        setLoading(true);
        console.log(rewardDetail)
        if (!rewardDetail?.title) {
            errors.title = 'Reward title is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!rewardDetail?.provider_id) {
            errors.provider_id = 'Provider is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!rewardDetail?.cost) {
            errors.cost = 'Cost is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!rewardDetail?.value) {
            errors.value = 'Value is required';
            setErrors(errors);
            setLoading(false);
        }
        else {
            setErrors({ title: '', provider_id: '', cost: '', value: '' });
            console.log(rewardDetail);
            if (edit) {
                dispatch(updateReward(rewardDetail));
            } else {
                dispatch(savedReward(rewardDetail));
            }
            setIsOpen(false);
            setRewardDetails({
                title: '', provider_id: '', cost: '', description: '', value: ''
            });
            setLoading(false);

        }
    }


    //Provider

    const onChangeCountry = (e) => {
        setCountry(e);
        setProviderDetails(prev => ({ ...prev, country_id: e.value }));
        setProviderErrors(prev => ({ ...prev, country_id: '' }));
    }

    const changeImage = (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB");
        } else {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageUrl(reader.result)
            };
            if (file) {
                reader.readAsDataURL(file);
                setImageUrl(reader.result);
                setImageName(file.name);
                setProviderDetails(prev => ({ ...prev, image: file }));
                setErrors(prev => ({ ...prev, image: '' }));
            } else {
                setImageUrl('');
                setImageName('');
            }
        }
    }


    function providerFormChange(e) {
        const { name, value } = e.target
        const newErrors = { ...providerErrors };
        if (name === 'title' && value === '') {
            newErrors.title = 'Provider is required';
        } else {
            newErrors.title = '';
        }
        if (name === 'bgcolor' && value === '') {
            newErrors.bgcolor = 'bgcolor is required';
        } else {
            newErrors.bgcolor = '';
        }
        setProviderErrors(newErrors)
        setProviderDetails(prev => ({ ...prev, [name]: value }))
    }

    const saveProvider = () => {
        const errors = {}
        setLoading(true);
        if (!providerDetail?.title) {
            errors.title = 'Provider title is required';
            setProviderErrors(errors);
            setLoading(false);
        }
        else if (!providerDetail?.bgcolor) {
            errors.bgcolor = 'bgcolor is required';
            setProviderErrors(errors);
            setLoading(false);
        }
        else if (!providerDetail?.country_id) {
            errors.country_id = 'Country is required';
            setProviderErrors(errors);
            setLoading(false);
        }
        else if (!providerDetail?.image) {
            errors.image = 'Image is required';
            setProviderErrors(errors);
            setLoading(false);
        }
        else {
            setProviderErrors({ title: '', image: '', bgolor: '' });

            const { title, image, bgcolor, country_id, _id } = providerDetail
            const data = new FormData();
            edit && data.append('_id', _id)
            data.append('title', title);
            data.append('country_id', country_id)
            typeof image === 'object' && data.append('image', image);
            data.append('bgcolor', bgcolor);
            if (edit) {
                dispatch(updateProvider(data));
            } else {
                dispatch(savedProvider(data));
            }
            setIsOpen(false);
            setImageUrl('/images/empty-image.svg');
            setImageName('');
            setProviderDetails({
                title: '', image: '', bgcolor: '', _id: ''
            });
            setLoading(false);

        }
    }






    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />

            <div className="user-module">
                <div className="engaged-users">
                    <div className="users-heading d-flex justify-content-between align-items-center">
                        <h3>{activeTab === 'rewards' ? 'Rewards' : 'Providers'}</h3>

                        <button className='add-btn' onClick={() => openAddForm(null, false, activeTab === 'rewards' ? 'reward' : 'provider')}>{activeTab === 'rewards' ? 'Add Reward' : 'Add Provider'}</button>
                    </div>
                    <div className="history-header1">
                        <ul>
                            <li className={activeTab === "rewards" ? "active-Tab" : null}
                                onClick={() => onTabChange("rewards")}>Rewards
                            </li>
                            <li className={activeTab === "providers" ? "active-Tab" : null}
                                onClick={() => onTabChange("providers")}>Providers
                            </li>
                        </ul>
                    </div>
                    {
                        activeTab === 'rewards' ?
                            <div className="table-admin">
                                <Table responsive>
                                    <thead>
                                        <tr className="header-table">
                                            <th>Reward</th>
                                            <th>Provider</th>
                                            <th>Cost</th>
                                            <th>Value</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            (rewards !== undefined && rewards?.length) ?
                                                rewards.map((reward) => {
                                                    return (
                                                        <tr key={reward._id}>
                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src={reward?.provider?.image} alt='' width={60} />&nbsp;&nbsp;&nbsp;
                                                                    <p dangerouslySetInnerHTML={{ __html: reward?.title }} />

                                                                </div>
                                                            </td>
                                                            <td>{reward?.provider?.title}</td>
                                                            <td>{reward.cost}</td>
                                                            <td>{reward?.value}</td>
                                                            <td className=''>
                                                                {
                                                                    reward.active === true || reward.active === false ?
                                                                        <Switch
                                                                            width={30}
                                                                            height={16}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            onColor='#00B24B'
                                                                            onChange={() => {
                                                                                dispatch(handleStatus(reward.active, reward._id))
                                                                            }}
                                                                            checked={reward.active === true ? true : false}
                                                                        /> : <span className="status-tags">{reward?.active}</span>
                                                                }<br />

                                                            </td>

                                                            <td>
                                                                <img src='/images/edit.svg' alt='' className='cursor-pointer' onClick={() => openAddForm(reward, true, 'reward')} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className='empty-list'>
                                                            <img src='/images/empty.png' alt='' />
                                                            <p>You have not added any rewards yet.</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div> :

                            <div className="table-admin">
                                <Table responsive>
                                    <thead>
                                        <tr className="header-table">
                                            <th>Provider</th>
                                            <th>BgColor</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            (providers !== undefined && providers?.length) ?
                                                providers.map((provider) => {
                                                    return (
                                                        <tr key={provider._id}>
                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src={provider?.image} alt='' className='rounded' width={60} height={40} />&nbsp;&nbsp;&nbsp;
                                                                    <p>{provider?.title}</p>

                                                                </div>
                                                            </td>
                                                            <td>{provider?.bgcolor}</td>

                                                            <td className=''>
                                                                {
                                                                    provider.active === true || provider.active === false ?
                                                                        <Switch
                                                                            width={30}
                                                                            height={16}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            onColor='#00B24B'
                                                                            onChange={() => {
                                                                                dispatch(handleProviderStatus(provider.active, provider._id))
                                                                            }}
                                                                            checked={provider.active === true ? true : false}
                                                                        /> : <span className="status-tags">{provider?.active}</span>
                                                                }<br />

                                                            </td>

                                                            <td>
                                                                <img src='/images/edit.svg' alt='' className='cursor-pointer' onClick={() => openAddForm(provider, true, 'provider')} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className='empty-list'>
                                                            <img src='/images/empty.png' alt='' />
                                                            <p>You have not added any rewards yet.</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>

                    }
                </div>

            </div>
            {/* ########## Modal for add rewards ###################### */}
            <Modal
                show={isOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-reward-model"}
                style={{ backdropFilter: 'none' }}
            >
                {
                    activeTab === 'rewards' ?
                        <Fragment>
                            <div className="model-header">
                                <h5 className="modal-label">{edit ? 'Edit Reward' : 'Add Reward'}</h5>

                                <div
                                    className="sticky-close"
                                    onClick={handleCloseModal}
                                >
                                    <img src="/images/close1.svg" width="15px" alt="" />
                                </div>
                            </div>
                            <Modal.Body style={{ padding: '15px 15px 15px 15px' }}>

                                {rewardDetail !== undefined &&
                                    <div>
                                        <Fragment>

                                            <div className='form-group mt-0'>
                                                <label>Reward title*
                                                </label>
                                                <div className='mt-2'>
                                                    <input type='text' name='title' className='form-control' value={rewardDetail?.title} onChange={(e) => rewardFormChange(e)} />
                                                </div>
                                                <span className='err'>{errors?.title}</span>
                                            </div>
                                            <div className='form-group mt-0'>
                                                <label>Provider*
                                                </label>
                                                <div className='mt-2'>
                                                    <Select
                                                        components={{ DropdownIndicator }}
                                                        options={providers?.map(el => [{ value: el._id, label: el.title }][0])}
                                                        onChange={onChangeProvider}
                                                        className='selectSub'
                                                        placeholder='Select Provider'
                                                        value={provider}
                                                    >
                                                    </Select>
                                                </div>
                                                <span className='err'>{errors?.provider_id}</span>
                                            </div>
                                            <div className='row pl-0 pr-0'>

                                                <div className='col-md-6'>
                                                    <div className='form-group mt-0'>
                                                        <label>Cost*
                                                        </label>
                                                        <div className='mt-2'>
                                                            <input type='text' name='cost' className='form-control' value={rewardDetail?.cost} onChange={(e) => rewardFormChange(e)} />
                                                        </div>
                                                        <span className='err'>{errors?.cost}</span>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group mt-0'>
                                                        <label>Value*
                                                        </label>
                                                        <div className='mt-2'>
                                                            <input type='text' name='value' className='form-control' value={rewardDetail?.value} onChange={(e) => rewardFormChange(e)} />
                                                        </div>
                                                        <span className='err'>{errors?.value}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group mt-0'>
                                                <label>Description
                                                </label>
                                                <div className='mt-2'>
                                                    <textarea name='description' className='form-control' value={rewardDetail?.description} onChange={(e) => rewardFormChange(e)} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <button onClick={saveReward} disabled={loading ? true : false} className="add-btn">Save</button>
                                            </div>
                                        </Fragment>
                                    </div>
                                }
                            </Modal.Body>
                        </Fragment> :
                        <Fragment>
                            <div className="model-header">
                                <h5 className="modal-label">{edit ? 'Edit Provider' : 'Add Provider'}</h5>

                                <div
                                    className="sticky-close"
                                    onClick={handleCloseModal}
                                >
                                    <img src="/images/close1.svg" width="15px" alt="" />
                                </div>
                            </div>
                            <Modal.Body style={{ padding: '15px 15px 15px 15px' }}>

                                {providerDetail !== undefined &&
                                    <div>
                                        <Fragment>

                                            <div className='form-group mt-0'>
                                                <label>Provider title*
                                                </label>
                                                <div className='mt-2'>
                                                    <input type='text' name='title' className='form-control' value={providerDetail?.title} onChange={(e) => providerFormChange(e)} />
                                                </div>
                                                <span className='err'>{errors?.title}</span>
                                            </div>
                                            <div className='form-group mt-0'>
                                                <label>bgcolor*
                                                </label>
                                                <div className='mt-2'>
                                                    <input type='text' name='bgcolor' className='form-control' value={providerDetail?.bgcolor} onChange={(e) => providerFormChange(e)} />
                                                </div>
                                                <span className='err'>{errors?.bgcolor}</span>
                                            </div>
                                            <div className='form-group mt-0'>
                                                <label>Country*
                                                </label>
                                                <div className='mt-2'>
                                                    <Select
                                                        components={{ DropdownIndicator }}
                                                        options={countries.map(el => [{ value: el._id, label: el.name }][0])}
                                                        onChange={onChangeCountry}
                                                        className='selectSub'
                                                        placeholder='Select Country'
                                                        value={country}
                                                    >
                                                    </Select>
                                                </div>
                                                <span className='err'>{errors?.country_id}</span>
                                            </div>
                                            <div className='mt-3'>
                                                <label>Reward Image*</label>
                                                <div className='d-flex align-items-center '>
                                                    {
                                                        providerDetail?.image ?
                                                            <div className='program-image'>
                                                                <img src={imageUrl} alt='' width='139px' height='78px' />
                                                            </div> :
                                                            <div className='program-image'>
                                                                <img src={imageUrl} alt='' />
                                                            </div>
                                                    }
                                                    <div className='d-flex flex-column mt-3 position-relative'>
                                                        <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small>{imageName}</small>
                                                            <input type='file' className='file-upload'
                                                                name="selectedFile" accept="image/png, image/jpeg" onChange={changeImage} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <span className='err'>{errors?.image}</span>
                                            </div>
                                            <div className='mt-3'>
                                                <button onClick={saveProvider} disabled={loading ? true : false} className="add-btn">Save</button>
                                            </div>
                                        </Fragment>
                                    </div>
                                }
                            </Modal.Body>
                        </Fragment>
                }
            </Modal >
        </Fragment >
    );
}

export default AdminRewards;
