import React, { Fragment, useEffect, useState } from 'react';
import './myLearning.scss';
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BubbleLoader } from '../../Loader/loader';
import { SampleNextArrow, SamplePrevArrow, convertMillisToHrsMins } from '../../utils/miscelleneous';
import { getStudentCourses, getSubjects, resetSubjects, getOtherSubjects, getAssignedSubjects, getResource } from '../../Redux/ActionCreators/myLearningAction';
import { backToTopic, showSideBar } from '../../Redux/ActionCreators/manageContentAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import ProgressBar from '../../utils/progressBar';
import { useTranslation } from 'react-i18next';
import ViewResource from '../../utils/viewResource';


const MyLearning = () => {
    // const [studentDetail, setStudentDetails] = useState(null)
    const [isView, setIsView] = useState(false);
    const [resource, setResource] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const { subject, isSubjectLoading, otherSubjects } = useSelector(state => state.myLearning)

    useEffect(() => {
        const firstRender = async () => {
            window.scrollTo(0, 0);
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            };
            await dispatch(showSideBar());
            await dispatch(backToTopic());
            dispatch(clearErrors());
            if (location.search) {

                let id = window.location.search.split('=')[1].split('&')[0]
                let type = window.location.search.split('=')[2]
                let obj = {
                    _id: id, type
                }
                const res = await dispatch(getResource(obj));
                if (res?.type === 'GET_INDIVIDUAL_RESOURCE') {
                    await setResource({ ...res?.payload, type: type });
                    await setIsView(true);
                }
            }
            if (window.location.pathname === '/my-learning') {
                const res = await dispatch(getStudentCourses());
                const classroomId = await res.payload.filter(el => !el.default)[0]?._id;
                localStorage.setItem('classroomId', classroomId);
                classroomId && await dispatch(getSubjects(classroomId));
            } else {
                await dispatch(getAssignedSubjects());
            }

            await dispatch(getOtherSubjects())
        }
        firstRender();
    }, [dispatch])

    const goToSubject = () => {
        navigate('/content-library')
    }

    const closeDetails = () => {
        setIsView(false);
        setResource(null);
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        // variableWidth: true,
        className: "variable-width",
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },


        ]
    };
    const settings1 = {
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        // variableWidth: true,
        className: "variable-width",
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },


        ]
    };
    return (

        <div className='my-learning-holder'>
            {isSubjectLoading ?
                <BubbleLoader /> :
                <Fragment>

                    <div className='subject-heading' >{window.location.pathname === '/my-learning' ? t('My School Subjects') : t('My Subjects')}</div>
                    <div className='skill-library'>
                        {
                            isSubjectLoading ?
                                <BubbleLoader /> :
                                (!subject?.length ?
                                    <div className="empty-list" style={{ height: '188px' }}>
                                        <p>{t('Subject data is not available for this class')}</p>
                                    </div>
                                    :
                                    <div className='bubble-card container-fluid ' style={{ paddingRight: 0 }}>
                                        {
                                            window.innerWidth > 767 ?

                                                <div className={'row'} style={{ marginLeft: -25 }}>
                                                    <div className='w-100'>
                                                        {
                                                            <div className='d-flex flex-wrap '>
                                                                {subject !== undefined && subject.map((el) => {
                                                                    return (
                                                                        <div key={el._id} className="card" style={{ width: 205, marginRight: 10 }} >
                                                                            <Link to={window.location.pathname === '/my-learning' ? `/my-learning/chapters` : `/my-subjects/chapters`}
                                                                                state={{
                                                                                    id: el._id,
                                                                                    detail: el,
                                                                                    type: 'main',
                                                                                    title: el.name
                                                                                }}
                                                                            >
                                                                                <img className="card-img-top" src={el.image} style={{ height: 150 }} alt="Card" />
                                                                                {el.articles + el.videos + el.documents + el.quizzes === 0 ? null :
                                                                                    <div className='time'>
                                                                                        <img src='images/duration.svg' alt='' />&nbsp;
                                                                                        {convertMillisToHrsMins((el.duration), t)}
                                                                                    </div>
                                                                                }
                                                                                <div className="card-body p-0">
                                                                                    <div style={{ padding: 7, paddingTop: 5, paddingRight: 7, }}>
                                                                                        <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                                            <h5 className='elipsis-2 w-96' title={el.name}>
                                                                                                {el.name}
                                                                                            </h5>
                                                                                            {
                                                                                                <aside className='d-flex align-items-center mt-2 mb-2' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}><img src='/images/chapter.svg' alt='' />&nbsp; {el.chapters} {t('Chapters')}</aside>

                                                                                            }
                                                                                        </div>

                                                                                        <div style={{ borderTop: '1px solid #ddd' }}></div>
                                                                                        {el?.analytic > 0 ?
                                                                                            <div style={{ marginTop: 16, marginBottom: -6 }}>

                                                                                                <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el?.analytic || 0}
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            <button>{t('Start learning')}</button>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>)
                                                                })
                                                                }
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto'}>
                                                    {
                                                        subject !== undefined && subject?.map((el) => {
                                                            return (
                                                                <div key={el._id} className="card" style={{ marginRight: 10 }} >
                                                                    <Link to={window.location.pathname === '/my-learning' ? `/my-learning/chapters` : `/my-subjects/chapters`}
                                                                        state={{
                                                                            id: el._id,
                                                                            detail: el,
                                                                            type: 'main',
                                                                            title: el.name
                                                                        }}
                                                                    >
                                                                        <img className="card-img-top" src={el.image} style={{ height: 170 }} alt="Card" />
                                                                        {el.articles + el.videos + el.documents + el.quizzes === 0 ? null :
                                                                            <div className='time'>
                                                                                <img src='images/duration.svg' alt='' />&nbsp;
                                                                                {convertMillisToHrsMins((el.duration), t)}
                                                                            </div>
                                                                        }
                                                                        <div className="card-body p-0">
                                                                            <div style={{ padding: 7, paddingTop: 5, paddingRight: 15, }}>
                                                                                <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                                    <h5 className='elipsis-2 w-96' title={el.name}>
                                                                                        {el.name}
                                                                                    </h5>
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                    <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el.analytic || 0}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ borderTop: '1px solid #ddd', marginTop: -10 }}></div>
                                                                                <button className='mt-1'>{t('Start learning')}</button>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                        }
                                    </div>)
                        }
                    </div>
                </Fragment>
            }

            {/* {otherSubjects?.length > 0 &&
                <div className='explore-subject'>
                    <h3 className='others mt-3'> {t('Explore new subjects')}
                        <aside onClick={() => goToSubject()}>{t('View all')}</aside>
                    </h3>
                    {
                        otherSubjects?.length > 0 &&
                        <div className='bubble-card mt-2' style={{ marginLeft: -10, paddingRight: 0 }}>
                            {
                                window.innerWidth > 767 ?
                                    <div className={'row'} >
                                        <div className='w-100'>
                                            {
                                                otherSubjects?.length > 2 ?
                                                    <Slider {...settings1} >
                                                        {
                                                            otherSubjects && otherSubjects?.map((el) => {
                                                                return (
                                                                    <div key={el._id} className="card" style={{ width: '19.5%', marginRight: 10 }} >
                                                                        <Link to={window.location.pathname === '/my-learning' ? `/my-learning/chapters` : `/my-subjects/chapters`}
                                                                            state={{
                                                                                id: el._id,
                                                                                detail: el,
                                                                                type: 'main',
                                                                                title: el.name
                                                                            }}
                                                                        >
                                                                            <img className="card-img-top" src={el.image} style={{ height: 150 }} alt="Card" />
                                                                            {el.articles + el.videos + el.documents + el.quizzes === 0 ? null :
                                                                                <div className='time'>
                                                                                    <img src='images/duration.svg' alt='' />&nbsp;
                                                                                    {convertMillisToHrsMins((el.duration), t)}
                                                                                </div>
                                                                            }
                                                                            <div className="card-body p-0">
                                                                                <div style={{ padding: 7, paddingTop: 5, paddingRight: 7, }}>
                                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                                        <h5 className='elipsis-2 w-96' title={el.name}>
                                                                                            {el.name}
                                                                                        </h5>
                                                                                        {<aside className='d-flex align-items-center mt-2 mb-2' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}><img src='/images/chapter.svg' alt='' />&nbsp; {el.chapters} {t('Chapters')}</aside>
                                                                                        }
                                                                                    </div>

                                                                                    <div style={{ borderTop: '1px solid #ddd' }}></div>
                                                                                    {el?.analytic > 0 ?
                                                                                        <div style={{ marginTop: 16, marginBottom: -6 }}>

                                                                                            <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el?.analytic || 0}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <button>{t('Start learning')}</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Slider> :
                                                    <div className='d-flex'>
                                                        {otherSubjects !== undefined && otherSubjects.map((el) => {
                                                            return (
                                                                <div key={el._id} className="card" style={{ width: 190, marginRight: 10 }} >
                                                                    <Link to={window.location.pathname === '/my-learning' ? `/my-learning/chapters` : `/my-subjects/chapters`}
                                                                        state={{
                                                                            id: el._id,
                                                                            detail: el,
                                                                            type: 'main',
                                                                            title: el.name
                                                                        }}
                                                                    >
                                                                        <img className="card-img-top" src={el.image} style={{ height: 150 }} alt="Card" />
                                                                        {el.articles + el.videos + el.documents + el.quizzes === 0 ? null :
                                                                            <div className='time'>
                                                                                <img src='images/duration.svg' alt='' />&nbsp;
                                                                                {convertMillisToHrsMins((el.duration), t)}
                                                                            </div>
                                                                        }
                                                                        <div className="card-body p-0">
                                                                            <div style={{ padding: 7, paddingTop: 5, paddingRight: 7, }}>
                                                                                <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                                    <h5 className='elipsis-2 w-96' title={el.name}>
                                                                                        {el.name}
                                                                                    </h5>
                                                                                    {
                                                                                        <aside className='d-flex align-items-center mt-2 mb-2' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}><img src='/images/chapter.svg' alt='' />&nbsp; {el.chapters} {t('Chapters')}</aside>

                                                                                    }
                                                                                </div>

                                                                                <div style={{ borderTop: '1px solid #ddd' }}></div>
                                                                                {el?.analytic > 0 ?
                                                                                    <div style={{ marginTop: 16, marginBottom: -6 }}>

                                                                                        <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el?.analytic || 0}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <button>{t('Start learning')}</button>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>)
                                                        })
                                                        }
                                                    </div>

                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto'}>
                                        {
                                            subject !== undefined && subject?.map((el) => {
                                                return (
                                                    <div key={el._id} className="card" style={{ marginRight: 10 }} >
                                                        <Link to={window.location.pathname === '/my-learning' ? `/my-learning/chapters` : '/my-subjects/chapters'}
                                                            state={{
                                                                id: el._id,
                                                                detail: el,
                                                                type: 'main',
                                                                title: el.name
                                                            }}
                                                        >
                                                            <img className="card-img-top" src={el.image} style={{ height: 170 }} alt="Card" />
                                                            {el.articles + el.videos + el.documents + el.quizzes === 0 ? null :
                                                                <div className='time'>
                                                                    <img src='images/duration.svg' alt='' />&nbsp;
                                                                    {convertMillisToHrsMins((el.duration), t)}
                                                                </div>
                                                            }
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: 7, paddingTop: 5, paddingRight: 15, }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='elipsis-2 w-96' title={el.name}>
                                                                            {el.name}
                                                                        </h5>
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el.analytic || 0}
                                                                        />
                                                                    </div>
                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: -10 }}></div>
                                                                    <button>{t('Start learning')}</button>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    }
                </div>
            } */}

            <div className="resource-details" style={{ display: isView ? 'block' : 'none', }}>
                <ViewResource
                    activeFeedData={resource}
                    closeNotes={closeDetails}
                />
            </div>
        </div >
    )
}



export default (MyLearning);