let intialState = {
    greetModal: false,
    points: { current: 1500, lifetime: 2000, redeemed: 500, upcoming: 100 },
    rewards: [],
    isOpenRedeem: false,
    cardDetails: {},
    rewardHistory: []
}

export default function rewardReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'GET_REWARDS':
            stateCopy.rewards = action.payload.sort(function (a, b) {
                return a.value - b.value;
            });
            return stateCopy;
        case 'GET_REWARDS_POINTS':
            stateCopy.score = action.payload.data;
            return stateCopy;
        case 'OPEN_REDEEM_MODAL':
            stateCopy.isOpenRedeem = true;
            stateCopy.cardDetails = action.payload;
            return stateCopy;
        case 'CLOSE_REDEEM_MODAL':
            stateCopy.isOpenRedeem = false;
            stateCopy.cardDetails = {};
            return stateCopy;
        case 'COUPON_REDEEMED':
            stateCopy.isOpenRedeem = false;
            stateCopy.greetModal = true;
            return stateCopy;
        case 'CLOSE_GREET_MODAL':
            stateCopy.greetModal = false;
            return stateCopy;
        case 'REWARD_HISTORY':
            stateCopy.rewardHistory = action.payload;
            return stateCopy
        default:
            return stateCopy;

    }
}