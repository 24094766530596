import React, { Fragment, useState } from 'react';
import './sharePopup.scss'
import EllipsisText from "react-ellipsis-text";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { shareAll, shareClose } from '../../Redux/ActionCreators/shareAction';


function SharePopup(props) {
    const [emails, setEmails] = useState([]);
    const [emailsCopy, setEmailsCopy] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [suggestion, setSuggestion] = useState([]);
    const [emailSuggestion, setEmailSuggestion] = useState('');
    const [remark, setRemark] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const dispatch = useDispatch();
    const { isShare, shareDetails } = useSelector(state => state.share);

    const selectedEmail = (items) => {
        if (!emailsCopy.includes(items._id)) {
            setEmails([...emails, items]);
            setEmailsCopy([...emailsCopy, items._id]);
            setEmailError('');
            setEmailSuggestion('');
            $('.email-suggestion').hide();
            $('#email1').val('').focus();
        }
        else {
            console.log('email is already present')
        }
    }

    const timerRef1 = React.useRef(null);


    const findUser = (val) => {
        clearTimeout(timerRef1.current);
        setEmailSuggestion(val);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    };


    const delaySearch = (val) => {
        if (localStorage.getItem('role') === 'student') {
            let AuthStr = 'Bearer ' + localStorage.getItem("loginToken");
            axios.get(process.env.REACT_APP_API_KEY + `share/to/section/email?query=${val}`, { headers: { Authorization: AuthStr } }).then((result) => {
                if (result.data.success) {
                    setSuggestion(result.data.emails);
                }
            })
        }
        else {
            let AuthStr = 'Bearer ' + localStorage.getItem("loginToken");
            axios.get(process.env.REACT_APP_API_KEY + `share/to/section/email?query=${val}`, { headers: { Authorization: AuthStr } }).then((result) => {
                if (result.data.success) {
                    setSuggestion(result.data.emails);
                }
            })
        }
    }

    const handleDelete = async (email, i) => {
        var mails = emails.filter((chip) => chip._id !== email._id)
        var mailsCopy = emailsCopy.filter((chip) => console.log("1183", chip))
        await setEmails(mails);
        await setEmailsCopy(mailsCopy);
    };

    const changeRemark = (val) => {
        setRemark(val);
    }

    const shareToAll = async (type, id) => {
        if (!emails.length) {
            setEmailError('Please select email address');
        }
        else {
            let resource = {
                all: false,
                to: emails.map(el => el._id),
                resource_type: type,
                resource_id: id,
                remark: remark
            }
            await dispatch(shareAll(resource));
            setEmails([]);
            setEmailsCopy([]);
            setEmailError('');
            setSuggestion([]);
            setRemark('');
            setSuccessMessage(false);

        }
    }

    const resetModal = () => {
        setEmails([]);
        setEmailsCopy([]);
        setEmailError('');
        setSuggestion([]);
        setEmailSuggestion('');
        dispatch(shareClose());
    }

    const snackClosed = () => {
        setSuccessMessage(false);
        setSuccessMessageText('');
    }
    return (
        <Fragment>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={props.successEnable}
                onClose={() => props.snackClosed()}
                message={props.successMessageText}
                autoHideDuration={4000}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMessage}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />

            {shareDetails !== undefined &&
                <Modal show={isShare} style={{ backdropFilter: 'none' }} centered aria-labelledby="contained-modal-title-vcenter" dialogClassName={"share-modal"}>
                    <div class="modal-header">
                        <h5 class="modal-title">Share this {shareDetails?.type} with</h5>
                        <button type="button" class="close" onClick={() => resetModal()} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body style={{ padding: 25 }}>
                        {

                            <div className='share-holder'>

                                <div>
                                    <div class="col-sm-12 form-control email-id-row">
                                        <span class="to-input">To</span>
                                        <div className="all-mail" >
                                            {
                                                emails?.length > 0 ?
                                                    emails?.map((email, i) => {
                                                        return (
                                                            <div style={{ paddingRight: 10 }}>
                                                                <Chip
                                                                    size="small"
                                                                    avatar={<Avatar src={email.profile_image} />}
                                                                    label={email.name}
                                                                    onDelete={() => handleDelete(email, i)}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                    : null
                                            }
                                            <input autoFocus id="email1" autoComplete="off" value={emailSuggestion} name="email" className="enter-mail-id" placeholder="Search by name .." onChange={(e) => findUser(e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                                {suggestion !== undefined && suggestion?.length > 0 && emailSuggestion !== "" ?
                                    <div className="email-suggestion" >
                                        <ul>
                                            {
                                                suggestion !== undefined && suggestion?.length > 0 ?
                                                    suggestion.map((items, i) => {

                                                        return (

                                                            <li onClick={() => selectedEmail(items)}>
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        {items.profile_image !== null && items.profile_image !== '' ?
                                                                            <div className="intial" >
                                                                                <img className="rounded-circle" src={items.profile_image} width="36px" height="36px" alt='' />
                                                                            </div>
                                                                            : <div className="intial" style={{ background: `#F5821F` }}>{items.initial}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-10 email-tag">
                                                                        {items.name}<br />
                                                                        {items.email}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) : null
                                            }
                                        </ul>
                                    </div> : null}
                            </div>
                        }
                        <div style={{ color: 'red', marginBottom: 5, marginTop: -5 }}>{emailError}</div>

                        <textarea className='form-control w-100' placeholder='Enter Remark (Optional)' onChange={(e) => changeRemark(e.target.value)} />
                        {
                            // <div style={{ color: 'red', marginTop: 5 }}>{remarkErr}</div>
                        }
                        <div className="specific">
                            <div className="share-title">
                                {/* <h1>{shareFeedTitle}</h1> */}
                                <div>
                                    {shareDetails?.type === 'Question' ? <div className='elipsis' dangerouslySetInnerHTML={{ __html: shareDetails?.title }} /> : (shareDetails?.title && <EllipsisText text={shareDetails?.title} length={"60"} />)}
                                    <p>
                                        {
                                            shareDetails?.type === "Video" ?
                                                <img
                                                    src="/images/video-icon.svg" alt='' /> : shareDetails?.type === "Document" ?
                                                    <img
                                                        src="/images/document-icon.svg" alt='' /> : shareDetails?.type === "Article" ?
                                                        <img
                                                            src="/images/article-icon.svg" alt='' /> : shareDetails?.type === "Question" ? <img
                                                                src="/images/quiz-icon.svg" alt='' /> : shareDetails?.type === "Course" ? <img
                                                                    src="/images/course-icon.svg" alt='' /> : shareDetails?.type === "Podcast" ? <img
                                                                        src="/images/podcast-icon.svg" alt='' /> :
                                                            null

                                        } &nbsp;
                                        <span>{shareDetails?.type}</span>&nbsp;

                                        &nbsp;

                                    </p>
                                </div>
                                {
                                    shareDetails?.type === 'Question' ?
                                        <div className="content-pic">
                                            <img src="/images/quiz.svg" alt='' className="rounded" width="70px" height="67px" />
                                        </div>
                                        :
                                        <div className="content-pic">
                                            <img src={shareDetails?.image} className="rounded" width="70px" height="67px" onError={(e) => {
                                                if (e.target.src !== "/images/error-image.jpg") {
                                                    e.target.onerror = "/images/error-image.jpg";
                                                    e.target.src = "/images/error-image.jpg";
                                                }
                                            }} alt='' />
                                        </div>
                                }
                            </div>
                            <br />
                            <br />
                            {

                                <button className="float-right share-btn" style={{ background: "#F5821F" }} onClick={() => { shareToAll(shareDetails?.type, shareDetails?._id || shareDetails?.id) }}>Share</button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    )
}

export default SharePopup