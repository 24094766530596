import { GET_STUDENT_LOGIN_TREND, GET_COMPLETIONS, GET_COUNT_SUBJECT, GET_ENGAGEMENT_SCORE, GET_TOPIC_MATRICS, GET_SCORE_HISTORY, GET_NEXT_SCORE_HISTORY, RESET_HISTORY, } from '../Types/types';

let intialState = {
    scores: [],
    engagement_score: '',
    subject: [],
    contentCounts: [],
    ishistoryLoading: false,
    scoreHistory: []
}

export default function dashboardReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state))
    switch (action.type) {
        case GET_TOPIC_MATRICS:
            stateCopy.topicMatrics = action.payload;
            return stateCopy;
        case GET_STUDENT_LOGIN_TREND:
            console.log(action.payload)
            const arr1 = [];
            action.payload.dates.forEach(function (v, i) {
                var obj = {};
                obj.date = v;
                obj.login = action.payload.logins[i];
                arr1.push(obj);
            });
            stateCopy.studentTrend = arr1;
            return stateCopy;
        case GET_COMPLETIONS:
            stateCopy.subjects = action.payload.data.subject;
            stateCopy.subjectComplete = action.payload.data.complete;
            stateCopy.subjectCount = action.payload.data.count;
            stateCopy.testComplete = action.payload.data1.complete;
            stateCopy.testCount = action.payload.data1.count;
            return stateCopy;

        case GET_ENGAGEMENT_SCORE:
            // stateCopy.months = action.payload.months;
            // stateCopy.scores = action.payload.scores;
            // stateCopy.engagement_score = action.payload.engagement_score;
            return stateCopy;

        case GET_COUNT_SUBJECT:
            // stateCopy.subject = action.payload.subjects;
            // stateCopy.contentCounts = action.payload.data;
            return stateCopy;
        case GET_SCORE_HISTORY:
            stateCopy.scoreHistory = action.payload;
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case GET_NEXT_SCORE_HISTORY:
            stateCopy.scoreHistory = stateCopy.scoreHistory.concat(action.payload);
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case RESET_HISTORY:
            stateCopy.scoreHistory = [];
            stateCopy.ishistoryLoading = true;
            return stateCopy;
        default:
            return stateCopy;
    }
}