import {
    OPEN_ADD_NOTICE, CLOSE_ADD_NOTICE, GET_ADMIN_NOTICE, GET_NEXT_ADMIN_NOTICE, GET_SEARCH_RESULT, GET_USER_SUGGESTION, DEACTIVATE_NOTICE, GET_VIEW_DETAIL, EMPTY_VIEWED, CLEAR_DETAILS, NOTICE_CREATED, GET_FILES, GET_STUDENTS, GET_STAFF,
    SELECT_ALL, SELECT_ONE_CHECKED, SELECT_SELECTED, RESET_USERLIST, RESET_EMAIL_USER,
    GET_STUDENT_NOTICE_LIST, GET_STUDENT_NEXT_NOTICE_LIST, GET_SEARCH_RESULT1, GET_ATTACHMENT_RESOURCE, GET_EXTRA_DETAILS
} from '../Types/types';


let intialState = {
    isAddNotice: false,
    suggestions: [],
    userList: [],
    programs: [],
    sections: [],
    roles: [{ value: 'admin', label: 'Admin', value1: "role" }, { value: 'faculty', label: 'Faculty', value1: "role" }, { value: 'coordinator', label: 'Coordinator', value1: "role" }, { value: 'counsellor', label: 'Counsellor', value1: "role" }],
    subjects: [],
    isListLoading: true,
    loading: true,
    isLoading: true,
    files: null,
    attachmentResource: []
}

export default function noticeReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case OPEN_ADD_NOTICE:
            stateCopy.isAddNotice = true;
            stateCopy.attachmentResource = [];
            return stateCopy;
        case CLOSE_ADD_NOTICE:
            stateCopy.isAddNotice = false;
            stateCopy.suggestions = [];
            return stateCopy;
        case GET_ADMIN_NOTICE:
            stateCopy.noticeLists = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case GET_NEXT_ADMIN_NOTICE:
            stateCopy.noticeLists = stateCopy.noticeLists.concat(action.payload);
            return stateCopy;
        case GET_SEARCH_RESULT1:
            stateCopy.noticeLists = action.payload;
            return stateCopy;
        case GET_USER_SUGGESTION:
            stateCopy.suggestions = action.payload;
            return stateCopy;
        case RESET_EMAIL_USER:
            stateCopy.suggestions = [];
            return stateCopy
        case DEACTIVATE_NOTICE:
            stateCopy.noticeLists.forEach(element => {
                if (element._id === action.payload) {
                    element.expired = true;
                }
            });
            return stateCopy;
        case GET_VIEW_DETAIL:
            stateCopy.toArr = action.payload.to.map(item => ({ ...item, isViewed: false }));
            stateCopy.readsArr = action.payload.read;
            stateCopy.toArr.map((a, i) => {
                if (stateCopy.readsArr.some(s => s._id === a._id)) {
                    return a.isViewed = true
                }
                return false
            }
            );
            stateCopy.loading = false;
            return stateCopy;
        case EMPTY_VIEWED:
            stateCopy.toArr = [];
            stateCopy.xtraDetail = {};
            stateCopy.loading = true;
            return stateCopy;
        case CLEAR_DETAILS:
            stateCopy.xtraDetail = {};
            stateCopy.isLoading = true;
            return stateCopy;
        case NOTICE_CREATED:
            stateCopy.isAddNotice = false;
            stateCopy.noticeLists = [action.payload, ...stateCopy.noticeLists]
            return stateCopy;
        case GET_FILES:
            stateCopy.files = { title: action.payload.title, url: action.payload.url }
            return stateCopy

        case GET_STUDENT_NOTICE_LIST: //student
            stateCopy.studentNoticeList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy
        case GET_STUDENT_NEXT_NOTICE_LIST:
            stateCopy.studentNoticeList = stateCopy.studentNoticeList.concat(action.payload);
            return stateCopy;
        case GET_SEARCH_RESULT:
            stateCopy.studentNoticeList = action.payload;
            return stateCopy
        case GET_ATTACHMENT_RESOURCE:
            stateCopy.attachmentResource = action.payload;
            return stateCopy;
        case GET_STUDENTS:
            stateCopy.userList = action.payload.map(item => ({ ...item, isChecked: false, type: 'student' }));
            stateCopy.userListcopy = stateCopy.userList;
            return stateCopy;
        case GET_STAFF:
            stateCopy.staffList = action.payload.map(item => ({ ...item, isChecked: false, type: 'staff' }));
            stateCopy.staffListCopy = stateCopy.staffList;
            return stateCopy;


        case SELECT_ALL:
            if (action.payload.type === 'student') {
                if (action.payload.checked) {
                    stateCopy.userList = stateCopy.userList.map(item => ({ ...item, isChecked: true }));
                }
                else {
                    stateCopy.userList = stateCopy.userList.map(item => ({ ...item, isChecked: false }));
                }
            }
            else {
                if (action.payload.checked) {
                    stateCopy.staffList = stateCopy.staffList.map(item => ({ ...item, isChecked: true }));
                }
                else {
                    stateCopy.staffList = stateCopy.staffList.map(item => ({ ...item, isChecked: false }));
                }
            }
            return stateCopy
        case SELECT_ONE_CHECKED:
            if (action.payload.type === 'student') {
                if (action.payload.checked) {
                    stateCopy.userList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = true
                        }
                    })
                }
                else {
                    stateCopy.userList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = false
                        }
                    })
                }
            }
            else {
                if (action.payload.checked) {
                    stateCopy.staffList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = true
                        }
                    })
                }
                else {
                    stateCopy.staffList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = false
                        }
                    })
                }
            }

            return stateCopy;
        case SELECT_SELECTED:
            if (action.payload.type === 'student') {
                if (action.payload.key === 'selected') {
                    stateCopy.userListcopy = stateCopy.userList
                    stateCopy.userList = stateCopy.userList.filter(el => el.isChecked === true)
                }
                else {
                    stateCopy.userList = stateCopy.userListcopy
                }
            }
            else {
                if (action.payload.key === 'selected') {
                    stateCopy.staffListCopy = stateCopy.staffList
                    stateCopy.staffList = stateCopy.staffList.filter(el => el.isChecked === true)
                }
                else {
                    stateCopy.staffList = stateCopy.staffListCopy
                }
            }

            return stateCopy;
        case RESET_USERLIST:
            stateCopy.userList = [];
            stateCopy.staffList = []
            return stateCopy;







        case 'Reset_Popups':
            if (action.payload === 'student') {
                stateCopy.userList = stateCopy.userListcopy.map(item => ({ ...item, isChecked: false }));
            }
            else {
                stateCopy.staffList = stateCopy.staffListCopy.map(item => ({ ...item, isChecked: false }));
            }
            return stateCopy;
        case GET_EXTRA_DETAILS:
            stateCopy.xtraDetail = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;

        default:
            return stateCopy;

    }
}
