import React, { Fragment, useState } from 'react';
import "./practiceTest.scss";
import Drawer from '@mui/material/Drawer';


function QuizPopup(props) {
    const [showExplanation, setShowExplanation] = useState(false);

    const { t, type, activeFeedData, questions, quiz, quizCompleted, question, count, score, time } = props
    return (
        <div className="modal-body" style={{ background: '#fff' }}>
            <div className='test-holder text-center'>
                {quiz ?
                    <div className='d-flex justify-content-between p-3'>
                        <img src={localStorage.getItem('logo')} alt='' width={100} />
                        <div className='d-flex align-items-center p-3 timer'>
                            <img src='/images/timer.svg' alt='' />&nbsp;&nbsp; <p>{Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}</p>
                        </div>
                    </div>
                    :
                    <div onClick={() => props.closeQuizpopup()} className='close-quiz'>
                        <img src='/images/close1.svg' alt='' />
                    </div>
                }
                {
                    quiz ?
                        <Fragment>
                            <div className='question-div'>

                                {/* <h1 className='float-left mt-2' style={{ fontSize: 34 }}>{activeFeedData?.title}</h1> */}
                                {question !== undefined &&
                                    <div className='col-md-6 offset-md-3 ' style={{ marginTop: 0 }}>
                                        <h5>{t('Question')} {count + 1}/{questions?.length}</h5>
                                        <div class="container">
                                            <div class="row justify-content-center align-items-center">
                                                <div class="col-md-12">
                                                    <div class="text-left">
                                                        <h4 dangerouslySetInnerHTML={{ __html: window.location.pathname.includes('content-library') ? question.title : question?.description }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            window.location.pathname.includes('content-library') ?
                                                <ul className='options'>
                                                    {
                                                        question?.options?.map((ans, i) => {
                                                            return (
                                                                <li className='row position-relative' key={ans?._id} >
                                                                    <div onClick={() => props.selectAnswer(question._id, ans._id)} className={question.answer === ans._id ? 'col-md-7 col-7 option1 ans-color' : 'option1 col-md-7 col-7'}>
                                                                        <div className='d-flex align-items-center' >
                                                                            <div>
                                                                                <div className={'choice'}>{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : i === 3 ? 'D' : 'E'}</div>
                                                                            </div>
                                                                            <p
                                                                                dangerouslySetInnerHTML={{ __html: ans.title }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul> :
                                                <ul className='options'>
                                                    {
                                                        question?.options?.map((ans, i) => {
                                                            return (
                                                                <li className='row position-relative' key={ans?._id} style={{ pointerEvents: question.answer !== null ? 'none' : 'auto' }} >
                                                                    <div onClick={() => props.selectAnswer(question._id, ans._id)} className={question.correct_id === ans._id && question.correct ? 'correct option col-md-7 col-9' : question.correct === false && question.answer === ans._id ? 'incorrect option col-md-7 col-9' : question.correct_id === ans._id ? 'correct option col-md-7 col-9' : 'option1 col-md-7 col-9'}>
                                                                        <div className='d-flex align-items-center' >
                                                                            <div className={question.correct_id === ans._id && question.correct ? 'correct choice' : question.correct === false && question.answer === ans._id ? 'incorrect  choice' : question.correct_id === ans._id ? 'correct  choice ' : 'choice'}>{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}</div>
                                                                            <p
                                                                                dangerouslySetInnerHTML={{ __html: ans.title }}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-1 col-2'>
                                                                            {question.correct_id === ans._id && question.correct ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : question.correct === false && question.answer === ans._id ? <img src='/images/incorrect.svg' style={{ verticalAlign: 'middle' }} alt='' /> : question.correct_id === ans._id ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-2 col-2' >
                                                                        {question.explanation !== null ? window.innerWidth > 767 && question?.correct_id === ans?._id ?
                                                                            showExplanation ?
                                                                                <div className='explanation-popup' >
                                                                                    <h1>{t('Explanation')}</h1>
                                                                                    <div className='close-explaination' onClick={() => setShowExplanation(false)}><img src='/images/close1.svg' alt='' width='10px' /></div>
                                                                                    <div style={{ textAlign: 'left', marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: question.explanation }}>
                                                                                    </div>
                                                                                </div> :
                                                                                <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => {
                                                                                    setShowExplanation(true);
                                                                                    // setTarget(e.target);
                                                                                }} />
                                                                            : question.correct_id === ans._id && <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => setShowExplanation(true)} />
                                                                            : null
                                                                        }
                                                                        {
                                                                            window.innerWidth < 767 && ['bottom'].map((anchor) => (
                                                                                <React.Fragment key={anchor}>
                                                                                    <Drawer
                                                                                        anchor={anchor}
                                                                                        open={showExplanation}
                                                                                        className='mobile-drawer'
                                                                                    >

                                                                                        {
                                                                                            showExplanation ?
                                                                                                <div style={{ padding: 16 }} >
                                                                                                    <h1>{t('Explanation')}</h1>
                                                                                                    <div className='close-explaination' onClick={() => setShowExplanation(false)}><img src='/images/close1.svg' alt='' width='12px' /></div>
                                                                                                    <small dangerouslySetInnerHTML={{ __html: question?.explanation }}></small>
                                                                                                </div>
                                                                                                : null
                                                                                        }

                                                                                    </Drawer>
                                                                                </React.Fragment>))


                                                                        }

                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                        }

                                        <div className='fixed-prev mt-4' style={{ marginLeft: window.innerWidth > 767 ? -25 : 5, cursor: 'pointer' }}>
                                            {
                                                count === 0 ?
                                                    null :
                                                    <div className='prev-quiz' onClick={() => {
                                                        props.getPrev(count)
                                                        setShowExplanation(false)
                                                    }
                                                    } ><img src='/images/left-arrow.png' width={12} alt='' />&nbsp;{t('Prev')}</div>
                                            }
                                            &nbsp;
                                            {
                                                count + 1 === questions?.length ? null :
                                                    <div className='next-quiz' onClick={() => {
                                                        props.getNext(count)
                                                        setShowExplanation(false)
                                                    }
                                                    } >{t('Next')} &nbsp;<img src='/images/right-arrow.png' width={12} alt='' /></div>
                                            }
                                            {count + 1 === questions?.length ?
                                                <div className='next-quiz' onClick={() => props.quizSubmited(activeFeedData?._id)} style={{ display: 'flex', marginLeft: 40, fontWeight: '600' }}>{t('Submit')}</div>
                                                : null
                                            }
                                        </div>
                                    </div>}
                            </div>
                        </Fragment>
                        :

                        quizCompleted ?
                            <div className='col-md-6 offset-md-3 d-flex justify-content-center flex-column align-items-center quiz-complete'>
                                <div onClick={() => props.closeQuizpopup()} className='close-quiz'>
                                    <img src='/images/close1.svg' alt='' />
                                </div>
                                <div className='mt-3'>
                                    <img src='/images/happy.gif' width='300px' height='300px' alt='' />
                                </div>
                                <p>{t('Yay! Test Completed')}</p>
                                <small>{t('Congrats, You have completed your test and your score is')}</small>
                                {type === 'test' ?
                                    <div className='score'>
                                        {score}%
                                    </div>
                                    : <div className='score'>
                                        {score}/{questions?.length}
                                    </div>
                                }
                                <button
                                    onClick={props.closeQuizpopup}
                                    // onClick={() => props.reTakeQuiz(activeFeedData?._id)} 
                                    className='start-btn'>
                                    {t('Close Test')}
                                </button>
                                {/* <p onClick={() => props.closeQuizpopup()} >
                                    Exit Test
                                </p> */}

                            </div>
                            :
                            <div className='col-md-6 offset-md-3 d-flex justify-content-center flex-column align-items-center mt-5'>

                                <h1>{activeFeedData?.title}</h1>
                                <p>{activeFeedData?.questions?.length} {t('Multiple Choice Question')} | {activeFeedData?.time} {t('Minutes')}</p>
                                <div className='mt-3'>

                                    <img src='/images/quiz-image.png' alt='' width={window.innerWidth > 767 ? 451 : 340} />
                                </div>
                                <small className='desc text-center'>
                                    {activeFeedData?.description}
                                </small>
                                <br />
                                <button onClick={() => props.goToQuestion(questions[0])} className='start-btn'>
                                    {t('Start Test')}
                                </button>

                            </div>

                }

            </div>
        </div >
    )
}




export default QuizPopup;