import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAffliations } from '../../Redux/ActionCreators/manageContentAction';
import { fileToBase64URL } from '../../utils/miscelleneous';
import { addSchool, updateSchool, addInstitute, updateInst, addFaculty, changeSections, updateClasses, getClasses } from '../../Redux/ActionCreators/accountAction';
import Select, { components } from 'react-select';
import { CompactPicker } from 'react-color';
import CreatableSelect from 'react-select/creatable';


const languages = [{ value: 'English', label: 'English' }, { value: 'Tamil', label: 'Tamil' }, { value: 'Kannada', label: 'Kannada' }]


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

const AddSchool = (props) => {
    const [inputFields, setInputFields] = useState({
        name: '', contact_name: '', contact_email: '', contact_phone: '', domain: '',
        institution_id: '', address: '', colors: {}, city: '', pincode: '', logo: '', logoImage: '/images/empty-image.svg'
    });
    const [inputError, setInputError] = useState({
        name: '', contact_name: '', contact_email: '', contact_phone: '', domain: '', institution_id: '', colors: '',
        address: '', city: '', pincode: '', logo: ''
    })
    const [instFields, setInstFields] = useState({
        name: '', contact_name: '', contact_email: '', contact_phone: '', domain: '', logo: '', logoImage: '/images/empty-image.svg', language: [], colors: { sidebarBgColor: '', btnColor: '' }
    });
    const [instErrors, setInstErrors] = useState({
        name: '', contact_name: '', contact_phone: '', contact_email: '', domain: '', logo: ''
    })

    const [lang, setLang] = useState('');
    const [langError, setLangError] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [affiliationError, setAffiliationError] = useState('');
    const [colors, setColors] = useState({ sidebarBgColor: '', btnColor: '', textColor: '#fff' });
    const [images, setImages] = useState([{ quote: '', image: '', imageError: '', imageUrl: '/images/empty-image.svg', imageName: '' }, { quote: '', image: '', imageError: '', imageUrl: '/images/empty-image.svg', imageName: '' }, { quote: '', image: '', imageUrl: '/images/empty-image.svg', imageError: '', imageName: '' }]);
    const [imagesError, setImagesError] = useState([{ quote: '', image: '' }, { quote: '', image: '' }, { quote: '', image: '' }]);
    const [id, setId] = useState('');
    const [successMessageText, setsuccessMessageText] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);
    const dispatch = useDispatch();
    const { affliations, selectFromSubject } = useSelector(state => state.manageContent);
    const { institutions, classList } = useSelector(state => state.account);


    useEffect(() => {
        dispatch(getAffliations());
        const { details, edit } = props
        if (edit && details) {
            if (type === 'institute') {
                setId(details?._id);
                setInstFields({ ...details, logoImage: details.logo });
                setLang(details?.language?.map(el => [{ label: el, value: el }][0]));
                setImages(images?.map((quote, index) => ({
                    quote: details?.quotes[index],
                    image: details?.images[index] || '',
                    imageUrl: details?.images[index] ? details?.images[index] : '/images/empty-image.svg',
                    imageName: '',
                    imageError: ''
                })));

            }
            else if (type === 'school') {
                setId(details._id);
                setInputFields({ ...details, institution_id: details?.institution?._id, logoImage: details.logo });
                setColors(details?.colors);
                setAffiliation(details?.affiliation?.map(el => [{ label: el.name, value: el._id }][0]))
                // const arr1 = details?.quotes?.map((quote, index) => ({
                //     quote,
                //     image: details?.images[index] || '',
                //     imageUrl: details?.images[index] ? details?.images[index] : '/images/empty-image.svg',
                //     imageName: '',
                //     imageError: ''
                // }));
                setImages(images.map((quote, index) => ({
                    quote: details.quotes[index],
                    image: details?.images[index] || '',
                    imageUrl: details?.images[index] ? details?.images[index] : '/images/empty-image.svg',
                    imageName: '',
                    imageError: ''
                })));

            }
            else if (type === 'class') {
                setAffiliation({ label: details?.activeTab?.name, value: details?.activeTab?._id })
            }
        } else if (!edit && details && type === 'class') {
            setAffiliation({ label: details?.activeTab?.name, value: details?.activeTab?._id });
        }
    }, [])

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        // Update input values
        if (type === 'file') {
            if (e.target.files[0]?.size > 2097152) {
                setSuccessMsg(true);
                setsuccessMessageText("The file size cannot exceed 2MB")
            }
            else {
                const file = e.target.files[0]
                if (file) {
                    fileToBase64URL(file, (base64URL) => {
                        setInputFields((prevValues) => ({
                            ...prevValues,
                            [name]: file,
                            logoImage: base64URL
                        }));
                    });

                }
            }

        } else {
            setInputFields((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));

        }

        setInputError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }

    const selectAffiliation = (e) => {
        setAffiliation(e);
        setAffiliationError('');
        dispatch(getClasses(e?.value));
    }

    const handleColors = (hex, type) => {
        if (type === 'sidebarcolor') {
            setColors({ ...colors, sidebarBgColor: hex })
        } else {
            setColors({ ...colors, btnColor: hex })
        }
    }

    const onChangeQuotes = (e, index) => {
        e.preventDefault();
        const { name, value, type } = e.target;
        if (type === 'file') {
            if (e.target.files[0]?.size > 2097152) {
                setSuccessMsg(true);
                setsuccessMessageText("The file size cannot exceed 2MB")
            }
            else {
                const file = e.target.files[0]
                if (file) {
                    fileToBase64URL(file, (base64URL) => {
                        setImages((prevData) => {
                            const newData = [...prevData];
                            newData[index] = {
                                ...newData[index], [name]: e.target.files[0], imageUrl: base64URL,
                                imageName: file.name
                            };
                            return newData;
                        });
                    });

                }
            }

        } else {
            setImages((prevData) => {
                const newData = [...prevData];
                newData[index] = { ...newData[index], [name]: value };
                return newData;
            });
        }

        setImagesError((prevData) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], [name]: '' };
            return newData;
        });
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        else if (object.target.value.length < object.target.maxLength) {
            setInputError((prevErrors) => ({
                ...prevErrors,
                [object.target.name]: 'Please enter correct number',
            }));
        }
        else if (object.target.value.length === object.target.maxLength) {
            setInputError((prevErrors) => ({
                ...prevErrors,
                pincode: '',
            }));
        }
    }

    const saveSchool = async (e, edit) => {
        e.preventDefault();

        // Validation
        const newErrors = {};
        Object.keys(inputFields).forEach((key) => {
            if (key === 'logo') {
                if (!inputFields[key]) {
                    newErrors[key] = 'Logo is required.';
                }
            } else if (inputFields[key] === '') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
            }
        });

        // const newImagesError = images.map((image, index) => {
        //     const imageError = {};
        //     if (image.quote.trim() === '') {
        //         imageError.quote = 'Quote is required.';
        //     } else {
        //         imageError.quote = '';
        //     }
        //     if (image?.image === '') {
        //         imageError.image = 'Image is required.';
        //     }
        //     else {
        //         imageError.image = '';
        //     }
        //     return imageError;
        // });
        // && !newImagesError.some((error) => error.quote !== '' || error.image !== '')


        setInputError(newErrors);

        // setImagesError(newImagesError);
        if (!affiliation?.length) {
            setAffiliationError('Please select Affiliation')
        }
        else {
            if ((Object.keys(newErrors).length === 0)) {
                const formData = new FormData();
                const data = { ...inputFields, affiliation_id: affiliation?.map(el => el.value), colors: colors, school_id: edit ? id : '', quotes: images.map(el => el.quote), images: images.map(el => el.image) };

                for (const key in data) {
                    if ((key !== 'logoImage') && (key !== '_id')) {
                        if (Array.isArray(data[key])) {
                            if (key === 'images') {
                                data['images'].forEach((file, index) => {
                                    formData.append(`images`, file);
                                });
                            } else {
                                formData.append(key, JSON.stringify(data[key]));
                            }
                        } else if (key === 'colors') {
                            formData.append(key, JSON.stringify(data[key]));
                        }
                        else {
                            // Handle other properties
                            formData.append(key, data[key]);
                        }
                    }
                }
                if (!edit) {
                    const res = await dispatch(addSchool(formData));
                    if (res?.type === 'ADD_SCHOOL') {
                        await props.getSchools();
                        await props.backToMain();
                    }
                } else {
                    const res = await dispatch(updateSchool(formData));
                    if (res?.type === 'UPDATE_SCHOOL') {
                        await props.getSchools();
                        await props.backToMain();
                    }
                }

            }
        }
    }



    // states

    const selectLanguages = (e) => {
        setLang(e);
        setLangError('');
    }
    const handleChangeColor = (val, type) => {
        if (type === 'header') {
            setInstFields((prevState) => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    sidebarBgColor: val,
                }
            }));
        } else {
            setInstFields((prevState) => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    btnColor: val,
                }
            }));
        }
    }

    const handleInstChange = (e) => {
        const { name, value, type } = e.target;
        // Update input values
        if (type === 'file') {
            if (e.target.files[0]?.size > 2097152) {
                setSuccessMsg(true);
                setsuccessMessageText("The file size cannot exceed 2MB")
            }
            else {
                const file = e.target.files[0]
                if (file) {
                    fileToBase64URL(file, (base64URL) => {
                        setInstFields((prevValues) => ({
                            ...prevValues,
                            [name]: file,
                            logoImage: base64URL
                        }));
                    });

                }
            }

        } else {
            setInstFields((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));

        }

        setInstErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }

    const saveInst = async (e, edit) => {
        e.preventDefault();
        const newErrors = {};
        Object.keys(instFields).forEach((key) => {
            if (key === 'logo') {
                if (!instFields[key]) {
                    newErrors[key] = 'Logo is required.';
                }
            } else if (instFields[key] === '') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
            }
        });

        // const newImagesError = images.map((image, index) => {
        //     const imageError = {};
        //     if (image.quote.trim() === '') {
        //         imageError.quote = 'Quote is required.';
        //     } else {
        //         imageError.quote = '';
        //     }
        //     if (image?.image === '') {
        //         imageError.image = 'Image is required.';
        //     }
        //     else {
        //         imageError.image = '';
        //     }
        //     return imageError;
        // });
        // !newImagesError.some((error) => error.quote !== '' || error.image !== '')

        setInstErrors(newErrors);
        // setImagesError(newImagesError);
        if (lang === '') {
            setLangError('Please select Language')
        } else {
            if ((Object.keys(newErrors).length === 0)) {

                const data = { ...instFields, institution_id: edit ? id : '', language: lang?.map(el => el.value), quotes: images.map(el => el.quote), images: images.map(el => el.image) }

                const formData = new FormData();
                for (const key in data) {
                    if (key !== 'logoImage') {
                        if (Array.isArray(data[key])) {
                            if (key === 'images') {
                                data['images'].forEach((file, index) => {
                                    formData.append(`images`, file);
                                });
                            } else {
                                formData.append(key, JSON.stringify(data[key]));
                            }
                        } else if (key === 'colors') {
                            formData.append(key, JSON.stringify(data['colors']));
                        }
                        else {
                            formData.append(key, data[key]);
                        }
                    }
                }
                if (!edit) {
                    const res = await dispatch(addInstitute(formData));
                    if (res?.type === 'ADD_INSTITUTE') {
                        await props.getAllInstitutes();
                        await props.backToMain();
                    }
                } else {
                    const res = await dispatch(updateInst(formData));
                    if (res?.type === 'EDIT_INSTITUTE') {
                        await props.getAllInstitutes();
                        await props.backToMain();
                    }
                }
            }
        }
    }




    const snackClosed = () => {
        setSuccessMsg(false);
        setsuccessMessageText('');
    }

    const changeSection = (e, el) => {
        dispatch(changeSections(e, el));
    }


    const saveClass = async () => {
        let obj = {
            affiliation_id: props?.details?.activeTab?._id,
            classes: classList?.map(el => [{ class: el.value, sections: el.sections?.map(el => [{ name: el.label, _id: el._id }][0]) }][0])
        }
        const res = await dispatch(updateClasses(obj));
        if (res?.type === 'UPDATE_CLASSES') {
            await dispatch(getClasses());
            await props.backToMain();
        }
    }


    const { edit, type } = props
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMsg}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            {type === 'school' ?
                <div className='add-center'>
                    <div className='d-flex justify-content-between align-items-center' style={{ width: '60%' }}>
                        <h2 onClick={props.backToMain}><img src='/images/back.svg' width='20px' alt='' /> </h2>
                        <h2>{edit ? 'Edit School' : 'New School'}</h2>
                    </div>
                    <p className='mt-4'>Fields marked * are mandatory</p>
                    <div className='form-group mt-2'>
                        <label>School Name*
                        </label>
                        <input className='form-control mt-2' style={{ width: window.innerWidth > 767 ? '86%' : '100%' }} name='name' value={inputFields.name} onChange={(e) => handleInputChange(e)} />
                        <small>{inputError.name}</small>
                    </div>
                    <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Contact name*
                                </label>
                                <input className='form-control mt-2 ' name='contact_name' value={inputFields.contact_name} onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.contact_name}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Contact number*
                                </label>
                                <input type='number' className='form-control mt-2 no-spin ' maxLength='10' onInput={maxLengthCheck} value={inputFields.contact_phone} name='contact_phone' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.contact_phone}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Contact email*
                                </label>
                                <input className='form-control mt-2 ' value={inputFields.contact_email} name='contact_email' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.contact_email}</small>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Domain*
                                </label>
                                <input className='form-control mt-2 ' value={inputFields.domain} name='domain' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.domain}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Affiliation*
                                </label>
                                <div className="mt-2">
                                    <Select
                                        isMulti
                                        components={{ DropdownIndicator }}
                                        placeholder='Select Affiliation'
                                        value={affiliation}
                                        onChange={selectAffiliation}
                                        options={affliations?.map(el => [{ label: el.name, value: el._id }][0])}
                                    />
                                </div>
                                <small>{affiliationError}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Institutions*
                                </label>
                                <select className='form-control mt-2 ' value={inputFields.institution_id} name='institution_id' onChange={(e) => handleInputChange(e)} >
                                    <option hidden>Select Institution</option>
                                    {
                                        institutions?.map((el) => {
                                            return (
                                                <option value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <small>{inputError.institution_id}</small>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>City*
                                </label>
                                <input className='form-control mt-2 ' value={inputFields.city} name='city' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.city}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>pinCode*
                                </label>
                                <input type='number' maxLength='6' onInput={maxLengthCheck} className='form-control mt-2 no-spin' name='pincode' value={inputFields.pincode} onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.pincode}</small>
                            </div>
                        </div>
                    </div>
                    <div className='image-upload mt-3'>
                        <label>School Logo*</label>
                        <div className='d-flex align-items-center mb-1'>
                            {
                                inputFields?.logoImage ?
                                    <div className='program-image'>
                                        <img src={inputFields.logoImage} alt='' width='139px' height='78px' />
                                    </div> :
                                    <div className='program-image'>
                                        <img src={inputFields.logoImage} alt='' />
                                    </div>
                            }

                            <div className='d-flex flex-column mt-3 position-relative'>
                                {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Logo &nbsp; <small></small>
                                    <input type='file' className='file-upload' name='logo'
                                        accept="image/png, image/jpeg" onChange={handleInputChange} />
                                </span>
                            </div>
                        </div>
                        <small>{inputError.logo}</small>
                    </div>
                    <div className='form-group mt-3'>
                        <label>Address
                        </label>
                        <textarea className='form-control mt-2' value={inputFields.address} name='address' onChange={(e) => handleInputChange(e)} />
                        <small>{inputError.address}</small>
                    </div>
                    <p className='mt-3'>Update Color details here</p>
                    <div className='d-flex flex-wrap'>
                        <div className='mt-3'>
                            <label>For Header</label>
                            <div className='mt-2'>
                                <CompactPicker
                                    color={colors.sidebarBgColor}
                                    onChangeComplete={(e) => handleColors(e.hex, 'sidebarcolor')}
                                    colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115',
                                        '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666',
                                        '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                />
                            </div>
                        </div>&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className='mt-3'>
                        <label>For Button</label>
                        <div className='mt-2'>
                            <CompactPicker
                                color={inputFields?.colors.btnColor}
                                onChangeComplete={(e) => handleColors(e.hex, 'btnColor')}
                                colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
                                    '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF',
                                    '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1',
                                    '#653294', '#AB149E']}
                            />
                        </div>
                    </div>
                    <span style={{ color: 'red' }}>{inputError?.colors}</span>
                    {/* colors - {sidebarBgColor: "#333269",  btnColor: "#5855d6", textColor: "#fff"} */}
                    {
                        images?.map((el, i) => {
                            return (
                                <div className='row mt-2'>
                                    <div className='col-md-6'>
                                        <div className='form-group mt-2'>
                                            <label>Quote {i + 1}*
                                            </label>
                                            <textarea className='form-control mt-2' name='quote' rows={3} value={el.quote} onChange={(e) => onChangeQuotes(e, i)} />

                                            <small>{imagesError[i].quote}</small>                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='image-upload mt-3'>
                                            <label>Login Image {i + 1}*</label>
                                            <div className='d-flex align-items-center '>

                                                <div className='d-flex flex-column mt-3 position-relative'>
                                                    {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                    <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small></small>
                                                        <input type='file' className='file-upload' name='image'
                                                            accept="image/png, image/jpeg" onChange={(e) => onChangeQuotes(e, i)} />
                                                    </span>
                                                </div>
                                                {
                                                    el.image ?
                                                        <div className='program-image'>
                                                            <img src={el.imageUrl} alt='' width='139px' height='78px' />
                                                        </div> :
                                                        <div className='program-image'>
                                                            <img src={el.imageUrl} alt='' />
                                                        </div>
                                                }

                                            </div>
                                            <small>{imagesError[i].image}</small>
                                        </div>
                                    </div>


                                </div>
                            )
                        })
                    }
                    {
                        localStorage.getItem('role').includes('super-admin') && edit ?
                            <div className='row' style={{ width: '89.5%', marginBottom: 50 }}>
                                <div className='col-md-4'>
                                    <div className='form-group mt-2'>
                                        <label>School Id*
                                        </label>
                                        <input className='form-control mt-2' value={id} disabled />
                                    </div>
                                </div>
                            </div> : null
                    }
                    <hr />
                    <div className='save-btn mt-2'>
                        {
                            edit ?
                                <button onClick={(e) => saveSchool(e, edit)} >Save</button>
                                :
                                <button onClick={(e) => saveSchool(e, edit)}>
                                    Save</button>

                        }
                        <button onClick={props.backToMain}>Cancel</button>
                    </div>
                </div> :
                type === 'class' ?
                    <div className='add-center' style={{ backgroundColor: '#F9F9FF' }}>
                        <div className='d-flex justify-content-between align-items-center' style={{ width: '100%', paddingBottom: 15 }}>
                            <h2 onClick={props.backToMain}><img src='/images/back.svg' width='20px' alt='' /> </h2>
                            <h2>{'Update Section to Class'}</h2>
                            <div className='save-btn mt-0'>
                                <button onClick={() => saveClass()}>Save</button>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Affiliation*
                                </label>
                                <div className="mt-2">
                                    <Select
                                        components={{ DropdownIndicator }}
                                        placeholder='Select Affiliation'
                                        value={affiliation}
                                        onChange={selectAffiliation}
                                        options={affliations?.map(el => [{ label: el.name, value: el._id }][0])}
                                    />
                                </div>
                                <small>{affiliationError}</small>
                            </div>
                        </div>
                        <div className="table-admin" >
                            <Table responsive >
                                <thead>
                                    <tr className="header-table">
                                        <th>No.

                                        </th>
                                        <th>Class</th>
                                        <th>Section</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {
                                        (classList !== "" && classList !== undefined && classList?.length > 0 ?
                                            classList.map((el, index) => {

                                                return (
                                                    <tr key={el._id} >
                                                        <td>{index + 1}
                                                        </td>
                                                        <td className='text-el'>Class {el.class}</td>
                                                        <td>
                                                            <div className=''>
                                                                <CreatableSelect
                                                                    noOptionsMessage={() => null}
                                                                    value={el.sections}
                                                                    isMulti
                                                                    placeholder='Please type section name and enter'
                                                                    onChange={(e) => changeSection(e, el)}
                                                                />
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                            :
                                            null)
                                    }


                                </tbody>
                            </Table>

                        </div>
                        <div className='save-btn mt-2'>
                            <button onClick={props.backToMain}>Cancel</button>
                        </div>
                    </div> :
                    <div className='add-center'>
                        <div className='d-flex justify-content-between align-items-center' style={{ width: '60%' }}>
                            <h2 onClick={props.backToMain}><img src='/images/back.svg' width='20px' alt='' /> </h2>
                            <h2>{edit ? 'Edit Institute' : 'New Institute'}</h2>
                        </div>
                        <p className='mt-4'>Fields marked * are mandatory</p>
                        <div className='form-group mt-2'>
                            <label>Institute Name*
                            </label>
                            <input className='form-control mt-2' style={{ width: window.innerWidth > 767 ? '86%' : '100%' }} name='name' value={instFields.name} onChange={(e) => handleInstChange(e)} />
                            <small>{instErrors.name}</small>
                        </div>
                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Language*
                                    </label>
                                    <Select
                                        className='mt-2'
                                        isMulti
                                        components={{ DropdownIndicator }}
                                        options={languages}
                                        onChange={selectLanguages}
                                        placeholder='Select Language'
                                        value={lang}
                                    />
                                </div>
                                <small>{langError}</small>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Contact name*
                                    </label>
                                    <input className='form-control mt-2 ' name='contact_name' value={instFields.contact_name} onChange={(e) => handleInstChange(e)} />
                                    <small>{instErrors.contact_name}</small>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Contact email*
                                    </label>
                                    <input className='form-control mt-2 ' value={instFields.contact_email} name='contact_email' onChange={(e) => handleInstChange(e)} />
                                    <small>{instErrors.contact_email}</small>
                                </div>
                            </div>


                        </div>
                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Contact number*
                                    </label>
                                    <input type='number' className='form-control mt-2 no-spin ' maxLength='10' onInput={maxLengthCheck} value={instFields.contact_phone} name='contact_phone' onChange={(e) => handleInstChange(e)} />
                                    <small>{instErrors.contact_phone}</small>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Domain*
                                    </label>
                                    <input className='form-control mt-2 ' value={instFields.domain} name='domain' onChange={(e) => handleInstChange(e)} />
                                    <small>{instErrors.domain}</small>
                                </div>
                            </div>
                        </div>
                        <div className='image-upload mt-3 '>
                            <label>State Logo*</label>
                            <div className='d-flex align-items-center mb-1 '>
                                {
                                    inputFields?.logoImage ?
                                        <div className='program-image'>
                                            <img src={instFields.logoImage} alt='' width='139px' height='78px' />
                                        </div> :
                                        <div className='program-image'>
                                            <img src={instFields.logoImage} alt='' />
                                        </div>
                                }

                                <div className='d-flex flex-column mt-3 position-relative'>
                                    {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                    <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Logo &nbsp; <small></small>
                                        <input type='file' className='file-upload' name='logo'
                                            accept="image/png, image/jpeg" onChange={handleInstChange} />
                                    </span>
                                </div>
                            </div>
                            <small>{instErrors.logo}</small>
                        </div>
                        {
                            images?.map((el, i) => {
                                return (
                                    <div className='row mt-2'>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <label>Quote {i + 1}*
                                                </label>
                                                <textarea className='form-control mt-2' name='quote' rows={3} value={el.quote} onChange={(e) => onChangeQuotes(e, i)} />

                                                <small>{imagesError[i].quote}</small>                                        </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='image-upload mt-3'>
                                                <label>Login Image {i + 1}*</label>
                                                <div className='d-flex align-items-center '>

                                                    <div className='d-flex flex-column mt-3 position-relative'>
                                                        {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                        <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small></small>
                                                            <input type='file' className='file-upload' name='image'
                                                                accept="image/png, image/jpeg" onChange={(e) => onChangeQuotes(e, i)} />
                                                        </span>
                                                    </div>
                                                    {
                                                        el.image ?
                                                            <div className='program-image'>
                                                                <img src={el.imageUrl} alt='' width='139px' height='78px' />
                                                            </div> :
                                                            <div className='program-image'>
                                                                <img src={el.imageUrl} alt='' />
                                                            </div>
                                                    }

                                                </div>
                                                <small>{imagesError[i].image}</small>
                                            </div>
                                        </div>


                                    </div>
                                )
                            })
                        }
                        <div className='d-flex flex-wrap'>
                            <div className='mt-3'>
                                <label>For Header</label>
                                <div className='mt-2'>
                                    <CompactPicker
                                        name='colors'
                                        color={instFields?.colors?.sidebarBgColor}
                                        onChangeComplete={(e) => handleChangeColor(e.hex, 'header')}
                                        colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115',
                                            '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666',
                                            '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                    />
                                </div>
                            </div>&nbsp;&nbsp;&nbsp;
                            <div className='mt-3'>
                                <label>For Button</label>
                                <div className='mt-2'>
                                    <CompactPicker
                                        name='colors'
                                        color={instFields?.colors?.btnColor}
                                        onChangeComplete={(e) => handleChangeColor(e.hex, 'btn')}
                                        colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
                                            '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF',
                                            '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1',
                                            '#653294', '#AB149E']}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            localStorage.getItem('role').includes('super-admin') && edit ?
                                <div className='row' style={{ width: '89.5%', marginBottom: 50 }}>
                                    <div className='col-md-4'>
                                        <div className='form-group mt-2'>
                                            <label>State Id*
                                            </label>
                                            <input className='form-control mt-2' value={id} disabled />
                                        </div>
                                    </div>
                                </div> : null
                        }
                        <hr />
                        <div className='save-btn mt-2'>
                            {
                                edit ?
                                    <button onClick={(e) => saveInst(e, edit)} >Save</button>
                                    :
                                    <button onClick={(e) => saveInst(e, edit)}>
                                        Save</button>

                            }
                            <button onClick={props.backToMain}>Cancel</button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default AddSchool