/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import './users.scss';
import Table from 'react-bootstrap/Table';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import Modal from 'react-bootstrap/Modal';
import Select, { components } from 'react-select';
// import { CSVReader } from 'react-papaparse';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { BallBeat, TopicLoader } from '../../Loader/loader';
import { getStudentList, editedStudent, getSectionFromClass, getStaffList, getFilterList, getFilterStaffList, clearFilter, changeStatus, resetLists, selectAllStudent, selectAllStaff, checkOneStudent, checkOneStaff, viewStudent, backToMain, updateUserStatus, updateUserClass, resendInvite, } from '../../Redux/ActionCreators/userAction';
import ViewUser from './viewUser';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubjects, getAffliations } from '../../Redux/ActionCreators/manageContentAction';
import { getClasses } from '../../Redux/ActionCreators/accountAction';




const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

const buttonRef = React.createRef();

function Users() {
    const [activeBtn, setActiveBtn] = useState('staff');
    const [course, setCourse] = useState('');
    const [section, setSection] = useState('');
    const [role, setRole] = useState('');
    const [subjectId] = useState('');
    const [subject, setSubject] = useState('');
    const [roleValue] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [status, setStatus] = useState('');
    const [isOpenUpload, setIsOpenUpload] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [isUpdateStatus, setIsUpdateStatus] = useState(false);
    const [isUpdateClass, setIsUpdateClass] = useState(false);
    const [staffPage, setStaffPage] = useState(1);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [staffSize, setStaffSize] = useState(20);
    const [openMenu, setOpenMenu] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const [checkedId, setCheckedId] = useState([]);
    const [checkedStaffId, setCheckedStaffId] = useState([]);
    const [search1, setSearch1] = useState('');
    const [search2, setSearch2] = useState('');
    const [targetMenu, setTargetMenu] = useState(false);
    const [id, setId] = useState('');
    const [successEnable, setSuccesEnable] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [isAccess, setIsAccess] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [status1, setStatus1] = useState('');
    const [statusErr, setStatusErr] = useState('');
    const [classs, setClass] = useState('');
    const [classError, setClassError] = useState('');
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate();

    const roleOption = [{ value: 'admin', label: 'Admin', value1: "role" }, { value: 'faculty', label: 'Faculty', value1: "role" }, { value: 'coordinator', label: 'Coordinator', value1: "role" }, { value: 'counsellor', label: 'Counsellor', value1: "role" },];
    const statusOption = [{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }, { value: 'invited', label: 'Invited' }];
    const statusOption1 = [{ value: 'active', label: 'Active' }, { value: 'invited', label: 'Invited' }];


    const { activeTab, active, inactive, invited, totalPage, total, activeStaff, inactiveStaff, invitedStaff, totalStaff, totalStaffpage, studentList, staffList, sectionOfClass, subjects, isView, student, isListLoading, isSelectTab } = useSelector(state => state.user);
    const { files } = useSelector(state => state.notice);
    const { selectFromSubject, affliations } = useSelector(state => state.manageContent);
    const { classList } = useSelector(state => state.account);

    const dispatch = useDispatch();

    useEffect(() => {
        if (activeTab === 'student') {
            setActiveBtn('student');
            dispatch(getStudentList(size, page, 'student'));
        }
        else {
            setActiveBtn('staff');
            dispatch(getStaffList(staffSize, staffPage));
        }
        // dispatch(getAllProgram());

        dispatch(getAllSubjects());
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
    }, [])



    const onBtnChange = async (e) => {
        setActiveBtn(e);
        setShowAction(false);
        setOpenMenu(false);
        setCheckedId([]);
        setCheckedStaffId([]);
        setSearch1('');
        setPage(1);
        setStaffPage(1);
        await dispatch(resetLists());
        await clearFilters(e);
        if (e === 'student') {
            const res = await dispatch(getAffliations());
            if (res?.type === 'GET_AFFLIATIONS') {
                (res?.payload?.length === 1) ? setAffiliation({ label: res.payload[0]?.name, value: res.payload[0]?._id }) : setAffiliation(null);
                await dispatch(getClasses(res.payload[0]?._id));
            }
        }
        else if (e === 'staff') {
        }
    }

    const nextPage = () => {
        if (activeBtn === 'student') {
            setPage(page + 1);
            dispatch(getFilterList(size, page + 1, 'student', search1, classs?.class, section?.value, status?.value));
        }
        else {
            setStaffPage(staffPage + 1)
            dispatch(getFilterStaffList(staffSize, staffPage + 1, search2, role?.value, subject?.value, status?.value));
        }

    }

    const prevPage = () => {
        if (activeBtn === 'student') {
            setPage(page - 1);
            dispatch(getFilterList(size, page - 1, 'student', search1, classs?.class, section?.value, status?.value));
        }
        else {
            setStaffPage(staffPage - 1);
            dispatch(getFilterStaffList(staffSize, staffPage - 1, search2, role?.value, subject?.value, status?.value));
        }
    }

    const getIntial = (f, l) => {
        return f.split('')[0] + l.split('')[0];
    }

    const selectAffiliation = (e) => {
        setAffiliation(e);
        dispatch(getClasses(e.value));
        setClass('');
    }

    const selectClass = (e, type) => {
        console.log(e)
        if (type === 'filter') {
            setClass(e);
            dispatch(getSectionFromClass(e.class, affiliation?.value))
            setSection('');
        } else {
            setClass(e);
            setClassError('');
        }
    }

    const selectSection = (e) => {
        setSection(e)
    }

    const selectStatus = (e) => {
        setStatus(e);
    }

    const selectSubject = (e) => {
        setSubject(e)
    }

    const getRole = (e) => {
        setRole(e)
    }

    const timerRef = React.useRef(null);

    const searchStudent = (val) => {
        dispatch(resetLists());
        clearTimeout(timerRef.current);
        setSearch1(val);
        setPage(1);
        timerRef.current = setTimeout(() => getSearchValue(val), 800)
    }

    const getSearchValue = (val) => {
        if (!val.length) {
            if (activeBtn === 'student') {
                dispatch(getStudentList(size, page, 'student'));
            }
        }
        else {
            if (activeBtn === 'student') {
                dispatch(getFilterList(size, page, 'student', val, classs?.class, section?.value, status?.value));
            }
        }
    }

    const searchStaff = (val) => {
        dispatch(resetLists());
        setSearch2(val);
        setStaffPage(1);
        if (!search2.length) {
            dispatch(getStaffList(staffSize, staffPage));
        }
        else {
            dispatch(getFilterStaffList(staffSize, staffPage, val, role?.value, subject?.value, status?.value));
        }
    }

    const changeSize = async (val) => {
        await dispatch(resetLists());
        if (activeBtn === 'student') {
            setSize(val);
            dispatch(getFilterList(val, page, 'student', search1, classs?.class, section?.value, status?.value));
        }
        else {
            setStaffSize(val);
            dispatch(getFilterStaffList(val, staffPage, search2, role?.value, subject?.value, status?.value));
        }

    }

    const onFilterClick = async (value) => {
        await dispatch(resetLists());
        if (value === 'class') {
            setClass('');
            dispatch(getFilterList(size, page, 'student', search1, '', section?.value, status?.value));
        }
        else if (value === 'section') {
            setSection('');
            dispatch(getFilterList(size, page, 'student', search1, classs?.class, '', status?.value));
        }
    }

    const onStaffFilterClick = async (value) => {
        await dispatch(resetLists());
        if (value === 'subject') {
            setSubject('')
            dispatch(getFilterStaffList(staffSize, staffPage, search2, role?.value, '', status?.val));
        }
        else if (value === 'role') {
            setRole('');
            dispatch(getFilterStaffList(staffSize, staffPage, search2, '', subject?.value, status?.value));
        }
    }

    const getResults = async () => {
        setIsloading(true);
        await dispatch(getFilterList(size, page, 'student', search1, classs?.class, section?.value, status?.value));
        setIsOpenFilter(false);
        setIsloading(false);
        setStatus('');
    }

    const getStaffResults = async () => {
        setIsloading(true);
        await dispatch(getFilterStaffList(staffSize, staffPage, search2, role?.value, subject?.value, status?.value));
        setIsOpenFilter(false);
        setIsloading(false);
        setStatus('');
        setRole('');
        setSubject('');
    }

    const clearFilters = async (active) => {
        await dispatch(resetLists());
        await dispatch(clearFilter(active));
        setClass('');
        setSection('');
        setStatus('');
        setRole('');
        setSubject('');
    }


    const addManual = (type) => {
        navigate('/users/add', {
            state: { type: type }
        })
    }


    const openUpload = () => {
        setIsOpenUpload(true);
    }

    const openFilter = () => {
        setIsOpenFilter(true);
        setCourse('');
        setSection('');
        setStatus('');
    }

    const handleClose = () => {
        setIsOpenFilter(false);
        setIsOpenUpload(false);
        setIsUpdateClass(false);
        setIsUpdateStatus(false);
        setCheckedId([]);
        setCheckedStaffId([]);
        setAffiliation('');
        setClass('');
        clearFilters(activeBtn);
    }

    const openMenus = (e, id) => {
        setTargetMenu(e.target);
        setOpenMenu(!openMenu);
        setId(id);
    }


    const makeInactive = (id) => {
        dispatch(changeStatus(id, activeBtn));
        setOpenMenu(false);
    }


    const getCheckedId = async (e) => {
        await dispatch(checkOneStudent(e.target.value, e.target.checked));
        if (e.target.checked) {
            setCheckedId([...checkedId, e.target.value]);
        }
        else {
            setCheckedId(checkedId.filter(el => el !== e.target.value));
        }
    }

    const checkAll = async (e) => {
        setChecked(e.target.checked);
        await dispatch(selectAllStudent(e.target.checked));

    }

    const getCheckedStaffId = async (e) => {
        await dispatch(checkOneStaff(e.target.value, e.target.checked));
        if (e.target.checked) {
            setCheckedStaffId([...checkedStaffId, e.target.value])
        }
        else {
            setCheckedStaffId(checkedStaffId.filter(el => el !== e.target.value));
        }
    }

    useEffect(() => {
        if (activeBtn === 'staff') {
            const list = staffList.filter(el => el.isChecked === true).map(el => [el._id][0]);
            setCheckedStaffId(list);
        } else {
            const list = studentList.filter(el => el.isChecked === true).map(el => [el._id][0]);
            setCheckedId(list);
        }
    }, [staffList, studentList])

    const checkAllStaff = async (e) => {
        setChecked(e.target.checked);
        await dispatch(selectAllStaff(e.target.checked));
    }

    const updateStatus = () => {
        if (activeBtn === 'student') {
            if (!checkedId.length) {
                setSuccesEnable(true);
                setSuccessMessageText('Select one or more item from below list');
            }
            else {
                setIsUpdateStatus(true);
            }
        }
        else {
            if (!checkedStaffId.length) {
                setSuccesEnable(true);
                setSuccessMessageText('Select one or more item from below list');
            }
            else {
                setIsUpdateStatus(true);
            }
        }
    }

    const changeStatus1 = (e) => {
        setStatus1(e);
        setStatusErr('');
    }

    const updateStatuses = async () => {
        if (status1 === '') {
            setStatusErr('Please enter status');
        }
        else {
            if (activeBtn === 'student') {
                const obj = {
                    _ids: checkedId,
                    status: status1?.value === undefined ? '' : status1?.value
                }
                await dispatch(updateUserStatus(obj));
                if (activeBtn === 'student') {
                    await dispatch(getStudentList(size, page, 'student'));
                }
                setIsUpdateStatus(false);
                setChecked(false);
                setStatus1(null);
                setCheckedId([]);
                setCheckedStaffId([]);
                setStatusErr('');
            }
            else {
                const obj = {
                    _ids: checkedStaffId,
                    status: status1?.value === undefined ? '' : status1?.value
                }
                await dispatch(updateUserStatus(obj));
                await dispatch(getStaffList(size, page));
                setIsUpdateStatus(false);
                setChecked(false);
                setStatus1(null);
                setCheckedId([]);
                setCheckedStaffId([]);
                setStatusErr('');
            }
        }
    }

    const updateClass = () => {
        if (!checkedId.length) {
            setSuccesEnable(true);
            setSuccessMessageText('Select one or more item from below list');
        }
        else {
            setIsUpdateClass(true);
        }
    }


    const updateClassStatus = async () => {
        if (classs === '') {
            setClassError('Please enter class');
        }
        else {
            if (activeBtn === 'student') {
                const obj = {
                    _ids: checkedId,
                    class: classs?.class
                }
                await dispatch(updateUserClass(obj));
                if (activeBtn === 'student') {
                    await dispatch(getStudentList(size, page, 'student'));
                }
                await clearFilters(activeBtn);
                setIsUpdateClass(false);
                setChecked(false);
                setClass(null);
                setCheckedId([]);
                setCheckedStaffId([]);
                setStatusErr('');
            }
            else {
                const obj = {
                    _ids: checkedStaffId,
                    class: classs?.value
                }
                // await dispatch(updateUserSem(obj));
                await dispatch(getStaffList(size, page));
                await clearFilters(activeBtn);
                setIsUpdateClass(false);
                setChecked(false);
                setClass(null);
                setCheckedId([]);
                setCheckedStaffId([]);
                setStatusErr('');
            }
        }
    }

    const viewStudents = (id) => {
        setEdit(true);
        dispatch(viewStudent(id));
    }

    const editStudent = (id) => {
        setEdit(false);
        dispatch(viewStudent(id));
    }

    const goToEdit = (el) => {
        navigate('/users/add', {
            state: { type: 'edit', details: el, role: 'staff' }
        })
    }

    const editStudent1 = async (obj) => {
        await dispatch(editedStudent(obj));
        if (activeBtn === 'student') {
            await dispatch(getStudentList(size, page, 'student'));
        }
    }

    const resendInvites = async () => {
        if (activeBtn === 'student') {
            if (!checkedId?.length) {
                setSuccesEnable(true);
                setSuccessMessageText('Select one or more item from below list');
            }
            else {
                await dispatch(resendInvite(checkedId, 'student'));
                if (activeBtn === 'student') {
                    await dispatch(getStudentList(size, page, 'student'));
                }
                await setSuccesEnable(true);
                await setSuccessMessageText(`Invite sent to ${checkedId.length} Student`);
                setCheckedId([]);
                clearFilters(activeBtn);
            }
        }
        else {
            if (!checkedStaffId?.length) {
                setSuccesEnable(true);
                setSuccessMessageText('Select one or more item from below list');
            }
            else {
                await dispatch(resendInvite(checkedStaffId, 'staff'));
                await dispatch(getStaffList(size, page));
                await setSuccesEnable(true);
                await setSuccessMessageText(`Invite sent to ${checkedStaffId?.length} Staff`);
                setCheckedStaffId([]);
                clearFilters(activeBtn);
            }
        }
    }



    const resendIndivisualInvites = async (id) => {
        if (activeBtn === 'student') {
            setCheckedId([...checkedId, id]);
            await dispatch(resendInvite([...checkedId, id], 'student'));
            setSuccesEnable(true);
            setSuccessMessageText('Invite sent');
            setCheckedId([]);
        }
        else {
            setCheckedStaffId([...checkedStaffId, id]);
            await dispatch(resendInvite([...checkedStaffId, id], 'staff'));
            setSuccesEnable(true);
            setSuccessMessageText('Invite sent');
            setCheckedStaffId([]);
        }

    }







    const notAccess = () => {
        setIsAccess(true);
    }

    const snackClosed = () => {
        setSuccesEnable(false);
        setSuccessMessageText('');
    }


    return (
        <div className='users-module'>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successEnable}
                autoHideDuration={4000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>


            {<div className="mobile-coming">
                <div className="mobile-inner-hide">
                    <img src="/images/mobile-error.svg" alt='' />
                    <h4>Please visit the website on your Desktop/Laptop</h4>
                    <p>Mobile view coming soon</p>
                </div>

            </div>}
            <Modal
                show={isAccess}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"ready-modal"}
            >
                <div className='p-4 text-center'>
                    <h1>This feature is currently not active for your account.</h1>
                    <button onClick={() => setIsAccess(false)}>Ok</button>
                </div>
            </Modal>



            {
                !isView ?
                    <Fragment>
                        <h3>Users Management</h3>
                        <div className='users-button'>
                            {
                                (localStorage.getItem('role') === 'admin_tot' || localStorage.getItem('role')?.includes('training manager')) ? null :
                                    <Fragment>
                                        <button className={activeBtn === 'staff' ? "active-btn" : null} onClick={() => onBtnChange('staff')}>Staff</button>&nbsp;
                                        <button className={activeBtn === 'student' ? "active-btn" : null} onClick={() => onBtnChange('student')}>Students</button>&nbsp;

                                    </Fragment>
                            }

                        </div>
                        {
                            activeBtn === 'student'
                                ?
                                <Fragment>
                                    <div className='users-details'>
                                        <p>{total} <br /><span>Total</span></p>
                                        <p>{active} <br /><span>Active</span></p>
                                        <p>{inactive}<br /> <span>Inactive</span></p>
                                        <p>{invited} <br /><span>Invited</span></p>
                                        {/* <p>2222 <br /><span>Payment Due</span></p> */}
                                        <small className='note-line'>Select one or more items before using the below items</small>
                                        <h2 className='cursor-pointer' onClick={updateStatus}>Update<br /> Status</h2>
                                        <h2 className='cursor-pointer' onClick={updateClass}>Update <br /> Class</h2>
                                        <h2 className='cursor-pointer' onClick={resendInvites}>Resend <br /> Invites</h2>
                                    </div>
                                    <div className='users-header'>
                                        {/* <Overlay
                                            show={showAction}
                                            target={target}

                                            onHide={() => setState({ showAction: false })}
                                            placement="bottom-end"
                                            rootClose={true}
                                        >
                                            <Popover id="popover-contained2" style={{ width: '170px', borderRadius: 2, zIndex: 3, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                <ul className='popover-add-view'>
                                                    <li onClick={addManual}>Add Manually</li>
                                                    <li onClick={openUpload}>Upload from file</li>
                                                </ul>
                                            </Popover>
                                        </Overlay> */}
                                        <button onClick={(e) => localStorage.getItem('role')?.includes('admin') ? addManual('student') : notAccess()}><img src='/images/plus-white.svg' alt='' />  Add Student</button>
                                        <div className="has-search"  >
                                            <span className="form-control-feedback">
                                                <img src="/images/search1.svg" width="14" alt='' />
                                            </span>
                                            <input type='search' name='student' placeholder='' value={search1} id='search' onChange={(e) => searchStudent(e.target.value)} autoComplete='off' className='form-control' />
                                        </div>
                                        <div className='d-flex align-items-center cursor-pointer' onClick={openFilter}>
                                            <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                        </div>
                                        <div className='d-flex align-items-center' style={{ width: '20%' }}>
                                            <select style={{ width: '30%' }} className='form-control' value={size} onChange={(e) => changeSize(e.target.value)}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                            </select> &nbsp;
                                            <p>Items per page</p>

                                        </div>
                                    </div>
                                    {isListLoading ?
                                        <div>
                                            <TopicLoader />
                                        </div>
                                        :
                                        <div className="table-admin" style={{ marginTop: studentList.length === 0 ? -43 : -45 }}>
                                            <Table responsive style={{ overflow: 'hidden' }}>
                                                <thead>
                                                    <tr className="header-table">
                                                        <th>
                                                            <input type='checkbox' style={{ width: 16, height: 16 }} checked={checked} onChange={(e) => checkAll(e)} />
                                                        </th>
                                                        <th>Student Name</th>
                                                        <th>Id</th>
                                                        <th>Affiliation</th>
                                                        <th>Class</th>
                                                        <th>Section</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <hr />
                                                </thead>
                                                <div className='showing-results' >
                                                    <p>{classs !== '' || section !== '' ?
                                                        <span onClick={() => clearFilters('student')}>CLEAR FILTERS</span> : null}</p>
                                                    {
                                                        classs !== '' || section !== '' ?
                                                            <div className='clear'>
                                                                <div className='select-list'>
                                                                    <ul>
                                                                        {classs !== '' ? <li >
                                                                            {classs !== undefined && classs?.label}
                                                                            <img src="/images/close1.svg" alt='' onClick={() => onFilterClick('class')} />
                                                                        </li> : null}
                                                                        {section !== '' ? <li >
                                                                            {section !== undefined && (section?.label)}
                                                                            <img src="/images/close1.svg" alt='' onClick={() => onFilterClick('section')} />
                                                                        </li> : null}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <tbody className="table-body" >
                                                    {
                                                        (studentList !== "" && studentList !== undefined && studentList.length > 0 ?
                                                            studentList.map((student, i) => {
                                                                var stylesInitials = {
                                                                    backgroundColor: 'green'
                                                                };
                                                                return (
                                                                    <tr key={i} style={{ borderTop: i === 0 ? (classs !== '' || section !== '') ? '65px solid #F7F7FA' : '15px solid #F7F7FA' : null }}>
                                                                        <td>
                                                                            <input type='checkbox' onChange={getCheckedId} style={{ width: 16, height: 16 }} value={student._id}
                                                                                checked={student.isChecked} />
                                                                        </td>
                                                                        <td style={{ width: '32%' }}><div className="user-table-holder text-capitalize"><div className="user-icon-table text-uppercase" style={student.profile_image !== "" && student.profile_image !== null ? null : stylesInitials}>{student.profile_image !== "" && student.profile_image !== null ? <img src={student.profile_image} alt='' height='30px' width='30px' /> : getIntial(student.firstname, student.lastname)}</div> &nbsp; &nbsp;{student.firstname} {student.lastname}</div></td>
                                                                        <td className='text-center'>{student.id_num}</td>
                                                                        <td className='text-center'>{student?.affiliation?.name}</td>
                                                                        <td style={{ width: '12%' }} className='text-center'>{student.class}</td>
                                                                        <td className='text-center' style={{ width: '15%' }}>{student?.section_name}</td>
                                                                        <td>{student.active ? 'Active' : !student.verified ? "Invited" : 'Inactive'}</td>
                                                                        <td className='action' style={{ width: '26%' }}>
                                                                            <img src='/images/view_grey.svg' alt='' width='22px' onClick={() => viewStudents(student._id)} />&nbsp;&nbsp;&nbsp;
                                                                            <img src='/images/edit.svg' alt='' width='22px' onClick={() => { editStudent(student._id) }} />&nbsp;&nbsp;
                                                                            <Overlay
                                                                                show={openMenu}
                                                                                target={targetMenu}
                                                                                onHide={() => setOpenMenu(false)}
                                                                                placement="bottom-start"
                                                                                rootClose={true}
                                                                            >
                                                                                <Popover id={`popover-containd${i}`} style={{ width: '170px', display: student._id === id ? 'block' : 'none', borderRadius: 2, zIndex: 3, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                                    <ul className='popover-add-view'>
                                                                                        <li onClick={() => resendIndivisualInvites(student._id)}>Resend Invite</li>
                                                                                        {
                                                                                            localStorage.getItem('role')?.includes('admin') ?
                                                                                                <li onClick={() => makeInactive(student._id)}>{(student.status === 'active' || student.status === 'invited') ? 'Make Inactive' : 'Make Active'}</li> : null}
                                                                                        {/* <li onClick={updateStatus}>Update Semester</li> */}
                                                                                    </ul>
                                                                                </Popover>
                                                                            </Overlay>

                                                                            <button onClick={(e) => openMenus(e, student._id)}>
                                                                                <img src='/images/menu.svg' width='20px' alt='' />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr style={{ borderTop: (classs !== '' || section !== '') ? '65px solid #F7F7FA' : '35px solid #F7F7FA' }}>
                                                                <td colSpan={7}>
                                                                    <div className='empty-list'>
                                                                        <img src='/images/empty.png' alt='' />
                                                                        {/* <p>You have not added any programs yet.</p> */}
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                    }


                                                </tbody>




                                            </Table>

                                        </div>
                                    }
                                    {
                                        totalPage > 1 && <div className='user-pagination' style={{ marginTop: (classs !== '' || section !== '') ? 40 : (studentList.length < 1 ? 315 : 25) }}>
                                            {
                                                page === 1 ?
                                                    <img src='/images/page_prev_disable.svg' alt='' />
                                                    : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                            }
                                            &nbsp;&nbsp;
                                            <div>Page&nbsp;&nbsp; <input value={page} readOnly />&nbsp;of {totalPage}</div>&nbsp;
                                            {
                                                page === totalPage ?
                                                    <img src='/images/page_next_disable.svg' alt='' /> :
                                                    <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                            }
                                        </div>
                                    }
                                </Fragment >
                                : activeBtn === 'staff' ?
                                    <Fragment>
                                        <div className='users-details'>
                                            <p>{totalStaff}<br /><span>Total</span></p>
                                            <p>{activeStaff} <br /><span>Active</span></p>
                                            <p>{inactiveStaff}<br /> <span>Inactive</span></p>
                                            <p>{invitedStaff} <br /><span>Invited</span></p>
                                            <h2 onClick={updateStatus}>Update Status</h2>
                                            {/* <h2>Assign Subject</h2> */}
                                            <h2 onClick={resendInvites}>Resend Invites</h2>
                                        </div>
                                        <div className='users-header'>

                                            <button onClick={(e) => localStorage.getItem('role')?.includes('admin') ? addManual('staff') : notAccess()}><img src='/images/plus-white.svg' alt='' />  Add Staff</button>
                                            <div className="has-search"  >
                                                <span className="form-control-feedback">
                                                    <img src="/images/search1.svg" width="14" alt='' />
                                                </span>
                                                <input type='search' placeholder='' id='search' value={search2} onChange={(e) => searchStaff(e.target.value)} autoComplete='off' className='form-control' />
                                            </div>
                                            <div className='d-flex align-items-center cursor-pointer' onClick={openFilter}>
                                                <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                            </div>
                                            <div className='d-flex align-items-center' style={{ width: '20%' }}>
                                                <select style={{ width: '30%' }} className='form-control' value={staffSize} onChange={(e) => changeSize(e.target.value)}>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                    <option>40</option>
                                                </select> &nbsp;
                                                <p>Items per page</p>

                                            </div>
                                        </div>
                                        {isListLoading ?
                                            <div>
                                                <TopicLoader />
                                            </div>
                                            :
                                            <div className="table-admin table-staff" style={{ marginTop: staffList.length === 0 ? -43 : -45 }}>
                                                <Table responsive style={{ overflow: 'hidden' }}>
                                                    <thead>
                                                        <tr className="header-table">
                                                            <th><input type='checkbox' style={{ width: 16, height: 16 }} checked={checked} onChange={(e) => checkAllStaff(e)} /></th>
                                                            <th>Staff Name</th>
                                                            <th>Id</th>
                                                            <th>Staff Type</th>
                                                            <th>Subjects</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        <hr />
                                                    </thead>
                                                    <div className='showing-results'  >
                                                        <p>{role !== '' || subject !== '' ? <span onClick={() => clearFilters('staff')}>CLEAR FILTERS</span> : null}</p>
                                                        {
                                                            role !== '' || subject !== '' ?
                                                                <div className='clear'>
                                                                    <div className='select-list'>
                                                                        <ul>
                                                                            {role !== '' ? <li >
                                                                                {role !== undefined && role.label}
                                                                                <img src="/images/close1.svg" alt='' onClick={() => onStaffFilterClick('role')} />
                                                                            </li> : null}
                                                                            {subjectId !== '' ? <li >
                                                                                {subject !== undefined && subject.label}
                                                                                <img src="/images/close1.svg" alt='' onClick={() => onStaffFilterClick('subject')} />
                                                                            </li> : null}

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    <tbody className="table-body">
                                                        {isListLoading ?
                                                            <tr style={{ position: 'relative', borderTop: (roleValue !== '' || subjectId !== '') ? '65px solid #F7F7FA' : '35px solid #F7F7FA' }}>
                                                                {/* <div style={{ width: '218%', padding: 20 }}>
                                                            <TopicLoader />
                                                        </div> */}
                                                            </tr>
                                                            :
                                                            staffList !== "" && staffList !== undefined && staffList.length > 0 ?
                                                                staffList.map((usersList, i5) => {
                                                                    var stylesInitials = {
                                                                        backgroundColor: 'green'
                                                                    };
                                                                    return (
                                                                        <tr key={i5} style={{ borderTop: i5 === 0 ? (role !== '' || subjectId !== '') ? '65px solid #F7F7FA' : '15px solid #F7F7FA' : null }}>
                                                                            <td><input type='checkbox' style={{ width: 16, height: 16 }} checked={usersList.isChecked} value={usersList._id} onChange={getCheckedStaffId} /></td>
                                                                            <td><div className="user-table-holder text-capitalize"><div className="user-icon-table text-uppercase" style={usersList.profile_image !== "" && usersList.profile_image !== null ? null : stylesInitials}>{usersList.profile_image !== "" && usersList.profile_image !== null ? <img src={usersList.profile_image} alt='' height='30px' width='30px' /> : getIntial(usersList.firstname, usersList.lastname)}</div> &nbsp; &nbsp;{usersList.firstname} {usersList.lastname}</div></td>
                                                                            <td className='text-center'>{usersList.id_num}</td>
                                                                            <td className='text-capitalize'>{usersList.role.map(el => el === 'sme' ? 'Subject Controller' : el).join(', ')}</td>
                                                                            <td className='text-center'><a title={usersList.subjects.map(el => el.name).join(', ')} >{usersList.subjects.slice(0, 2).map(el => el.name).join(' , ')} {usersList.subjects.length > 2 ? <p>+{usersList.subjects.length - 2}</p> : null}</a></td>
                                                                            <td>{usersList.active ? 'Active' : !usersList.verified ? "Invited" : 'Inactive'}</td>
                                                                            <td className='action' style={{ width: '15%' }}>
                                                                                <img src='/images/edit.svg' alt='' onClick={() => goToEdit(usersList)} />&nbsp;&nbsp;
                                                                                <Overlay
                                                                                    show={openMenu}
                                                                                    target={targetMenu}

                                                                                    onHide={() => setOpenMenu(false)}
                                                                                    placement="bottom-start"
                                                                                    rootClose={true}
                                                                                >
                                                                                    <Popover id="popover-contained4" style={{ width: '170px', display: usersList._id === id ? 'block' : 'none', borderRadius: 2, zIndex: 3, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                                        <ul className='popover-add-view'>
                                                                                            {usersList.active ? null : <li onClick={() => resendIndivisualInvites(usersList._id)}>Resend Invite</li>}
                                                                                            {
                                                                                                localStorage.getItem('role')?.includes('admin') ?
                                                                                                    <li onClick={() => makeInactive(usersList._id)}>{(usersList.active || usersList.active === 'invited') ? 'Make Inactive' : 'Make active'}</li>
                                                                                                    : null
                                                                                            }
                                                                                            {/* <li>Assign Subject</li> */}
                                                                                        </ul>
                                                                                    </Popover>
                                                                                </Overlay>
                                                                                <button onClick={(e) => openMenus(e, usersList._id)}>
                                                                                    <img src='/images/menu.svg' alt='' />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr style={{ borderTop: (role !== '' || subjectId !== '' || status !== '') ? '65px solid #F7F7FA' : '35px solid #F7F7FA' }}>
                                                                    <td colSpan={7}>
                                                                        <div className='empty-list'>
                                                                            <img src='/images/empty.png' alt='' />
                                                                            {/* <p>You have not added any programs yet.</p> */}
                                                                        </div>

                                                                    </td>

                                                                </tr>
                                                        }


                                                    </tbody>
                                                </Table>

                                            </div>
                                        }
                                        {totalStaffpage > 1 && <div className='user-pagination' style={{ marginTop: (role !== '' || subjectId !== '' || status !== '') ? 40 : (staffList.length < 1 ? 315 : 25) }}>
                                            {
                                                staffPage === 1 ?
                                                    <img src='/images/page_prev_disable.svg' alt='' />
                                                    : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                            }
                                            &nbsp;&nbsp;
                                            <div>Page&nbsp;&nbsp; <input value={staffPage} readOnly />&nbsp;of {totalStaffpage}</div>&nbsp;
                                            {
                                                staffPage === totalStaffpage ?
                                                    <img src='/images/page_next_disable.svg' alt='' /> :
                                                    <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                            }
                                        </div>}
                                    </Fragment> :
                                    null

                        }
                    </Fragment>
                    :
                    <ViewUser
                        type={activeBtn}
                        // uploadFile={props.uploadFile}
                        files={files}
                        student={student}
                        goToUsers={() => dispatch(backToMain())}
                        edit={edit}
                        classes={classList}
                        getAffliations={() => dispatch(getAffliations())}
                        getClasses={(id) => dispatch(getClasses(id))}
                        affliations={affliations}
                        getSectionFromClass={(id, aff) => dispatch(getSectionFromClass(id, aff))}
                        sections={sectionOfClass}
                        statusOption={statusOption}
                        editStudent={editStudent1}
                    />

            }



            {/* ################## Filter modal ############################ */}
            <Modal show={isOpenFilter} size='lg' centered aria-labelledby="contained-modal-title-vcenter" dialogClassName={"filter-modal"}>
                <Modal.Body style={{ paddingTop: 20, paddingLeft: 70, paddingRight: 70, paddingBottom: 30 }}>
                    <div className="filter">
                        <h1>Filter {activeBtn === 'student' ? 'Student' : 'Staff'}</h1>
                        <div onClick={handleClose} className='close-upload'><img src='/images/close1.svg' alt='' /></div>
                        {
                            (activeBtn === 'student') ?
                                <div>
                                    {affliations?.length === 1 ? null :
                                        <div className='form-group mt-2'>
                                            <label>Affiliation
                                            </label>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={affliations?.map(el => [{ label: el.name, value: el._id }][0])}
                                                onChange={(e) => selectAffiliation(e)}
                                                placeholder=''
                                                value={affiliation}
                                            />
                                        </div>}
                                    <div className='form-group mt-2'>
                                        <label>Class
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={classList?.filter(el => el.sections?.length)?.map(el => [{ ...el, label: `Class ${el.class}`, value: el._id }][0])}
                                            onChange={(e) => selectClass(e, 'filter')}
                                            value={classs}
                                            placeholder=''
                                        />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label>{'Section'}
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder=''
                                            onChange={selectSection}
                                            options={sectionOfClass}
                                            value={section}
                                        />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label>Status
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={statusOption}
                                            onChange={selectStatus}
                                            value={status}
                                            placeholder=''
                                        />
                                    </div>

                                    <div className='filter-btn '>
                                        <button className='position-relative' onClick={getResults}>{isloading ? <BallBeat color='#fff' /> : 'Apply Filters'}</button>
                                    </div>
                                </div> :
                                <div>
                                    <div className='form-group mt-2'>
                                        <label>Staff Role
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder=''
                                            options={roleOption}
                                            onChange={getRole}
                                            value={role}
                                        />
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label>Subject
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder=''
                                            options={selectFromSubject}
                                            onChange={selectSubject}
                                            value={subject}
                                        />
                                    </div>
                                    {/* <div className='form-group mt-2'>
                                        <label>SME Status
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder=''
                                        />
                                    </div> */}
                                    <div className='form-group mt-2'>
                                        <label>Staff Status
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder=''
                                            options={statusOption}
                                            onChange={selectStatus}
                                            value={status}
                                        />
                                    </div>
                                    <div className='filter-btn'>
                                        <button className='position-relative' onClick={getStaffResults}>{isloading ? <BallBeat color='#fff' /> : 'Apply Filters'}</button>
                                    </div>
                                </div>
                        }

                    </div>
                </Modal.Body>
            </Modal>

            {/* ################## Update Status modal ############################ */}
            <Modal
                show={isUpdateStatus}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"update-modal"}
            >
                <div class="modal-header" style={{ background: '#1D8BCB' }}>
                    <h5 class="modal-title">Update Status </h5>

                    <div className="sticky-close" onClick={handleClose}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>
                    <div>
                        <label>New Status</label>
                        <Select
                            components={{ DropdownIndicator }}
                            options={localStorage.getItem('role')?.includes('admin') ? statusOption : statusOption1}
                            onChange={changeStatus1}
                            value={status1}
                            placeholder=''
                        />
                        <p style={{ color: 'red' }}>{statusErr}</p>
                    </div>
                    <div className='save-btn'>
                        <button onClick={() => updateStatuses()}>Save</button>
                        <button onClick={handleClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ################## Update semester modal ############################ */}
            <Modal
                show={isUpdateClass}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"update-modal"}
            >
                <div class="modal-header" style={{ background: '#1D8BCB' }}>
                    <h5 class="modal-title">Update Class</h5>

                    <div className="sticky-close" onClick={handleClose}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>
                    {affliations?.length === 1 ? null :
                        <div className='form-group mt-2'>
                            <label>Affiliation
                            </label>
                            <Select
                                components={{ DropdownIndicator }}
                                options={affliations?.map(el => [{ label: el.name, value: el._id }][0])}
                                onChange={(e) => selectAffiliation(e)}
                                placeholder=''
                                value={affiliation}
                            />
                        </div>
                    }
                    <div>
                        <label>Select Class</label>
                        <Select
                            components={{ DropdownIndicator }}
                            options={classList?.filter(el => el.sections?.length)?.map(el => [{ ...el, label: `Class ${el.class}`, value: el._id }][0])}
                            value={classs}
                            onChange={(e) => selectClass(e, 'update')}
                        />
                        <p style={{ color: 'red' }}>{classError}</p>
                    </div>
                    <div className='save-btn'>
                        <button onClick={updateClassStatus} >Save</button>
                        <button onClick={handleClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>



            {/* <Modal
                show={isPreviewTable}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                dialogClassName={"up-modal"}
            >
                <Modal.Body style={{ padding: '22px 40px 22px 40px', height: '90vh', overflow: 'auto' }}>
                    <Table responsive>
                        <thead>
                            <tr className="header-table">
                                <th>Region</th>
                                <th>Country</th>
                                <th>Item_Type</th>
                                <th>Sales_Channel</th>
                                <th>Order_Priority</th>
                                <th>Order_Date</th>
                                <th>Order_Id</th>
                                <th>Ship_Date</th>
                                <th>Units_Sold</th>
                                <th>Unit_Price</th>
                                <th>Unit_Cost</th>
                                <th>Total_Revenue</th>
                                <th>Total_Cost</th>
                                <th>Total_Profit</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                arr !== "" && arr !== undefined ?
                                    arr.map((usersList, i5) => {
                                        return (
                                            <tr key={i5}>
                                                <td>{usersList.Region}</td>
                                                <td>{usersList.Country}</td>
                                                <td>{usersList.Item_Type}</td>
                                                <td>{usersList.Sales_Channel}</td>
                                                <td>{usersList.Order_Priority}</td>
                                                <td>{usersList.Order_Date}</td>
                                                <td>{usersList.Order_Id}</td>
                                                <td>{usersList.Ship_Date}</td>
                                                <td>{usersList.Units_Sold}</td>
                                                <td>{usersList.Unit_Price}</td>
                                                <td>{usersList.Unit_Cost}</td>
                                                <td>{usersList.Total_Revenue}</td>
                                                <td>{usersList.Total_Cost}</td>
                                                <td>{usersList.Total_Profit}</td>
                                            </tr>
                                        )
                                    })
                                    : null
                            }


                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal> */}
        </div >
    )
}


export default Users;