import React, { Fragment, useState, useEffect } from 'react';
import './notification.scss'
import { getNotifications, markedRead, getNextNotifications } from '../../Redux/ActionCreators/notificationAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TopicLoader } from '../../Loader/loader';
import { formatAMPM } from '../../utils/miscelleneous';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


function Notification() {
    const [pageCount, setPageCount] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { notifications, isLoading } = useSelector(state => state.notification);


    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(getNotifications(pageCount));
    }, [])


    const convertDate = (d1) => {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const date = new Date(d1)
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(date)
        return [pad(d.getDate()), pad(monthNames[d.getMonth()])].join(' ')
    }


    const redirectToResources = (id, r_id, type, read, action) => {
        if (read) {
            localStorage.getItem('role') === 'student' ?
                window.location.href = `/feeds?_id=${r_id}&type=${type}` : window.location.href = `/my-content?_id=${r_id}&type=${type}`
        }
        else {
            localStorage.getItem('role') === 'student' ?
                dispatch(markedRead(id, `/feeds?_id=${r_id}&type=${type}`)) : dispatch(markedRead(id, `/my-content?_id=${r_id}&type=${type}`))
        }
    }
    const redirectToAchievers = (id, read, action) => {
        if (read) {
            window.location.href = '/achievers'
        }
        else {
            dispatch(markedRead(id, '/achievers'));
        }
    }

    const redirectNoticeBoard = (id, read, action) => {
        if (read) {
            window.location.href = '/notice-board'
        }
        else {
            dispatch(markedRead(id, '/notice-board'))
        }
    }

    const redirectToLibrary = (id, r_id, type, read, action) => {
        if (read) {
            window.location.href = `/skill-pods/id=${r_id}`;
        }
        else {
            dispatch(markedRead(id, `/skill-pods/id=${r_id}`))
        }
    }

    const redirectToConnect = (id, read) => {
        if (read) {
            window.location.href = '/connect?tab=conversation'
        }
        else {
            dispatch(markedRead(id, '/connect?tab=conversation'))
        }
    }

    const fetchData = async () => {
        await setPageCount(pageCount + 1)
        await dispatch(getNextNotifications(pageCount + 1));
    }

    return (
        <div className='notification-holder'>

            <h1><img src='/images/back.svg' onClick={() => navigate(-1)} alt='' /> &nbsp; &nbsp;Notifications</h1>
            <ul className='notification-list'>
                <InfiniteScroll
                    dataLength={notifications !== undefined && notifications.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={true}
                    style={{}}
                    loader={<div className="loader-main">{isLoading ?
                        <Fragment><TopicLoader /></Fragment> : null}</div>}
                >
                    {
                        notifications !== undefined && notifications.map((el, index) => {
                            const date1 = el.type === 'Notice Board' ? convertDate(el.createdAt) : convertDate(el.create)
                            const time = el.type === 'Notice Board' ? formatAMPM(el.createdAt) : formatAMPM(el.create)
                            return (
                                <li key={index} style={{ background: el.read ? '#fff' : '#E6F7FF' }} onClick={() => el.type === "appreciation" ? redirectToAchievers(el._id, el.read, el.action) : el.resource_type === "Pod" ? redirectToLibrary(el._id, el.resource_id, el.resource_type, el.read, el.action) : el.type === "resource" ? redirectToResources(el._id, el.resource_id, el.resource_type, el.read, el.action) : el.type === 'connect' ? redirectToConnect(el._id, el.read) : redirectNoticeBoard(el._id, el.read, el.action)}>
                                    <div className='icon-image'>
                                        {
                                            el.resource_type !== undefined && el.resource_type === "Video" ?
                                                <img className="mr-2"
                                                    src="/images/video-icon.svg" alt='' /> : el.resource_type === "Pod" ?
                                                    <img className="mr-2"
                                                        src="/images/pod-icon.svg" alt='' /> : el.resource_type === "Article" ?
                                                        <img className="mr-2"
                                                            src="/images/article-icon.svg" alt='' /> : el.type === "appreciation" ?
                                                            <img className="mr-2"
                                                                src="/images/apperciation-icon.svg" alt='' /> : el.resource_type === "Document" ?
                                                                <img className="mr-2"
                                                                    src="/images/document-icon.svg" alt='' /> : el.resource_type === "Question" ?
                                                                    <img className="mr-2"
                                                                        src="/images/quiz.svg" alt='' /> : el.type === "Notice Board" ? <img className="mr-2"
                                                                            src="/images/notice-board-layout-icon.svg" alt='' /> : el.type === "connect" ?
                                                                        <img className="mr-2" src="/images/qna-layout-icon.svg" alt='' />
                                                                        : null
                                        }
                                    </div>
                                    <div className='user-image'>
                                        <div >
                                            {
                                                el.profile_image !== null && el.profile_image !== '' ? <img src={el.profile_image} width="40px" height="40px" alt='' /> : <div style={{ background: '#F5821F' }} className='text-uppercase'> {el.initial}</div>
                                            }
                                            {/* <div className='text-uppercase'> RJ</div> */}

                                        </div>
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <h2 style={{ fontWeight: el.read ? 'bold' : 'normal' }} dangerouslySetInnerHTML={{ __html: el.type === 'Notice Board' ? el.subject : el.text }} />
                                        {/* <h2 >{el.text}</h2> */}
                                        <p>{time} &nbsp;{date1}</p>
                                    </div>
                                </li>
                            )
                        })
                    }
                </InfiniteScroll>
            </ul>

        </div>
    )
}






export default Notification;
