import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import mentorConnectReducer from './mentorConnectReducer';
import practiceTestReducer from "./practiceTestReducer";
import contentLibraryReducer from "./contentLibraryReducer";
import myLearningReducer from "./myLearningReducer";
import manageContentReducer from "./manageContentReducer";
import counsellingReducer from "./counsellingReducer";
import layoutReducer from "./layoutReducer";
import accountReducer from "./accountReducer";
import userReducer from "./userReducer";
import noticeReducer from "./noticeReducer";
import notificationReducer from "./notificationReducer";
import rewardReducer from "./rewardReducer";
import adminRewardReducer from "./adminRewardsReducer";
import adminContestReducer from "./adminContestReducer";
import achieverReducer from "./achieverReducer";
import shareReducer from "./shareReducer";
import myContentReducer from "./myContentReducer";
import notesReducer from "./notesReducer";
import dashboardReducer from "./dashboardReducer";
import reportReducer from "./reportReducer";



export const rootReducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    notification: notificationReducer,
    myLearning: myLearningReducer,
    practiceTest: practiceTestReducer,
    achiever: achieverReducer,
    profile: profileReducer,
    contentLibrary: contentLibraryReducer,
    counselling: counsellingReducer,
    notes: notesReducer,
    mentorConnect: mentorConnectReducer,
    reward: rewardReducer,
    adminReward: adminRewardReducer,
    contest: adminContestReducer,
    account: accountReducer,
    user: userReducer,
    noticeBoard: noticeReducer,
    manageContent: manageContentReducer,
    myContent: myContentReducer,
    report: reportReducer,
    notice: noticeReducer,
    layout: layoutReducer,
    share: shareReducer,
    error: errorReducer
});