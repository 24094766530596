import React, { useRef, useState, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { resetPercentage, setTitle, setChoice1, setChoice2, setChoice3, setChoice4, setExplanation } from '../../../Redux/ActionCreators/manageContentAction';

export const Editor = (props) => {
    const title = useSelector((state) => state.manageContent?.qTitle, shallowEqual)
    const dispatch = useDispatch()
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        placeholder: '',
        statusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        uploader: {
            url: `${process.env.REACT_APP_API_KEY}admin/resource/question/image/upload`,
            headers: { Authorization: AuthStr },
            filesVariableName: function (e) {
                return "image";
            },
            insertImageAsBase64URI: false,
            prepareData: function (data) {
                // data.append("image", );
                return data;
            },
            isSuccess: function (resp) {
                return resp;
            },
            process: function (resp) {
                return {
                    files: resp.data.image_url,
                    path: resp.data.image_url,
                    baseurl: resp.data.image_url,
                    error: resp.error,
                    message: resp.message,
                };
            },

            defaultHandlerSuccess: function (data) {
                if (this.componentName === 'jodit-uploader') {
                    this.jodit.selection.insertImage(data.baseurl, null, 200);
                }
                else {
                    this.selection.insertImage(data.baseurl, null, 200);
                }
            }
        },
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'source', '|',
            'insertFormula',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'superscript',
            'subscript',
            'undo', 'redo', '|',
        ],
        buttonsMD: [
            'source', '|',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'superscript',
            'subscript',
            'undo', 'redo', '|',
        ],
        buttonsSM: [
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'fontsize',
            'brush',
            'superscript',
            'subscript',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
        ],
        buttonsXS: [
            'bold',
            'italic',
            'underline', '|',
            'brush',
            'paragraph', '|',
            'image',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
            'eraser'
        ],
    }

    useEffect(() => {
        dispatch(resetPercentage())
        setTitle(content)
    }, [])

    return useMemo(() => (
        <JoditEditor ref={editor} value={title} config={config} onChange={content => {
            setContent(content)
            dispatch(setTitle(content))
        }} />

    ), [])

}

export const OptionEditor = (props) => {
    const choice1 = useSelector((state) => state.manageContent.choice1, shallowEqual);
    const choice2 = useSelector((state) => state.manageContent.choice2, shallowEqual);
    const choice3 = useSelector((state) => state.manageContent.choice3, shallowEqual);
    const choice4 = useSelector((state) => state.manageContent.choice4, shallowEqual);
    const dispatch = useDispatch()
    const editor = useRef(null)
    const [choice_1, setChoice_1] = useState('')
    const [choice_2, setChoice_2] = useState('')
    const [choice_3, setChoice_3] = useState('')
    const [choice_4, setChoice_4] = useState('')

    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        tabIndex: 1,
        placeholder: '',
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        statusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        height: 50,
        minHeight: 100,
        allowResizeX: false,
        allowResizeY: false,
        uploader: {
            url: `${process.env.REACT_APP_API_KEY}admin/resource/question/image/upload`,
            headers: { Authorization: AuthStr },
            filesVariableName: function (e) {
                return "image";
            },
            insertImageAsBase64URI: false,
            prepareData: function (data) {
                // data.append("image", );
                return data;
            },
            isSuccess: function (resp) {
                return resp;
            },
            process: function (resp) {
                return {
                    files: resp.data.image_url,
                    path: resp.data.image_url,
                    baseurl: resp.data.image_url,
                    error: resp.error,
                    message: resp.message,
                };
            },

            defaultHandlerSuccess: function (data) {
                if (this.componentName === 'jodit-uploader') {
                    this.jodit.selection.insertImage(data.baseurl, null, 100);
                }
                else {
                    this.selection.insertImage(data.baseurl, null, 100);
                }
            }
        },
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'superscript',
            'subscript',
            'image',
        ],
        buttonsMD: [
            'superscript',
            'subscript',
            'image',
        ],
        buttonsSM: [
            'superscript',
            'subscript',
            'image',
        ],
        buttonsXS: [
            'superscript',
            'subscript',
            'image',
        ],
    }

    useEffect(() => {
        dispatch(resetPercentage())
        setChoice1(choice_1)
        setChoice2(choice_2)
        setChoice3(choice_3)
        setChoice4(choice_4)

    }, [])

    return useMemo(() => {
        switch (props.type) {
            case 'option1':
                return <JoditEditor ref={editor} value={choice1} config={config} onChange={choice_1 => {
                    setChoice_1(choice_1)
                    dispatch(setChoice1(choice_1))
                }} />
            case 'option2':
                return <JoditEditor ref={editor} value={choice2} config={config} onChange={choice_2 => {
                    setChoice_2(choice_2)
                    dispatch(setChoice2(choice_2))
                }} />

            case 'option3':
                return <JoditEditor ref={editor} value={choice3} config={config} onChange={choice_3 => {
                    setChoice_3(choice_3)
                    dispatch(setChoice3(choice_3))
                }} />
            case 'option4':
                return (<JoditEditor ref={editor} value={choice4} config={config} onChange={choice_4 => {
                    setChoice_4(choice_4)
                    dispatch(setChoice4(choice_4))
                }} />)
            default:
                break;
        }


    }, [])

}



export const ExplanationEditor = (props) => {
    const explanation = useSelector((state) => state.manageContent.qExplanation, shallowEqual)
    const dispatch = useDispatch()
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        placeholder: '',
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        statusbar: props.type === 'title',
        showCharsCounter: props.type === 'title',
        showWordsCounter: props.type === 'title',
        uploader: {
            url: `${process.env.REACT_APP_API_KEY}admin/resource/question/image/upload`,
            headers: { Authorization: AuthStr },
            filesVariableName: function (e) {
                return "image";
            },
            insertImageAsBase64URI: false,
            prepareData: function (data) {
                // data.append("image", );
                return data;
            },
            isSuccess: function (resp) {
                return resp;
            },
            process: function (resp) {
                return {
                    files: resp.data.image_url,
                    path: resp.data.image_url,
                    baseurl: resp.data.image_url,
                    error: resp.error,
                    message: resp.message,
                };
            },

            defaultHandlerSuccess: function (data) {
                if (this.componentName === 'jodit-uploader') {
                    this.jodit.selection.insertImage(data.baseurl, null, 200);
                }
                else {
                    this.selection.insertImage(data.baseurl, null, 200);
                }
            }
        },
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'source', '|',
            'insertFormula',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsMD: [
            'source', '|',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsSM: [
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'fontsize',
            'brush',
            'superscript',
            'subscript',
            'paragraph', '|',
            'table',
            'link', '|',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
        ],
        buttonsXS: [
            'bold',
            'italic',
            'underline', '|',
            'brush',
            'paragraph', '|',
            'image',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
            'eraser'
        ],
    }

    useEffect(() => {
        dispatch(resetPercentage())
        setExplanation(content)
    }, [])
    return useMemo(() => (
        <JoditEditor ref={editor} value={explanation} config={config} onChange={content => {
            setContent(content)
            dispatch(setExplanation(content))
        }} />
    ), [])

}