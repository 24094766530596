import { returnErrors } from './errorAction';
import { AUTO_LOGIN, GET_LOGO, LOGIN_USER, AUTO_ADMIN_LOGIN } from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';

export const getLogo = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/logo`);
        localStorage.setItem('logo', response.data.data.logo);
        // localStorage.setItem('state_id', response.data.data?.state_id?._id);
        return dispatch({ type: GET_LOGO, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const userLogin = (login, lang) => async (dispatch) => {
    const response = await postApiCall(`auth/login/school`, login);
    const data = response.data.data
    if (response.data.success) {
        return dispatch({ type: LOGIN_USER, payload: { data, lang } });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, 202)
    }
}

export const autoLogin = (code) => async (dispatch) => {
    const login = { otp: code }
    const response = await postApiCall(`auth/login/auto`, login);

    if (response.data.success) {
        dispatch({ type: AUTO_LOGIN, payload: response.data.data });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}

export const autoAdminLogin = (otp) => async (dispatch) => {
    let obj = {
        otp: otp
    }
    const response = await postApiCall(`auth/school/login/auto`, obj);

    if (response.data.success) {
        dispatch({ type: AUTO_ADMIN_LOGIN, payload: response.data.data });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}

export const acceptInvite = (obj, lang = 'en') => async (dispatch) => {
    try {
        const response = await postApiCall(`auth/accept-invite`, obj);
        const data = response.data.data
        if (response.data.success) {
            return dispatch({ type: LOGIN_USER, payload: { data, lang } });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const forgotPassword = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`auth/forgot-password`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'OTP is sent on your registered Email', response.status)
            return dispatch({ type: "Forgot_Password", payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetPasswords = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`auth/reset-password`, obj);
        if (response.data.success) {
            dispatch({ type: "Reset_Password", payload: response.data });
            returnErrors(dispatch, 'Password has been succesfully changed', response.status)
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const instituteLogin = (login, lang) => async (dispatch) => {
    const response = await postApiCall(`auth/login/institute`, login);
    const data = response.data.data
    if (response.data.success) {
        return dispatch({ type: LOGIN_USER, payload: { data, lang } });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}

export const masterAdminLogin = (login, lang) => async (dispatch) => {
    const response = await postApiCall(`auth/login/master`, login);
    const data = response.data.data
    if (response.data.success) {
        return dispatch({ type: LOGIN_USER, payload: { data, lang } });
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
    }
    else {
        returnErrors(dispatch, response?.data?.message, response?.status)
    }
}