import React from 'react';
// import './sidebar.scss';
import { Overlay, Popover } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { formatAMPM } from '../../utils/miscelleneous';
import { convertDate } from '../../utils/miscelleneous';
import { useTranslation } from "react-i18next";

function NotificationPopover(props) {
    const [show, setShow] = React.useState(false);
    const [target, setTarget] = React.useState(null);
    const ref = React.useRef(null);
    const { t } = useTranslation();
    React.useEffect(() => {
        document.body.addEventListener("click", (e) => {
            if (
                !document.getElementById("popover-contained") ||
                (e.target !== target && !document.getElementById("popover-contained").contains(e.target)) || window.location.pathname === '/notifications') {
                setShow(false)
            }
        });
        return (
            document.body.removeEventListener("click", (e) => {
                if (
                    !document.getElementById("popover-contained") ||
                    (e.target !== target &&
                        !document.getElementById("popover-contained").contains(e.target)) || window.location.pathname === '/notifications'
                ) {
                    setShow(false)
                }
            })
        )
    }, [])

    const handleClick = (event) => {
        event.stopPropagation();
        setShow(!show);
        setTarget(event.target);
    };

    return (
        <div className={window.innerWidth > 767 ? "bell mr-2 position-relative" : 'mobile-bell'} ref={ref} style={{ display: props.isHide ? 'none' : '' }} onClick={(e) => handleClick(e)}>
            <img
                src={"/images/bell.svg"}
                ref={target} onClick={(e) => handleClick(e)}
                width="21px"
                height="24px"
                alt=''
            />
            {props.unread > 0 ?
                <span className="badge" ref={target} onClick={(e) => handleClick(e)}>
                    {props.unread}
                </span>
                : null
            }
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Header style={{ background: "none", display: 'flex', justifyContent: 'space-between' }} as="h3">{t('Notifications')}
                        {props.myData !== undefined && props.myData.length > 0 ? <button onClick={props.goToNotification} >{t('View All')}</button> : null}
                    </Popover.Header>
                    <Popover.Body>
                        <ul>
                            {props.myData !== undefined && props.myData.length > 0 ?
                                props.myData.slice(0, 4).map((el, index) => {
                                    const date1 = convertDate(el.create)
                                    const time = formatAMPM(el.create)
                                    return (
                                        <li key={index} className='notification-row' style={{ background: el.read ? '#fff' : '#E6F7FF' }} onClick={() => el.type === "appreciation" ? props.redirectToAchievers(el._id, el.read, el.action) : el.resource_type === "Pod" ? props.redirectToLibrary(el._id, el.resource_id, el.resource_type, el.read, el.action) : el.type === "resource" ? props.redirectToResources(el._id, el.resource_id, el.resource_type, el.read, el.action) : el.type === 'connect' ? props.redirectToConnect(el._id, el.read) : props.redirectNoticeBoard(el._id, el.read, el.action)}>
                                            <div className='user-image'>
                                                {
                                                    el.type === 'alert' ?
                                                        <img src='/images/reward.svg' alt='' className='pl-2' width={30} />
                                                        :
                                                        <div >
                                                            {
                                                                el.profile_image !== null && el.profile_image !== '' ? <img src={el.profile_image} width="40px" height="40px" alt='' /> : <div style={{ background: '#F5821F' }} className='text-uppercase'>{el.initial}</div>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                            <div style={{ width: '75%' }}>
                                                <h1 style={{ fontWeight: el.read ? 'bold' : 'normal' }} dangerouslySetInnerHTML={{ __html: el.type === 'Notice Board' ? `Notice-${el.subject}` : el.text }} />
                                                <p>{time}&nbsp;{date1}</p>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <div>
                                    <h1 className="text-center mt-2 mb-2"> You have no Notifications</h1>
                                </div>
                            }
                        </ul>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
}


export default NotificationPopover;