import React, { Fragment, useEffect, useState } from 'react';
import './manageTopic.scss';
import Switch from 'react-switch';
import Modal from 'react-bootstrap/Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTopics, hideSideBar, showSideBar, getActiveBtn, savedTopic, updateTopic, updateStatusTopic, confirmDeleteTopic, openManageSchedule, backToTopic, reorderTopic, reorderdTopic, setDate, savedDate } from '../../../Redux/ActionCreators/manageContentAction';
import { clearErrors } from '../../../Redux/ActionCreators/errorAction';
import { TopicLoader, BallBeat } from '../../../Loader/loader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../utils/confirmModal';

function ManageTopic() {
    const [details, setDetails] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isAddTopic, setIsAddTopic] = useState(false);
    const [name, setName] = useState('');
    const [active, setActive] = useState('');
    const [chars_left, setCharLeft] = useState(75);
    const [isLoading, setIsLoading] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [chapterId, setChapterId] = useState('');
    const [chapter, setChapter] = useState('');
    const [topicId, setTopicId] = useState('');
    const [nameError, setNameError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const maxChar = 75
    const { topicsList, chapter_id, isTopicLoading, isSchedule } = useSelector(state => state.manageContent);
    useEffect(() => {
        window.scrollTo(0, 0);

        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(clearErrors());
        dispatch(showSideBar());
        setChapter(location.state.title);
        setChapterId(location.state.id);
        setDetails(location.state.details);
        // sub: location.state.subject,
        dispatch(getTopics(location.state.id));
    }, [])



    const handleChange = (detail) => {
        let obj = {
            topic_id: detail?._id,
            active: !detail?.active
        }
        dispatch(updateStatusTopic(obj));
    }


    const backtoBubble = () => {
        if (location.state.subject) {
            dispatch(getActiveBtn('subject'));
        }
        navigate(-1);
    }



    const changeName = (val) => {
        const charCount = val.length;
        const charLength = maxChar - charCount;
        setName(val);
        setNameError('');
        setCharLeft(charLength);
    }

    const addTopic = (el, val) => {
        if (el !== null) {
            setName(el.name);
            setCharLeft(maxChar - el.name?.length);
            setTopicId(el._id);
            setActive(el.active)
        }

        setEdit(val);
        setIsAddTopic(true);
    }


    const saveTopic = async () => {
        if (name === "") {
            setNameError("Please enter Unit name");
        }
        else {
            let obj = {
                name: name,
                chapter_id: chapterId,
            }
            setIsLoading(true);
            const res = await dispatch(savedTopic(obj));
            if (res?.type === 'ADD_TOPIC') {
                setIsAddTopic(false);
                setName('');
            }
            setIsLoading(false);
        }
    }

    const editTopic = async () => {
        if (name === '') {
            setNameError("Please enter Unit name");
        }
        else {

            let obj = {
                name: name,
                topic_id: topicId,
                active: active
            }
            setIsLoading(true);
            const res = await dispatch(updateTopic(obj));
            if (res?.type === 'UPDATE_TOPIC') {
                setIsAddTopic(false);
                setName('');
            }
            setIsLoading(false);
        }
    }


    const openDelete = (detail) => {
        setIsConfirm(true);
        setDeleteId(detail._id);
    }

    const handleModal = () => {
        setIsAddTopic(false);
        setName('');
        setNameError('');
        setDeleteId('');
        setIsLoading(false);
        setCharLeft(75);
    }


    const goToTopicResources = (id, title, el) => {
        navigate('/manage-content/topic/resources', {
            state: { id: id, topic: title, chapter: chapter, chapterId: chapterId, details: el, subject: details?.name, subjectId: location.state.subjectId }
        })
        dispatch(hideSideBar());
    }



    const getDate = (e, id) => {
        dispatch(setDate(e.target.value, id));
    }

    const saveDate = () => {
        const result = topicsList.map(({ _id, completion }) => [{ _id: _id, completion: completion }][0]).filter(el => el.completion !== null)
        dispatch(savedDate(result));
    }

    const handleDragEnd = async (result) => {
        if (!result.destination) return
        const data = await dispatch(reorderTopic(result));
        const contents = Array.from(topicsList);
        const [reorderedItem] = contents.splice(data.payload.source.index, 1);
        contents.splice(data.payload.destination.index, 0, reorderedItem);
        contents.forEach((el, i) => {
            if (el.index !== i + 1) {
                el.index = i + 1;
            }
        });
        await dispatch(reorderdTopic(contents.map(el => [{ _id: el._id, index: el.index }][0])));
    }



    const checkFocus = () => {
        // dummy element
        let dummyEl = document.getElementById('express-form-typeahead');
        // check for focus
        let isFocused = (document.activeElement === dummyEl);
        if (isFocused) {
            setIsFocused(isFocused);
        }
        else {
            setIsFocused(false);
        }
    }




    return (
        <div className="manage-subjects">
            <div className='d-flex justify-content-between' style={{ fontSize: 20, cursor: 'pointer' }}>
                <div className='d-flex'>

                    <p style={{ marginTop: 6 }} onClick={() => backtoBubble()}>
                        <img src='/images/back.svg' alt='' width='20px' />
                    </p>&nbsp;&nbsp;
                    <h1>{location.state.title}</h1>
                </div>
                {
                    isSchedule ?
                        <button className='save-schedule' onClick={saveDate}>Save</button> : null
                }
            </div>
            <div className='bc-nav' style={{ marginLeft: 3 }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" onClick={() => navigate('/manage-content')} >Manage Content</li>
                        <li className="breadcrumb-item" onClick={() => navigate(-1)} >Subjects</li>
                        <li className="breadcrumb-item b-active " >{location.state.title}</li>
                    </ol>
                </nav>
            </div>
            {details !== undefined &&
                <Fragment>
                    <div className='upper-details'>
                        <div>
                            <img src={details?.image} alt='' width='176px' height='120px' />
                        </div>
                        {
                            <Fragment>
                                <div className='content-count'>
                                    {<img src='/images/topic-icon.svg' alt='' />}
                                    <p>{details?.topics}</p>
                                    <span>Units</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/article-icon.svg' alt='' />}
                                    <p>{details?.articles}</p>
                                    <span>Articles</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/video-icon.svg' alt='' />}
                                    <p>{details?.videos}</p>
                                    <span>Videos</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/quiz-icon.svg' alt='' />}
                                    <p>{details?.quizzes}</p>
                                    <span>Quizes</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/document-icon.svg' alt='' />}
                                    <p>{details?.documents}</p>
                                    <span>Documents</span>
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className='subject-header'>
                        <div className='d-flex'>
                            <button onClick={() => addTopic(null, false)}><img src='/images/plus-white.svg' alt='' /> &nbsp;&nbsp;&nbsp; Add Unit</button>

                        </div>

                        <div class="animated-search d-none" >
                            <input id="express-form-typeahead" autoComplete="off" onBlur={checkFocus} onFocus={checkFocus} spellCheck="false" type="text" />
                            <div class="symbol" >
                                <img src='/images/search1.svg' alt='' />
                            </div>
                            {
                                isFocused ?
                                    <img src='/images/close2.png' alt='' className='close-input' onClick={() => setIsFocused(false)} />
                                    : ''
                            }
                        </div>
                        <div className='content-heading'>
                            <ul>
                                <li>
                                    <div>No.</div>
                                    <div>Unit</div>
                                    {/* <div>Subunits</div> */}
                                    <div>Items</div>
                                    <div>Status</div>
                                    <div>Actions</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId={chapterId}>
                                {
                                    (provided) => (
                                        <ul className='subject-list' {...provided.droppableProps} ref={provided.innerRef}>
                                            {isTopicLoading ?
                                                <div>
                                                    <TopicLoader />
                                                </div> :
                                                topicsList && topicsList?.length > 0 ?
                                                    topicsList.map((el, index) => {
                                                        return (
                                                            <Draggable key={el._id} draggableId={el._id} index={index} >
                                                                {
                                                                    (provided) => (
                                                                        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} onClick={() => goToTopicResources(el._id, el.name, el)}>
                                                                            <div className='subject-num' style={{ paddingLeft: 45 }} onClick={(e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                            }} >
                                                                                <img src='/images/drag.svg' alt='' />
                                                                            </div>
                                                                            <div className='subject-name'>
                                                                                {/* <div style={{ width: '56px' }}>
                                                                                        <img src={el.image} className='rounded' alt='' width='56px' height='32px' />
                                                                                    </div> &nbsp;&nbsp; */}
                                                                                <p style={{ width: '100%' }}>{el.name}</p>
                                                                            </div>
                                                                            {/* <div className='text-center' >
                                                                                <h3>{el.subtopics || 0}</h3>
                                                                            </div> */}
                                                                            <div className='text-center'>
                                                                                <h3>{el.articles + el.videos + el.documents + el.quizzes}</h3>
                                                                            </div>
                                                                            {localStorage.getItem('role') === 'sme' ?
                                                                                <div className="text-center">
                                                                                    <p> {el.active ? "Active" : "Inactive"}</p>
                                                                                </div>
                                                                                :
                                                                                <div className='text-center' >
                                                                                    <p onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        e.stopPropagation()
                                                                                        handleChange(el)
                                                                                    }}> <Switch
                                                                                            onChange={(e) => { console.log(e) }}
                                                                                            width={30}
                                                                                            height={16}
                                                                                            uncheckedIcon={false}
                                                                                            checkedIcon={false}
                                                                                            onColor='#00B24B'
                                                                                            checked={el.active} /><br />
                                                                                        {el.active ? 'Active' : 'Inactive'}</p>
                                                                                </div>}
                                                                            <div className='text-center'>
                                                                                <img src='/images/edit.svg' alt='' onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    e.stopPropagation()
                                                                                    addTopic(el, true)
                                                                                }} /> &nbsp;&nbsp;
                                                                                {el.active ? null :
                                                                                    <img src='/images/delete.svg' alt='' onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation()
                                                                                        openDelete(el)
                                                                                    }} />}
                                                                                {/* <img src='/images/menu.svg' alt='' /> */}
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            </Draggable>
                                                        )
                                                    })
                                                    : <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                        <p>You have not added any units  yet.</p>
                                                    </div>
                                            }
                                            {provided.placeholder}
                                        </ul>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    }
                </Fragment>
            }

            {/* ############## add and edit topic modal ############### */}
            <Modal
                show={isAddTopic}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-topic-modal"}
            >
                <div class="modal-header" style={{ background: '#1D8BCB' }}>
                    <h5 class="modal-title">{edit ? 'Edit Unit' : 'Add Unit'} <br /><p>{chapter}</p> </h5>

                    <div className="sticky-close" onClick={handleModal}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>

                    <div>
                        <label>Unit Name*
                            <aside className='float-right'>{chars_left}</aside>
                        </label>
                        <input className='form-control mt-2' value={name} maxLength={maxChar} onChange={(e) => changeName(e.target.value)} />
                    </div>
                    <small>{nameError}</small>
                    {
                        localStorage.getItem('role').includes('super-admin') && edit ?
                            <div className='mt-4'>
                                <label>Unit Id*
                                    {/* <aside className='float-right'>{chars_left}</aside> */}
                                </label>
                                <input className='form-control mt-2' value={topicId} disabled />
                            </div>
                            : null
                    }
                    <div className='save-btn'>
                        {
                            edit ?
                                <button disabled={isLoading} onClick={editTopic} >
                                    {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                                :
                                <button disabled={isLoading} onClick={saveTopic}>
                                    {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                        }
                        <button onClick={handleModal}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ################## Confirm Delete modal ############################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                confirmDelete={async () => {
                    await dispatch(confirmDeleteTopic(deleteId))
                    await setIsConfirm(false)
                }}
                closeModal={() => setIsConfirm(false)}
                type='topic'
            />

        </div >
    )
}

export default (ManageTopic);