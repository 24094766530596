import { getApiCall, postApiCall } from '../../utils/request';
import { GET_SCHOOL_ACHIEVERS, GET_CLASS_ACHIEVERS, GET_SECTION_ACHIEVERS, APPERCIATION_SENT, LOADER_ACTIVE, GET_MONTHLY_DATA, GET_SUBJECT_DATA } from '../Types/types';
import { returnErrors } from './errorAction';


export const getSchoolAchiever = (weekly, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/achiever/leaderboard/list?weekly=${weekly}&type=${type}`);
        return dispatch({ type: GET_SCHOOL_ACHIEVERS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const getClassAchiever = (weekly, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/achiever/leaderboard/list?weekly=${weekly}&type=${type}`);
        return dispatch({ type: GET_CLASS_ACHIEVERS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}


export const getSectionAchiever = (weekly, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/achiever/leaderboard/list?weekly=${weekly}&type=${type}`);
        return dispatch({ type: GET_SECTION_ACHIEVERS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}




export const apperciationSent = (obj, name) => async (dispatch) => {
    try {
        const response = await postApiCall(`student/achiever/appreciation/send`, obj);
        if (response.data.success) {
            dispatch({ type: APPERCIATION_SENT, payload: { obj, name } });
            returnErrors(dispatch, `Appreciation sent to ${name}`, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.messsage, error.response.status);
    }
}

export const activeLoader = () => async (dispatch) => {
    return dispatch({
        type: LOADER_ACTIVE
    })
}

export const getMonthlyGraph = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/achiever/monthly/graph?user_id=${id}`);
        dispatch({ type: GET_MONTHLY_DATA, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}

export const getSubjectGraph = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/achiever/subject/graph?user_id=${id}`);
        dispatch({ type: GET_SUBJECT_DATA, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

