import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

const CircularProgressBar = (props) => {
    const { chapterComplete, testComplete } = props;
    return (

        <div className='circular-progresss' >
            <CircularProgressbarWithChildren value={chapterComplete}

                styles={{
                    root: {
                        width: '110px',
                        fontSize: 10,
                        borderRadius: '50%',
                        background: '#fff',
                        // boxShadow: '0px 0px 10px 3px #0000001f',
                        marginRight: 0
                    },
                    path: {
                        strokeLinecap: 'round',
                        stroke: '#ECC30B',
                        strokeWidth: 8
                    },
                    trail: {
                        stroke: '#EDF3F3',
                        strokeLinecap: 'butt',

                    },
                    text: {
                        fontSize: '12px',
                    },
                }}
            >

            </CircularProgressbarWithChildren>
            <div className='circular-progress2'>
                <CircularProgressbarWithChildren value={testComplete}

                    styles={{
                        root: {
                            width: '80px',
                            fontSize: 10,
                            borderRadius: '50%',
                            background: '#fff',
                            // boxShadow: '0px 0px 10px 3px #0000001f',
                            marginRight: 0
                        },
                        path: {
                            strokeLinecap: 'round',
                            stroke: '#5BCC4B',
                            strokeWidth: 12
                        },
                        trail: {
                            stroke: '#EDF3F3',
                            strokeLinecap: 'butt',

                        },
                        text: {
                            fontSize: '12px',
                        },
                    }}
                >

                </CircularProgressbarWithChildren>
            </div>

        </div>
    );
};

export default CircularProgressBar;