/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import "../manageContent.scss";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ProgressBar } from 'react-bootstrap';
import {
    hideSideBar, backToMain, addVideoorDocument, savedQuestions, savedNewQuiz, savedNewQuizByID, deleteQuizQuestions, deleteDocumentData,
    googleLinkPreview, googleLinkPreviewSetNull, uploadVideo, uploadDocument, selectOrRemoveQuestions, reorederSubTopicsProps,
    updateVideoorArticles, editedQuiz, resetPercentage, getAllSubjects, getChapters, getTopics, getClubbedQuestionList, getResourcesForType,
    addToResource, addParams,
    showSideBar,
    getChapterOfSub
} from "../../../Redux/ActionCreators/manageContentAction";
import { updatedArticle, uploadDocumentContent, uploadVideoContent } from "../../../Redux/ActionCreators/myContentAction";
import AddQuestions from "./addQuestion";
import QuizQuestionList from "./quizQuestionList";
import QuizList from "./quizList";
import PreviewLink from "./previewLink";
import EllipsisText from "react-ellipsis-text";
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { renameProperties, TimeDifferenceInMinutes, minutesToMilliseconds, msTominutes, renameArrProperties } from '../../../utils/miscelleneous';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addArticles, savedContentQuestion, editContentQuestion } from "../../../Redux/ActionCreators/myContentAction";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function AddResource() {
    const [chapter, setChapter] = useState('');
    const [chapterId, setChapterId] = useState('');
    const [topic, setTopic] = useState('');
    const [topicId, setTopicId] = useState('');
    const [isQuizAddShow, setIsQuizAddShow] = useState(false);
    const [quizTitle, setQuizTitle] = useState('');
    const [id, setId] = useState('');
    const [selectType, setSelectType] = useState('');
    const [isShowList, setIsShowList] = useState(false);
    const [file, markFile] = useState(true);
    const [link, markLink] = useState(false);
    const [videoTitle, setVideoTitle] = useState('');
    const [vTitle, setVTitle] = useState('');
    const [videoName, setVideoName] = useState('')
    const [video, setVideo] = useState('');
    const [videoUrl, setVideoUrl] = useState("/images/empty-image.svg");
    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState("/images/empty-image.svg");
    const [imageName, setImageName] = useState('');
    const [description, setDescription] = useState('');
    const [to, setTo] = useState('');
    const [from, setFrom] = useState("00:00-");
    const [from1, setFrom1] = useState('00');
    const [from2, setFrom2] = useState('00');
    const [to1, setTo1] = useState('00');
    const [to2, setTo2] = useState('00');
    const [duration, setDuration] = useState('');
    const [source, setSource] = useState('');
    const [durationError, setDurationError] = useState('');
    const [sourceError, setSourceError] = useState('');
    const [chars_left, setCharLeft] = useState(50);
    const [desc_char_left, setDescCharLeft] = useState(250);
    const [recommended, setRecommanded] = useState(false);
    const [url_type, setUrlType] = useState('');
    const [subjects, setSubject] = useState('');
    const [topics, setTopics] = useState('');
    const [topicsId, setTopicsId] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [contentTags, setContentTags] = useState(null);
    const [contentTagsTitle, setConteneTagsTitle] = useState('');
    const [videoTitleError, setVideoTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [addVideoError, setAddVideoError] = useState('');
    const [update, setUpdate] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [topicError, setTopicError] = useState('');
    const [imageError, setImageError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [isQuestionVisible, setIsQuestionVisible] = useState(false);
    const [isQuestionListVisble, setIsQuestionListVisble] = useState(false);
    const [typeError, setTypError] = useState('');
    const [toError, setToError] = useState('');
    const [resource, setResource] = useState(null);
    const [isView, setIsView] = useState(false);
    const [resourceObj, setResourceObj] = useState(null);


    const maxChar = 50;
    const max_desc_char = 250
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { selectFromSubject, chapterList, subjectChapters, topicsList, questionListForQuiz, previewData, topicsListOfSubject, documentDataState, progressLoader, percent, subject_id } = useSelector(state => state.manageContent);
    const { documentContentState } = useSelector(state => state.myContent);
    const type = location?.state?.type;
    const edit = location?.state?.edit

    useEffect(() => {
        const firstRender = async () => {
            await dispatch(resetPercentage());
            window.location.pathname.split('/')[1] === 'my-content' ? dispatch(showSideBar()) : dispatch(hideSideBar());
            setChapter(location.state.title);
            setTopic(location.state.topic);
            setTopicId(location.state.topicId);
            setChapterId(location.state.chapterId);
            const { details, edit, type } = location.state;
            dispatch(getAllSubjects());
            if (type === 'Quiz' && edit) {
                setIsQuizAddShow(true);
                setQuizTitle(details?.title);
                setId(details?._id);
                setSelectType('mcq');
                setIsShowList(true);
            }
            else if (type === 'Question' && edit) {
            }
            if (details !== undefined && edit) {
                if (!details?.isLink && details.type === 'Video') {
                    markFile(true);
                    markLink(false);
                }
                else if (details?.isLink && details.type === 'Video') {
                    markFile(false);
                    markLink(true);
                    const res = await dispatch(googleLinkPreview(details.url));
                    if (res?.type === 'GET_PREVIEW') {
                        setDescription(res?.payload?.description ? res.payload?.description : '')
                        setSource(res?.payload?.site_name ? res.payload?.site_name : '');
                    }
                }

                if (details !== undefined && edit) {
                    setId(details?._id);
                    setVTitle(details?.title);
                    setVideoTitle(details?.title);
                    setVideo(details?.url);
                    setVideoName(details?.url);
                    setImageUrl(details?.image);
                    setDescription(details?.description);
                    setImage(details?.image);
                    setDuration(msTominutes(details?.duration));
                    setSource(details?.source);
                    type === 'Video' && setTo(details.time !== undefined && details.time !== null && details.time.split('-')[1]?.toString());
                    type === 'Video' && setFrom(details.time !== undefined && details.time !== null && details.time.split('-')[0]);
                    type === 'Video' && setFrom1((details.time !== undefined && details.time !== null) ? details.time.split('-')[0].split(':')[0] : '00');
                    type === 'Video' && setFrom2((details.time !== undefined && details.time !== null) ? details.time.split('-')[0].split(':')[1] : '00');
                    type === 'Video' && setTo1((details.time !== undefined && details.time !== null) ? details.time.split('-')[1].split(':')[0] : '00');
                    type === 'Video' && setTo2((details.time !== undefined && details.time !== null) ? details.time.split('-')[1].split(':')[1] : '00');
                    setCharLeft(maxChar - details?.title?.length);
                    setRecommanded(details?.recommended);
                    setUrlType(details?.url_type);
                    setSubjectId(details.subject && details?.subject?._id);
                    setTopicsId(details?.chapter && details.chapter?._id)
                    setSubject(details?.subject && renameProperties(details.subject, { _id: 'value', name: 'label' }));
                    setTopics(details?.chapter && renameProperties(details.chapter, { _id: 'value', name: 'label' }));
                    setContentTags(details.tags && renameArrProperties(details?.tags));
                    setConteneTagsTitle(details?.tags);
                }

            }
        }
        firstRender();


        return () => {
            dispatch(deleteQuizQuestions());
        }
    }, [])




    const handleShow = () => {
        setIsQuizAddShow(true);
        setQuizTitle('');
        setSubject('');
        setSelectType('');
        setIsShowList(false);
    };

    const onFileChanged = (e) => {
        markFile(e.currentTarget.checked);
        markLink(false);
    };

    const onLinkChanged = (e) => {
        markLink(e.currentTarget.checked);
        markFile(false);
    };

    const handleChangeVideoLink = async (evt) => {
        var regex =
            /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(evt.target.value)) {
            setVideoTitleError("Please enter link");
            dispatch(googleLinkPreviewSetNull())
            return false;
        } else {
            await setVideo(evt.target.value);
            const res = await dispatch(googleLinkPreview(evt.target.value));
            if (res?.type === 'GET_PREVIEW') {
                setDescription(res?.payload?.description ? res.payload?.description : '')
                setSource(res?.payload?.site_name ? res.payload?.site_name : '');
                setVideoTitleError('');
            }
        }
    };

    const onvideoTitleChange = (val) => {
        const charCount = val?.length;
        const charLength = maxChar - charCount;
        setVideoTitle(val);
        setVideoTitleError('');
        setCharLeft(charLength);
    };

    const onvideodescriptionChange = (val) => {
        const charCount = val.length;
        const charLength = max_desc_char - charCount;
        setDescription(val);
        setDescriptionError('');
        setDescCharLeft(charLength);
    };

    const changeVideo = (e) => {
        var Extension = e.target.value
            .substring(e.target.value.lastIndexOf(".") + 1)
            .toLowerCase();

        if (location.state.type === "Video" && (Extension === "mkv" || Extension === "avi" || Extension === "mp4")) {
            const file = e.target.files[0];
            const reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                setUpdate(true);
                setVideoUrl(reader.result);
                setVideoName(file.name);
                setVideo(file);
                setAddVideoError('');
            } else {
                setImageUrl('');
                setVideoName('');
                setUpdate(false);
            }
        }
        else if (location.state.type === "Document" && (Extension === "png" || Extension === "jpeg" || Extension === "jpg" || Extension === "pdf")) {
            const file = e.target.files[0];
            const reader = new FileReader();

            if (file) {
                reader.readAsDataURL(file);
                setUpdate(true);
                setVideoUrl(reader.result);
                setVideoName(file.name);
                setVideo(file);
                setAddVideoError('');
            } else {
                setImageUrl('');
                setVideoName('');
                setUpdate(false);
            }
        } else {
            setSuccessMsg(false);
            setSuccessMessageText("The file Format not allow");
        }
    };



    const getFromTime1 = (val) => {
        setFrom1(val);
        setToError('');
        setFrom(val + ':' + from2);
    }

    const getFromTime2 = (val) => {
        setFrom2(val);
        setFrom(from1 + ':' + val);
        setToError('');
        var regExp = /(\d{1,2})\:(\d{1,2})/;
        if (parseInt(to.replace(regExp, "$1$2$3")) < parseInt(from.replace(regExp, "$1$2$3"))) {
            setToError('Please select correct time');
            setTo1('00');
            setTo2('00');
        }
        else {
            setToError('');
        }
    }

    const getToTime1 = (val) => {
        setTo1(val);
        setToError('');
        setTo(val + ':' + (to2 !== undefined ? to2 : '00'));
    }

    const getToTime2 = (val) => {
        setTo2(val);
        setTo(to1 ? to1 : '00' + ':' + val);
        setToError('');
        var regExp = /(\d{1,2})\:(\d{1,2})/;
        if (parseInt(to.replace(regExp, "$1$2$3")) < parseInt(from.replace(regExp, "$1$2$3"))) {
            setToError('Please select correct time');
            setTo1('00');
            setTo2('00');
        }
        else {
            setTo((to1 !== undefined ? to1 : '00') + ':' + val);
            setToError('');
        }
    }
    const changeDuration = (val) => {
        setDuration(val);
        setDurationError('')
    }

    const changeSource = (val) => {
        setSource(val);
        setSourceError('');
    }

    const handleChecked = (e) => {
        setRecommanded(e.target.checked);
    }


    const saveVideoOrDocument = async () => {
        if (previewData) {

            if (location.state.type === 'Document' && duration === '') {
                setDurationError(`Duration can't empty`);
            } else if (description === '') {
                setDescriptionError(`Description can't empty`);
            }
            else if (source === '') {
                setSourceError(`Source can't empty`);
            }
            else {
                if (window.location.pathname.split('/')[1] === 'manage-content') {
                    let data
                    if (location.state.type === 'Video') {
                        data = {
                            title: previewData?.title,
                            image: previewData?.image,
                            url: previewData?.url,
                            description: previewData?.description || description,
                            source: previewData?.site_name || source,
                            duration: Number((to || from) ? minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) : '0'),
                            time: from === '00:00-' ? '00:00-' : `${from}-${to}`,
                            topic_id: topicId,
                            type: 'Video'
                        }
                        const res = await dispatch(addVideoorDocument(data));
                        if (res?.type === 'ADD_RESOURCE') {
                            await backtoBubble();
                        }
                    }
                    else {
                        data = {
                            title: previewData?.title,
                            image: previewData?.image,
                            url: previewData?.url,
                            description: previewData?.description || description,
                            source: previewData?.site_name || source,
                            duration: Number(minutesToMilliseconds(duration)),
                            topic_id: topicId,
                            type: 'Document'
                        }
                        const res = await dispatch(addVideoorDocument(data));
                        if (res?.type === 'ADD_RESOURCE') {
                            await backtoBubble();
                        }
                    }
                } else {
                    if (location.state.type === 'Video') {
                        let obj = {
                            title: previewData?.title,
                            image: previewData?.image,
                            url: previewData?.url,
                            description: previewData?.description || description,
                            source: previewData?.site_name || source,
                            duration: Number((to || from) ? minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) : '0'),
                            time: from === '00:00-' ? '00:00-' : `${from}-${to}`,
                            subject_id: subjectId,
                            chapter_id: topicsId,
                            tags: contentTagsTitle,
                            type: 'Video'
                        }
                        await dispatch(addArticles(obj));
                        await backtoBubble();
                    }
                    else {
                        const obj = {
                            title: previewData?.title,
                            image: previewData?.image,
                            url: previewData?.url,
                            description: previewData?.description || description,
                            source: previewData?.site_name || source,
                            duration: Number(minutesToMilliseconds(2)),
                            type: 'Document'
                        }
                        await dispatch(addArticles(obj));
                        await backtoBubble();
                    }
                }
            }
        }
        else {
            if (videoTitle.trim() === "" || null) {
                setVideoTitleError(`Title can't empty`)
            }
            else if (description.trim() === "" || null) {
                setDescriptionError(`description can't empty`)
            }
            else if (video === '') {
                setAddVideoError(location.state.type === 'Video' ? "Please upload video" : "Please upload document");
            }
            else if (image === '') {
                setImageError("Please upload Image");
            }
            else if ((window.location.pathname.split('/')[1] === 'manage-content') && (source === '')) {
                setSourceError("Please enter source");
            }

            else {
                setVideoTitleError('');
                setDescriptionError('');
                setAddVideoError('');
                if (window.location.pathname.split('/')[1] === 'manage-content') {
                    if (location.state.type === 'Video') {
                        const obj = new FormData();

                        obj.append("title", videoTitle);
                        obj.append("topic_id", topicId);
                        obj.append("description", description);
                        obj.append("image", image);
                        obj.append("video", video);
                        obj.append("duration", Number(minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) || '00:00-'))
                        obj.append("source", source)
                        obj.append("time", from === '00:00-' ? '00:00-' : `${from}-${to}`)
                        await dispatch(uploadVideo(obj));
                        !documentDataState && await backtoBubble();
                    }
                    else {
                        var data1 = new FormData();
                        data1.append("title", videoTitle);
                        data1.append("topic_id", topicId);
                        data1.append("description", description);
                        data1.append("image", image);
                        data1.append("document", video);
                        data1.append("duration", Number(minutesToMilliseconds(duration)));
                        data1.append("source", source);
                        await dispatch(uploadDocument(data1));
                        !documentDataState && await backtoBubble();
                    }
                } else {
                    if (subjectId === '') {
                        setSubjectError('Please Select Subject');
                    }
                    else if (topicsId === '') {
                        setTopicError('Please Select Topic');
                    } else {
                        const obj = new FormData();
                        if (location.state.type === 'Video') {
                            obj.append("description", description);
                            obj.append("image", image);
                            obj.append("title", videoTitle);
                            obj.append("video", video);
                            obj.append("duration", Number(minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) || '00:00-'))
                            obj.append("source", source)
                            obj.append("time", from === '00:00-' ? '00:00-' : `${from}-${to}`)
                            obj.append('url_type', 'file')
                            obj.append('subject_id', subjectId);
                            obj.append('chapter_id', topicsId);
                            obj.append('tags', JSON.stringify(contentTagsTitle));
                            await dispatch(uploadVideoContent(obj));
                            !documentContentState && await backtoBubble()
                        } else {
                            obj.append("description", description);
                            obj.append("image", image);
                            obj.append("title", videoTitle);
                            // obj.append("source", source);
                            obj.append("duration", Number(minutesToMilliseconds(2)));
                            obj.append("document", video);
                            obj.append('subject_id', subjectId);
                            obj.append('chapter_id', topicsId);
                            obj.append('tags', JSON.stringify(contentTagsTitle));
                            await dispatch(uploadDocumentContent(obj));
                            !documentContentState && await backtoBubble()
                        }
                    }

                }
            }
        }
    };

    const editResource = async () => {
        if (previewData && link) {

            var data = new FormData();
            if (window.location.pathname.split('/')[1] === 'manage-content') {
                data.append("r_id", id);
                data.append("description", previewData?.description);
                data.append("image", previewData?.image || image);
                data.append("title", vTitle ? vTitle : previewData.title);
                data.append("type", location.state.type);
                data.append("url", previewData?.url);
                data.append('time', from === '00:00-' ? '00:00-' : `${from}-${to}`)
                data.append('duration', Number((to || from) ? minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) : '0'))
                previewData?.site_name && data.append('source', previewData?.site_name)
                await dispatch(updateVideoorArticles(data));
                await backtoBubble();
            } else {
                data.append("r_id", id);
                data.append("description", previewData?.description);
                data.append("image", previewData?.image || image);
                data.append("title", previewData?.title);
                data.append("type", location.state.type);
                data.append("url", previewData?.url);
                data.append('time', from === '00:00-' ? '00:00-' : `${from}-${to}`)
                data.append('duration', Number(minutesToMilliseconds(duration)))
                previewData?.site_name && data.append('source', previewData?.site_name)
                await dispatch(updatedArticle(data));
                await backtoBubble();
            }
        }
        else {
            if (videoTitle.trim() === "" || null) {
                setVideoTitleError(`Title can't empty`);
            } else if (description === "" || null) {
                setDescriptionError(`description can't empty`);
            }
            else if (video === '') {
                setAddVideoError(location.state.type === 'Video' ? "Please upload video" : "Please upload document");
            }
            else {
                setAddVideoError('');
                setDescriptionError('');
                setVideoTitleError('');
                if (window.location.pathname.split('/')[1] === 'manage-content') {
                    if (location.state.type === 'Video') {
                        var obj = new FormData();

                        obj.append("r_id", id);
                        obj.append("description", description);
                        obj.append("image", image);
                        obj.append("title", videoTitle);
                        (update ?
                            obj.append("video", video) :
                            obj.append('url', video))
                        obj.append('time', from === '00:00-' ? '00:00-' : `${from}-${to}`)
                        obj.append('duration', Number((to || from) ? minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) : '0'))
                        obj.append('source', source)
                        obj.append('type', location.state.type);

                        await dispatch(updateVideoorArticles(obj));
                        !documentDataState && await backtoBubble()
                    }
                    else {
                        var data2 = new FormData();
                        data2.append("r_id", id);
                        data2.append("description", description);
                        data2.append("image", image);
                        data2.append("title", videoTitle);
                        (update ?
                            data2.append("document", video) :
                            data2.append('url', video))
                        data2.append('type', location.state.type)
                        data2.append('duration', minutesToMilliseconds(duration));
                        data2.append('source', source)
                        await dispatch(updateVideoorArticles(data2));
                        !documentDataState && await backtoBubble();


                    }
                }
                else {
                    if (location.state.type === 'Video') {
                        var obj = new FormData();

                        obj.append("_id", id);
                        obj.append("description", description);
                        obj.append("image", image);
                        obj.append("title", videoTitle);
                        (update ?
                            obj.append("video", video) :
                            obj.append('url', video))
                        obj.append('time', from === '00:00-' ? '00:00-' : `${from}-${to}`)
                        obj.append('duration', Number((to || from) ? minutesToMilliseconds(TimeDifferenceInMinutes(from, to)) : '0'))
                        obj.append('source', source)
                        obj.append('subject_id', subjectId)
                        obj.append('chapter_id', topicsId)
                        obj.append('tags', JSON.stringify(contentTagsTitle));
                        obj.append('type', location.state.type);

                        await dispatch(updatedArticle(obj));
                        !documentDataState && await backtoBubble()
                    }
                    else {
                        var data2 = new FormData();
                        data2.append("_id", id);
                        data2.append("description", description);
                        data2.append("image", image);
                        data2.append("title", videoTitle);
                        (update ?
                            data2.append("document", video) :
                            data2.append('url', video))
                        data2.append('type', location.state.type)
                        data2.append('duration', minutesToMilliseconds(duration));
                        data2.append('source', source)
                        data2.append('subject_id', subjectId)
                        data2.append('chapter_id', topicsId)
                        data2.append('tags', JSON.stringify(contentTagsTitle))
                        await dispatch(updatedArticle(data2));
                        !documentDataState && await backtoBubble();
                    }
                }
            }
        }

    }



    const onChangeType = (val) => {
        setSelectType(val);
        setTypError('');
    }

    const selectSubject = (e) => {
        setSubject(e);
        setSubjectId(e.value)
        setChapter('');
        setTopicsId('');
        setTopicError('');
        setSubjectError('');
        dispatch(getChapters(e.value));
    }

    const selectTopic = (e) => {
        setTopics(e);
        setTopicsId(e.value);
        setTopicError('');
        dispatch(getTopics(e.value));
    }

    const selectTags = (e) => {
        setContentTags(e);
        setConteneTagsTitle(e.map(el => [el.label][0]));
    }

    const backToMain = () => {
        setIsQuestionListVisble(true);
        setIsQuestionVisible(false);
        window.location.pathname === '/my-content/add-resources' && navigate(-1);
    };

    const backToList = () => {
        setIsQuestionListVisble(false);
    };

    const changeImage = (e) => {
        var Extension = e.target.value
            .substring(e.target.value.lastIndexOf(".") + 1)
            .toLowerCase();
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB");
        }
        if (
            Extension === "png" ||
            Extension === "jpeg" ||
            Extension === "jpg" ||
            Extension === "mp4"
        ) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageUrl(reader.result);
                setImageError('');
            };
            if (file) {
                reader.readAsDataURL(file);
                setImageUrl(reader.result);
                setImage(file);
                setImageName(file.name);
            } else {
                setImageUrl('');
                setImageName('');
            }
        } else {
            setSuccessMsg(true);
            setSuccessMessageText("File Format not allowed");
        }
    };




    //Quiz


    const onChangetitle = (val) => {
        setQuizTitle(val);
        setTitleError('');
    };

    const saveQuizByID = async (id) => {
        const data = {
            quiz_id: id,
            topic_id: location.state.topicId
        };
        await dispatch(savedNewQuizByID(data));
    };

    const saveQuiz = async () => {
        if (quizTitle.trim() === "" || null) {
            setTitleError(`Title can't be empty`);
        } else if (questionListForQuiz?.length === 0 || null) {
            setQuestionError("please select the questions");
        }
        else {
            setTitleError('');
            const data = {
                title: quizTitle,
                time: 0,
                subject_ids: [location.state.subjectId],
                q_ids: questionListForQuiz,
                type: selectType
            };
            await dispatch(savedNewQuiz(data, 'quiz'));
            await setIsQuizAddShow(false);
            await dispatch(deleteQuizQuestions());
        }
    };

    const showQuizList = () => {
        if (quizTitle.trim() === "" || null) {
            setTitleError(`Title can't be empty`)
        }
        else if (selectType === '') {
            setTypError(`Type can't be empty`);
        }
        else {
            setIsShowList(true);
        }
    }

    const editQuiz = async () => {
        if (quizTitle === "") {
            setTitleError(`Title can't be empty`);
        }
        else if (questionListForQuiz?.length === 0 || null) {
            setQuestionError("please select the questions");
        }
        else {
            setTitleError('');
            setQuestionError('');

            const data = {
                _id: id,
                title: quizTitle,
                time: 0,
                q_ids: questionListForQuiz,
            };
            await dispatch(editedQuiz(data, 'quiz'));
            // setState({ isQuizAddShow: false });
            await navigate(-1)
            await dispatch(deleteQuizQuestions());
        }
    }

    const onQuizSave = () => {
        setIsQuizAddShow(false);
        setQuizTitle('');
        dispatch(deleteQuizQuestions());
    };


    const handleDragEnd = async (result) => {
        if (!result.destination) return
        await dispatch(reorederSubTopicsProps(result));
    }



    const goToSubTopicsContent = (chapterId, chapter, topic, topicId) => {
        setChapterId(chapterId);
        setTopicId(topicId);
        setChapter(chapter);
        setTopic(topic);
        dispatch(addParams(chapterId, chapter, topic, topicId));
    }

    const backtoBubble = () => {
        navigate(-1);
    };



    var times = [];
    var min = null;

    for (min = 0; min < 60; min++) {
        times.push(min < 10 ? ('0' + min) : min)
    }

    const subject = { label: location.state?.subject, value: subject_id }


    return (
        <Fragment>
            {
                (progressLoader) ?
                    <div className='div-overlay'>
                        {
                            type === 'Video' ?
                                <div className='progress-upload'>
                                    <p>Please wait uploading video... </p>
                                    <ProgressBar striped variant='success' now={percent} active label={`${percent}%`} />
                                </div>
                                :
                                <div className='progress-upload'>
                                    <p>Please wait uploading document... </p>
                                    <ProgressBar striped variant='success' now={percent} active label={`${percent}%`} />
                                </div>
                        }
                    </div> : null
            }
            <div className="subtopics-module">
                {
                    <div className='collapse-topics' style={{ background: '#fff', width: window.location.pathname.split('/')[1] === 'my-content' ? '23%' : '21.5%', visibility: window.location.pathname.split('/')[1] === 'my-content' ? 'hidden' : 'visible' }} >
                        <h1>{location?.state?.subject}</h1>
                        <div id="accordion" className="accordion">
                            {
                                topicsListOfSubject !== undefined && topicsListOfSubject.map((items, index) => {

                                    return (
                                        <div className="card mb-0" >
                                            <div className={items.topics?.length === 0 ? 'card-header collapsed display' : 'card-header collapsed'} data-toggle="collapse" href={`#${index}`}>
                                                <a className="card-title" >
                                                    <div style={{ marginLeft: 30 }} className='d-flex justify-content-start align-items-center' >
                                                        <p>{items.name !== null && <EllipsisText text={items.name} length={"30"} />}</p>

                                                    </div>
                                                </a>
                                            </div>
                                            <div id={index} className={chapterId === items._id ? 'card-body collapse show' : 'card-body collapse'} data-parent={`#accordion`} >
                                                <ul className='content-holder' >
                                                    {
                                                        items.topics.sort((a, b) => (a.index > b.index) ? 1 : -1).map((el, i) => {
                                                            return (
                                                                <li key={i} className="resource " style={{ background: topic === el.name ? '#EDF3F3' : '' }}>
                                                                    <div className="col-md-12 col-8 recent-title" >
                                                                        <div
                                                                            onClick={() => goToSubTopicsContent(items._id, items.name, el.name, el._id)}
                                                                        >
                                                                            <h1> {el.name !== null && <EllipsisText text={el.name} length={"120"} />}</h1>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>

                                        </div>


                                    )
                                })
                            }
                        </div>
                    </div>}

                {type === "Quiz" ? (
                    isQuizAddShow === false ? (
                        <QuizList
                            backToMain={() => backToMain()}
                            isQuiz={handleShow}
                            quizData={saveQuizByID}
                            subjectId={location.state.subjectId}
                            subject={location.state.subject}
                            selectFromSubject={selectFromSubject}
                        />
                    ) : isQuestionVisible ? (
                        <AddQuestions
                            type={"Question Bank"}
                            backToMain={backToMain}
                            isAddfromQuiz={true}
                            savedQuestions={(obj) => dispatch(savedQuestions(obj))}
                            resetPercentage={() => dispatch(resetPercentage())}
                            selectFromSubject={selectFromSubject}
                            qType={selectType}
                            getChapterOfSub={(id) => dispatch(getChapterOfSub(id))}
                            subjectChapters={subjectChapters}
                        // getClubbedQuestionList={getClubbedQuestionList}
                        />
                    ) : isQuestionListVisble ? (
                        <QuizQuestionList
                            backToMain={() => backToList()}
                            type="selectQuestion"
                            isnavRender={false}
                            // isQuizoff={handleShowoff}
                            subjectSelect={location.state.subjectId}
                            selectType={selectType}
                            selectFromSubject={selectFromSubject}
                            subject={selectFromSubject?.filter(el => el.value === location.state.subjectId)[0]}
                        />
                    ) : (
                        <div className="manage-subtopics add-video-background" style={{ width: "80%", marginLeft: 0 }} >
                            <div div className="practise">
                                <div className="d-flex justify-content-between">
                                    <h2>
                                        {
                                            isShowList && !edit ?
                                                null :
                                                <img
                                                    src="/images/back.svg"
                                                    onClick={() => location.state.edit ? navigate(-1) : onQuizSave()}
                                                    width="20px"
                                                    alt=""
                                                />
                                        }
                                        {" "}
                                        &nbsp;&nbsp;{location.state.edit ? 'Edit Quiz' : 'Add Quiz'}
                                    </h2>

                                    {
                                        !isShowList ? null :
                                            <button onClick={() => location.state.edit ? editQuiz() : saveQuiz()}>Save Quiz</button>
                                    }
                                </div>
                                <>
                                    <div className="form-group mt-2 w-100">
                                        <label>Quiz Title*</label>
                                        <input
                                            className="form-control mt-2  w-100"
                                            value={quizTitle}
                                            onChange={(e) => onChangetitle(e.target.value)}
                                        />
                                        <small>{titleError}</small>
                                    </div>
                                    {
                                        location.state.edit ? null
                                            :
                                            <div className="d-flex justify-content-between">
                                                <div className="form-group mt-2 w-100">
                                                    <label>Question type*</label>
                                                    <select
                                                        name="type"
                                                        id="subjects"
                                                        value={selectType}
                                                        onChange={(e) => onChangeType(e.target.value)}
                                                        disabled={isShowList ? true : false}
                                                    >
                                                        <option hidden>Select Question Type</option>
                                                        <option value='mcq'>Multiple choice question</option>
                                                        {/* <option value='Subjective'>Long Answer</option> */}
                                                    </select>
                                                    <small>{typeError}</small>
                                                </div>
                                            </div>
                                    }
                                    {
                                        !isShowList ?
                                            <button className="mt-2" onClick={showQuizList}>
                                                Save
                                            </button>
                                            :
                                            <div className="form-group mt-2">
                                                <label className="pb-2">Quiz Questions</label>
                                                <div className="addpointer d-flex align-items-center">
                                                    <button
                                                        onClick={() => {
                                                            setIsQuestionListVisble(true)
                                                            edit &&
                                                                setSelectType('mcq');
                                                        }}
                                                        style={{ pointerEvents: (!edit && !selectType) ? 'none' : 'auto', opacity: (!edit && !selectType) ? '0.7' : '1' }}
                                                    >
                                                        Select from list
                                                    </button>&nbsp;&nbsp;
                                                    <button
                                                        style={{ background: 'none', border: '1px solid #5855d6', color: '#5855d6' }}
                                                        onClick={() => setIsQuestionVisible(true)}
                                                    >
                                                        Add new question
                                                    </button>
                                                </div>
                                            </div>
                                    }
                                    <small style={{ color: 'red' }}>{questionListForQuiz?.length ? '' : questionError}</small>
                                </>
                            </div>

                            {
                                !isShowList ? null
                                    :
                                    <Fragment>
                                        <div className="selected-count">
                                            {" "}
                                            <h4>Selected Questions({questionListForQuiz.length})</h4>
                                        </div>
                                        <div className="select-qes-list">

                                            <div className="" style={{ width: '90%', marginLeft: 20 }}>
                                                {/* <div className="add-subject1" style={{ width: '90%', marginLeft: 20 }}> */}
                                                <Fragment>
                                                    <div className="content-header" >
                                                        <div className="content-heading" >
                                                            <ul>
                                                                <li>
                                                                    <div className="text-center" style={{ width: "3%" }}>

                                                                    </div>
                                                                    <div style={{ width: "20%", paddingLeft: 30 }}>
                                                                        #Question
                                                                    </div>
                                                                    <div style={{ width: "20%", paddingLeft: 0 }}>
                                                                        Type
                                                                    </div>
                                                                    <div style={{ width: "45%", textAlign: 'center' }}>Title</div>
                                                                    <div style={{ width: "20%" }} className="text-center">Action</div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {
                                                        <DragDropContext onDragEnd={handleDragEnd}>
                                                            <Droppable droppableId={id}>
                                                                {
                                                                    (provided) => (
                                                                        questionListForQuiz && questionListForQuiz.length ? (

                                                                            <ul className="program-list" {...provided.droppableProps} ref={provided.innerRef}>
                                                                                {questionListForQuiz !== undefined && questionListForQuiz.map((el, index) => {
                                                                                    return (
                                                                                        <Draggable key={el._id} draggableId={el._id} index={index} >
                                                                                            {
                                                                                                (provided) => (
                                                                                                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                                        <div>

                                                                                                        </div>
                                                                                                        <div className='d-flex align-items-center' style={{ width: "20%" }}>
                                                                                                            <img src='/images/drag.svg' alt='' />&nbsp;
                                                                                                            <p style={{ paddingLeft: 30 }}>{index + 1}</p>
                                                                                                        </div>

                                                                                                        <div style={{ width: "20%" }}>
                                                                                                            <p>{el.type === 'mcq' ? 'MCQ' : "Essay"}</p>
                                                                                                        </div>

                                                                                                        <div style={{ width: "42%" }}>
                                                                                                            <p dangerouslySetInnerHTML={{ __html: el.description }} />
                                                                                                        </div>
                                                                                                        <div className="text-center" style={{ width: '18%' }}>
                                                                                                            <img src="/images/delete.svg" alt="" onClick={() => dispatch(selectOrRemoveQuestions(el._id))} />
                                                                                                        </div>
                                                                                                    </li>)
                                                                                            }
                                                                                        </Draggable>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        ) : (
                                                                            <div className="empty-list">
                                                                                <img src="/images/empty.png" alt="" />
                                                                                <p>You have not added any Questions yet.</p>
                                                                            </div>

                                                                        ))
                                                                }
                                                            </Droppable>
                                                        </DragDropContext>}

                                                </Fragment>

                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </Fragment>
                            }

                        </div>
                    )
                ) : (type === 'Question' && window.location.pathname.split('/')[1] === 'my-content') ?
                    < AddQuestions
                        type={"Question Bank"}
                        backToMain={backToMain}
                        questions={location.state.details}
                        edit={location.state.edit}
                        // editContentQuestion={editContentQuestion}
                        isAddfromQuiz={true}
                        resetPercentage={resetPercentage}
                        selectFromSubject={selectFromSubject}
                        savedContentQuestion={(obj) => dispatch(savedContentQuestion(obj))}
                        editContentQuestion={(obj) => dispatch(editContentQuestion(obj))}
                        getChapterOfSub={(id) => dispatch(getChapters(id))}
                        chapterList={chapterList}
                    // getClubbedQuestionList={getClubbedQuestionList}
                    />
                    :
                    (
                        <div className={type === "Video" || type === "Document" ? "manage-subtopics subtopic-video" : "manage-subtopics"} style={{ width: "70%", marginLeft: 20 }}>
                            <div style={{ padding: 18, paddingLeft: 39 }}>
                                <div className="d-flex" style={{ fontSize: 20, cursor: "pointer" }}>
                                    <p style={{ marginTop: 5 }} onClick={() => backtoBubble()}>
                                        <img
                                            src="/images/back.svg"
                                            alt=""
                                            width="20px"
                                            className="mr-4"
                                        />
                                    </p>
                                    <div className="bc-nav">
                                        <h1>{type === "Video" && !edit ? "Add Video" : type === 'Video' && edit ? 'Edit Video' : type === 'Document' && !edit ? "Add Document" : type === 'Document' && edit ? 'Edit Document' : null}</h1>
                                    </div>
                                </div>
                                <div className="order-table-header">
                                    <div className="subject-header border-none">
                                        <div div className="add-subject mt-1">
                                            <p className='mt-0'>Fields marked * are mandatory</p>
                                            <div className="mt-2 custom-radio-button">
                                                <label>
                                                    {type === "Video" ? "Video Source*" : "Document Source*"}
                                                </label>
                                                <div className="duration d-flex mt-2">
                                                    <label style={{ width: "13%", display: 'flex' }}>
                                                        <input
                                                            onChange={onFileChanged}
                                                            type="radio"
                                                            className="mr-2"
                                                            value={file}
                                                            name="radio1"
                                                            checked={file}
                                                            disabled={edit ? file ? false : true : false}
                                                        />
                                                        File
                                                    </label>
                                                    {type === 'Video' &&
                                                        <label style={{ width: "13%", display: 'flex' }}>
                                                            <input
                                                                type="radio"
                                                                className="mr-2"
                                                                name="radio1"
                                                                value={link}
                                                                onChange={onLinkChanged}
                                                                checked={link}
                                                                disabled={edit ? link ? false : true : false}

                                                            />
                                                            Link
                                                        </label>}
                                                </div>
                                            </div>
                                            {link ? (
                                                <>
                                                    <div className="form-group mt-3">
                                                        <label>
                                                            {type === "Video" ? "Video Link*" : "Document Link"}
                                                            <aside className="float-right">
                                                                {/* {chars_left} */}
                                                            </aside>
                                                        </label>
                                                        <input
                                                            className="form-control mt-2"
                                                            style={{ width: "52%" }}
                                                            // maxLength={maxChar}
                                                            value={video}
                                                            onChange={(e) => handleChangeVideoLink(e)}
                                                        />
                                                        <small>{videoTitleError}</small>
                                                    </div>
                                                    {(type === "Video" && edit) &&
                                                        <div className="form-group mt-2">
                                                            <div className="mt-2">
                                                                <label>
                                                                    Video Title
                                                                </label>
                                                                <textarea className="form-control mt-2" value={vTitle} onChange={(e) => setVTitle(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {previewData ? (
                                                        <div style={{ width: "52%" }}>
                                                            <PreviewLink previewData={previewData} />
                                                        </div>
                                                    ) : null}
                                                    {previewData?.description === '' &&
                                                        <div className="mt-3">
                                                            <label>Description*</label>
                                                            <textarea
                                                                className="form-control mt-2"
                                                                value={description}
                                                                onChange={(e) => onvideodescriptionChange(e.target.value)}
                                                            />
                                                        </div>
                                                    }
                                                    <small>{descriptionError}</small>

                                                </>
                                            ) :
                                                file ?
                                                    (
                                                        <>
                                                            <div className="form-group mt-5">
                                                                <label>
                                                                    {type === "Video" ? "Video Title*" : "Document Title*"}
                                                                    <aside className="float-right">
                                                                        {chars_left}
                                                                    </aside>
                                                                </label>
                                                                <input
                                                                    className="form-control mt-2"
                                                                    style={{ width: "52%" }}
                                                                    maxLength={maxChar}
                                                                    value={videoTitle}
                                                                    onChange={(e) =>
                                                                        onvideoTitleChange(e.target.value)
                                                                    }
                                                                />
                                                                <small>{videoTitleError}</small>
                                                            </div>
                                                            <div className="form-group mt-4">
                                                                <label>
                                                                    Description*
                                                                    <aside className="float-right">
                                                                        {desc_char_left}
                                                                    </aside>
                                                                </label>
                                                                <textarea
                                                                    className="form-control mt-2"
                                                                    maxLength={max_desc_char}
                                                                    value={description}
                                                                    onChange={(e) =>
                                                                        onvideodescriptionChange(e.target.value)
                                                                    }
                                                                />
                                                                <small>{descriptionError}</small>
                                                            </div>
                                                            <div className="mt-4">
                                                                <label>
                                                                    {" "}
                                                                    {type === "Video" ? "Video File*" : "Document File*"}
                                                                </label>
                                                                {type === "Video" ? null : (
                                                                    <p>Only PDF accepted</p>
                                                                )}
                                                                <div className="d-flex align-items-center ">
                                                                    <div className="d-flex flex-column mt-3 position-relative ">
                                                                        {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                                        <span>
                                                                            <img src="/images/upload-icon.svg" alt="" />{" "}
                                                                            &nbsp;Upload file from computer &nbsp;
                                                                            <small>{videoName}</small>
                                                                            <input
                                                                                type="file"
                                                                                // ref="uploadImg"
                                                                                className="file-upload_question"
                                                                                name="selectedFile"
                                                                                accept={
                                                                                    type === "Video"
                                                                                        ? "video/*"
                                                                                        : ".pdf,.doc"
                                                                                }
                                                                                onChange={changeVideo}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <small>{addVideoError}</small>

                                                            </div>
                                                            <div className="mt-3">
                                                                <label>Unit Cover Image</label>
                                                                <div className="d-flex align-items-center ">
                                                                    {image ? (
                                                                        <div className="program-image">
                                                                            <img
                                                                                src={imageUrl}
                                                                                alt=""
                                                                                width="139px"
                                                                                height="78px"
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="program-image">
                                                                            <img src={imageUrl} alt="" />
                                                                        </div>
                                                                    )}

                                                                    <div className="d-flex flex-column mt-3 position-relative upload-img">
                                                                        {/* <span>
                              <img
                                src="/images/gallery-icon.svg"
                                width="24px"
                                alt=""
                              />{" "}
                              &nbsp;Select From Gallery
                            </span> */}
                                                                        <span>
                                                                            {" "}
                                                                            <img src="/images/upload-icon.svg" alt="" />{" "}
                                                                            &nbsp;Upload Image &nbsp;{" "}
                                                                            <small>{imageName}</small>
                                                                            <input
                                                                                type="file"
                                                                                // ref="uploadImg"
                                                                                className="file-upload"
                                                                                style={{ left: 0 }}
                                                                                name="selectedFile"
                                                                                accept="image/png, image/jpeg,.pdf,.doc"
                                                                                onChange={changeImage}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <small>{imageError}</small>
                                                            </div>
                                                        </>
                                                    )
                                                    :
                                                    null
                                            }

                                            <Fragment>

                                                {
                                                    type === 'Video' ?
                                                        <div className='fromtoTime form-group d-flex mt-4'>

                                                            <div style={{ width: '15%' }}>
                                                                <label>
                                                                    From*
                                                                </label>
                                                                <div className='d-flex'>
                                                                    <select className='form-control mt-1' value={from1?.length === 1 ? `0${from1}` : from1} onChange={(e) => { getFromTime1(e.target.value) }}>
                                                                        {/* <option hidden>--:--</option> */}
                                                                        {
                                                                            times.map(el => {
                                                                                return (
                                                                                    <option>{el}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select> &nbsp; &nbsp;&nbsp;
                                                                    <select className='form-control mt-1' value={from2?.length === 1 ? `0${from2}` : from2} onChange={(e) => { getFromTime2(e.target.value) }}>
                                                                        {/* <option hidden>--:--</option> */}
                                                                        {
                                                                            times.map(el => {
                                                                                return (
                                                                                    <option>{el}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {/* <input type="time" className='form-control without_ampm' value={from}  onChange={(e) => { getFromTime(e.target.value) }} name="appt" required /> */}
                                                            </div>&nbsp; &nbsp;&nbsp;
                                                            <div style={{ width: '15%', marginLeft: 20 }}>
                                                                <label>
                                                                    To*
                                                                </label>
                                                                <div className='d-flex'>
                                                                    <select className='form-control mt-1' value={to1?.length === 1 ? `0${to1}` : to1} onChange={(e) => { getToTime1(e.target.value) }}>
                                                                        {/* <option hidden>--:--</option> */}
                                                                        {
                                                                            times.map(el => {
                                                                                return (
                                                                                    <option value={el}>{el}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select> &nbsp;&nbsp; &nbsp;
                                                                    <select className='form-control mt-1' value={to2?.length === 1 ? `0${to2}` : to2} onChange={(e) => { getToTime2(e.target.value) }}>
                                                                        {/* <option hidden>--:--</option> */}
                                                                        {
                                                                            times.map(el => {
                                                                                return (
                                                                                    <option value={el}>{el}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                <small>{toError}</small>
                                                {(type === 'Document' && window.location.pathname.split('/')[1] === 'my-content') ? null :
                                                    <div className="mt-3">
                                                        <label>Duration(in mins)*</label>
                                                        <input
                                                            type="number"
                                                            className="form-control mt-2"
                                                            value={duration}
                                                            onChange={(e) => changeDuration(e.target.value)}
                                                        />
                                                    </div>
                                                }

                                                <small>{durationError}</small>
                                                {
                                                    window.location.pathname.split('/')[1] === 'my-content' ?
                                                        <Fragment>
                                                            <div className='row'>
                                                                <div className="mt-2 col-md-4">
                                                                    <label>Subject*</label>
                                                                    <div className="mt-2">
                                                                        <Select
                                                                            components={{ DropdownIndicator }}
                                                                            placeholder='Select Subject'
                                                                            value={subjects}
                                                                            onChange={selectSubject}
                                                                            options={selectFromSubject}
                                                                            menuPlacement="auto"
                                                                            maxMenuHeight={200}

                                                                        />
                                                                    </div>
                                                                    <small>{subjectError}</small>
                                                                </div>
                                                                <div className="mt-2 col-md-4 " style={{ marginLeft: -3 }}>
                                                                    <label>Unit*</label>
                                                                    <div className="mt-2">
                                                                        <Select
                                                                            components={{ DropdownIndicator }}
                                                                            placeholder='Select Unit'
                                                                            value={topics}
                                                                            onChange={selectTopic}
                                                                            options={chapterList?.map(el => [{ label: el.name, value: el._id }][0])}
                                                                            menuPlacement="auto"
                                                                            maxMenuHeight={200}
                                                                        />
                                                                    </div>
                                                                    <small>{topicError}</small>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="mt-2 col-md-4">
                                                                    <label>Tags</label>
                                                                    <p className='mt-0'>Add search tags like unit , sub unit to help you easily find this Video</p>
                                                                    <div className="mt-2">
                                                                        <CreatableSelect
                                                                            components={{ DropdownIndicator }}
                                                                            isSearchable
                                                                            isMulti
                                                                            placeholder='Select Tags'
                                                                            value={contentTags}
                                                                            onChange={selectTags}
                                                                            options={topicsList?.map(el => [{ label: el.name, value: el._id }][0])}
                                                                            menuPlacement="auto"
                                                                            maxMenuHeight={200}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                        :
                                                        null
                                                }
                                                {(type === 'Document' && window.location.pathname.split('/')[1] === 'my-content') ? null :
                                                    <div className='row'>
                                                        <div className="col-md-8 mt-3">
                                                            <label>Source*</label>
                                                            <input
                                                                type="text"
                                                                className="form-control mt-2"
                                                                value={source}
                                                                onChange={(e) => changeSource(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                }
                                                <small>{sourceError}</small>
                                                {/* {
                                                    <div className='recommend-checkbox'>
                                                        <label><input type="checkbox" onChange={handleChecked} checked={recommended} />Recommended</label>
                                                    </div>
                                                } */}
                                            </Fragment>

                                            <hr style={{ marginTop: 25 }} />

                                            <div className="save-btn mt-2">
                                                {
                                                    edit ?
                                                        <button
                                                            disabled={documentDataState}
                                                            onClick={() => editResource()}
                                                        >
                                                            Save
                                                        </button> :
                                                        <button
                                                            disabled={documentDataState}
                                                            onClick={() => saveVideoOrDocument()}
                                                        >
                                                            Save
                                                        </button>
                                                }
                                                <button onClick={() => backtoBubble()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            {/* <div className="feeds-details" style={{ display: isView ? 'block' : 'none', }}>
                <Notespopup
                    activeFeedData={resource}
                    // saveNote={saveNote}
                    // likeClick={likeClick}
                    closeNotes={closeDetails}
                />
            </div> */}
        </Fragment >
    );
}

export default (AddResource);
