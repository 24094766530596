/* eslint-disable no-useless-escape */
import React, { Fragment, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { getStudentNotices, getNextStudentNotices, getSearch, getAdminNotice, getNextAdminNotice, getUser, openNotice, backToMain, getListofUsers, selectAll, checkOne, resetUserList, uploadFile, getTopics, getSubTopics, resetUser, createdNotice, deactivateNotice, resetPopup, markedRead, getViews, emptyDetails, getExtraDetails, getAttachmentResource, clearDetails } from '../../Redux/ActionCreators/noticeAction';
import { getMyContents, getNextMyContents, addResourceToAttachment } from '../../Redux/ActionCreators/myContentAction';
import './noticeBoard.scss';
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import Select, { components } from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import Chip from '@mui/material/Chip';
import $ from 'jquery'
import Snackbar from '@mui/material/Snackbar';
import { TopicLoader, BallBeat } from '../../Loader/loader';
import { convertDate } from '../../utils/miscelleneous';
import { useDispatch, useSelector } from 'react-redux';
import { getClasses, getSectionFromClass } from '../../Redux/ActionCreators/userAction';


function DaysBetween(StartDate, EndDate) {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());

    // so it's safe to divide by 24 hours
    return (start - end) / oneDay;
}
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};


function NoticeBoard() {

    const [pageCount, setPageCount] = useState(1);
    const [details, setDetails] = useState(null);
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    // const [activeTab, setActiveTab] = useState('all');
    const [activeBtn, setActiveBtn] = useState('student');
    // const [activeItem, setActiveItem] = useState('content');
    const [list, setList] = useState(false);
    const [isOpenItem, setIsOpenItem] = useState(false);
    const [targetMenu, setTargetMenu] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [isSelectGroup, setIsSelectGroup] = useState(false);
    const [id, setId] = useState('');
    const [classs, setClass] = useState(null);
    const [role, setRole] = useState(null);
    const [subject, setSubject] = useState(null);
    const [section, setSection] = useState(null);
    const [classError, setClassError] = useState('');
    const [roleError, setRoleError] = useState('')
    const [subjectError, setSubjectError] = useState('')
    const [sectionError, setSectionError] = useState('')
    const [emailSuggestion, setEmailSuggestion] = useState('');
    const [emailError, setEmailError] = useState('');
    const [emails, setEmails] = useState([]);
    const [audience, setAudience] = useState(null);
    const [noticeSubject, setNoticeSubject] = useState('');
    const [titleError, setTitleError] = useState('');
    const [description, setDescription] = useState('');
    const [descError, setDescError] = useState('')
    const [expiry, setExpiry] = useState('');
    const [dateError, setDateError] = useState('');
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState('');
    const [fileError, setFileError] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [resource, setResource] = useState([]);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [page, setPage] = useState(1);
    const [isAddLink, setIsAddLink] = useState(false);
    const [fileType, setFileType] = useState('');
    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const [title, setTitle] = useState('');
    const [titleError1, setTitleError1] = useState('');
    const [url, setUrl] = useState('');
    const [urlError, setUrlError] = useState('');
    const [isloading, setisloading] = useState(false);
    const [checkedId, setCheckedId] = useState([]);
    const [checkedStaffId, setCheckedStaffId] = useState([]);
    const [checked, setChecked] = useState(false);
    const [checked1, setChecked1] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [viewId, setViewId] = useState('');
    const [target, setTarget] = useState(false);

    const dispatch = useDispatch();
    const { studentNoticeList, noticeLists, suggestions, isAddNotice, programs, sections, subjects, roles, userList, staffList, files, topics, subtopics, resources, sectionsList, isListLoading, toArr, xtraDetail, attachmentResource, loading, } = useSelector(state => state.noticeBoard);
    const { isResourceLoading, isLoading, contents } = useSelector(state => state.myContent);
    const { classes, sectionOfClass } = useSelector(state => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        if (localStorage.getItem('role') === 'student') {
            dispatch(getStudentNotices(pageCount));
        }
        else {
            dispatch(getAdminNotice(pageCount));
            // getAllSubjects();
        }
    }, [])


    const fetchData = () => {
        setPageCount(pageCount + 1);
        dispatch(getNextAdminNotice(pageCount + 1));
    }

    const fetchNotification = () => {
        setPageCount(pageCount + 1);
        dispatch(getNextStudentNotices(pageCount + 1));
    }

    const onBtnChange = (e) => {
        setActiveBtn(e);
        setClassError('');
        setRoleError('');
    }



    const openMenus = (e, id) => {
        setTargetMenu(e.target);
        setOpenMenu(!openMenu);
        setId(id);
    }

    const isOpenViews = (id) => {
        setOpenView(true);
        dispatch(getViews(id));
    }


    const handleCloseModal = () => {
        setOpenView(false);
        setOpen(false);
        dispatch(emptyDetails());
    }

    const selectGroup = () => {
        setIsSelectGroup(true);
        dispatch(getClasses());
    }

    const closeModal = () => {
        setIsSelectGroup(false);
        setList(false);
        setIsOpenItem(false);
        setClass(null);
        setSection(null);
        setSubject(null);
        setRole(null);
        setClassError('');
        setRoleError('');
        dispatch(resetUserList());
    }

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            if (query) {
                dispatch(getSearch(query));
                setPageCount(1);
            } else {
                dispatch(getSearch(''))
            }
        }, 500);

        return () => {
            clearTimeout(delaySearch);
        };
    }, [query]);



    const getSearchNotice = (val) => {
        setQuery(val);
    }

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            if (emailSuggestion) {
                dispatch(resetUser());
                setEmailError('');
                dispatch(getUser(emailSuggestion));
            }
        }, 500);

        return () => {
            clearTimeout(delaySearch);
        };
    }, [emailSuggestion]);

    const selectUser = (val) => {
        setEmailSuggestion(val);
    };

    const selectedEmail = (items) => {
        var existObj = emails.find(({ _id }) => _id === items._id);
        if (!existObj) {
            setEmails([...emails, items]);
            setAudience('all');
            setEmailError('')
            $('.email-suggestion').hide();
            $('#email1').val('').focus();
        }
        else {
            setSuccessMsg(true);
            setSuccessMessageText('Email is already present');
            $('#email1').val('').focus();
        }
    }

    const changeSubject = (val) => {
        setNoticeSubject(val);
        setTitleError('');
    }

    const changeDescription = (val) => {
        setDescription(val);
        setDescError('');
    }

    const changeDate = (e) => {
        setExpiry(e.target.value);
        setDateError('');
    }

    const changeImage = (e) => {
        var Extension = e.target.value
            .substring(e.target.value.lastIndexOf(".") + 1)
            .toLowerCase();
        if (
            Extension === "png" ||
            Extension === "jpeg" ||
            Extension === "jpg" ||
            Extension === "pdf" || Extension === 'doc' || Extension === 'docx'
        ) {
            if (e.target.files[0].size > 2097152) {
                setSuccessMsg(true);
                setSuccessMessageText("The file size cannot exceed 2MB");
            } else {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onloadend = () => {

                };
                if (file) {
                    reader.readAsDataURL(file);
                    setImage(file);
                    setImageName(file.name);
                    setFileError('');
                } else {
                    setImageName('');
                }
            }
        } else {
            setSuccessMsg(true);
            setSuccessMessageText("The file Format not allow");
        }
    };

    const deleteUrl = (items, type) => {
        if (type === 'attachment') {
            const attachment = attachments.filter(el => el.title !== items.title) || resource.filter(el => el.title !== items.title)
            setAttachments(attachment);
        }
        else {
            const resources = resource.filter(el => el.title !== items.title)
            setResource(resources);
        }
    }

    const addContent = () => {
        setIsOpenAdd(true);
        dispatch(getMyContents(1));

    }

    const fetchContent = () => {
        setPage(page + 1);
        dispatch(getNextMyContents(page + 1));
    }


    const addLink = (type) => {
        setIsAddLink(true);
        setFileType(type);
    }

    const handleModal = () => {
        setIsAddLink(false);
        setIsOpenNotification(false);
        setTitle('');
        setImage('');
        setTitleError1('');
        setFileError('')
        setUrl('');
        setUrlError('');
    }


    const changeTitle = (val) => {
        setTitle(val);
        setTitleError1('')
    }

    const changeUrl = (val) => {
        var regex =
            /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(val)) {
            setUrlError("please enter valid link")
            return false;
        } else {
            setUrlError('');
            setUrl(val);
        }
    }



    const saveLink = async (type) => {
        setisloading(true);
        if (type === 'link') {
            if (title === '') {
                setTitleError1('Please enter title');
                setisloading(false);
            }
            else if (url === '') {
                setUrlError('Please enter Link');
                setisloading(false);
            }
            else {
                const obj = {
                    title: title,
                    url: url
                }
                setAttachments([...attachments, obj]);
                setIsAddLink(false);
                setTitle('');
                setUrl('');
                setisloading(false);
            }
        }
        else {
            if (title === '') {
                setTitleError1('Please enter title');
                setisloading(false);
            }
            else if (image === '') {
                setFileError('Please upload file');
                setisloading(false);

            }
            else {
                let obj = new FormData()
                obj.append('file', image);
                const data = await dispatch(uploadFile(obj, title));
                console.log(data)
                let urlObj = {
                    title: data?.payload?.title,
                    url: data?.payload?.url
                }
                await setAttachments([...attachments, urlObj]);
                setIsAddLink(false);
                setTitle('');
                setImage('');
                setImageName('');
                setisloading(false);
            }
        }
    }


    const addResourceToAttachments = async (details) => {
        await dispatch(addResourceToAttachment(details._id));
        console.log('1', resource)
        await setResource(prevResource => [...(prevResource || []), details]);
        console.log('2', resource)
    }

    const handleDelete = (email, i) => {
        let mails = emails.filter((chip) => chip.email !== email.email)
        setEmails(mails);
    };

    const selectClass = (e) => {
        setClass(e);
        setClassError('');
        setSection(null);
        dispatch(getSectionFromClass(e?.class, e.affiliation[0]?._id));
    }

    const selectSection = async (e) => {
        setSection(e);
        setSectionError('');
        console.log(e)
        await dispatch(getListofUsers(activeBtn, classs?.class, e?.value, role?.value, ''));
    }

    const selectRole = async (e) => {
        setRole(e);
        setRoleError('');
        await dispatch(getListofUsers(activeBtn, classs?.class, section?.value, e?.value, ''));
    }



    useEffect(() => {
        if (activeBtn === 'student') {
            // Filter the updated userList after the API call.
            const filteredList = userList.filter(el => el.isChecked === true);
            setCheckedId(filteredList);
        }
        else {
            const list = staffList.filter(el => el.isChecked === true);
            setCheckedStaffId(list)
        }
    }, [userList, staffList]);

    const checkAll = async (e) => {
        if (activeBtn === 'student') {
            setChecked(e.target.checked);
            dispatch(selectAll(activeBtn, e.target.checked));
        }
        else {
            setChecked1(e.target.checked);
            dispatch(selectAll(activeBtn, e.target.checked));
        }
    }

    const selectOne = async (e, el) => {
        await dispatch(checkOne(activeBtn, e.target.value, e.target.checked));
        if (activeBtn === 'student') {
            if (e.target.checked) {
                setCheckedId([...checkedId, el]);
            }
            else {
                setCheckedId(checkedId.filter(el => el._id !== e.target.value));
            }
        }
        else {
            if (e.target.checked) {
                setCheckedStaffId([...checkedStaffId, el]);
            }
            else {
                setCheckedStaffId(checkedStaffId.filter(el => el._id !== e.target.value));
            }
        }

    }


    const selectDone = () => {
        setIsSelectGroup(false);
        const checked = checkedId.concat(checkedStaffId)
        var ids = new Set(emails.map(d => d._id));
        setEmails([...emails, ...checked.filter(d => !ids.has(d._id))]);
        setEmailError('');
        setAudience(((checkedId.length > 0 && checkedStaffId.length > 0)) ? 'all' : checkedId.length > 0 ? 'student' : checkedStaffId.length > 0 ? 'staff' : null);
        setChecked(false);
        setChecked1(false);
        setRole(null);
        setClass(null);
        setSection(null);
        dispatch(resetPopup(activeBtn))
    }


    const getReplicate = async (notice) => {
        await dispatch(openNotice());
        await dispatch(getAttachmentResource(notice._id));
        setNoticeSubject(notice?.subject);
        setDescription(notice?.description);
        setExpiry(moment(notice.expiry_date).format('YYYY-MM-DD'));
        setAttachments(notice.attachments);
        // setResource(attachmentResource);
    }

    useEffect(() => {
        setResource(attachmentResource)
    }, [!isAddNotice && attachmentResource])

    const createNotifications = async () => {
        if (!emails.length && (audience !== 'student' && audience !== 'staff')) {
            setEmailError('Please enter emails');
        }
        else if (noticeSubject === '') {
            setTitleError('Please enter notice subject');
        }
        else if (description === '') {
            setDescError('Please enter description');
        }
        else if (expiry === '') {
            setDateError('Please enter date');
        }
        else {
            const obj = {
                to: emails.map(el => el._id),
                all: audience,
                subject: noticeSubject,
                description: description,
                expiry_date: expiry,
                audience: audience,
                attachments: attachments,
                resources: resource ? resource.map(el => [{ _id: el._id, type: el.type }][0]) : []
            }
            await dispatch(createdNotice(obj));
            await dispatch(getAdminNotice(1));
            setPageCount(1);
            setEmailError('')
            setEmails([]);
            setDescription('');
            setNoticeSubject('');
            setExpiry('');
            setCheckedId([]);
            setAttachments([])
            setCheckedStaffId([]);
            setResource([]);
        }
    }

    const focusInput = () => {
        $('#email1').focus()
    }

    const checkFocus = () => {
        // dummy element
        var dummyEl = document.getElementById("express-form-typeahead");
        // check for focus
        var isFocused = document.activeElement === dummyEl;
        if (isFocused) {
            setIsFocused(isFocused);
        } else {
            setIsFocused(false);
        }
    };

    const openDetails = async (e, id) => {
        // e.preventDefault();
        e.stopPropagation();
        setTarget(e.target);
        setViewId(id);
        setOpen(true);
        await dispatch(clearDetails());
        await dispatch(getExtraDetails(id));
    }


    // const selectSubject1 = (e) => {
    //     // this.setState({
    //     //     subject1: e,
    //     //     subjectId1: e.value
    //     // }, () => {
    //     //     this.props.getTopics(this.state.subjectId1)
    //     // })
    // }

    // const selectTopic = (e) => {
    //     this.setState({
    //         topic: e,
    //         topicId: e.value,
    //         list1: true
    //     }, () => {
    //         this.props.getSubTopics(this.state.topicId)
    //     })
    // }

    // const selectSubject2 = (e) => {
    //     this.setState({
    //         subject2: e,
    //         subjectId2: e.value
    //     }, () => {
    //         // this.props.getTopics(this.state.subjectId1)
    //     })
    // }

    // const onTabChange = (e) => {
    //     setActiveTab(e);
    //     props.selected(activeBtn, e);
    // }

    // const onItemChange = (e) => {
    //     setActiveItem(e);
    //     // if (activeItem === 'content') {
    //     //     if (this.state.subjectId1 === '') {
    //     //         this.setState({
    //     //             list1: false
    //     //         })
    //     //     }
    //     //     else {
    //     //         this.setState({
    //     //             list1: true,
    //     //             subjectError1: ''
    //     //         })
    //     //     }
    //     // }
    //     // else {
    //     //     if (this.state.subjectId2 === '') {
    //     //         this.setState({
    //     //             list1: false
    //     //         })
    //     //     }
    //     //     else {
    //     //         this.setState({
    //     //             list1: true,
    //     //             subjectError2: ''
    //     //         })
    //     //     }
    //     // }
    // }


    const closeSuggestion = () => {
        $(".email-suggestion").fadeOut(200);
    };

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }


    $("body").click((e) => {
        closeSuggestion();
    });
    const mQuery = window.matchMedia("(min-width: 1500px)")
    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />
            {
                localStorage.getItem('role')?.includes('admin') || localStorage.getItem('role')?.includes('master')
                    || localStorage.getItem('role').includes('faculty') || localStorage.getItem('role') === 'sme' || localStorage.getItem('role') === 'program manager' || localStorage.getItem('role')?.includes('training manager')
                    ?
                    <div className='notice-board-holder'>
                        {
                            !isAddNotice ?
                                <Fragment>
                                    <h1>Notice Board</h1>
                                    <div className='users-header' >
                                        <div className='d-flex justify-content-between align-items-center' style={{ width: '70%' }}>
                                            <button onClick={() => dispatch(openNotice())} ><img src='/images/plus-white.svg' alt='' />  Add Notice</button>
                                            <div className="has-search"  >
                                                <span className="form-control-feedback">
                                                    <img src="/images/search1.svg" width="14" alt='' />
                                                </span>
                                                <input placeholder='' id='search' onChange={(e) => getSearchNotice(e.target.value)} autoComplete='off' className='form-control' />
                                            </div>
                                        </div>
                                        {/* <div className='d-flex align-items-center cursor-pointer' >
                                            <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                        </div> */}
                                    </div>
                                    {
                                        isListLoading ?
                                            <TopicLoader />
                                            :
                                            <div className="table-admin" style={{ marginTop: -43 }}>
                                                <InfiniteScroll
                                                    dataLength={noticeLists !== undefined && noticeLists.length} //This is important field to render the next data
                                                    next={fetchData}
                                                    hasMore={true}
                                                >
                                                    <Table responsive style={{ overflow: 'hidden' }}>
                                                        <thead>
                                                            <tr className="header-table">
                                                                <th>Date</th>
                                                                <th>Notice title</th>
                                                                <th>Expiry</th>
                                                                <th>Status</th>
                                                                <th>Sent</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            <hr />
                                                        </thead>
                                                        <div className='showing-results' >
                                                            {noticeLists !== undefined && noticeLists.length > 0 ? <p>Showing {noticeLists !== undefined && noticeLists.length} Notices</p> : null}
                                                        </div>
                                                        <tbody className="table-body" >
                                                            {
                                                                (noticeLists !== "" && noticeLists !== undefined && noticeLists.length > 0 ?
                                                                    noticeLists.map((notice, i) => {

                                                                        return (
                                                                            <tr key={i} style={{ borderTop: i === 0 && noticeLists.length > 0 ? '35px solid #F7F7FA' : null }}>
                                                                                <td className='text-center'>{notice.createdAt !== undefined && moment(new Date(notice.createdAt)).format('DD-MMM-YYYY')}</td>
                                                                                <td style={{ width: '25%' }} >{notice.subject}</td>
                                                                                <td style={{ width: '12%' }} className='text-center'>{convertDate(notice.expiry_date)} </td>
                                                                                <td className='text-center'>{notice.expired ? 'Expired' : 'Active'}</td>
                                                                                <td className='text-capitalize cursor-pointer' style={{ width: '10%' }}>
                                                                                    <h4 onClick={(e) => new Date(notice.createdAt) < new Date(2021, 11, 22) ? '' : isOpenViews(notice._id)} className={new Date(notice.createdAt) < new Date(2021, 11, 22) ? 'deco-none' : 'deco'}  >
                                                                                        {notice.total}
                                                                                    </h4>
                                                                                </td>
                                                                                <td className='action' style={{ width: '15%' }}>
                                                                                    <img src='/images/view_grey.svg' alt='' width='25px' onClick={() => {
                                                                                        dispatch(markedRead(notice._id))
                                                                                        dispatch(getAttachmentResource(notice._id))
                                                                                        setIsOpenNotification(true)
                                                                                        setDetails(notice);
                                                                                    }} />&nbsp;&nbsp;&nbsp;
                                                                                    <Overlay
                                                                                        show={openMenu}
                                                                                        target={targetMenu}
                                                                                        onHide={() => setOpenMenu(false)}
                                                                                        placement="bottom-start"
                                                                                        rootClose={true}
                                                                                    >
                                                                                        <Popover id={`popover-containd${i}`} style={{ width: '130px', display: notice._id === id ? 'block' : 'none', borderRadius: 2, zIndex: 3, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                                            <ul className='popover-add-view' style={{ padding: '10px 20px', listStyle: 'none' }}>
                                                                                                <li className='cursor-pointer' onClick={() => getReplicate(notice)}>Replicate</li>
                                                                                                {notice.expired ? <li className='cursor-pointer' onClick={() => dispatch(deactivateNotice(notice._id))}>Deactivate</li> : null}
                                                                                            </ul>
                                                                                        </Popover>
                                                                                    </Overlay>

                                                                                    <button onClick={(e) => openMenus(e, notice._id)}>
                                                                                        <img src='/images/menu.svg' alt='' />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr >
                                                                        <td colSpan={6}>
                                                                            <div className='empty-list'>
                                                                                <img src='/images/empty.png' alt='' />
                                                                                {/* <p>You have not added any programs yet.</p> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>)
                                                            }


                                                        </tbody>




                                                    </Table>
                                                </InfiniteScroll>
                                            </div>
                                    }
                                </Fragment> :
                                <div className='add-notice'>
                                    <div className='notice-header' style={{ width: '55%' }}>
                                        <img onClick={() => {
                                            dispatch(backToMain());
                                            setNoticeSubject('');
                                            setEmails([]);
                                            setDescription('');
                                            setAttachments([]);
                                            setExpiry('');
                                        }
                                        } src='/images/back.svg' alt='' />
                                        <p>New Notice</p>
                                    </div>
                                    <p className='mt-2'>Fields marked * are mandatory</p>
                                    <div className='mt-2'>
                                        <label className='d-flex justify-content-between align-items-center mb-1'>To*
                                            <ul>
                                                <li>
                                                    {/* <select className='select-all form-control' onChange={(e) => setAudience(e.target.value)} >
                                                        <option hidden>Send To All</option>
                                                        <option value='student'>Send To Students</option>
                                                        <option value='staff'>Send To Staffs</option>
                                                    </select> */}
                                                </li>
                                                <li onClick={() => selectGroup()}>Select Groups</li>
                                            </ul>
                                        </label>
                                        <div className="col-sm-12 form-control email-id-row" onClick={focusInput}>
                                            <div className="all-mail" >
                                                <div style={{ maxWidth: '68%', display: 'flex' }}>

                                                    {mQuery.matches ? (
                                                        emails !== undefined && emails.length > 0 ?
                                                            emails.slice(0, 5).map((email, i) => {
                                                                return (
                                                                    <div style={{ paddingRight: 5 }}>
                                                                        <Chip
                                                                            size="small"
                                                                            // avatar={<Avatar src={email.profile_image} />}
                                                                            label={email.email}
                                                                            onDelete={() => handleDelete(email, i)}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                            : null)
                                                        :
                                                        (emails !== undefined && emails.length > 0 ?
                                                            emails.slice(0, 3).map((email, i) => {
                                                                return (
                                                                    <div style={{ paddingRight: 5 }}>
                                                                        <Chip
                                                                            size="small"
                                                                            // avatar={<Avatar src={email.profile_image} />}
                                                                            label={email.email}
                                                                            onDelete={() => handleDelete(email, i)}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                            : null)
                                                    }
                                                </div>
                                                <input
                                                    placeholder='Type email ids  of students or faculties '
                                                    className='form-control position-relative'
                                                    onChange={(e) => selectUser(e.target.value)}
                                                    autoComplete="off"
                                                    id='email1'
                                                />
                                                {mQuery.matches ?
                                                    (emails !== undefined && emails.length > 5 ? <p className='more' title={emails.slice(5, emails?.length).map(el => el.email)}>{emails?.length - 5}&nbsp; more</p> : null)
                                                    : (emails !== undefined && emails.length > 3 ? <p className='more' title={emails.slice(3, emails?.length).map(el => el.email)}>{emails?.length - 3}&nbsp; more</p> : null)
                                                }
                                            </div>

                                        </div>
                                        <small>{emailError}</small>
                                    </div>
                                    {suggestions !== undefined && suggestions.length > 0 && emailSuggestion !== "" ?
                                        <div className="email-suggestion" >
                                            <ul>
                                                {
                                                    suggestions !== undefined && suggestions.length > 0 ?
                                                        suggestions.map((items, i) => {

                                                            return (

                                                                <li onClick={() => selectedEmail(items)}>
                                                                    <div className="container-fluid">
                                                                        <div className="row">

                                                                            <div className="col-md-12 email-tag" style={{ fontWeight: 'normal' }}>
                                                                                {items.firstname} {items.lastname}<br />
                                                                                {items.email}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }) : null
                                                }
                                            </ul>
                                        </div> : null
                                    }
                                    <div className='mt-3'>
                                        <label>Notice Subject*
                                        </label>
                                        <input
                                            placeholder=''
                                            className='form-control mt-1'
                                            onChange={(e) => changeSubject(e.target.value)}
                                            value={noticeSubject}
                                        />
                                        <small>{titleError}</small>
                                    </div>
                                    <div className='mt-3'>
                                        <label>Notice Description*
                                        </label>
                                        <textarea
                                            placeholder=''
                                            className='form-control mt-1'
                                            onChange={(e) => changeDescription(e.target.value)}
                                            value={description}
                                        />
                                        <small>{descError}</small>
                                    </div>
                                    <div className='mt-3'>
                                        <label>Expiry*</label>
                                        <input type='date' className='form-control mt-1' min={new Date().toISOString().slice(0, 10)} value={expiry} onChange={(e) => changeDate(e)} />
                                        <small>{dateError}</small>
                                    </div>
                                    <div className='mt-3'>
                                        <label>Attachments</label>
                                        <ul>
                                            <li className='position-relative mt-3' onClick={() => addLink('upload')}>
                                                <p>Upload a file</p>
                                            </li>
                                            <li onClick={() => addLink('link')}>Add external link</li>
                                            <li onClick={() => addContent()}>Add My Content</li>
                                            {/* <li onClick={()=>setIsOpenItem(true)}>Add syllabus item</li> */}
                                        </ul>
                                    </div>
                                    <ul className="resource-holder">
                                        {
                                            attachments !== undefined && attachments.map((el, index) => {
                                                var Extension = el?.url
                                                    ?.substring(el?.url.lastIndexOf(".") + 1)
                                                    .toLowerCase()
                                                return (
                                                    <li className="resource border-bottom" >
                                                        <div className="col-md-1 col-1 d-flex justify-content-center align-items-center" >
                                                            <img src={Extension === 'pdf' ? '/images/file.png' : (Extension === 'png' || Extension === 'jpg' || Extension === 'jpeg') ? '/images/png-placeholder.png' :
                                                                '/images/default.png'} alt='' />
                                                        </div>
                                                        <div className="col-md-10 col-10 recent-title">
                                                            <div>
                                                                <h1>{el?.title}</h1>
                                                                <p className='d-flex justify-content-start align-items-center mt-1'>
                                                                    {el?.url}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 col-1 d-flex justify-content-end align-items-center edit-bin" onClick={() => { deleteUrl(el, 'attachment') }}>
                                                            <img src='/images/delete.svg' alt='' />
                                                        </div>
                                                    </li>
                                                )
                                            })

                                        }
                                    </ul>
                                    <ul className="resource-holder">
                                        {
                                            resource !== undefined && resource?.map((el, index) => {
                                                console.log(el)
                                                var type = el.type
                                                return (
                                                    <li className="resource border-bottom" >
                                                        <div className="col-md-1 col-1 d-flex justify-content-center align-items-center" >
                                                            <img src={
                                                                type === 'Video' ? '/images/video-icon.svg' : type === 'Article' ? '/images/article-icon.svg' : type === 'Document' ? '/images/document-icon.svg' :
                                                                    type === 'Question' ? '/images/quiz-icon.svg' :
                                                                        '/images/default.png'} alt='' />
                                                        </div>
                                                        <div className="col-md-10 col-10 recent-title">
                                                            <div>
                                                                {el?.type === 'Question' ?
                                                                    <h1 dangerouslySetInnerHTML={{
                                                                        __html: el.description
                                                                    }} />
                                                                    : <h1>{el.title}</h1>}
                                                                <p className='elipsis d-flex justify-content-start align-items-center mt-1'>
                                                                    {el.url}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 col-1 d-flex justify-content-end align-items-center edit-bin" onClick={() => { deleteUrl(el, 'resource') }}>
                                                            <img src='/images/delete.svg' alt='' />
                                                        </div>
                                                    </li>
                                                )
                                            })

                                        }
                                    </ul>
                                    <hr style={{ marginRight: 80 }} />
                                    <div className='save-btn mt-2'>
                                        {

                                            <button onClick={() => createNotifications()}>
                                                {'Save'}</button>

                                        }
                                        <button onClick={() => {
                                            dispatch(backToMain())
                                            setEmails([]);
                                            setDescription('');
                                            setExpiry('');
                                            setNoticeSubject('');
                                            setAttachments([]);
                                        }}>Cancel</button>
                                    </div>
                                </div>
                        }

                        {/* ######################################### Select group modal ########################################### */}
                        <Modal
                            show={isSelectGroup}
                            size='lg'
                            aria-labelledby="contained-modal-title-vcenter"
                            // centered
                            dialogClassName={"select-modal"}
                        >
                            <Modal.Body style={{ paddingBottom: '20px' }}>
                                <div className='notice-header' >
                                    <h3>New Notice  <p>Select group to send notice</p></h3>
                                </div>
                                <div className='group-button'>
                                    <button className={activeBtn === 'student' ? "active-btn" : null} onClick={() => onBtnChange('student')}>Students</button> &nbsp;
                                    <button className={activeBtn === 'staff' ? "active-btn" : null} onClick={() => onBtnChange('staff')}>Staff</button>
                                </div>
                                <div className='select-buttons'>
                                    {
                                        activeBtn === 'student' ?
                                            <Fragment>
                                                <p>Select class and/or section. Notice will be sent to all the students of this program/section. To select specific students, select students from list</p>
                                                <div className='d-flex'>
                                                    <div className='select-program mt-2'>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Class'
                                                            value={classs}
                                                            onChange={selectClass}
                                                            options={classes}
                                                        />
                                                        <span>{classError}</span>
                                                    </div>
                                                    <div className='select-program mt-2'>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Sections'
                                                            value={section}
                                                            onChange={selectSection}
                                                            options={sectionOfClass}
                                                        />
                                                        <span>{sectionError}</span>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <p>Select role. Notice will be sent to all the staff of this role. To select specific staff, select staff from list</p>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='select-program mt-2' style={{ width: '56%', marginRight: 0 }}>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Role'
                                                            value={role}
                                                            onChange={selectRole}
                                                            options={roles}
                                                        />
                                                        <span>{roleError}</span>
                                                    </div>
                                                    {/* <div className='select-program mt-2'>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Subject'
                                                            value={subject}
                                                            onChange={selectSubject}
                                                            options={props.subjects}
                                                        />
                                                        <span>{state.subjectError}</span>
                                                    </div> */}
                                                </div>
                                            </Fragment>
                                    }

                                </div>
                                <span style={{ fontSize: 14, color: 'red', textAlign: 'center', paddingLeft: 80 }}>{classError}</span>
                                <span style={{ fontSize: 14, color: 'red', textAlign: 'center', paddingLeft: 80 }}>{roleError}</span>
                                {
                                    activeBtn === 'student' ?
                                        (
                                            classs?.value && section?.value ?
                                                <Fragment>
                                                    <h2>Select students from list</h2>
                                                    <div className='select-student'>
                                                        <div className="row-header">
                                                            <p> <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} checked={checked} onChange={(e) => checkAll(e)} /> &nbsp;Select all students</p>
                                                            <div className="student-tab">
                                                                {/* <ul>
                                                                    <li className={activeTab === "all" ? "active-tab" : null} onClick={() => onTabChange("all")}>
                                                                        All Students
                                                                    </li>
                                                                    <li className={activeTab === "selected" ? "active-tab" : null} onClick={() => onTabChange("selected")}>
                                                                        Selected
                                                                    </li>

                                                                </ul> */}
                                                            </div>
                                                        </div>
                                                        <ul className='student-list'>{
                                                            userList !== undefined && userList.map((el, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} onChange={(e) => selectOne(e, el)} value={el._id} checked={el.isChecked} /> &nbsp;{el.firstname} {el.lastname}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        </ul>
                                                    </div>
                                                </Fragment>
                                                : null
                                        )
                                        :
                                        (
                                            role?.value ?
                                                <Fragment>
                                                    <h2>Select staffs from list</h2>
                                                    <div className='select-student'>
                                                        <div className="row-header">
                                                            <p> <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} checked={checked1} onChange={(e) => checkAll(e)} /> &nbsp; Select all staff</p>
                                                        </div>
                                                        <ul className='student-list'>{
                                                            staffList !== undefined && staffList.map((el, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} onChange={(e) => selectOne(e, el)} value={el._id} checked={el.isChecked} /> &nbsp; {el.firstname} {el.lastname}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        </ul>
                                                    </div>
                                                </Fragment>
                                                : null)

                                }
                                <div className='done-btn mt-2'>
                                    <button onClick={closeModal}>Cancel</button> &nbsp;
                                    <button onClick={selectDone}>
                                        Done</button>

                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* ######################################### Add Link modal ########################################### */}
                        <Modal
                            show={isAddLink}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            dialogClassName={"add-topic-modal"}
                        >
                            <div className="modal-header" style={{ background: '#1D8BCB' }}>
                                <h5 className="modal-title">{fileType === 'upload' ? 'Upload a file' : 'Add External Link'}</h5>

                                <div className="sticky-close" style={{ top: 15 }} onClick={handleModal}>
                                    <img src="/images/close-white.svg" width='15px' alt='' /></div>

                            </div>
                            <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>
                                <div>
                                    <label>Title*
                                    </label>
                                    <input className='form-control mt-2 ' value={title} onChange={(e) => changeTitle(e.target.value)} />
                                </div>
                                <small>{titleError1}</small>
                                {
                                    fileType === 'upload' ?
                                        <div className='mt-2'>
                                            <label>File*
                                            </label>
                                            <div className='d-flex flex-column  position-relative mt-3'>
                                                {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload File &nbsp; <small>{imageName}</small>
                                                    <input type='file' className='file-upload' style={{ top: 0 }}
                                                        name="selectedFile" accept="image/png, image/jpeg,.pdf,.doc,.docx" onChange={changeImage} />
                                                </span>
                                            </div>
                                            <small>{fileError}</small>
                                        </div>
                                        :
                                        <div className='mt-2'>
                                            <label>Link*
                                            </label>
                                            <input className='form-control mt-2 ' value={url} onChange={(e) => changeUrl(e.target.value)} />
                                        </div>}
                                <small>{urlError}</small>
                                <div className='save-btn'>
                                    <button className='position-relative' onClick={() => saveLink(fileType)}>
                                        {isloading ? <BallBeat /> : 'Save'}</button>
                                    <button onClick={handleModal}>Cancel</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* ######################################### Select attachment modal ########################################### */}
                        {/* <Modal
                            show={isOpenItem}
                            size='lg'
                            aria-labelledby="contained-modal-title-vcenter"
                            // centered
                            dialogClassName={"select-modal"}
                        >
                            <Modal.Body style={{ paddingBottom: '20px' }}>
                                <div className='notice-header' >
                                    <h3>New Notice Attachment <p>Select item or practice test</p></h3>
                                </div>
                                <div className='group-button'>
                                    <button className={activeItem === 'content' ? "active-btn" : null} onClick={() => onItemChange('content')}>Content</button> &nbsp;
                                    <button className={activeItem === 'test' ? "active-btn" : null} onClick={() => onItemChange('test')}>Practice Test</button>
                                </div>
                                <div className='select-buttons'>
                                    {
                                        activeItem === 'content' ?
                                            <Fragment>
                                                <p>Select Subject and Topic</p>
                                                <div className='d-flex'>
                                                    <div className='select-program mt-2'>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Subjects'
                                                            options={this.props.subjects}
                                                            value={subject1}
                                                            onChange={this.selectSubject1}
                                                        />
                                                    </div>
                                                    <div className='select-program mt-2'>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Topics'
                                                            options={this.props.topics}
                                                            value={topic}
                                                            onChange={this.selectTopic}
                                                        />

                                                    </div>
                                                </div>
                                                {
                                                    subject1 !== '' && topic !== '' ?
                                                        <p className='mt-2'>Select subtopics or expand subtopic to select content item</p>
                                                        : <div className='subject-msg'>
                                                            <p>Subtopic and content items will appear here after you select subject and topic</p>
                                                        </div>
                                                }

                                            </Fragment>
                                            :
                                            <Fragment>

                                                <div className='d-flex justify-content-center'>
                                                    <div className='select-program mt-0  mr-0' style={{ width: '55%' }}>
                                                        <p className='mb-1'>Select Subject</p>
                                                        <Select
                                                            components={{ DropdownIndicator }}
                                                            placeholder='Subjects'
                                                            options={this.props.subjects}
                                                            value={subject2}
                                                            onChange={this.selectSubject2}
                                                        />
                                                    </div>

                                                </div>
                                            </Fragment>
                                    }

                                </div>

                                {
                                    list1 ?
                                        <div className='select-student'>
                                            {
                                                activeItem === 'content' ?
                                                    <Fragment>
                                                        <div className="student-list">
                                                            <div id="accordion" className="accordion">
                                                                {
                                                                    resources !== undefined && resources.map((items, index) => {
                                                                        return (
                                                                            <div key={index} className="card mb-0">
                                                                                <div className="card-header collapsed" data-toggle="collapse" aria-expanded="true" href={`#${index}`}>
                                                                                    <div className="card-title" >
                                                                                        <div style={{ marginLeft: 32 }} className='d-flex justify-content-start align-items-center'>
                                                                                            <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} checked={checked} /> &nbsp;<p style={{ marginLeft: 5 }}>{items[0].topic}</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div id={index} className="card-body collapse" data-parent="#accordion" >
                                                                                    <ul className='content-holder'>
                                                                                        {
                                                                                            items.map((el, i) => {
                                                                                                return (
                                                                                                    <li key={i} className="resource">
                                                                                                        <div className="col-md-12 col-12 recent-title">
                                                                                                            <div className='d-flex align-items-center'>
                                                                                                                <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} checked={checked} />
                                                                                                                <h1 className='d-flex justify-content-start align-items-center  ml-2' >
                                                                                                                    {
                                                                                                                        el.type === "Video" ?
                                                                                                                            <img
                                                                                                                                src="/images/video-icon.svg" alt='' /> : el.type === "Document" ?
                                                                                                                                <img
                                                                                                                                    src="/images/document-icon.svg" alt='' /> : el.type === "Article" ?
                                                                                                                                    <img
                                                                                                                                        src="/images/article-icon.svg" alt='' /> : el.type === "Quiz" ? <img
                                                                                                                                            src="/images/quiz-icon.svg" alt='' /> : null

                                                                                                                    }&nbsp;&nbsp;&nbsp;
                                                                                                                    <EllipsisText text={el.title} length={window.innerWidth > 767 ? "40" : '70'} />

                                                                                                                </h1>

                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </li>
                                                                                                )


                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </div>

                                                                            </div>)
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <ul className='student-list'>
                                                            <li>
                                                                <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} checked={checked} /> &nbsp;Christine Bryant
                                                            </li>
                                                            <li>
                                                                <input type='checkbox' style={{ width: 16, height: 16, verticalAlign: 'middle' }} checked={checked} /> &nbsp;Christine Bryant
                                                            </li>
                                                        </ul>
                                                    </Fragment>

                                            }

                                        </div> : null
                                }
                                <div className='done-btn mt-2'>
                                    <button onClick={closeModal}>Cancel</button> &nbsp;
                                    <button>
                                        Done</button>

                                </div>
                            </Modal.Body>
                        </Modal> */}
                    </div >
                    :
                    <div className='student-notice-holder'>
                        <h3>Notice Board</h3>
                        <div className='d-flex mt-1 ml-1 position-relative' style={{ width: '92%' }}>
                            {studentNoticeList !== undefined && studentNoticeList.length > 0 ? <span>Showing {studentNoticeList.length} notices</span> : null}
                            {studentNoticeList?.length > 2 &&
                                <div className="animated-search" style={{ top: -28 }}>
                                    <input
                                        id="express-form-typeahead"
                                        autoComplete="off"
                                        onBlur={checkFocus}
                                        onFocus={checkFocus}
                                        type="text"
                                        // value={query}
                                        onChange={(e) => getSearchNotice(e.target.value)}
                                    />
                                    <div className="symbol">
                                        <img src="/images/search1.svg" alt="" />
                                    </div>
                                    {isFocused ? (
                                        <img
                                            src="/images/close2.png"
                                            alt=""
                                            className="close-input"
                                            onClick={() => setIsFocused(false)}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>}
                        </div>
                        {
                            isListLoading ?
                                <TopicLoader /> :
                                <InfiniteScroll
                                    dataLength={studentNoticeList !== undefined && studentNoticeList.length} //This is important field to render the next data
                                    next={fetchNotification}
                                    hasMore={true}
                                >
                                    {studentNoticeList !== undefined && studentNoticeList.length > 0 ?
                                        <ul>
                                            {
                                                studentNoticeList.map((el, index) => {
                                                    return (
                                                        <li key={index} onClick={() => {
                                                            dispatch(markedRead(el._id));
                                                            dispatch(getAttachmentResource(el._id))
                                                            setIsOpenNotification(true);
                                                            setDetails(el);
                                                        }
                                                        }>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className='new-notification d-flex align-items-center'>
                                                                    {new Date().toDateString() === new Date(el.createdAt).toDateString() ? <div className='new'>New</div> : null}&nbsp;
                                                                    <p>{moment(new Date(el.createdAt)).format('DD-MMM-YYYY')}</p>
                                                                </div>
                                                                {
                                                                    el.attachments.length > 0 ?
                                                                        <img src='/images/attachment.png' alt='' />
                                                                        : null
                                                                }
                                                            </div>
                                                            <div className='card-body p-0 d-flex flex-column'>
                                                                <h2>{el.subject}</h2>
                                                                <p className='mb-2'>
                                                                    {el.description}
                                                                </p>
                                                            </div>
                                                            <div className="flex-item">
                                                                <small className=' mt-auto'>{DaysBetween(new Date(), new Date(el.expiry_date)) > 0 ? `Expires in ${DaysBetween(new Date(), new Date(el.expiry_date))} days` : 'Expired'} </small>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                        :
                                        <div className="empty-list" style={{ width: '92%' }}>
                                            <img src="/images/empty.png" alt="" />
                                            <p>You have not notices yet.</p>
                                        </div>
                                    }
                                </InfiniteScroll>
                        }
                    </div>
            }
            {/* // open Notifications */}
            <Modal
                show={isOpenNotification}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName={"notification-modal"}
            >
                {details !== undefined &&
                    <Modal.Body style={{ padding: '22px', position: 'relative' }}>

                        <div className="sticky-close" onClick={handleModal}>
                            <img src="/images/close2.png" width='15px' alt='' />
                        </div>
                        <h1>{details?.subject}
                            <p>{moment(new Date(details?.createdAt)).format('DD-MMM-YYYY')} &nbsp; &nbsp; {DaysBetween(new Date(), new Date(details?.expiry_date)) > 0 ? `Notice Expires in ${DaysBetween(new Date(), new Date(details?.expiry_date))} days` : 'Notice Expired'} </p>
                            <p className='mt-1' style={{ fontWeight: 'bold', color: '#9B9AA3' }} >From : {details?.from}</p>
                        </h1>
                        <p className='mt-3'>
                            {details?.description}
                        </p>
                        <ul className="resource-holder">
                            {
                                details?.attachments !== undefined && details?.attachments.map((el, index) => {
                                    var Extension = el?.url
                                        .substring(el?.url.lastIndexOf(".") + 1)
                                        .toLowerCase()

                                    return (
                                        <li key={index} className="resource d-flex pl-0 align-items-center" >
                                            <div className="col-md-1 col-1 d-flex justify-content-center align-items-center " >
                                                <img src={Extension === 'pdf' ? '/images/file.png' : (Extension === 'png' || Extension === 'jpg' || Extension === 'jpeg') ? '/images/png-placeholder.png' :
                                                    '/images/default.png'} alt='' />
                                            </div>
                                            <div className="col-md-12 col-12 recent-title">
                                                <div onClick={() => window.open(el.url, '_blank')}>
                                                    {
                                                        el.type === 'Question' ?
                                                            <h1 dangerouslySetInnerHTML={{
                                                                __html: 'Question'
                                                            }} /> :
                                                            <h1>{el?.title}</h1>
                                                    }

                                                    <p className='elipsis d-flex justify-content-start align-items-center mt-1'>
                                                        {el?.url}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })

                            }
                        </ul>
                        <ul className="resource-holder">
                            {
                                attachmentResource !== undefined && attachmentResource.map((el, index) => {
                                    var type = el.type
                                    return (
                                        <li key={index} className="resource d-flex pl-0 align-items-center" >
                                            <div className="col-md-1 col-1 d-flex justify-content-center align-items-center" >
                                                <img src={
                                                    type === 'Video' ? '/images/video-icon.svg' : type === 'Article' ? '/images/article-icon.svg' : type === 'Document' ? '/images/document-icon.svg' :
                                                        type === 'Question' ? '/images/quiz-icon.svg' :
                                                            '/images/default.png'} alt='' />
                                            </div>
                                            <div className="col-md-10 col-10 recent-title" style={{ cursor: 'default' }}>
                                                <div  >
                                                    {el.type === 'Question' ?
                                                        <h1 dangerouslySetInnerHTML={{
                                                            __html: 'Question'
                                                        }} />
                                                        : <h1>{el.title}</h1>}
                                                    <p className='elipsis d-flex justify-content-start align-items-center mt-1'>
                                                        {el.url}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-1 col-1 d-flex justify-content-end align-items-center edit-bin cursor-pointer" onClick={() => window.open(`/attachment?type=${type}&_id=${el._id}`, '_blank')} >
                                                <img src='/images/view_grey.svg' alt='' width={22} />
                                            </div>
                                        </li>
                                    )
                                })

                            }
                        </ul>
                    </Modal.Body>}
            </Modal>

            {/* // view read and Reciever */}
            <Modal
                show={openView}
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"notification-modal"}
                onHide={handleCloseModal}
            >
                <div className='modal-body1 p-4 position-relative' >
                    <div className='sticky-holder' onClick={handleCloseModal}>
                        <img src='/images/close1.svg' alt='' />
                    </div>
                    <div>
                        {loading ?
                            <TopicLoader /> :
                            <Table responsive style={{ overflowX: 'hidden' }}>
                                <thead>
                                    <th >Name</th>
                                    <th>Role</th>
                                    <th>Viewed</th>
                                    {/* <th>Details</th> */}
                                </thead>
                                <tbody className="table-body"  >

                                    {
                                        toArr !== undefined && toArr.map((el, i) => {
                                            return (
                                                <tr>
                                                    <td className='text-capitalize'>{el.name}</td>
                                                    <td className='text-capitalize'>{el.role}</td>
                                                    <td className=''>{el.isViewed ? <img src='/images/passed.svg' alt='' width='20px' height='20px' /> : 'No'}</td>
                                                    {/* <td className='text-capitalize'>
                                                        <Overlay
                                                            show={open}
                                                            target={target}
                                                            onHide={() => {
                                                                setOpen(false)
                                                                setRole(el.role)
                                                            }}
                                                            placement="bottom-start"
                                                            rootClose={true}
                                                        >
                                                            <Popover id={`popover-containd${i}`} style={{ minWidth: el.role === 'student' ? '29%' : '18%', display: el._id === viewId ? 'block' : 'none', borderRadius: 2, zIndex: 9999, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                {isLoading ?
                                                                    <TopicLoader />
                                                                    :
                                                                    el.role === 'student' ?
                                                                        xtraDetail && <ul className='popover-details' style={{ padding: '10px 20px' }}>
                                                                            <table>
                                                                                <tr>
                                                                                    <td className='text-capitalize' style={{ width: '20%' }}>Name :</td>
                                                                                    <td>{el.firstname} {el.lastname}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ width: '20%' }}>Class :</td>
                                                                                    <td>{xtraDetail.class && xtraDetail.class}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ width: '20%' }}>Section :</td>
                                                                                    <td> {xtraDetail.section && xtraDetail.section.name}</td>
                                                                                </tr>


                                                                            </table>

                                                                        </ul>
                                                                        :
                                                                        xtraDetail && <ul className='popover-details' style={{ padding: '10px 20px' }}>
                                                                            <table>
                                                                                <tr>
                                                                                    <td className='text-capitalize' style={{ width: '25%' }}>Name :</td>
                                                                                    <td>{el.name}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ width: '25%' }}>Subject :</td>
                                                                                    <td> {xtraDetail.subject && xtraDetail.subject.map(el => el.name).join(', ')}</td>
                                                                                </tr>
                                                                              
                                                                            </table>
                                                                        </ul>
                                                                }
                                                            </Popover>
                                                        </Overlay>
                                                        <img src='/images/view_grey.svg' className='cursor-pointer' alt='' onClick={(e) => openDetails(e, el._id)} width='20px' height='20px' />
                                                    </td> */}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>

                        }
                    </div>

                </div>

            </Modal >

            {/* Add Resource Modal from my content */}
            <Modal
                show={isOpenAdd}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-content-modal"}
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Add content from content library
                    </h5>

                    <div className="sticky-close" onClick={() => setIsOpenAdd(false)} >
                        <img src="/images/close2.png" width='15px' alt='' /></div>

                </div>
                <Modal.Body className='content-drawer'  >
                    {
                        isResourceLoading ? (
                            <div>
                                <TopicLoader />
                            </div>
                        ) :
                            <div className="bg-white" style={{
                                height: window.innerWidth > 767 ? 400 : 510,
                                overflow: 'auto',
                                overflowX: 'hidden'
                            }} id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={contents.length} //This is important field to render the next data
                                    next={fetchContent}
                                    hasMore={true}
                                    style={{ overflow: 'unset' }}
                                    scrollableTarget="scrollableDiv"
                                    loader={<div >{isLoading ?
                                        <Fragment><TopicLoader /></Fragment> : null}</div>}
                                >
                                    {
                                        contents !== undefined && contents.length > 0 ?
                                            <ul className='content-list' >
                                                {
                                                    contents !== undefined && contents.map((el, index) => {
                                                        return (
                                                            <li >
                                                                <div className='subject-name'  >
                                                                    <div className="d-flex align-items-center">
                                                                        <article >
                                                                            <img
                                                                                src={el.type === 'Question' ? '/images/quiz.svg' : el.image !== null && el.image ? el.image : '/images/error-image.jpg'}
                                                                                alt=""
                                                                                className="rounded"
                                                                                onError={(e) => {
                                                                                    if (e.target.src !== "/images/empty-image.png") {
                                                                                        e.target.onerror = null;
                                                                                        e.target.src = "/images/empty-image.png";
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                        </article> &nbsp;
                                                                        <div className='ps-3'>
                                                                            <h3 className='d-flex  align-items-center'>
                                                                                {el.type === "Video" ?
                                                                                    <img
                                                                                        src="/images/video-icon.svg" alt='' /> : el.type === "Article" ?
                                                                                        <img
                                                                                            src="/images/article-icon.svg" alt='' /> : el.type === "Quiz" ? <img
                                                                                                src="/images/quiz-icon.svg" alt='' /> : el.type === "Document" ? <img
                                                                                                    src="/images/document-icon.svg" alt='' /> : el.type === "Question" ? <img src='/images/quiz-icon.svg' alt='' /> : null}
                                                                                &nbsp; &nbsp;
                                                                                {el.type}</h3>
                                                                            <p className='mt-1' style={{ lineHeight: "normal" }} dangerouslySetInnerHTML={{ __html: el.title || el.description }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div >
                                                                    {
                                                                        el.add ?
                                                                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                                                            : <img src='/images/plus-circle.svg' alt='' className='cursor-pointer' onClick={() => addResourceToAttachments(el)} />
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            :
                                            <div className="empty-list">
                                                <img src="/images/empty.png" alt="" />
                                                <p>You have not added any content yet.</p>
                                            </div>
                                    }
                                </InfiniteScroll>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </Fragment >
    )
}

export default NoticeBoard;

