import { Fragment } from 'react'
import ContentLoader from 'react-content-loader'
import './loader.scss'


export const BallBeat = (props) => {
    return (
        <div className="ball-loader" >
            <div className="ball-loader-ball ball1" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball2" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball3" style={{ backgroundColor: props.color }}></div>
        </div>
    )

}

export const FeedsLoader = () => (
    <ContentLoader
        speed={1}
        width={window.innerWidth > 767 ? 600 : 300}
        height={window.innerWidth > 767 ? 485 : 200}
        viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle cx="31" cy="31" r="15" />
        <rect x="58" y="18" rx="2" ry="2" width="140" height="5" />
        <rect x="58" y="34" rx="2" ry="2" width="100" height="5" />
        <rect x="0" y="60" rx="2" ry="2" width="300" height="200" />
    </ContentLoader>
)

export const TopicLoader = props => (
    <ContentLoader
        width='100%'
        height='100%'
        viewBox="0 0 1000 320"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
        {...props}
    >
        {
            window.innerWidth > 767 ?
                <Fragment>
                    <rect x="0" y="10" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="60" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="110" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="160" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="210" rx="6" ry="6" width="1000" height="40" />
                </Fragment>
                : <Fragment>
                    <rect x="0" y="10" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="120" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="230" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="350" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="400" rx="6" ry="6" width="1000" height="85" />
                </Fragment>
        }

    </ContentLoader>
)


export const ProgramLoader = () => (
    <ContentLoader
        speed={1}
        width='100%'
        height={300}
        // viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="10" y="10" rx="2" ry="2" width="175" height="150" />
        <rect x="10" y="165" rx="2" ry="2" width="150" height="10" />
        <rect x="195" y="10" rx="2" ry="2" width="175" height="150" />
        <rect x="195" y="165" rx="2" ry="2" width="150" height="10" />
        <rect x="385" y="10" rx="2" ry="2" width="175" height="150" />
        <rect x="385" y="165" rx="2" ry="2" width="150" height="10" />
        <rect x="575" y="10" rx="2" ry="2" width="175" height="150" />
        <rect x="575" y="165" rx="2" ry="2" width="150" height="10" />
    </ContentLoader>
)


export const NotificationLoader = props => {
    return (
        <ContentLoader
            speed={2}
            width={344}
            height={160}
            viewBox="0 0 344 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#dbdbdb"
            {...props}
        >
            <rect x="48" y="13" rx="3" ry="3" width="88" height="4" />
            <rect x="48" y="20" rx="3" ry="3" width="52" height="4" />
            <circle cx="20" cy="20" r="10" />
            <rect x="48" y="37" rx="3" ry="3" width="88" height="4" />
            <rect x="48" y="44" rx="3" ry="3" width="52" height="4" />
            <circle cx="21" cy="45" r="10" />
            <rect x="48" y="62" rx="3" ry="3" width="88" height="4" />
            <rect x="48" y="69" rx="3" ry="3" width="52" height="4" />
            <circle cx="21" cy="70" r="10" />
        </ContentLoader>

    )
}

export const BubbleLoader = () => (
    <ContentLoader
        speed={1}
        width='100%'
        height={200}
        // viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="10" y="10" rx="2" ry="2" width="175" height="168" />
        <rect x="195" y="10" rx="2" ry="2" width="175" height="168" />
        <rect x="385" y="10" rx="2" ry="2" width="175" height="168" />
        <rect x="575" y="10" rx="2" ry="2" width="175" height="168" />
        <rect x="765" y="10" rx="2" ry="2" width="175" height="168" />
    </ContentLoader>
)

export const CardLoader = () => (
    <ContentLoader
        speed={1}
        width='100%'
        height={200}
        // viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="10" y="10" rx="2" ry="2" width="175" height="168" />
        <rect x="195" y="10" rx="2" ry="2" width="175" height="168" />
        <rect x="385" y="10" rx="2" ry="2" width="175" height="168" />
    </ContentLoader>
)

export const BigCardLoader = () => (
    <ContentLoader
        speed={1}
        width='100%'
        height={400}
        // viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="10" y="10" rx="2" ry="2" width="275" height="300" />
        <rect x="305" y="10" rx="2" ry="2" width="275" height="300" />
        <rect x="600" y="10" rx="2" ry="2" width="275" height="300" />
    </ContentLoader>
)
export const SemLoader = (props) => (
    <ContentLoader
        speed={2}
        width={340}
        height={84}
        viewBox="0 0 340 25"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="50" y="0" rx="3" ry="3" width="25" height="25" />
        <rect x="80" y="0" rx="3" ry="3" width="25" height="25" />
        <rect x="110" y="0" rx="3" ry="3" width="25" height="25" />
        <rect x="140" y="0" rx="3" ry="3" width="25" height="25" />
        <rect x="170" y="0" rx="3" ry="3" width="25" height="25" />
        <rect x="200" y="0" rx="3" ry="3" width="25" height="25" />
        <rect x="230" y="0" rx="3" ry="3" width="25" height="25" />
    </ContentLoader>
)
export const CountLoader = props => (
    <ContentLoader
        speed={1}
        width={70}
        height={20}
        viewBox="0 0 70 20"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="15" y="0" width="67" height="20" rx="3" />
        <circle cx="31" cy="31" r="15" />
    </ContentLoader>
)


export const ProfileLeftLoader = () => (
    <ContentLoader
        speed={1}
        width={400}
        height={300}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="120" y="25" rx="4" ry="4" width="250" height="6" />
        <rect x="120" y="45" rx="3" ry="3" width="150" height="6" />
        <rect x="120" y="65" rx="2" ry="2" width="100" height="6" />
        <circle cx="50" cy="50" r="50" width="70" height="70" />
    </ContentLoader>
);

export const DashboardLoader = props => (
    <ContentLoader
        width='100%'
        height='100%'
        viewBox="0 0 1000 310"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
        {...props}
    >
        <Fragment>
            <rect x="0" y="10" rx="4" ry="4" width="975" height="300" />
        </Fragment>
    </ContentLoader>
)

export const SmallBoxLoader = props => (
    <ContentLoader
        width='100%'
        height='100%'
        viewBox="0 0 1000 310"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
        {...props}
    >
        <Fragment>
            <rect x="0" y="10" rx="4" ry="4" width="482" height="300" />
            <rect x="490" y="10" rx="4" ry="4" width="485" height="300" />
        </Fragment>
    </ContentLoader>
)

export const BarChart = props => {
    return (
      <ContentLoader width={200} height={200} viewBox="0 0 200 200" {...props}>
        <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
        <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
        <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
        <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
        <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
      </ContentLoader>
    )
  }