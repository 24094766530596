import React, { useState, useEffect, Fragment } from 'react';
import './mentorConnect.scss';
import Select, { components } from 'react-select';
import EllipsisText from "react-ellipsis-text";
import { TopicLoader } from '../../Loader/loader';
import Table from 'react-bootstrap/Table';
import { resetLoading, getMentorList, getQuestionList, getSubjectFilter, getQDetails, closeDetails, resetFacultySubject, replySent, markasclosed, uploadFile } from '../../Redux/ActionCreators/mentorConnectAction';
import { getStudentCourses } from '../../Redux/ActionCreators/myLearningAction';
import { getAllSubjects } from '../../Redux/ActionCreators/manageContentAction';
import { convertDate, formatAMPM } from '../../utils/miscelleneous';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
const QueryDetail = React.lazy(() => import('./queryDetail'));

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function MentorConnect(props) {
    const [details, setDetails] = useState({});
    const [questionId, setQuestionId] = useState('');
    const [activeBtn, setActiveBtn] = useState('mentor');
    const [conversationSearch, setConversationSearch] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [facultyPage, setFacultyPage] = useState(1);
    const [subjectfilterId, setSubjectfilterId] = useState('')
    const [subjectfilter, setSubjectfilter] = useState('')
    const [subject, setSubject] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { isMentorLoading, mentorList, filterSubjects, conversationList, viewDetails, activeTab, conversation, question, attachment } = useSelector(state => state.mentorConnect)
    const { selectFromSubject } = useSelector(state => state.manageContent);

    useEffect(() => {
        const firstRender = async () => {
            if (localStorage.getItem('role') === 'student') {
                const res = await dispatch(getStudentCourses());
                if (res.payload?.length) {
                    const classroomId = await res.payload.filter(el => !el.default)[0]?._id;
                    await dispatch(getSubjectFilter(classroomId));
                }
                if (activeTab === 'conversation') {
                    setActiveBtn('conversation')
                    dispatch(getQuestionList(subjectfilterId, conversationSearch));
                }
                else if (activeTab === 'mentor') {
                    setActiveBtn('mentor');
                }
            }
            else {
                setActiveBtn('conversation');
                setConversationSearch('');
                dispatch(getQuestionList(subjectfilterId, conversationSearch));
                dispatch(getAllSubjects());
            }

            if (location.search.split('=')[1] === 'conversation') {
                setActiveBtn('conversation');
                setFacultyPage(1);
                setPage(1);
                if (localStorage.getItem('role') === 'student') {
                    // dispatch(getQuestionList(page, conversationSearch, subjectfilterId));
                }
                else {
                    // dispatch(getFacultyQuestions(facultyPage, conversationSearch, subjectfilterId));
                }
            }
        }
        firstRender();
    }, [])


    useEffect(() => {
        dispatch(resetLoading())
        setSubject('');
        setSubjectfilter('');
        if (localStorage.getItem('role') === 'student') {
            if (activeBtn === 'mentor') {
                mentorList !== undefined && !mentorList?.length && dispatch(getMentorList(subject ? subject?.value : subject, search));
            }
            else {
                dispatch(getQuestionList(subjectfilterId, conversationSearch));
            }
        }
        else {
            dispatch(getQuestionList(subjectfilterId, conversationSearch));
            dispatch(getAllSubjects());
        }
    }, [activeBtn])



    const timerRef1 = React.useRef(null);
    const timerRef2 = React.useRef(null);


    const getSearch = (val) => {
        clearTimeout(timerRef1.current);
        setSearch(val);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    };

    const delaySearch = (val) => {
        dispatch(getMentorList(subject ? subject?.value : subject, val));
    }

    const querySearch1 = (val) => {
        clearTimeout(timerRef2.current);
        setConversationSearch(val);
        setPage(1);
        setFacultyPage(1);
        timerRef2.current = setTimeout(() => getquerySearch(val), 800);
    }

    const getquerySearch = (val) => {
        dispatch(getQuestionList(subjectfilterId, val));
    }

    const onBtnChange = (e) => {
        setActiveBtn(e);
        setConversationSearch('');
        setSubjectfilterId('');
    }

    const goToDetails = (el) => {
        setDetails(el);
        setQuestionId(el._id);
        dispatch(getQDetails(el, el._id));
    }

    const goToQuery = (el) => {
        if (el !== null) {
            navigate("/mentor-connect/query",
                {
                    state: {
                        details: el,
                        empty: false
                    }
                }
            )
        }
        else {
            dispatch(resetFacultySubject());
            navigate("/mentor-connect/query",
                {
                    state: {
                        empty: true
                    }
                }
            );
        }
    }

    const selectSubject = (e) => {
        setSubject(e)
        dispatch(getMentorList(e ? e?.value : '', search));
    }

    const selectSubjectFilter = (e) => {
        setSubjectfilter(e);
        setSubjectfilterId(e ? e.value : '');
        dispatch(getQuestionList(e ? e.value : '', search));;
    }

    const prevPage = () => {
        if (localStorage.getItem('role') === 'student') {
            setPage(page - 1);
            props.getQuestionList(page - 1, search, subjectfilterId);
        }
        else {
            setPage(facultyPage - 1)
            props.getFacultyQuestions(facultyPage - 1, conversationSearch, subjectfilterId);
        }

    }

    const nextPage = () => {
        if (localStorage.getItem('role') === 'student') {
            setPage(page + 1);
            props.getQuestionList(page + 1, search, subjectfilterId);
        }
        else {
            setPage(facultyPage + 1)
            props.getFacultyQuestions(facultyPage + 1, conversationSearch, subjectfilterId);
        }

    }


    const reply = async (obj) => {
        await dispatch(replySent(obj));
        await dispatch(getQDetails(details, questionId));
    }

    return (
        <div className='mentor-connect-module'>
            {
                !viewDetails ?
                    <Fragment>
                        <h3>Teacher Connect</h3>
                        <p>{(localStorage.getItem('role') === 'student') ? 'Start a conversation with your Teacher' : 'View queries raised by your students'}</p>
                        < div className='qna-btns'>
                            {
                                (localStorage.getItem('role') === 'student') ?
                                    <Fragment>
                                        <button className={activeBtn === 'mentor' ? "active-btn" : null} onClick={() => onBtnChange('mentor')}>{'Teacher'}</button> &nbsp; &nbsp;
                                    </Fragment>
                                    : null
                            }

                            <button className={activeBtn === 'conversation' ? "active-btn" : null} onClick={() => onBtnChange('conversation')}>Conversations</button>

                            {(localStorage.getItem('role') === 'student') ? activeBtn === 'conversation' && window.innerWidth > 767 ? <button className="new-btn float-right" onClick={() => goToQuery(null)} ><img src='/images/plus-white.svg' alt='' /> &nbsp; New Query</button> :
                                activeBtn === 'conversation' && window.innerWidth < 767 ? <div className='btn-mobile'>
                                    <img src='/images/add-query.svg' onClick={() => goToQuery(null)} alt='' />
                                </div> : null : null}

                        </div>

                        {
                            activeBtn === 'mentor' ?
                                <Fragment>
                                    <div className='qna-search' style={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
                                        <div className="faculty-search"  >
                                            <span className="form-control-feedback">
                                                <img src="/images/search.svg" width="14" alt='' />
                                            </span>
                                            <input placeholder='Search For Teacher' id='search' autoComplete='off' onChange={(e) => getSearch(e.target.value)} value={search} className='form-control' />
                                        </div>
                                        <div className='filters row'>
                                            {/* <div className='col-6 pr-0'>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    placeholder='School'
                                                    value={center}
                                                    // onChange={selectSchool}
                                                    options={schools}
                                                />
                                            </div> */}
                                            <div className='col-8 offset-md-4 pr-0'>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    placeholder='Subject'
                                                    value={subject}
                                                    onChange={selectSubject}
                                                    options={filterSubjects}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={window.innerWidth > 767 ? 'faculty-cards row row-cols-5 mt-2' : 'faculty-cards row ml-0 mt-2'} >
                                        {
                                            mentorList !== undefined && mentorList.map((el, i) => {
                                                return (
                                                    <Fragment>
                                                        <div className={window.innerWidth > 767 ? 'col pr-0 mt-1 mb-2 d-flex align-self-stretch' : 'col-6 pl-0 mt-1 mb-2 d-flex align-self-stretch'}>
                                                            <div class="card card-01" style={{ maxWidth: window.innerWidth > 767 ? 175 : '', minWidth: window.innerWidth > 767 ? 175 : '' }}>
                                                                <div class="profile-box">
                                                                    {
                                                                        el.profile_image !== null ?
                                                                            <img className="card-img-top rounded-circle" src={el.profile_image} alt="Card" />
                                                                            :
                                                                            <div className='intial' style={{ background: el.color }}>{el.initial}</div>
                                                                    }
                                                                </div>
                                                                <div class="card-body text-center">
                                                                    <h4 class="card-title mb-0 elipsis text-capitalize">{el.name}</h4>
                                                                    <p class="card-text elipsis-2" style={{ height: '38px' }} title={el.subjects.map(el => el.title).join(",")}>
                                                                        {el.subjects.map(el => el.name).join(", ")}
                                                                    </p>
                                                                    <span class="social-box">
                                                                        <img src='/images/add-query.svg' className='press cursor-pointer mt-2' onClick={() => goToQuery(el)} alt='' />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <div className='qna-search'>
                                        <div className="faculty-search"  >
                                            <span className="form-control-feedback">
                                                <img src="/images/search.svg" width="14" alt='' />
                                            </span>
                                            <input placeholder={localStorage.getItem('role') === 'student' ? 'Search For Teacher' : 'Search For Student'} id='search' autoComplete='off' onChange={(e) => querySearch1(e.target.value)} value={conversationSearch} className='form-control' />
                                        </div>
                                        <div className='filters row'>

                                            <div className='col-8 offset-md-4'>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    placeholder='Subject'
                                                    value={subjectfilter}
                                                    onChange={selectSubjectFilter}
                                                    options={localStorage.getItem('role') === 'student' ? filterSubjects : selectFromSubject}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {isMentorLoading ?
                                        <div>
                                            <TopicLoader />
                                        </div>
                                        :
                                        <div className="table-admin" >
                                            <Table responsive className={window.innerWidth > 767 ? 'overflow-hidden' : ''}>
                                                <thead>
                                                    <tr className="header-table">
                                                        {window.innerWidth > 767 ? <th></th> : null}
                                                        <th>Query</th>
                                                        <th>{(localStorage.getItem('role') === 'student') ? 'Teacher/Subject' : 'Student/Subject'} </th>
                                                        <th className='text-center'>Status</th>
                                                        <th className='text-center'>Created</th>
                                                        <th className='text-center'>Updated</th>
                                                        <th className='text-center'>Action</th>
                                                    </tr>
                                                    <hr />
                                                </thead>
                                                <tbody className="table-body" >
                                                    {
                                                        (conversationList !== undefined && conversationList?.length > 0 ?
                                                            conversationList.map((el, i) => {
                                                                return (
                                                                    <tr key={i} className='cursor-pointer' onClick={() => goToDetails(el)} >
                                                                        {window.innerWidth > 767 ? <td>{el.new ? <img src='/images/point.svg' style={{ verticalAlign: 'middle' }} alt='' /> : ''}</td> : null}
                                                                        <td style={{ width: '30%' }}>
                                                                            <EllipsisText text={el.question} length={window.innerWidth > 767 ? "120" : '90'} />
                                                                        </td>
                                                                        <td >
                                                                            {
                                                                                (localStorage.getItem('role') === 'student') ?
                                                                                    <Fragment>
                                                                                        <div className='d-flex'>{
                                                                                            el?.faculty?.profile_image !== null ?
                                                                                                <div className='intial'>
                                                                                                    <img className="rounded-circle" src={el?.faculty?.profile_image} alt="" />
                                                                                                </div>
                                                                                                :
                                                                                                <div className='intial' style={{ background: el.color }}>
                                                                                                    {el?.faculty?.initial}
                                                                                                </div>
                                                                                        }&nbsp;&nbsp;<p className='text-capitalize'>{el.faculty?.name}<br /><span>{el.subject?.name}</span></p>
                                                                                        </div>
                                                                                    </Fragment> :
                                                                                    <Fragment>
                                                                                        <div className='d-flex'>{
                                                                                            el?.student?.profile_image !== null ?
                                                                                                <div className='intial'>
                                                                                                    <img className="rounded-circle" src={el?.student?.profile_image} alt="" />
                                                                                                </div>
                                                                                                :
                                                                                                <div className='intial' style={{ background: el.color }}>
                                                                                                    {el?.student?.initial}
                                                                                                </div>
                                                                                        }&nbsp;&nbsp;<p className='text-capitalize'>{el.student?.name}<br /><span>{el.subject?.name}</span></p>
                                                                                        </div>
                                                                                    </Fragment>
                                                                            }
                                                                        </td>
                                                                        <td className='text-center'>{el.closed ? 'Closed' : 'In Progress'}</td>
                                                                        <td className='text-center'>{convertDate(el.createdAt)}<br />{formatAMPM(el.createdAt)}</td>
                                                                        <td className='text-center'>{convertDate(el.updatedAt)}<br />{formatAMPM(el.updatedAt)}</td>
                                                                        <td className='text-center'><img src='/images/arrow-up-grey.svg' alt='' className='arrow-rotate' /></td>
                                                                    </tr>
                                                                )
                                                            })

                                                            :
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <div className='empty-list'>
                                                                        <img src='/images/empty.png' alt='' />
                                                                        <p>There is no query/conversation currently available for you</p>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                    }
                                    {/* {
                                        (localStorage.getItem('role') === 'student') ?

                                            totalPage > 1 && <div className='user-pagination' style={{ marginTop: (questionList.length < 1 ? 315 : 25) }}>
                                                {
                                                    page === 1 ?
                                                        <img src='/images/page_prev_disable.svg' alt='' />
                                                        : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                                }
                                                &nbsp;&nbsp;
                                                <div>Page&nbsp;&nbsp; <input value={page} readOnly />&nbsp;of {totalPage}</div>&nbsp;
                                                {
                                                    page === totalPage ?
                                                        <img src='/images/page_next_disable.svg' alt='' /> :
                                                        <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                                }
                                            </div>
                                            :
                                            totalFacultyPage > 1 && <div className='user-pagination' style={{ marginTop: facultyQuestionList.length < 1 ? 315 : 25 }} >
                                                {
                                                    facultyPage === 1 ?
                                                        <img src='/images/page_prev_disable.svg' alt='' />
                                                        : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                                }
                                                &nbsp;&nbsp;
                                                <div>Page&nbsp;&nbsp; <input value={facultyPage} readOnly />&nbsp;of {totalFacultyPage}</div>&nbsp;
                                                {
                                                    facultyPage === totalFacultyPage ?
                                                        <img src='/images/page_next_disable.svg' alt='' /> :
                                                        <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                                }
                                            </div>
                                    } */}
                                </Fragment>

                        }
                    </Fragment>
                    :
                    <QueryDetail
                        conversation={conversation}
                        question={question}
                        reply={reply}
                        markasclosed={async (id) => {
                            await dispatch(markasclosed(id))
                            await dispatch(getQuestionList('', ''));
                        }}
                        attachment={attachment}
                        closeDetails={() => dispatch(closeDetails())}
                    />
            }
        </div>
    )
}


export default (MentorConnect);