/* eslint-disable eqeqeq */
import React, { Fragment, useState } from 'react';
import './notes.scss';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import ReactPlayer from 'react-player';
import { setNote } from '../../Redux/ActionCreators/notesAction';
import $ from 'jquery';
import PdfReader from '../../utils/pdfReader';
// import ReactAudioPlayer from 'react-audio-player';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';

// ################################# Used notes ############################################3

export default function Notespopup(props) {
    const [target, setTarget] = useState(null);
    const [showAction, setShowAction] = useState(false);
    const [notesValue, setNotesValue] = useState('');
    const needWords = 250
    const [wordCount, setWordCount] = useState('');
    const [limWords, setLimWords] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditNo, setIsEditNo] = useState(true);
    const [fs, setFs] = useState(false);
    const [height, setHeight] = useState();
    const [width, setWidth] = useState();
    const [saveError, setSaveError] = useState('');
    const [showExplanation, setShowExplanation] = useState(false);
    const imgRef = React.createRef(null);

    const dispatch = useDispatch();
    const handleZoomIn = () => {
        // Fetching current height and width
        const height = imgRef.current.clientHeight;
        const width = imgRef.current.clientWidth;
        // Increase dimension(Zooming)
        setWidth(width + 50);
        setHeight(height + 50);
    }


    const handleReset = () => {
        setWidth(imgRef.current.initialHeight);
        setHeight(imgRef.current.initialWidth);
    }

    const openOriginal = async (e) => {
        await setTarget(e.target);
        await setShowAction(!showAction);
    }



    const changeNotes = async (event) => {
        const wordCount = event.target.value !== null && event.target.value === "" ? 0 : event.target.value.match(/\b[-?(\w+)?]+\b/gi).length;
        setSaveError('');
        setNotesValue(event.target.value);
        setWordCount(wordCount);
        setLimWords(needWords - wordCount < 0 ? notesValue?.length : null);
        await dispatch(setNote(event.target.value));
    }

    const goToOriginal = (url) => {
        window.open(url, '_blank');
    }

    const editNote = () => {
        $("#note").prop("disabled", false);
        setIsEdit(true);
        setIsEditNo(true);
    }

    const cancel = () => {
        if (notesValue === '' && props.activeFeedData?.note === null) {
            setIsEdit(true);
            setIsEditNo(false);
        }
        else {
            setIsEdit(false);
            setIsEditNo(true);
        }

    }

    const saveNote1 = (id, type, chapter, subject, note) => {
        if (note === '') {
            setSaveError(`Notes can't empty`)
        }
        else {
            props.saveNote(id, type, chapter, subject, note);
            setIsEdit(false);
            setIsEditNo(true);
        }
    }


    const fullscreen = () => {
        setFs(true);
    }

    var result = needWords - wordCount;
    let tooManyChars;
    if (result < 0) {
        const tooManyCharStyle = {
            color: "red"
        };
        tooManyChars = (
            <p style={tooManyCharStyle}>
                You exceeded the maximum number of words!!
            </p>
        );
    }
    const { activeFeedData, time } = props
    if ((window.location.pathname.split('/')[1] === 'manage-content' || window.location.pathname.split('/')[1] == 'my-content' || window.location.pathname.split('/')[1] == 'my-notes') && activeFeedData !== undefined && activeFeedData?.type === 'Video') {
        var startTime = activeFeedData?.time !== null && activeFeedData?.time?.split('-')[0].split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        var endTime = activeFeedData?.time !== null && activeFeedData?.time?.split('-')[1].split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        console.log(startTime, endTime)
    }
    return (
        <div className="modal-body">
            {activeFeedData !== undefined && <div className={activeFeedData?.type == 'Video' ? 'notes-details padd' : 'notes-details'}>
                {
                    activeFeedData?.type === 'Article' || activeFeedData?.type === 'Document' ?
                        <Fragment>
                            <div className='playlist-header'>
                                <h1 className={time ? 'topic-back mb-4' : 'topic-back'} ><small >{activeFeedData?.title}</small> &nbsp;&nbsp;&nbsp;
                                    {(activeFeedData?.type === 'Document') ?
                                        <div className='text-center' style={{ verticalAlign: 'middle', cursor: 'pointer', position: 'absolute', right: window.innerWidth > 767 ? '16%' : '10%', top: 0 }}>
                                            {time && <p style={{ fontSize: 20, float: 'right' }}>{props.time?.m}:{props.time?.s}</p>}

                                        </div>
                                        :
                                        <div className='text-center' style={{ verticalAlign: 'middle', cursor: 'pointer', position: 'absolute', right: window.innerWidth > 767 ? '16%' : '10%', top: 0 }}>
                                            <img src='/images/horizontal-ham.svg' onClick={(e) => openOriginal(e)} alt='' /><br />
                                            {time && <p style={{ fontSize: 20, float: 'right' }}>{time?.m}:{props.time?.s}</p>}

                                        </div>
                                    }
                                    <Overlay
                                        show={showAction}
                                        target={target}
                                        onHide={() => setShowAction(false)}
                                        placement="bottom-start"
                                        rootClose={true}
                                    >
                                        <Popover id="popover-contained2" style={{ width: '200px', borderRadius: 2, zIndex: 8, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                            <ul className='popover-view'>
                                                <li onClick={() => goToOriginal(activeFeedData.url)}><img src='/images/external-link.svg' alt='' /> &nbsp; View Original {activeFeedData.type}</li>
                                                {/* <li><img src='/images/not-relvent.svg' alt='' width='14px' /> &nbsp; This {activeFeedData.type} is not relevent.</li> */}
                                            </ul>
                                        </Popover>
                                    </Overlay>
                                </h1>
                                {
                                    window.location.pathname.split('/')[1] === 'attachment' ? null :
                                        <div className="sticky-cross-holder"
                                            onClick={() => {
                                                props.closeNotes();
                                                setShowAction(false)
                                            }}>
                                            <img src="/images/cross-mark.svg" alt='' />
                                        </div>
                                }
                                {
                                    (window.location.pathname.split('/')[1] === 'feeds' || window.location.pathname.split('/')[1] === 'manage-content' || window.location.pathname.split('/')[1] === 'reports' || window.location.pathname.split('/')[1] === 'my-content' || window.location.pathname.split('/')[1] === 'skill-pods' || window.location.pathname.split('/')[1] === 'search' || window.location.pathname.split('/')[1] === 'attachment') ? null
                                        : <nav aria-label="breadcrumb" style={{ marginLeft: window.innerWidth < 767 ? 0 : 2 }}>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">Notes &#62; {activeFeedData?.title}</li>
                                            </ol>
                                        </nav>

                                }

                            </div>
                            {activeFeedData?.type === "Document" ?
                                // <iframe title='doc' src='https://view.officeapps.live.com/op/embed.aspx?src=http://www.prepgeek.com/gate.pptx' width='70%' height='100%' frameborder='0'></iframe>
                                <div className='content-middle'>
                                    <PdfReader url={activeFeedData?.url} />
                                </div>
                                :
                                <div className='content-middle'>
                                    {activeFeedData?.source_image ?
                                        (fs
                                            ?
                                            <div className='pdf-fullscreen'>
                                                <div onClick={() => setFs(false)} className='close-quiz'>
                                                    <img src='/images/close1.svg' alt='' />
                                                </div>
                                                <div className='fullscreen-container' style={{ left: '22%', right: '15%' }}>
                                                    <div className='tools'>
                                                        <button onClick={handleZoomIn}><img src='/images/zoom-out.png' alt='' /></button>
                                                        <button onClick={handleReset}><img src='/images/zoom-in.png' alt='' /></button>

                                                    </div>
                                                    <div className='mt-2'>
                                                        <img ref={imgRef} src={activeFeedData?.source_image} onDoubleClick={handleZoomIn} alt='gfg' width='100%' height='auto' />
                                                    </div>
                                                </div>
                                            </div>
                                            : <div>
                                                <div className='tools'>
                                                    <button onClick={handleZoomIn}><img src='/images/zoom-out.png' alt='' /></button>
                                                    <button onClick={handleReset}><img src='/images/zoom-in.png' alt='' /></button>
                                                    {window.innerWidth > 767 ? <button id="fullscreenButton" onClick={fullscreen}><img src='/images/fullscreen.png' alt='' /></button> : null}
                                                </div>
                                                <div className='mt-2'>
                                                    <img ref={imgRef} src={activeFeedData.source_image} onDoubleClick={handleZoomIn} alt='gfg' width='100%' height='auto' />
                                                </div>
                                            </div>)
                                        : activeFeedData?.description}
                                </div>
                            }
                            {(window.location.pathname.split('/')[1] !== "manage-content" && window.location.pathname.split('/')[1] !== 'reports' && window.location.pathname.split('/')[1] !== "my-content") && window.location.pathname.split('/')[1] !== 'skill-pods' &&
                                window.location.pathname.split('/')[1] !== "attachment" && <ul className='like-btn' >
                                    <li className={activeFeedData.liked ? "liked-active" : null}
                                        onClick={() => props.likeClick(activeFeedData.id || activeFeedData._id, activeFeedData.type, activeFeedData.liked)}>
                                        {
                                            activeFeedData.liked ?
                                                <img src="/images/enable-like.svg" alt='' width='15px' /> :
                                                <img src="/images/disable-like.svg" alt='' width='15px' />
                                        }&nbsp;
                                        {
                                            activeFeedData.liked ? "Liked" : "Like"
                                        }
                                    </li>
                                    {/* <li>
                                        <img src='/images/bookmark-disable.svg' width='10px' alt='' /> Save
                                    </li> */}
                                    <li onClick={() => props.shareFeed(activeFeedData)}>
                                        <img src='/images/share1.svg' alt='' width='15px' /> Share
                                    </li>
                                </ul>}
                            {(window.location.pathname.split('/')[1] !== 'reports' && window.location.pathname.split('/')[1] !== 'manage-content' && window.location.pathname.split('/')[1] !== 'my-content' && window.location.pathname.split('/')[1] !== 'reports') && window.location.pathname.split('/')[1] !== 'search' && window.location.pathname.split('/')[1] !== 'skill-pods' &&
                                !window.location.href.includes('_id') &&
                                <div className='save-notes mt-4' style={{ width: window.innerWidth > 767 ? '85%' : '97.5%' }}>
                                    {
                                        activeFeedData?.text !== null
                                            ? <Fragment>
                                                {activeFeedData?.text !== null ?
                                                    <div>
                                                        <label >Your notes</label>
                                                        {isEdit ?
                                                            <span className='float-right p-1'>{result - (activeFeedData?.text?.length || 0)}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    :
                                                    ''}
                                                {
                                                    isEdit ?
                                                        <textarea
                                                            rows="3" cols="200"
                                                            className={isEdit ? 'form-control mt-2' : 'form-control mt-2 input-edit'}
                                                            value={activeFeedData?.text}
                                                            onChange={(e) => changeNotes(e)}
                                                            maxLength={limWords}
                                                            placeholder='Enter your notes here'
                                                            id='note'

                                                        />
                                                        :
                                                        <div>
                                                            {activeFeedData?.text}
                                                        </div>
                                                }
                                                <span style={{ color: 'red' }}>{saveError}</span>
                                                <div className={isEdit ? 'removeWidth' : 'addWidth'}>

                                                    {
                                                        !isEdit ?
                                                            <p onClick={() => editNote()} className="float-left p-1 cursor-pointer">Edit</p>
                                                            :
                                                            <div className='mt-2'>&nbsp;<button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#F5821F' }} onClick={() => saveNote1(activeFeedData?._id, activeFeedData?.type, activeFeedData?.chapter, activeFeedData?.subject_id, notesValue)}>Save</button>
                                                                <button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#F5821F' }} onClick={() => cancel()}>Cancel</button>
                                                            </div>

                                                    }

                                                    {tooManyChars}
                                                </div>

                                            </Fragment> :
                                            <Fragment>
                                                {
                                                    isEditNo ?
                                                        <textarea
                                                            rows="3" cols="200"
                                                            className={isEditNo ? 'form-control' : 'form-control input-edit'}
                                                            value={activeFeedData?.text}
                                                            onChange={(e) => changeNotes(e)}
                                                            maxLength={limWords}
                                                            placeholder='Enter your notes here'
                                                            id='note'
                                                        />
                                                        :
                                                        <div >
                                                            {activeFeedData?.text}
                                                        </div>
                                                }

                                                {<span style={{ color: 'red' }}>{saveError}</span>}
                                                <div className={isEditNo ? 'removeWidth' : 'addWidth'}>
                                                    {!isEditNo ? <p onClick={() => editNote()} className="float-left p-1 cursor-pointer">  Edit</p> :
                                                        <button style={{ background: 'white', border: 'none', color: '#F5821F', fontWeight: 700 }} onClick={() => saveNote1(activeFeedData?._id, activeFeedData?.type, activeFeedData?.chapter, activeFeedData?.subject_id, notesValue)}>Save</button>
                                                    }
                                                    {isEdit ?
                                                        <span className='float-right p-1'>{result}</span>
                                                        : null
                                                    }
                                                    {tooManyChars}
                                                </div>
                                            </Fragment>
                                    }

                                </div>}
                        </Fragment>
                        :
                        activeFeedData !== undefined && (localStorage.getItem('role').includes('admin') || localStorage.getItem('role').includes('master') || localStorage.getItem('role') === 'faculty' || localStorage.getItem('role') === 'sme'
                            || localStorage.getItem('role') === 'program manager')
                            && activeFeedData?.type1 === 'Question' ?
                            <Fragment>
                                <div className='playlist-header'>
                                    {
                                        window.location.pathname.split('/')[1] === 'attachment' ? null :
                                            <div className="sticky-cross-holder"
                                                onClick={() => {
                                                    props.closeNotes();
                                                    setShowAction(false);
                                                }}>
                                                <img src="/images/cross-mark.svg" alt='' />
                                            </div>
                                    }
                                </div>
                                <div className='quiz-holder text-center'>
                                    <small className='ques-title' dangerouslySetInnerHTML={{ __html: activeFeedData.description }} />
                                    {
                                        activeFeedData?.type === 'essay' ?
                                            <ul className='options col-md-6 col-7 '>
                                                <textarea className='form-control'
                                                    value={activeFeedData?.answer}
                                                    rows='4'
                                                    // cols='4'
                                                    disabled
                                                />
                                                <div className='text-left mt-3 ' style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                    {activeFeedData?.description !== null && <article className='d-flex'>Hints: &nbsp;{activeFeedData.hints}</article>}
                                                </div>
                                            </ul>
                                            :
                                            <ul className='options'>
                                                {
                                                    activeFeedData?.choices !== undefined && activeFeedData?.choices.map((ans, index) => {
                                                        return (
                                                            <li className='row' key={index} style={{ pointerEvents: 'none' }} >
                                                                <div className='option1 col-md-7 col-7' dangerouslySetInnerHTML={{ __html: ans?.title }}
                                                                />
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    localStorage.getItem('role') === 'student' ? null :
                                                        <div className='text-left mt-3 w-30' style={{ fontWeight: 'bold', fontSize: '15px', paddingLeft: '22%' }}>
                                                            <article className='d-flex'>Correct Answer : &nbsp;<div dangerouslySetInnerHTML={{ __html: activeFeedData.answer !== undefined && activeFeedData.answer.title }} /></article>
                                                            {activeFeedData.description !== null && <p>Explanation : <div dangerouslySetInnerHTML={{ __html: activeFeedData?.description }} /></p>}
                                                        </div>
                                                }
                                            </ul>
                                    }
                                </div>

                                {(window.location.pathname.split('/')[1] !== "manage-content" && window.location.pathname.split('/')[1] !== "my-content" && window.location.pathname.split('/')[1] !== 'reports' && window.location.pathname.split('/')[1] !== "feeds" && window.location.pathname.split('/')[1] !== "skill-pods" && window.location.pathname.split('/')[1] !== "reports" && window.location.pathname.split('/')[1] !== "tot-reports") &&
                                    window.location.pathname.split('/')[1] !== "attachment" &&
                                    <ul className='like-btn' >
                                        <li className={activeFeedData?.liked ? "liked-active" : null}
                                            onClick={() => props.likeClick(activeFeedData?.id || activeFeedData?._id, activeFeedData?.type, activeFeedData?.liked)}>
                                            {
                                                activeFeedData?.liked ?
                                                    <img src="/images/enable-like.svg" alt='' width='15px' /> :
                                                    <img src="/images/disable-like.svg" alt='' width='15px' />
                                            }&nbsp;
                                            {
                                                activeFeedData?.liked ? "Liked" : "Like"
                                            }
                                        </li>
                                        {/* <li>
                                        <img src='/images/bookmark-disable.svg' width='10px' alt='' /> Save
                                    </li> */}
                                        <li onClick={() => props.shareFeed(activeFeedData)}>
                                            <img src='/images/share1.svg' alt='' width='15px' /> Share
                                        </li>
                                    </ul>}

                            </Fragment>
                            :
                            activeFeedData !== undefined && localStorage.getItem('role') === 'student' && activeFeedData?.type1 === 'Question' ?
                                <Fragment>
                                    <div className='playlist-header'>
                                        {
                                            window.location.pathname.split('/')[1] === 'attachment' ? null :
                                                <div className="sticky-cross-holder"
                                                    onClick={() => {
                                                        props.closeNotes();
                                                        setShowAction(false);
                                                    }}>
                                                    <img src="/images/cross-mark.svg" alt='' />
                                                </div>
                                        }
                                    </div>
                                    <div className='quiz-holder text-center'>
                                        <small className='ques-title' dangerouslySetInnerHTML={{ __html: activeFeedData.title }} />
                                        {
                                            activeFeedData?.type === 'Essay' ?
                                                <ul className='options col-md-6 col-7 '>
                                                    <textarea className='form-control'
                                                        value={activeFeedData?.answer}
                                                        rows='4'
                                                        // cols='4'
                                                        disabled
                                                    />
                                                    <div className='text-left mt-3 ' style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                        {activeFeedData?.description !== null && <article className='d-flex'>Hints: &nbsp;{activeFeedData.hints}</article>}
                                                    </div>
                                                </ul>
                                                :
                                                <ul className='options'>
                                                    {
                                                        activeFeedData?.choices !== undefined && activeFeedData.choices.map((ans, index) => {
                                                            return (
                                                                <li className='row' key={index} style={{ width: '100%' }} >
                                                                    <div className='col-md-1 col-1'>
                                                                        {activeFeedData.correct_id === ans._id && activeFeedData.correct ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : activeFeedData.correct === false && activeFeedData.answered === ans._id ? <img src='/images/incorrect.svg' style={{ verticalAlign: 'middle' }} alt='' /> : activeFeedData.correct_id === ans._id ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : null}
                                                                    </div>
                                                                    <div className={activeFeedData.correct_id === ans._id && activeFeedData.correct ? 'correct option col-md-7 col-7' : activeFeedData.correct === false && activeFeedData.answered === ans._id ? 'incorrect option col-md-7 col-7' : activeFeedData.correct_id === ans._id ? 'correct option col-md-7 col-7' : 'option1 col-md-7 col-7'}
                                                                        // onClick={(e) => props.selectAnswer(activeFeedData._id, ans._id)}
                                                                        dangerouslySetInnerHTML={{ __html: ans.title }}
                                                                    />


                                                                    <div className='col-md-2 col-2' >
                                                                        {activeFeedData?.description !== null ? window.innerWidth > 767 && activeFeedData.correct_id === ans._id ?
                                                                            showExplanation ?
                                                                                <div className='explanation-popup' >
                                                                                    <h1>Explanation</h1>
                                                                                    <div className='close-explaination' onClick={() => setShowExplanation(false)}><img src='/images/close1.svg' alt='' width='10px' /></div>
                                                                                    <div style={{ textAlign: 'left', marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: activeFeedData?.description }} />
                                                                                </div> :
                                                                                <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => {
                                                                                    setShowExplanation(true)
                                                                                    setTarget(e.target)
                                                                                }} />
                                                                            : activeFeedData.correct_id === ans._id && <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => setShowExplanation(true)} />
                                                                            : null
                                                                        }
                                                                        {
                                                                            window.innerWidth < 767 && ['bottom'].map((anchor) => (
                                                                                <React.Fragment key={anchor}>
                                                                                    <Drawer
                                                                                        anchor={anchor}
                                                                                        open={showExplanation}
                                                                                        className='mobile-drawer'
                                                                                    >

                                                                                        {
                                                                                            showExplanation ?
                                                                                                <div style={{ padding: 16 }} >
                                                                                                    <h1>Explanation</h1>
                                                                                                    <div className='close-explaination' onClick={() => setShowExplanation(false)}><img src='/images/close1.svg' alt='' width='12px' /></div>
                                                                                                    <small>{activeFeedData?.description}</small>
                                                                                                </div>
                                                                                                : null
                                                                                        }

                                                                                    </Drawer>
                                                                                </React.Fragment>))
                                                                        }

                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                        }
                                    </div>
                                    {(window.location.pathname.split('/')[1] !== "manage-content" && window.location.pathname.split('/')[1] !== "my-content"
                                        && window.location.pathname.split('/')[1] !== "feeds" && window.location.pathname.split('/')[1] !== "skill-pods" && window.location.pathname.split('/')[1] !== 'reports' &&
                                        window.location.pathname.split('/')[1] !== "attachment")
                                        && <ul className='like-btn' >
                                            <li className={activeFeedData?.liked ? "liked-active" : null}
                                                onClick={() => props.likeClick(activeFeedData?.id || activeFeedData?._id, activeFeedData?.type, activeFeedData?.liked)}>
                                                {
                                                    activeFeedData?.liked ?
                                                        <img src="/images/enable-like.svg" alt='' width='15px' /> :
                                                        <img src="/images/disable-like.svg" alt='' width='15px' />
                                                }&nbsp;
                                                {
                                                    activeFeedData?.liked ? "Liked" : "Like"
                                                }
                                            </li>
                                            {/* <li>
                                        <img src='/images/bookmark-disable.svg' width='10px' alt='' /> Save
                                    </li> */}
                                            <li onClick={() => props.shareFeed(activeFeedData)}>
                                                <img src='/images/share1.svg' alt='' width='15px' /> Share
                                            </li>
                                        </ul>}

                                </Fragment>

                                : <div className={window.innerWidth > 767 ? "row m-0 videodiv" : "row videodiv"}>
                                    <div className="content-details w-100">
                                        {
                                            window.location.pathname.split('/')[1] === 'attachment' ? null :
                                                <div className="sticky-cross-holder"
                                                    onClick={() => {
                                                        props.closeNotes();
                                                        setShowAction(false)
                                                    }}>
                                                    <img src="/images/cross-mark.svg" alt='' />
                                                </div>
                                        }
                                        <div className="content-middle1">
                                            {/* <h2>{activeFeedData !== undefined && activeFeedData.title}</h2> */}
                                            <div className='playlist-header'>
                                                <h1 className='topic-back' ><small >{activeFeedData?.title}</small> &nbsp;
                                                    <>
                                                        <img src='/images/horizontal-ham.svg' style={{ cursor: 'pointer', position: 'absolute', top: 10, right: window.innerWidth > 767 ? -15 : 30 }} onClick={(e) => openOriginal(e)} alt='' /><br />
                                                        {/* {time && window.innerWidth > 767 &&
                                                                <p style={{ fontSize: 20, float: 'right', marginRight: -25 }}>{time?.m}:{time?.s}</p>
                                                            } */}
                                                    </>
                                                    <Overlay
                                                        show={showAction}
                                                        target={target}

                                                        onHide={() => this.setState({ showAction: false })}
                                                        placement="bottom-start"
                                                        rootClose={true}
                                                    >
                                                        <Popover id="popover-contained2" style={{ width: '200px', borderRadius: 2, zIndex: 8, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                            <ul className='popover-view'>
                                                                <li onClick={() => goToOriginal(activeFeedData?.url)}><img src='/images/external-link.svg' alt='' /> &nbsp; View Original {activeFeedData?.type}</li>
                                                                {/* <li><img src='/images/not-relvent.svg' alt='' width='14px' /> &nbsp; This {activeFeedData.type} is not relevent.</li> */}
                                                            </ul>
                                                        </Popover>
                                                    </Overlay>
                                                </h1>


                                                {(window.location.pathname.split('/')[1] === 'feeds' || window.location.pathname.split('/')[1] === 'manage-content' || window.location.pathname.split('/')[1] == 'my-content' || window.location.pathname.split('/')[1] === 'skill-pods' || window.location.pathname.split('/')[1] === 'reports' || window.location.pathname.split('/')[1] === 'search' || window.location.pathname.split('/')[1] === 'attachment') ? null :
                                                    <nav aria-label="breadcrumb" style={{ marginLeft: window.innerWidth < 767 ? 0 : 2 }}>
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item " >Notes &#62; {activeFeedData?.title}</li>

                                                        </ol>
                                                    </nav>
                                                }

                                            </div>

                                            <p className={activeFeedData?.type == "Podcast" ? '' : 'elipsis'} dangerouslySetInnerHTML={{
                                                __html: activeFeedData !== undefined && activeFeedData?.description
                                            }} />
                                            {time && window.innerWidth < 767 &&
                                                <p style={{ fontSize: 20, float: 'right', marginRight: window.innerWidth > 767 ? -25 : 0, marginBottom: 10 }}>{time?.m}:{time?.s}</p>
                                            }
                                            <div className="leftimg py-3">

                                                <span className="imgdiv">
                                                    {
                                                        activeFeedData?.type == "Podcast" && activeFeedData?.audio !== null && activeFeedData?.audio !== undefined ?

                                                            <div className="d-flex justify-content-center">
                                                                {/* <ReactAudioPlayer
                                                                        //   src='"http://feeds.dabcc.com/~r/dabcc-radio/~5/4bTJ2oaaSgs/e293_datacore.mp3"'
                                                                        src={activeFeedData.audio !== null && activeFeedData.audio}
                                                                        autoPlay
                                                                        controls
                                                                        onError={() => this.onError(activeFeedData?.type, activeFeedData?.id)}
                                                                    /> */}


                                                            </div>
                                                            :
                                                            activeFeedData?.type === "Video" ?
                                                                (activeFeedData.url.includes('vimeo') || activeFeedData?.source === "Vimeo" || activeFeedData.source === "vimeo.com") ?
                                                                    <div className="embed-responsive embed-responsive-4by3">
                                                                        <iframe class="embed-responsive-item" src={`https://player.vimeo.com/video/${activeFeedData.video_id ? activeFeedData.video_id.split('/')[2] : activeFeedData.url.split('/')[3]}?autoplay=1&muted=0&autopause=0#t=${startTime}`} width="470" height="360" frameborder="0" allowfullscreen allow="autoplay; fullscreen" title={`${activeFeedData.title}`}></iframe>
                                                                    </div>
                                                                    : (activeFeedData.url.includes('youtube') || activeFeedData.source === "Youtube" || activeFeedData.source === "YouTube" || activeFeedData.source === 'NTTF' || activeFeedData.source === "youtube.com" || activeFeedData.source === "youtu.be") ?
                                                                        window.location.pathname === '/feeds' ?
                                                                            <ReactPlayer url={(activeFeedData.source === 'NTTF' || activeFeedData.source === localStorage.getItem('company')) ? `${activeFeedData.url}#t=${startTime},${endTime}` : activeFeedData.url}
                                                                                config={{
                                                                                    youtube: {
                                                                                        playerVars: { start: startTime, end: endTime, disablekb: 1, rel: 0 },
                                                                                        embedOptions: {
                                                                                            host: 'https://www.youtube-nocookie.com',
                                                                                            // events: {
                                                                                            //     'onStateChange': this.onStateChange
                                                                                            // }
                                                                                        }

                                                                                    }
                                                                                }}
                                                                                controls playing={true} autoPlay width='100%' height={320}
                                                                            // onPlay={() => props.onPlayChange()}
                                                                            // onPause={() => props.onPause()}
                                                                            // onEnded={() => this.onEnded()}
                                                                            />
                                                                            :
                                                                            <ReactPlayer url={(activeFeedData?.source === 'NTTF' || activeFeedData.source === localStorage.getItem('company')) ? `${activeFeedData.url}#t=${startTime},${endTime}` : activeFeedData?.url}
                                                                                config={{
                                                                                    youtube: {
                                                                                        playerVars: { start: startTime, end: endTime, disablekb: 1, rel: 0 },
                                                                                        embedOptions: {
                                                                                            host: activeFeedData?.url.includes('youtube') ? 'https://www.youtube-nocookie.com' : '',
                                                                                            // events: {
                                                                                            //     'onStateChange': this.onStateChange
                                                                                            // }
                                                                                        }

                                                                                    }
                                                                                }}
                                                                                controls playing={true} autoPlay width='100%' height={320} />

                                                                        :
                                                                        (activeFeedData?.source !== "Vimeo" || activeFeedData.source !== "vimeo.com" || activeFeedData?.source !== "Youtube" || activeFeedData.source !== "youtube.com" || activeFeedData.source !== "YouTube" || activeFeedData.source !== "youtu.be") ?
                                                                            <ReactPlayer url={`${activeFeedData?.url}#t=${startTime},${endTime}`}
                                                                                config={{
                                                                                    youtube: {
                                                                                        playerVars: { start: startTime, end: endTime, disablekb: 1, rel: 0 },
                                                                                        embedOptions: {
                                                                                            host: activeFeedData?.url.includes('youtube') ? 'https://www.youtube-nocookie.com' : '',
                                                                                            // events: {
                                                                                            //     'onStateChange': this.onStateChange
                                                                                            // }
                                                                                        }

                                                                                    }
                                                                                }}
                                                                                controls playing={true} autoPlay width='100%' height={320}
                                                                            />
                                                                            : null :
                                                                <img
                                                                    src={activeFeedData !== undefined && activeFeedData?.image ? activeFeedData?.source === "tunein" ? "/images/error-image.jpg" : activeFeedData?.image : "/images/error-image.jpg"} alt=''
                                                                    width='550px' height='320px'
                                                                    onError={(e) => {
                                                                        if (e.target.src !== "/images/error-image.jpg") {
                                                                            e.target.onerror = "/images/error-image.jpg";
                                                                            e.target.src = "/images/error-image.jpg";
                                                                        }
                                                                    }} />

                                                    }
                                                </span>

                                                {window.location.pathname.split('/')[1] !== 'manage-content' && window.location.pathname.split('/')[1] !== 'my-content' && window.location.pathname.split('/')[1] !== 'skill-pods' && window.location.pathname.split('/')[1] !== 'reports' &&
                                                    window.location.pathname.split('/')[1] !== "attachment" &&
                                                    <ul className='like-btn justify-content-center' >
                                                        <li className={activeFeedData?.liked ? "liked-active" : null}
                                                            onClick={() => props.likeClick(activeFeedData?.id || activeFeedData?._id, activeFeedData?.type, activeFeedData?.liked)}>
                                                            {
                                                                activeFeedData?.liked ?
                                                                    <img src="/images/enable-like.svg" alt='' width='15px' /> :
                                                                    <img src="/images/disable-like.svg" alt='' width='15px' />
                                                            }&nbsp;
                                                            {
                                                                activeFeedData?.liked ? "Liked" : "Like"
                                                            }
                                                        </li>
                                                        {/* <li>
                                                    <img src='/images/bookmark-disable.svg' width='10px' alt='' /> Save
                                                </li> */}
                                                        <li onClick={() => props.shareFeed(activeFeedData)}>
                                                            <img src='/images/share1.svg' alt='' width='15px' /> Share
                                                        </li>
                                                    </ul>}
                                                {
                                                    window.location.pathname.split('/')[1] !== 'reports' && window.location.pathname.split('/')[1] !== 'manage-content' && window.location.pathname.split('/')[1] !== 'my-content' && window.location.pathname.split('/')[1] !== 'search' && window.location.pathname.split('/')[1] !== 'skill-pods' &&
                                                    !window.location.href.includes('_id') &&
                                                    <div className='save-notes mt-4 w-100' style={{ width: window.innerWidth > 767 ? '64%' : '97.5%' }}>
                                                        {
                                                            activeFeedData?.text !== null
                                                                ? <Fragment>
                                                                    {activeFeedData?.text !== null ?
                                                                        <div>
                                                                            <label >Your notes</label>
                                                                            {isEdit ?
                                                                                <span className='float-right p-1'>{result - (activeFeedData?.text?.length || 0)}</span>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }
                                                                    {
                                                                        isEdit ?
                                                                            <textarea
                                                                                rows="3" cols="200"
                                                                                className={isEdit ? 'form-control mt-2' : 'form-control mt-2 input-edit'}
                                                                                value={activeFeedData?.text}
                                                                                onChange={(e) => changeNotes(e)}
                                                                                maxLength={limWords}
                                                                                placeholder='Enter your notes here'
                                                                                id='note'
                                                                            /> :
                                                                            <div>
                                                                                {activeFeedData?.text}
                                                                            </div>
                                                                    }

                                                                    {<span style={{ color: 'red' }}>{saveError}</span>}
                                                                    <div className={isEdit ? 'removeWidth' : 'addWidth'}>

                                                                        {
                                                                            !isEdit ? <p onClick={() => editNote()} className="float-left p-1 cursor-pointer">Edit</p> :
                                                                                <div className='mt-2'><button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#F5821F' }} onClick={() => saveNote1(activeFeedData?._id, activeFeedData?.type, activeFeedData?.chapter, activeFeedData?.subject_id, notesValue)}>Save</button>
                                                                                    &nbsp;<button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#F5821F' }} onClick={() => cancel()}>Cancel</button>
                                                                                </div>

                                                                        }

                                                                        {tooManyChars}
                                                                    </div>

                                                                </Fragment> :
                                                                <Fragment>
                                                                    {
                                                                        isEditNo ?
                                                                            <textarea
                                                                                rows="3" cols="200"
                                                                                className={isEditNo ? 'form-control' : 'form-control input-edit'}
                                                                                value={activeFeedData?.text}
                                                                                onChange={(e) => changeNotes(e)}
                                                                                maxLength={limWords}
                                                                                placeholder='Enter your notes here'
                                                                                id='note'
                                                                            />
                                                                            :
                                                                            <div>
                                                                                {activeFeedData?.text}
                                                                            </div>
                                                                    }

                                                                    {<span style={{ color: 'red' }}>{saveError}</span>}
                                                                    <div className={isEditNo ? 'removeWidth' : 'addWidth'}>
                                                                        {!isEditNo ? <p onClick={() => editNote()} className="float-left p-1 cursor-pointer">  Edit</p> :
                                                                            <button style={{ background: 'white', border: 'none', color: '#F5821F', fontWeight: 700 }} onClick={() => saveNote1(activeFeedData?._id, activeFeedData?.type, activeFeedData?.chapter, activeFeedData?.subject_id, notesValue)}>Save</button>
                                                                        }
                                                                        {isEdit ?
                                                                            <span className='float-right p-1'>{result}</span>
                                                                            : null
                                                                        }
                                                                        {tooManyChars}
                                                                    </div>
                                                                </Fragment>
                                                        }

                                                    </div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                }
            </div>
            }
        </div >
    )
}






