/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */

import React, { useEffect, useState, Fragment } from 'react';
import './users.scss';
import Select, { components } from 'react-select';
import moment from 'moment';
import { checkUser, savedStudent, savedStaff, editedStaff } from '../../Redux/ActionCreators/userAction';
import { getClasses } from '../../Redux/ActionCreators/accountAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCourseList, getAllSubjects, getAffliations } from '../../Redux/ActionCreators/manageContentAction';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function AddUsers() {
    const [initialKey, setIntialKey] = useState(0);
    const max_length = 75
    const [chars_left4, setCharLeft4] = useState(75);
    const [chars_left5, setCharLeft5] = useState(75);
    const [chars_left6, setCharLeft6] = useState(75);
    const [chars_left7, setCharLeft7] = useState(75);
    const [checkDisabled, setCheckDisabled] = useState(true);
    const [classs, setClass] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [paid, setPaid] = useState('');
    const [tId, setTid] = useState('');
    const [id, setId] = useState('');
    const [payStatus, setPayStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [staff_first_name, setStaffFirstName] = useState('');
    const [staff_last_name, setStaffLastName] = useState('');
    const [staff_Id, setStaffId] = useState('');
    const [staff_email, setStaffEmail] = useState('');
    const [role, setRole] = useState('');
    const [roleValue, setRoleValue] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [affiliation, setAffiliation] = useState('');
    const [sections, setSections] = useState([]);
    const [section, setSection] = useState('');
    const [emailError, setEmailError] = useState('')
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [staffIdError, setStaffIdError] = useState('');
    const [addList, setAddList] = useState([{ key: 0, firstname: '', lastname: '', student_id: '', email: '', chars_left: 50, chars_left1: 50, chars_left2: 20, chars_left3: 50, FirstNameValidated: false, LastNameValidated: false, studentIdValidated: false, emailValidated: false, emailErrorValidated: false, isEmail: false }])
    const [classError, setClassError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [roleError, setRoleError] = useState('');
    const [sectionError, setSectionError] = useState('');
    const [courseError, setCourseError] = useState('');
    const [affiliationError, setAffilitionError] = useState('');
    const payStatusOption = [{ label: 'Paid', value: true }, { label: 'Unpaid', value: false }];
    const roleOption = [{ value: 'admin', label: 'Admin', value1: "role" }, { value: 'faculty', label: 'Faculty', value1: "role" }, { value: 'coordinator', label: 'Coordinator', value1: "role" }, { value: 'counsellor', label: 'Counsellor', value1: "role" }];
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { classList } = useSelector(state => state.account);
    const { courseList, selectFromSubject, affliations } = useSelector(state => state.manageContent);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const { type, role } = location.state
        console.log(type)
        dispatch(getAffliations());
        if (type === 'staff' || role === 'staff') {
            dispatch(getAllSubjects());
            dispatch(getCourseList());
        }


        const { details } = location.state;
        if (details !== undefined) {
            setStaffFirstName(details?.firstname);
            setStaffLastName(details?.lastname);
            setStaffId(details?.id_num);
            setStaffEmail(details?.email);
            setId(details?._id);
            setAffiliation({ label: details?.affiliation?.name, value: details?.affiliation?._id })
            setCharLeft4(max_length - details?.firstname?.length);
            setCharLeft5(max_length - details?.lastname?.length);
            setCharLeft6(max_length - details?.id_num?.length);
            setCharLeft7(max_length - details?.email?.length);
            setSubjects(details.subjects.map(elm => ({ value: elm._id, label: elm.name })));
            setRole(details.role.map(elm => ({ value: elm, label: elm === 'admin' ? 'Admin' : elm === 'faculty' ? 'Faculty' : elm === 'coordinator' ? 'Coordinator' : elm === 'counsellor' ? 'Counsellor' : null })));
            setRoleValue(details?.role);
            setPaid(details?.paid);
            setCourse(details.role?.includes('coordinator') ? details?.course : null);
            // setStartDate(details.payments.length ? moment(new Date(details.payments[0].start_date)).format('YYYY-MM-DD') : '');
            // setEndDate(details.payments.length ? moment(new Date(details.payments[0].end_date)).format('YYYY-MM-DD') : '');
            // setTid(details.payments.length ? details.payments[0].order_id : '');
            // setAmount(details.payments.length ? (details.payments[0].amount / 100) : '');
            // setPayStatus(details.paid ? { label: 'Paid', value: true } : { label: 'Unpaid', value: false });

        }

    }, [])

    const goToUsers = () => {
        navigate(-1);
    }



    const onChangeFirstName = async (key, firstname) => {
        changeChar(key, 'firstname', firstname);
        const updatedAnswers = addList.map(answer => {
            if (answer.key === key) {
                return { ...answer, firstname: firstname, FirstNameValidated: false };
            }
            return answer;
        });
        setAddList(updatedAnswers);

    }

    const onChangeLastName = (key, lastname) => {
        changeChar(key, 'lastname', lastname);
        const updatedAnswers = addList.map(answer => {
            if (answer.key === key) {
                return { ...answer, lastname: lastname, LastNameValidated: false };
            }
            return answer;
        });
        setAddList(updatedAnswers);
    }

    const onChangeStudentId = (key, studentId) => {
        changeChar(key, 'student_id', studentId);
        const updatedAnswers = addList.map(answer => {
            if (answer.key === key) {
                return { ...answer, student_id: studentId, studentIdValidated: false };
            }
            return answer;
        });
        setAddList(updatedAnswers);
    }

    const onChangeEmail = async (key, email) => {
        setCheckDisabled(true);
        changeChar(key, 'email', email)
        if (email === "") {
            const answersns = addList.map(answer => {
                if (answer.key === key) return { key: answer.key, firstname: answer.firstname, lastname: answer.lastname, student_id: answer.student_id, email: answer.email, FirstNameValidated: answer.FirstNameValidated, LastNameValidated: answer.LastNameValidated, studentIdValidated: answer.studentIdValidated, emailValidated: true, emailErrorValidated: answer.emailErrorValidated, isEmail: answer.isEmail, chars_left: answer.chars_left, chars_left1: answer.chars_left1, chars_left2: answer.chars_left2, chars_left3: answer.chars_left3 }
                return answer
            })
            setAddList(answersns);
        }

        const answersns = addList.map(answer => {
            if (answer.key === key) return { key: answer.key, firstname: answer.firstname, lastname: answer.lastname, email: email, student_id: answer.student_id, FirstNameValidated: answer.FirstNameValidated, LastNameValidated: answer.LastNameValidated, studentIdValidated: answer.studentIdValidated, emailValidated: false, emailErrorValidated: false, isEmail: false, chars_left: answer.chars_left, chars_left1: answer.chars_left1, chars_left2: answer.chars_left2, chars_left3: answer.chars_left3 }
            return answer
        })
        setAddList(answersns);
    }

    const checkFirstName = (key, e) => {
        submitform();
        if (e.firstname === "") {
            const updatedAnswers = addList.map(answer => {
                if (answer.key === key) {
                    return { ...answer, FirstNameValidated: true };
                }
                return answer;
            });
            setAddList(updatedAnswers);
        }

    }

    const checkLastName = (key, e) => {
        submitform()
        if (e.lastname === "") {
            const updatedAnswers = addList.map(answer => {
                if (answer.key === key) {
                    return { ...answer, LastNameValidated: true };
                }
                return answer;
            });
            setAddList(updatedAnswers);
        }
    }

    const checkStudentId = (key, e) => {
        submitform()
        if (e.student_id === "") {
            const updatedAnswers = addList.map(answer => {
                if (answer.key === key) {
                    return { ...answer, studentIdValidated: true };
                }
                return answer;
            });
            setAddList(updatedAnswers);
        }
    }

    const checkEmail = async (key, e) => {
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        changeChar(key, 'email', e.email)
        if (e.email === "") {
            const updatedAnswers = addList.map(answer => {
                if (answer.key === key) {
                    return { ...answer, emailValidated: true };
                }
                return answer;
            });
            setAddList(updatedAnswers);
        }
        else if (!emailrex.test(String(e.email))) {
            const updatedAnswers = addList.map(answer => {
                if (answer.key === key) {
                    return { ...answer, emailValidated: false, emailErrorValidated: true, };
                }
                return answer;
            });
            setAddList(updatedAnswers);
            submitform();
            return false
        }
        else {
            const exist = await dispatch(checkUser(e.email));

            if (exist.payload) {
                const updatedAnswers = addList.map(answer => {
                    if (answer.key === key) {
                        return { ...answer, isEmail: true };
                    }
                    return answer;
                });
                setAddList(updatedAnswers);
            } else {
                const updatedAnswers = addList.map(answer => {
                    if (answer.key === key) {
                        return { ...answer, isEmail: false };
                    }
                    return answer;
                });
                setAddList(updatedAnswers);
            }
            submitform();
        }
    }


    const addRow = () => {
        setIntialKey(initialKey + 1);
        setCheckDisabled(true);
        setAddList(addList.concat([{ key: initialKey + 1, firstname: '', lastname: '', student_id: '', email: '', chars_left: 50, chars_left1: 50, chars_left2: 20, chars_left3: 50, FirstNameValidated: false, LastNameValidated: false, studentIdValidated: false, emailValidated: false, emailErrorValidated: false, isEmail: false }]));
    }

    const removeAddList = (idx) => {
        setAddList(addList.filter((s, index) => idx !== index));
        addList.forEach(el => {
            if (idx < el.key) {
                el.key = el.key - 1
            }
        })
    };

    const checkEmail1 = async (e) => {
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value === "") {
            setEmailError('Please enter email');
        }
        else if (!emailrex.test(String(e.target.value))) {
            setEmailError('Please enter correct email');
            return false
        }
        else {
            const exist = await dispatch(checkUser(e.target.value));
            if (exist.payload) {
                setEmailError('Email already exist');
            }
            else {
                setEmailError('');
            }
        }
    }


    const submitform = () => {
        const subscribedSkillIsEmail = addList.filter((item) => item.isEmail === true);
        const subscribedSkillsList = addList.filter((item) => item.firstname === "");
        const subscribedSkillsLastName = addList.filter((item) => item.lastname === "");
        const subscribedSkillsemail = addList.filter((item) => item.email === "");
        const subscribedSkillId = addList.filter((item) => item.student_id === "");
        const subscribedSkillIsEmailValidated = addList.filter((item) => item.emailErrorValidated === true);
        if (subscribedSkillsList.length <= 0 && subscribedSkillsLastName <= 0 && subscribedSkillId <= 0 && subscribedSkillsemail <= 0 && subscribedSkillIsEmail <= 0 && subscribedSkillIsEmailValidated <= 0) {
            setCheckDisabled(false);
        }
        else {
            setCheckDisabled(true);
        }
    }


    const selectAffiliation = (e) => {
        setAffiliation(e);
        setAffilitionError('');
        type === 'student' && dispatch(getClasses(e.value));
        type === 'student' && setClass('');
        setSubjects('');
    }


    const selectClass = async (e) => {
        setClass(e);
        setClassError('');
        const secs = await classList.filter(el => el.label === e.label)[0].sections;
        setSections(secs);
    }

    const selectSection = (e) => {
        setSection(e);
        setSectionError('');
    }

    const saveStudent = async () => {
        if (classs === '') {
            setClassError('classs cannot be empty');
        }
        else if (section === '') {
            setSectionError('Section cannot be empty');
        }
        else if (affiliation === '') {
            setSectionError('Section cannot be empty');
        }
        else {
            const result = addList.map(({ firstname, lastname, email, student_id }) => [{ firstname: firstname, lastname: lastname, student_id: student_id, email: email, class: classs?.class, section_id: section?.value, affiliation_id: affiliation?.value, role: 'student' }][0]);
            await dispatch(savedStudent(result));
            setAffiliation('');
            navigate('/users');
        }
    }





    const getSubject = (e) => {
        setSubjects(e);
        setSubjectError('');
        // subjectIds: e.map(el => [el.value][0]),
    }


    const getRole = (e) => {
        setRole(e);
        setRoleValue(e.map(el => [el.value][0]));
        setRoleError('');
    }

    const selectCourse = (e) => {
        setCourse(e);
        setCourseError('');
    }





    const onChangeStaff = (e) => {
        changeChar('', e.target.name, e.target.value);
        if (e.target.name === 'staff_first_name') {
            setStaffFirstName(e.target.value);
            setFirstNameError('')
        }
        else if (e.target.name === 'staff_last_name') {
            setStaffLastName(e.target.value);
            setLastNameError('');
        }
        else if (e.target.name === 'staff_Id') {
            setStaffId(e.target.value);
            setStaffIdError('');
        }
        else if (e.target.name === 'staff_email') {
            setStaffEmail(e.target.value);
            setEmailError('');
        }
    }




    const saveStaff = async () => {

        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (staff_first_name === "") {
            setFirstNameError('Please enter firstname');
        }
        else if (staff_last_name === "") {
            setLastNameError('Please enter lastname');
        }
        else if (staff_Id === "") {
            setStaffIdError('Please enter staff id');
        }
        else if (role === "") {
            setRoleError('Please select role');
        }
        else if (staff_email === "") {
            setEmailError('Please enter email');
        }
        else if (!emailrex.test(String(staff_email))) {
            setEmailError('Please enter correct email');
            return false
        }
        else if (!affiliation) {
            setAffilitionError('Please select affiliations');
        }
        else if (!subjects?.length) {
            setSubjectError('Please select subjects');
        }
        else if (roleValue?.includes('coordinator') && !course?.length) {
            setCourseError('Please select course')
        }
        else {
            await setLoading(true);
            const staff = {
                firstname: staff_first_name,
                lastname: staff_last_name,
                email: staff_email,
                staff_id: staff_Id,
                role: roleValue,
                subjects: subjects.map(el => [el.value][0]),
                affiliation_id: affiliation?.value,
                courses: roleValue?.includes('coordinator') ? course?.map(el => [el.value][0]) : []
            }
            await dispatch(savedStaff(staff));
            await setLoading(false);
            navigate(-1);
        }

    }

    const editStaff = async () => {

        if (staff_first_name === "" || null) {
            setFirstNameError('Please enter firstname');
        }
        else if (staff_last_name === "" || null) {
            setLastNameError('Please enter lastname');
        }
        else if (staff_Id === "" || null) {
            setStaffIdError('Please enter staff id');
        }
        else if (!affiliation) {
            setAffilitionError('Please select affiliations');
        }
        else if (!subjects?.length) {
            setSubjectError('Please select subjects');
        }
        else if (roleValue?.includes('coordinator') && !course?.length) {
            setCourseError('Please select course')
        }
        else {
            await setLoading(true);
            const staff = {
                _id: id,
                firstname: staff_first_name,
                lastname: staff_last_name,
                staff_id: staff_Id,
                role: roleValue,
                affiliation_id: affiliation?.value,
                subjects: subjects.map(el => [el.value][0]),
                courses: roleValue?.includes('coordinator') ? course?.map(el => [el.value][0]) : []
            }
            await dispatch(editedStaff(staff));
            await setLoading(false);
            navigate(-1);
        }
    }

    // const handleRemove = (value) => {
    //     let difference = subjects !== undefined && subjects.filter(x => { return x.value !== value });
    //     setSubjects(difference);
    // }


    const changeChar = (key, name, val) => {
        const charCount = val.length;
        const maxChar = max_length
        const charLength = maxChar - charCount;
        // const addList1 = addList.slice();
        if (name === 'firstname') {
            addList[key].chars_left = charLength
        }
        else if (name === 'lastname') {
            addList[key].chars_left1 = charLength
        }
        else if (name === 'student_id') {
            const maxChar = 20;
            const charLength = maxChar - charCount;
            addList[key].chars_left2 = charLength
        }
        else if (name === 'email') {
            addList[key].chars_left3 = charLength
        }
        else if (name === 'staff_first_name') {
            setCharLeft4(charLength);
        }
        else if (name === 'staff_last_name') {
            setCharLeft5(charLength);
        }
        else if (name === 'staff_Id') {
            setCharLeft6(charLength);
        }
        else if (name === 'staff_email') {
            setCharLeft7(charLength);
        }
    }


    const getDate = async (val) => {
        setStartDate(val);
        var d = new Date(val);
        d.setMonth(d.getMonth() + 12);
        await setEndDate(moment(new Date(d)).format('YYYY-MM-DD'));;
    }

    const getPayStatus = (e) => {
        setPayStatus(e);
        setPaid(e.value);
    }

    const getAffliationSubject = () => {
        const firstAffiliationData = selectFromSubject?.filter(el => el.affiliation_id === affliations[0]?._id);
        const secondAffiliationData = selectFromSubject?.filter(el => el.affiliation_id === affliations[1]?._id);
        return [...firstAffiliationData, ...secondAffiliationData];
    }


    const { type, edit } = location.state

    return (
        <div className='add-form'>

            {
                type === 'student' ?
                    <Fragment>
                        <div className='add-student-form'>
                            <div className='student-header'>
                                <img onClick={goToUsers} src='/images/back1.svg' alt='' />
                                <p>Add Student</p>
                            </div>
                            <div className='form-inputs'>
                                <p>Fields marked * are mandatory</p>
                                <div className='row mt-1'>
                                    <div className='col-lg-4'>
                                        <div className='mt-2'>
                                            <label>Affiliation*
                                            </label>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                placeholder=''
                                                value={affiliation}
                                                onChange={selectAffiliation}
                                                options={affliations?.map(el => [{ ...el, label: el.name, value: el._id }][0])}
                                            />
                                            <span>{affiliationError}</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='mt-2'>
                                            <label>Classes*
                                            </label>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                placeholder=''
                                                value={classs}
                                                onChange={selectClass}
                                                options={classList?.filter(el => el.sections?.length)?.map(el => [{ ...el, label: el.label, value: el._id }][0])}
                                            />
                                            <span>{classError}</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='mt-2'>
                                            <label>Sections*
                                            </label>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={sections}
                                                placeholder=''
                                                onChange={selectSection}
                                                value={section}
                                            />
                                            <span>{sectionError}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='details-row'>
                            {
                                addList !== undefined && addList.map((items, index) => {
                                    return (
                                        <div className='row'>
                                            <div className='col-lg-3'>
                                                <div className='form-group mt-2'>
                                                    <label>Student First Name*
                                                        <aside className='float-right'>{items.chars_left}</aside>
                                                    </label>
                                                    <input className='form-control mt-2 text-capitalize' onKeyUp={(e) => checkFirstName(index, items)} style={{ borderColor: items.FirstNameValidated ? 'red' : '#5EB5E7' }} maxLength='50' autoComplete='off' name='firstname' value={items.firstname} onChange={(e) => onChangeFirstName(index, e.target.value)}
                                                        onMouseMoveCapture={() => submitform()}
                                                        pattern="[A-Za-z]" />
                                                    {
                                                        items.FirstNameValidated ? <p>Please enter the first name</p> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className='form-group mt-2'>
                                                    <label>Student Last Name*
                                                        <aside className='float-right'>{items.chars_left1}</aside>
                                                    </label>
                                                    <input className='form-control mt-2 text-capitalize ' style={{ borderColor: items.LastNameValidated ? 'red' : '#5EB5E7' }} maxLength='50' autoComplete='off' name='lastname' value={items.lastname} onChange={(e) => onChangeLastName(index, e.target.value)}
                                                        onKeyUp={(e) => checkLastName(index, items)} onMouseMoveCapture={() => submitform()}
                                                        pattern="[A-Za-z]" />
                                                    {
                                                        items.LastNameValidated ? <p>Please enter the last name</p> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className='form-group mt-2'>
                                                    <label>Student Id*
                                                        <aside className='float-right'>{items.chars_left2}</aside>
                                                    </label>
                                                    <input className='form-control mt-2 ' maxLength='50' autoComplete='off' style={{ borderColor: items.studentIdValidated ? 'red' : '#5EB5E7' }} name='student_id' value={items.student_id} onChange={(e) => onChangeStudentId(index, e.target.value)}
                                                        onKeyUp={(e) => checkStudentId(index, items)} onMouseMoveCapture={() => submitform()} />
                                                    {
                                                        items.studentIdValidated ? <p>Please enter the student id</p> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className='form-group mt-2'>
                                                    <label>Student Email*
                                                        <aside className='float-right'>{items.chars_left3}</aside>
                                                    </label>
                                                    <input className='form-control mt-2' style={{ textTransform: 'none', borderColor: (items.emailValidated || items.emailErrorValidated || items.isEmail) ? 'red' : '#5EB5E7' }} maxLength='50' autoComplete='off' name='email' value={items.email} onBlur={(e) => checkEmail(index, items)} onFocus={(e) => checkEmail(index, items)} onChange={(e) => onChangeEmail(index, e.target.value)}
                                                        onMouseMoveCapture={() => submitform()} />
                                                    {
                                                        items.emailValidated ? <p>Please enter the email</p> : items.emailErrorValidated ? <p>Please enter the valid email</p> : items.isEmail ? <p>Email already exist</p> : null
                                                    }
                                                </div>
                                            </div>
                                            {
                                                index === 0 ? null : <button
                                                    type="button"
                                                    onClick={() => removeAddList(index)}
                                                    className="delete-row"
                                                ><img src="/images/delete-icon.svg" alt='' /></button>
                                            }
                                        </div>

                                    )
                                })
                            }
                            <button className='add-row' onClick={() => addRow()} disabled={addList.filter((item) => item.isEmail === true)?.length || checkDisabled}>Add row</button>
                        </div>
                        <div className='save-btn'>
                            <button disabled={addList.filter((item) => item.isEmail === true)?.length || checkDisabled} style={{ opacity: checkDisabled ? 0.7 : 1 }} onClick={saveStudent}>Save</button>
                            <button onClick={goToUsers}>Cancel</button>
                        </div>
                    </Fragment>
                    :
                    <div className='add-staff-form'>
                        <div className='student-header' style={{ width: '55%' }}>
                            <img onClick={goToUsers} src='/images/back1.svg' alt='' />
                            <p>{location.state.type === 'edit' ? 'Edit Staff' : 'Add Staff'}</p>
                        </div>
                        <div className='form-inputs'>
                            <p>Fields marked * are mandatory</p>
                            <div className='row mt-1'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Staff First Name*
                                            <aside className='float-right'>{chars_left4}</aside>
                                        </label>
                                        <input className='form-control mt-2 text-capitalize' maxLength={max_length} value={staff_first_name} name='staff_first_name' onChange={(e) => onChangeStaff(e)} />
                                        <span>{firstNameError}</span>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Staff Last Name*
                                            <aside className='float-right'>{chars_left5}</aside>
                                        </label>
                                        <input className='form-control mt-2 text-capitalize' maxLength={max_length} value={staff_last_name} name='staff_last_name' onChange={(e) => onChangeStaff(e)} />
                                        <span>{lastNameError}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Staff Id*
                                            <aside className='float-right'>{chars_left6}</aside>
                                        </label>
                                        <input className='form-control mt-2 ' maxLength={max_length} value={staff_Id} name='staff_Id' onChange={(e) => onChangeStaff(e)} />
                                        <span>{staffIdError}</span>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Staff Type*
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={roleOption}
                                            placeholder=''
                                            value={role}
                                            onChange={getRole}
                                            isSearchable
                                            isMulti
                                        />
                                        <span>{roleError}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Email*
                                            <aside className='float-right'>{chars_left7}</aside>
                                        </label>
                                        <input className='form-control mt-2 ' style={{ textTransform: 'none' }} maxLength={max_length} onFocus={(e) => checkEmail1(e)} onBlur={(e) => checkEmail1(e)} disabled={location.state.type === 'edit' ? true : false} value={staff_email} name='staff_email' onChange={(e) => onChangeStaff(e)} />
                                        <span>{emailError}</span>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Affiliation*
                                        </label>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={affliations?.map(el => [{ value: el._id, label: el.name }][0])}
                                            onChange={(e) => selectAffiliation(e)}
                                            value={affiliation}
                                            placeholder='Select Affiliations'
                                        />
                                        <span>{affiliationError}</span>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='form-group mt-2'>
                                        <label>Subjects*
                                        </label>
                                        <Select
                                            isDisabled={affiliation?.value ? false : true}
                                            components={{ DropdownIndicator }}
                                            options={affiliation ? (affliations?.length > 1 ? getAffliationSubject() : selectFromSubject?.filter(el => (el.affiliation_id === affiliation?.value))) : null}
                                            value={subjects}
                                            isSearchable
                                            isMulti
                                            placeholder=''
                                            onChange={getSubject}
                                        />
                                        <span>{subjectError}</span>
                                    </div>
                                </div>
                                {roleValue?.includes('coordinator') ?
                                    <div className='col-lg-5'>
                                        <div className='form-group mt-2'>
                                            <label>Courses*
                                            </label>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={courseList?.map(el => [{ label: el.name, value: el._id }][0])}
                                                value={course}
                                                isSearchable
                                                isMulti
                                                placeholder=''
                                                onChange={selectCourse}
                                            />
                                            <span>{courseError}</span>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            {
                                localStorage.getItem('role').includes('master') ?
                                    location.state.type === 'edit' ?
                                        <div className='payment-history'>
                                            <h1>Subscription Details</h1>
                                            <div className='row mt-1'>
                                                <div className='col-lg-5'>
                                                    <div className='form-group mt-2'>
                                                        <label>Payment Status*
                                                        </label>
                                                        {
                                                            edit ?
                                                                <input className='form-control mt-2 text-capitalize' style={{ border: edit ? 'none' : '1px solid #5EB5E7' }} disabled value={paid ? 'Paid' : 'Unpaid'} readOnly />
                                                                :
                                                                <Select
                                                                    components={{ DropdownIndicator }}
                                                                    placeholder=''
                                                                    onChange={getPayStatus}
                                                                    options={payStatusOption}
                                                                    value={payStatus}
                                                                />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                paid ?
                                                    <Fragment>
                                                        <div className='row mt-1'>
                                                            <div className='col-lg-5'>
                                                                <div className='form-group mt-2'>
                                                                    <label>Start Date*
                                                                    </label>
                                                                    <input type='date' className='form-control mt-2' value={startDate} onChange={(e) => getDate(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <div className='form-group mt-2'>
                                                                    <label>End Date*
                                                                    </label>
                                                                    <input type='date' className='form-control mt-2' value={endDate} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-1'>
                                                            <div className='col-lg-5'>
                                                                <div className='form-group mt-2'>
                                                                    <label>Amount
                                                                    </label>
                                                                    <input className='form-control mt-2' style={{ textTransform: 'none' }} value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <div className='form-group mt-2'>
                                                                    <label>Transction Id*
                                                                    </label>
                                                                    <input className='form-control mt-2' style={{ textTransform: 'none' }} value={tId} onChange={(e) => setTid(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                    : null
                                            }
                                        </div> : null
                                    : null
                            }

                            <hr style={{ borderTop: '1px solid #DDDDE2', marginRight: 0 }} />
                            <div className='save-btn' style={{ background: "#fff", borderTop: 0 }}>
                                <button onClick={location.state.type === 'edit' ? editStaff : saveStaff} disabled={(emailError !== '') || loading}>Save</button>
                                <button onClick={goToUsers}>Cancel</button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}


export default AddUsers;
