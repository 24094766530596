import { replaceObjectInArray } from "../../utils/miscelleneous";
import {
    EDIT_ACCOUNT, GET_ACCOUNT_DETAILS, IS_ADD_SCHOOL, RESET_LIST, BACK_TO_ACCOUNT, GET_INSTITUTION,
    GET_ALL_INSTITUTION, ADD_INSTITUTE, UPDATE_INST_STATUS, EDIT_INSTITUTE, GET_SCHOOL_LIST, ADD_SCHOOL, UPDATE_SCHOOL_STATUS, UPDATE_SCHOOL, DELETE_STATE_OR_SCHOOL,
    GET_CLASS_LIST, CHANGE_SECTION,
    GET_FACULTY_LIST, ADD_USER,
    GET_COLORS, APPLY_THEME,
} from "../Types/types";

const classes = [{ label: 'Class 1', value: 1, class: 1, sections: [] }, { label: 'Class 2', class: 2, value: 2, sections: [] }, { label: 'Class 3', class: 3, value: 3, sections: [] }, { label: 'Class 4', value: 4, class: 4, sections: [] }, { label: 'Class 5', value: 5, class: 5, sections: [] }, { label: 'Class 6', value: 6, class: 6, sections: [] }, { label: 'Class 7', value: 7, class: 7, sections: [] }, { label: 'Class 8', value: 8, class: 8, sections: [] }, { label: 'Class 9', value: 9, class: 9, sections: [] }, { label: 'Class 10', value: 10, class: 10, sections: [] }, { label: 'Class 11', value: 11, class: 11, sections: [] }, { label: 'Class 12', value: 12, class: 12, sections: [] }]


let intialState = {

    institutions: [],
    theme: (localStorage.getItem('theme')) ? (JSON.parse(localStorage.getItem('theme')) || {}) : { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' },
    isListLoading: false,
    facultyList: [
        {
            "_id": "65a645fa4db0a89ec6e805fd",
            email: 'f1@email.com',
            "profile_image": null,
            "color": "#6457e8",
            "initial": "FO",
            "name": "Faculty One",
            "subjects": [
                {
                    "_id": "659f92937122112afd449e56",
                    "name": "Mathematics_Part 1(10th_Karnataka Board)"
                },
                {
                    "_id": "659f92b47122112afd449e6e",
                    "name": "Mathematics_Part 2(10th_Karnataka Board)"
                }
            ]
        },
        {
            "_id": "65a646124db0a89ec6e80607",
            email: 'f2@email.com',
            "profile_image": null,
            "color": "#6457e8",
            "initial": "FT",
            "name": "Faculty Two",
            "subjects": [
                {
                    "_id": "659f92de7122112afd449e81",
                    "name": "Science (10th_Karnataka Board)"
                },
                {
                    "_id": "659f93017122112afd449eb4",
                    "name": "English Part 1(10th Karnataka Board)"
                }
            ]
        },
        {
            "_id": "65a646204db0a89ec6e80611",
            email: 'f3@email.com',
            "profile_image": null,
            "color": "#6457e8",
            "initial": "FT",
            "name": "Faculty Three",
            "subjects": [
                {
                    "_id": "659f93017122112afd449eb4",
                    "name": "English Part 1(10th Karnataka Board)"
                }
            ]
        }
    ],
}

function mergeArraysWithProperties(arr1, arr2) {
    const map = new Map();

    arr1.forEach(item => map.set(item.class, { ...item }));
    arr2.forEach(item => {
        if (map.has(item.class)) {
            map.set(item.class, { ...map.get(item.class), ...item });
        } else {
            map.set(item.class, item);
        }
    });

    return Array.from(map.values());
}


export default function accountReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_CLASS_LIST:
            stateCopy.classList = action.payload?.length ?
                mergeArraysWithProperties(classes, action.payload).map(el => [{ ...el, sections: el.sections.map(el1 => [{ label: el1.name, value: el1._id }][0]) }][0])
                : classes;
            stateCopy.isListLoading = false;
            return stateCopy;
        case CHANGE_SECTION:
            stateCopy.classList.forEach(el => {
                if (el.label === action.payload.detail.label) {
                    el.sections = action.payload.section
                }
            })
            return stateCopy;
        case GET_INSTITUTION:
            stateCopy.institutions = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case GET_ALL_INSTITUTION:
            stateCopy.institutesList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_INSTITUTE:
            stateCopy.institutesList = [...stateCopy.institutesList, action.payload];
            return stateCopy;
        case UPDATE_INST_STATUS:
            stateCopy.institutesList.forEach(el => {
                if (el._id === action.payload.obj.institution_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case EDIT_INSTITUTE:
            stateCopy.institutesList = replaceObjectInArray(stateCopy.institutesList, action.payload._id, action.payload);
            return stateCopy;
        case GET_SCHOOL_LIST:
            stateCopy.schools = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_SCHOOL:
            stateCopy.schools = [...stateCopy.schools, action.payload];
            return stateCopy;
        case UPDATE_SCHOOL_STATUS:
            stateCopy.schools.forEach(el => {
                if (el._id === action.payload.obj.school_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case UPDATE_SCHOOL:
            stateCopy.schools = replaceObjectInArray(stateCopy.schools, action.payload._id, action.payload);
            return stateCopy;
        case DELETE_STATE_OR_SCHOOL:
            if (action.payload.type === 'state') {
                stateCopy.states = stateCopy.states.filter(el => el._id !== action.payload.id);
            }
            else {
                stateCopy.schools = stateCopy.schools.filter(el => el._id !== action.payload.id);
            }
            return stateCopy;
        case IS_ADD_SCHOOL:
            stateCopy.isAddSchool = true;
            return stateCopy;
        case BACK_TO_ACCOUNT:
            stateCopy.isAddSchool = false;
            return stateCopy;
        case GET_FACULTY_LIST:
            stateCopy.facultyList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_USER:
            // stateCopy.facultyList = [...stateCopy.facultyList, action.payload];
            return stateCopy;
        case EDIT_ACCOUNT:
            stateCopy.successMessage = true;
            stateCopy.successMessageText = 'Campus details updated';
            return stateCopy;
        case RESET_LIST:
            stateCopy.isListLoading = true;
            return stateCopy;
        case GET_COLORS:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
            return stateCopy;
        case APPLY_THEME:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            return stateCopy;
        default:
            return stateCopy;
    }
}

