import React from 'react'

export default function DynamicInput({ array, handleField, addField, removeField }) {

    return (
        <div>
            {array.map((field, index) => (
                <div key={index}>
                    <div className='col-md-12 position-relative'>
                        <div className='form-group mt-2'>
                            <input
                                className='form-control'
                                type="text"
                                name='title'
                                placeholder="Title"
                                value={field.title}
                                onChange={(e) => handleField(index, e)}
                            />

                        </div>
                        {
                            index === 0 ? null : <button
                                type="button"
                                onClick={() => removeField(index)}
                                className="remove-btn"
                            ><img src="/images/delete.svg" alt='' /></button>
                        }
                    </div>
                </div>
            ))}
            <button className='add-btn text-left'
                disabled={array[array.length - 1].title === ''}
                onClick={addField}>Add
            </button>
        </div>
    )
}
