import { getApiCall, postApiCall, putApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';
import {
    OPEN_INDIVIDUAL, CLOSE_iNDIVIDUAL, REFRESH_DATA,
    GET_ALL_SCHOOL, SCHOOL_LOGIN_TRENDS, SCHOOL_SCORE_TRENDS, GET_SCHOOL_STUDENTS,                                  //School
    GET_TOP_COURSES, GET_COURSE_GRAPH, GET_COURSE_DATA, RESET_COURSE_DETAILS, SORT_PROGRAM, //Course
    GET_SUBJECTS_LIST, GET_NEXT_SUBJECTS_LIST, RESET_SUB, GET_SUBJECT_CHAPTER_DATA, GET_CHAPTER_DATA, GET_SECTION_CHAPTER_DATA, SEARCH_CHAPTER, SEARCH_SECTION_CHAPTER, REFRESH_CHPATER_DATA, SORT_SUBJECT, RESET_SUBJECT_LOADING, //Subjects
    RESET_FACULTY_DETAILS, FACULTY_LOGIN_TRENDS, FACULTY_SCORE_TRENDS, GET_FACULTY_DATA, GET_FACULTY_SUBJECT_DATA, GET_FACULTY_CONTENT_COUNT, SORT_FACULTY, SORT_FACULTY_SUBJECT, RESET_FACULTY_LOADING, RESET_FACULTY_SUBJECT_LOADING, HIDE_CHAPTER, //FACULTY
    STUDENT_LOGIN_TRENDS, STUDENT_SCORE_TRENDS, RESET_STUDENT_LOADING, STUDENT_LIST, STUDENT_REPORT, STUDENT_REPORT1, GET_SUBJECT_COMPLETION, GET_ACTIVE_CONTENTS, RESET_STUDENT_DETAILS, CHANGE_SUBJECTS, GET_ACTIVE_SUBJECT_TOPICS, GET_TEST_LIST1, RESET_SUBTOPICS_DATA, //students
    GET_ACTIVE_SUBJECT_SUBTOPICS, GET_SUBTOPIC_QUIZ_HISTORY, CLEAR_QUIZ_HISTORY, GET_SCORE_HISTORY, GET_NEXT_SCORE_HISTORY, RESET_HISTORY,
    REFRESH_TEST, REFRESH_QUESTION, REFRESH_HISTORY, GET_QUIZ_LIST, GET_NEXT_QUIZ_LIST, RESET_TEST_DETAILS, GET_TEST_CLASS_WISE, GET_CLASS_SUBJECTS, GET_TEST_LISTS, GET_NEXT_TEST_LISTS, GET_QUESTIONS_LIST, GET_TEST_HISTORY, //tests
    RESET_ASSESSMENT, ASSESMENT_STUDENT_LIST, GET_ABILITIES, REPORT_ENABLE, GET_ASSESSMENT_REPORT, GET_ABILITIES_QUESTIONS, CHANGE_SCORE, ASSESSMENT_GRAPH, GET_ONE_REPORTS, SEARCH_ASSESSMENT_STUDENT
} from '../Types/types';

export const openIndividualItem = (type, el) => async (dispatch) => {
    return dispatch({
        type: OPEN_INDIVIDUAL,
        payload: { el, type }
    })
}

export const backToMain = () => async (dispatch) => {
    return dispatch({
        type: CLOSE_iNDIVIDUAL,
    })
}

export const refreshData = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_DATA,
    })
}

//For superadmin schools api
export const getAllSchools = () => async (dispatch) => {
    try {
        const response = await getApiCall(`super-admin/reports/school`);
        return dispatch({ type: GET_ALL_SCHOOL, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


export const getSchoolStudentTrend = (id, role) => async (dispatch) => {
    try {
        const response = await getApiCall(`super-admin/reports/student/login/trend?school_id=${id}&role=${role}`);
        dispatch({ type: SCHOOL_LOGIN_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSchoolScoreTrend = (id, role) => async (dispatch) => {
    try {
        const response = await getApiCall(`super-admin/reports/student/score/trend?school_id=${id}&role=${role}`);
        dispatch({ type: SCHOOL_SCORE_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSchoolStudents = (id, role, page, sort, query, sectionId) => async (dispatch) => {
    try {
        const response = await getApiCall(`super-admin/reports/student/ranking/data?role=${role}&page=${page}&sort=${sort}&query=${query}&section_id=${sectionId}&school_id=${id}`);
        console.log(response.data)
        dispatch({ type: GET_SCHOOL_STUDENTS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

// for courses api call

export const getTopCourses = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/course/top/engaged`);
        dispatch({ type: GET_TOP_COURSES, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getCourseGraph = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/course/student`);
        dispatch({ type: GET_COURSE_GRAPH, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getCoursesList = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/course/details`);
        dispatch({ type: GET_COURSE_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetProgramDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_COURSE_DETAILS,
    })
}


export const sortProgramList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_PROGRAM, payload: { key, asc }
    })
}




//for subject api call


export const getSubjectList = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/completion?page=${page}`);
        console.log(response.data)
        dispatch({ type: GET_SUBJECTS_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getNextSubjectList = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/completion?page=${page}`);
        dispatch({ type: GET_NEXT_SUBJECTS_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const resetSubjectDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_SUB
    })
}

export const getTopChapterCompletion = (id) => async (dispatch) => {
    // try {
    //     const response = await getApiCall(`admin/reports/subject/chapter/top/completion?subject_id=${id}`);
    //     dispatch({ type: "Get_Subject_Chapter_Graph", payload: response.data });
    // } catch (error) {
    //             returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    // }
}

export const getAllChapterCompletion = (id, course_id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/chapter/completion?subject_id=${id}&course_id=${course_id}`);
        dispatch({ type: GET_SUBJECT_CHAPTER_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getTopSectionChapterCompletion = (id) => async (dispatch) => {
    // try {
    //     const response = await getApiCall(`admin/reports/subject/chapter/top/completion?subject_id=${id}`);
    //     dispatch({ type: "Get_Section_Chapter_Graph", payload: response.data });
    // } catch (error) {
    //             returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    // }
}

export const getAllSectionChapterCompletion = (id, course_id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/section/completion?subject_id=${id}&course_id=${course_id}`);
        dispatch({ type: GET_SECTION_CHAPTER_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSectionChapterDetails = (id, pid, sectionId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/chapter/completion/section?subject_id=${id}&course_id=${pid}&section_id=${sectionId}`);
        console.log(response.data)
        dispatch({ type: GET_CHAPTER_DATA, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const searchChapterResult = (val) => async (dispatch) => {
    return dispatch({
        type: SEARCH_CHAPTER, payload: val
    })
}

export const searchSectionChapterResult = (val) => async (dispatch) => {
    return dispatch({
        type: SEARCH_SECTION_CHAPTER, payload: val
    })
}

export const refreshChapterData = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_CHPATER_DATA,
    })
}

export const sortSubjectList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_SUBJECT, payload: { key, asc }
    })
}


export const resetSubjectLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_SUBJECT_LOADING
    })
}


//faculty

export const resetFacultyDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_FACULTY_DETAILS
    })
}

export const getFacultyLoginTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/login/trend?role=${role}`);
        dispatch({ type: FACULTY_LOGIN_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getFacultyScoreTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/trend?role=${role}`);
        dispatch({ type: FACULTY_SCORE_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFacultyList = (page, query, sort) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/faculty/details?page=${page}&query=${query}&sort=${sort}`);
        dispatch({ type: GET_FACULTY_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}




export const getFacultySubject = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/faculty/subject/details?user_id=${id}`);
        dispatch({ type: GET_FACULTY_SUBJECT_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFacultyContentCount = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/faculty/count/content?_id=${id}`);
        dispatch({ type: GET_FACULTY_CONTENT_COUNT, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const sortFacultyList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_FACULTY, payload: { key, asc }
    })
}

export const sortFacultySubjectList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_FACULTY_SUBJECT, payload: { key, asc }
    })
}

export const resetFacultyLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_FACULTY_LOADING
    })
}


export const resetFacultySubjectChapter = () => async (dispatch) => {
    return dispatch({
        type: RESET_FACULTY_SUBJECT_LOADING
    })
}

export const isHideChapter = () => async (dispatch) => {
    return dispatch({
        type: HIDE_CHAPTER,
    })
}







// for students API call
export const getStudentLoginTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/login/trend?role=${role}`);
        dispatch({ type: STUDENT_LOGIN_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getStudentScoreTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/trend?role=${role}`);
        dispatch({ type: STUDENT_SCORE_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetStudentLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_STUDENT_LOADING
    })
}

export const getListOfStudent = (page, val, courseId, sectionId, sort, role, cls) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/ranking/data?page=${page}&query=${val}&course_id=${courseId === undefined ? '' : courseId}&section_id=${sectionId === undefined ? '' : sectionId}&sort=${sort}&role=${role}&class=${cls ? cls : ''}`);
        dispatch({ type: STUDENT_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getStudentReportDetail = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/details?user_id=${id}`);
        const subject = await getApiCall(`student/achiever/subject/graph?user_id=${id}`);
        const data = response.data.data
        const subject1 = await getApiCall(`admin/reports/student/subject/completion?course_id=${data.course_id}&user_id=${id}`);
        const test = await getApiCall(`admin/reports/student/practise/test/completion?course_id=${data.course_id}&user_id=${id}`);
        const subjects = subject.data.subjects
        const subjectContent = subject.data.data
        const test1 = test.data
        const subject2 = subject1.data
        const response1 = await getApiCall(`admin/reports/student/subject/chapter?subject_id=${subject2.subject[0]._id}&user_id=${id}`);
        // const response2 = await getApiCall(`admin/reports/student/practise/test?subject_id=${subject2.subject[0]._id}&user_id=${id}`);
        const chapters = response1.data.data;
        // const testList=response2.data.data
        dispatch({ type: STUDENT_REPORT, payload: { data, subjects, subjectContent, id } });
        dispatch({ type: GET_SUBJECT_COMPLETION, payload: { subject2, test1 } });
        dispatch({ type: GET_ACTIVE_CONTENTS, payload: chapters });
        // dispatch({ type: "Get_Test_Completion", payload: test1 })

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getStudentReportDetail1 = (id) => async (dispatch) => {
    try {
        const monthly = await getApiCall(`student/achiever/monthly/graph?user_id=${id}`);
        const login = await getApiCall(`student/dashboard/login/trend?user_id=${id}`);
        const month = monthly.data.months
        const contents = monthly.data.data
        const logins = login.data
        dispatch({ type: STUDENT_REPORT1, payload: { month, contents, id, logins } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetStudentDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_STUDENT_DETAILS,
    })
}

export const changeSubject = (sub, subId) => async (dispatch) => {
    return dispatch({
        type: CHANGE_SUBJECTS,
        payload: { sub, subId }
    })
}

export const getTopicsForStudent = (userId, subId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/subject/chapter?subject_id=${subId}&user_id=${userId}`);
        const data = response.data.data;
        dispatch({ type: GET_ACTIVE_SUBJECT_TOPICS, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const getTestList = (subid, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/practise/test?subject_id=${subid}&user_id=${userId}`);
        dispatch({ type: GET_TEST_LIST1, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const resetSubtopicsData = () => async (dispatch) => {
    return dispatch({
        type: RESET_SUBTOPICS_DATA
    })
}

export const getSubtopics = (key, topicId, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/subject/chapter/details?chapter_id=${topicId}&user_id=${userId}`);
        const data = response.data.data;
        dispatch({ type: GET_ACTIVE_SUBJECT_SUBTOPICS, payload: { data, key } });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const getQuizHistory = (chapterId, topicId, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/quiz/completion?chapter_id=${chapterId}&topic_id=${topicId}&user_id=${userId}`);
        const data = response.data.data;
        dispatch({ type: GET_SUBTOPIC_QUIZ_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const clearQuizHistory = () => async (dispatch) => {
    return dispatch({
        type: CLEAR_QUIZ_HISTORY
    })
}

export const getScoresHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/history?_id=${id}&page=${page}`);
        const data = response.data.data;
        dispatch({ type: GET_SCORE_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const getNextScoresHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/history?_id=${id}&page=${page}`);
        const data = response.data.data;
        dispatch({ type: GET_NEXT_SCORE_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const resetHistory = (type) => async (dispatch) => {
    return dispatch({
        type: RESET_HISTORY, payload: type
    })
}

//TEsts

export const refreshTests = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_TEST,
    })
}

export const refreshQuestion = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_QUESTION,
    })
}

export const resetHistoryLoading = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_HISTORY,
    })
}

export const getQuizList = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/quiz?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_QUIZ_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const nextQuizList = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/quiz?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_NEXT_QUIZ_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const resetTestDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_TEST_DETAILS,
    })
}

export const getClassSubjects = (cid) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/subject/test?course_id=${cid}`);
        console.log(response.data.data)
        dispatch({ type: GET_CLASS_SUBJECTS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getTestClassWise = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/course/test`);
        console.log(response.data.data)
        dispatch({ type: GET_TEST_CLASS_WISE, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}


export const getPracticeTest = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/practise?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_TEST_LISTS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getNextPracticeTest = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/practise?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_NEXT_TEST_LISTS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getQuestions = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/questions?_id=${id}`)
        dispatch({ type: GET_QUESTIONS_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getTestHistory = (id, question) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/quiz/history?_id=${id}`);
        const data = response.data.data
        dispatch({ type: GET_TEST_HISTORY, payload: { data, question } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// assessment 
export const resetAssessment = () => async (dispatch) => {
    dispatch({ type: RESET_ASSESSMENT });
}

export const getAssesmentStudent = (page, type, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/counselling/students?page=${page}&type_id=${type}&query=${query ? query : ''}`);
        dispatch({ type: ASSESMENT_STUDENT_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}



export const getUserAbility = (id, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/counselling/abilities?user_id=${id}&type_id=${type}`);
        dispatch({ type: GET_ABILITIES, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const changeEnable = (type, userId, testId, report) => async (dispatch) => {
    let obj = {
        user_id: userId,
        test_id: testId,
        type: type
    }
    try {
        const response = await putApiCall(`admin/reports/counselling/test/toggle`, obj);
        const data = response.data.data
        dispatch({ type: REPORT_ENABLE, payload: !report });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAssessmentReport = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/counselling/riasec/report/list?user_id=${id}`);
        dispatch({ type: GET_ASSESSMENT_REPORT, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getTestReports = (ability_id, testId, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/counselling/ability/questions?test_id=${testId}&user_id=${userId}&ability_id=${ability_id}`);
        dispatch({ type: GET_ABILITIES_QUESTIONS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const changeScore = (userId, abilityId, score) => async (dispatch) => {
    let obj = {
        user_id: userId,
        ability_id: abilityId,
        score: score
    }
    try {
        const response = await postApiCall(`admin/reports/counselling/ability/pm/score`, obj);
        const data = response.data.data
        dispatch({ type: CHANGE_SCORE, payload: { ...obj, ...data } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAssessmentGraph = (userId, id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/counselling/ability/graph?user_id=${userId}&type_id=${id}`);
        dispatch({ type: ASSESSMENT_GRAPH, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getOneReports = (id, testId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/counselling/riasec/report?user_id=${id}&test_id=${testId}`);
        dispatch({ type: GET_ONE_REPORTS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const searchAssesmentStudent = (val) => async (dispatch) => {
    return dispatch({
        type: SEARCH_ASSESSMENT_STUDENT, payload: val
    })
}















export const onResetSubject = () => async (dispatch) => {
    return dispatch({
        type: 'Reset_Sem_Subject'
    })
}










export const getAttendanceHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`campus/tm/reports/attendance/trainee?_id=${id}`);
        const data = response.data.data;
        dispatch({ type: "Get_Scores_History", payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}







export const getEngagementScores = (id) => async (dispatch) => {
    // try {
    //     const response = await getApiCall(`user/score/engagement?_id=${id}`)
    //     dispatch({ type: "Get_Faculty_Engagement_Score", payload: response.data });

    // } catch (error) {
    //             returnErrors(dispatch, error?.response?.data?.message, error?.response?.status)
    // }
}


export const getFacultyLogins = (id) => async (dispatch) => {
    // try {
    //     const response = await getApiCall(`campus/admin/reports/getStudentLoginTrend?user_id=${id}`);
    //     dispatch({ type: "Get_Faculty_Logins", payload: response.data });
    // } catch (error) {
    //             returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    // }
}

