import { OPEN_SHARE, CLOSE_SHARE, SHARE_CONTENT } from '../Types/types';
import { postApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';


export const openShare = (data) => async (dispatch) => {
    dispatch({ type: OPEN_SHARE, payload: data });
}

export const shareClose = () => async (dispatch) => {
    dispatch({ type: CLOSE_SHARE });
}



export const shareAll = (obj) => async (dispatch) => {

    try {
        const response = await postApiCall(`share/content`, obj);
        returnErrors(dispatch, `${obj.resource_type} has been shared`, response?.status);
        dispatch({ type: SHARE_CONTENT });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}





