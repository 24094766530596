import React, { useEffect } from 'react';
import './App.scss';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PrivateRoute from './Routes/privateRoutes';
import LoginModule from './Components/Login/login';
import SidebarLayout from './Components/Layout/sidebarLayout';
import RouteItems from './Routes/routes';
import { useSelector, useDispatch } from 'react-redux';
import { getLogo } from './Redux/ActionCreators/authAction';
import Nhps from './Components/Info-Pages/nhps';
import Nhgs from './Components/Info-Pages/nhgs';
import Nhis from './Components/Info-Pages/nhis';
import PrivacyPolicy from './Components/privacyPolicy';

function App() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logoDetails } = useSelector(state => state.auth);

  useEffect(() => {
    const firstRender = async () => {
      const res = await dispatch(getLogo());
      if (res?.type === 'GET_LOGO') {
        document.title = res?.payload?.type === 'masteradmin' ? 'School Master Admin' : res?.payload?.name
      }
    }
    firstRender();
    if (localStorage.getItem('loginToken')) {
      if (localStorage.getItem("profileDetails")) {
        const details = JSON.parse(localStorage.getItem("profileDetails"));
        if (details?.role?.includes('master-admin')) {
          window.location.pathname === '/' && (
            navigate('/admin-rewards')
          )
        }
        else if (details?.role?.includes('admin') || details?.role?.includes('super-admin')) {
          window.location.pathname === '/' && (
            navigate('/reports')
          )
        } else if (details?.role?.includes('faculty') || details?.role?.includes('counsellor') || details?.role?.includes('coordinator')) {
          window.location.pathname === '/' && (
            navigate('/my-subjects')
          )
        }
        else {
          window.location.pathname === '/' && (
            navigate('/my-learning')
          )
        }
      }
      else {
        window.location.pathname === '/' && (
          navigate('/login')
        )
      }

    } else {
      (window.location.pathname === '/') && (
        navigate('/login')
      )
    }
  }, [])

  if (process.env.NODE_ENV === 'production') {
    console.log = function no_console() { };
  }

  return (
    <div >
      {
        // (localStorage.getItem('loginToken') === null && window.location.pathname !== '/signup') ?
        //   <Navigate to='/login' /> :
        <Routes>
          {<Route path='/login' element={<LoginModule />} />}
          {<Route path='/privacy-policy' element={<PrivacyPolicy />} />}
          {<Route path='/new-horizon-public-school' element={<Nhps />} />}
          {<Route path='/new-horizon-gurukul' element={<Nhgs />} />}
          {<Route path='/new-horizon-international-school' element={<Nhis />} />}

          {/* <Route path='/signup' element={<Signup />} /> */}
          <Route element={<PrivateRoute />} >
            <Route element={<SidebarLayout />} >
              {(RouteItems || []).map((item) => {
                if (item.layout === "mainLayout") {
                  return (
                    <Route
                      key={item.path}
                      path={item.path}
                      element={item.component}
                    />
                  );
                }

              })
              }
            </Route>
          </Route>
          {/* <Route path='*' element={<Notfound />} /> */}
        </Routes>
      }
    </div>
  );
}

export default App;
