import React, { Fragment } from 'react';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import html2pdf from 'html2pdf.js';
import { convertDate, formatAMPM, getInterest } from '../../utils/miscelleneous';
import { useSelector } from 'react-redux';


export default function TestReport({ loading, }) {

    const { testReport } = useSelector(state => state.report);

    const { name, image, createdAt, chart, top3, lastThree, education, interest, interestedJobs } = testReport;

    const downloadReportPdf = () => {
        const element = document.getElementById('report');
        var opt = {
            format: 'A4',
            orientation: 'landscape', // Can also be 'landscape'

            margin: [0.5, 0.05, 0.5, 0.05],
            filename: `${name}-riasec.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 1,
                useCORS: true, // Enable this for cross-origin images
                logging: true
            },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();
    }

    const contentCompletionGraph = {
        chart: {
            type: 'bar',
            width: 550,
            height: 250,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: chart.map(el => el.name),
            title: {
                text: null
            },

            labels: {

                align: 'left',
                reserveSpace: true,
                useHTML: true,
                style: {
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    color: '#696873',
                    align: 'left'
                }
            }
        },
        yAxis: {
            visible: true,
            title: false,
            // labels: {
            //     enabled: false,
            // },
            gridLineColor: 'transparent',
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold'
                }
            },
        },

        plotOptions: {
            series: {
                borderRadius: 0,
                dataLabels: {
                    align: 'right',
                    format: '{y}%',
                    color: '#ffffff',
                    x: -10
                },
                pointPadding: 0.12,
                groupPadding: 0
            },
            bar: {
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: false,
                    padding: 0,
                    allowOverlap: true,
                    formatter: function () {
                        var pcnt = (this.y);
                        if (pcnt > 0) {
                            return '<tspan style="color:' + this.color + '">' + pcnt + '</tspan>';
                        }
                        else {
                            return '';
                        }
                    }
                }
            }
        },

        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                data: chart
            }
        ]

    }

    return (
        <Fragment>

            {loading ? 'Loading...' :
                <div className='test-report-holder' id='report'>

                    <h2>Career Assessment Test Report
                        <div className='btns'>
                            <button onClick={() => downloadReportPdf()}>Download PDF</button>&nbsp;
                            {/* <button>Share</button> */}
                        </div>
                    </h2>
                    <hr style={{ marginTop: 12, marginRight: 0, border: '0px solid #0000001A' }} />
                    <div className='user-details d-flex justify-content-between'>
                        <div>
                            <h5>Test taken by : <span style={{ fontWeight: 700 }}> {name}</span></h5>
                            <h5>Test taken on : <span style={{ fontWeight: 700 }}> {convertDate(createdAt)}</span></h5>
                            <h5>Test timing : <span style={{ fontWeight: 700 }}> {formatAMPM(createdAt)}</span></h5>
                        </div>
                        <div>
                            <img src={image} alt='' width={160} />
                        </div>
                    </div>
                    <div className='career-header'>
                        Your Career Interests
                    </div>
                    <div className='career-interests'>
                        <p>Results are in. Results are in a bar graph with six categories: Realistic (R), Investigative (I), Artistic (A), Social (S), Enterprising (E), and Conventional (C). The taller the bar, the stronger your interest in that category. Check out your graph to discover your top interests and potential career paths. Use it as a guide to embrace your unique strengths and explore exciting possibilities ahead!"</p>
                        <div>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={contentCompletionGraph}
                            />
                        </div>
                    </div>
                    <div className="html2pdf__page-break"></div>
                    <div className='career-header'>
                        Your top three interest areas
                    </div>
                    <div className='career-interests'>
                        <div className='d-flex align-items-center flex-wrap'>
                            {
                                top3 && top3?.map((el, i) => {
                                    return (
                                        <div className='career-interest-progress'>
                                            <div style={{ padding: 20, width: '25%', backgroundColor: el.type === 'Realistic' ? "#F1FEEB" : el.type === 'Invetigative' ? '#EBFAFE' : el.type === 'Artistic' ? "#EBF4FE" : el.type === 'Social' ? "#FFF3F3" : el.type === 'Enterprising' ? '#FFEBE1' : "#FDF8DF", }}>
                                                <div className="progress-circular">
                                                    <CircularProgressbarWithChildren
                                                        value={el.value}
                                                        maxValue={100}
                                                        minValue="0"
                                                        styles={{
                                                            root: {
                                                                width: "100%",
                                                                fontSize: 10,
                                                                borderRadius: 150,
                                                                marginTop: 0,
                                                                background: "#fff",
                                                                verticalAlign: "middle",
                                                                position: "relative",
                                                            },
                                                            path: {
                                                                strokeLinecap: "square",
                                                                stroke: el.type === 'Realistic' ? "#38A071" : el.type === 'Investigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e",
                                                                strokeWidth: 8,
                                                            },
                                                            trail: {
                                                                // Trail color
                                                                stroke: "#F2f2f3",
                                                                strokeWidth: 8,
                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: "round",
                                                            },
                                                        }}
                                                    >
                                                        <div
                                                            className="text-center"
                                                        >
                                                            <p className='text-center text-uppercase' style={{ fontWeight: 600, fontSize: 14, color: el.type === 'Realistic' ? "#38A071" : el.type === 'Investigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e", marginBottom: 10 }}>
                                                                {el.type}
                                                            </p>
                                                        </div>
                                                    </CircularProgressbarWithChildren>

                                                </div>
                                            </div>
                                            <div style={{ width: '45%', marginLeft: 30 }}>
                                                <h6 style={{ color: el.type === 'Realistic' ? "#38A071" : el.type === 'Investigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e" }}>{el.type} - {el.value}%</h6>
                                                <p>
                                                    {getInterest(el.type, 'interest')}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    {
                        top3 && top3.concat(lastThree).map((el, i) => {
                            return (
                                <div className='suggested-career'>
                                    <div className="html2pdf__page-break"></div>
                                    <div className='d-flex div1' >
                                        {el.type === 'Realistic' ?
                                            <div className='elite-text-vertical'>
                                                <span className='characters'>C</span>
                                                <span className='characters'>I</span>
                                                <span className='characters'>T</span>
                                                <span className='characters'>S</span>
                                                <span className='characters'>I</span>
                                                <span className='characters'>L</span>
                                                <span className='characters'>A</span>
                                                <span className='characters'>E</span>
                                                <span className='characters'>R</span>
                                            </div> :
                                            el.type === 'Investigative' ?
                                                <div className='elite-text-vertical' style={{ background: '#EBFAFE' }}>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>E</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>V</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>T</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>A</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>G</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>T</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>S</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>E</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>V</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>N</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                </div> :
                                                el.type === 'Artistic' ?
                                                    <div className='elite-text-vertical' style={{ background: '#EBF4FE' }}>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>C</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>I</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>T</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>S</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>I</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>T</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>R</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>A</span>
                                                    </div> :
                                                    el.type === 'Social' ?
                                                        <div className='elite-text-vertical' style={{ background: '#FFF3F3' }}>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>L</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>A</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>I</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>C</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>O</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>S</span>
                                                        </div> :
                                                        el.type === 'Enterprising' ?
                                                            <div className='elite-text-vertical' style={{ background: '#FFEBE1' }}>
                                                                <span className='characters' style={{ color: "#EE824B" }}>G</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>N</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>I</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>S</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>I</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>R</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>P</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>R</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>E</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>T</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>N</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>E</span>
                                                            </div> :
                                                            <div className='elite-text-vertical' style={{ background: '#FDF8DF' }}>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>L</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>A</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>O</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>I</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>T</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>E</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>V</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>O</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>C</span>
                                                            </div>
                                        }
                                        <div className='suggest-job-roles' style={{ background: el.type === 'Realistic' ? '' : el.type === 'Investigative' ? "#EBFAFE" : el.type === 'Artistic' ? '#EBF4FE' : el.type === 'Social' ? '#FFF3F3' : el.type === 'Enterprising' ? '#FFEBE1' : "#FDF8DF" }}>
                                            <div style={{ width: window.innerWidth > 767 ? '30%' : '100%' }}>
                                                <p>{getInterest(el.type, 'suggested')}</p>
                                            </div>
                                            <div className='job-roles row'>
                                                <h6>Job role</h6>
                                                {
                                                    getInterest(el.type, 'jobrole')?.map((el1, i) => {
                                                        return (
                                                            <div className='col-md-6 col-12'>
                                                                <div className={el.type === 'Realistic' ? 'job-div' : el.type === 'Investigative' ? 'job-div-i' : el.type === 'Artistic' ? 'job-div-a' : el.type === 'Social' ? 'job-div-s' : el.type === 'Enterprising' ? 'job-div-e' : el.type === 'Conventional' ? 'job-div-c' : null}>
                                                                    {el1}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>}
        </Fragment >
    )
}
