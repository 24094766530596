import { updateActiveStatus } from "../../utils/miscelleneous";
import {
    GET_COUN_QUESTION, GET_ONE_QUESTION, SELECT_COUN_ANSWER, GET_REPORT_LIST, GET_REPORT, GET_ONE_REPORT, RESET_REPORT, GET_INTERESTS, RESET_LIST_LOADER, GET_SLOT_LIST, CHANGE_SLOT_STATUS, GET_ALL_BOOKINGS, GET_BOOKINGS, GET_BOOKING_SLOTS,
    RESET_ABILITY_QUESTIONS, GET_ABILITY_DETAILS, GET_ABILITY_LIST, GET_ABILITY_QUESTIONS, CHANGE_VALUE, SAVE_TEST,
    GET_TEST_GRAPH
} from "../Types/types";




let intialState = {
    loading: true,
    isLoading: true,
    loadingAbility: false,
    loadingAbilityQues: false,
    questions: []
}


export default function counsellingReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_COUN_QUESTION:
            stateCopy.questions = action.payload.map(el => ({ ...el, score: null }));
            stateCopy.question = stateCopy.questions[0];
            return stateCopy;
        case GET_ONE_QUESTION:
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            return stateCopy;
        case SELECT_COUN_ANSWER:
            stateCopy.questions.forEach(el => {
                if (el._id === action.payload.qid) {
                    el.score = action.payload.id
                }
            })
            return stateCopy;
        case GET_REPORT_LIST:
            stateCopy.reportList = action.payload;
            return stateCopy;
        case RESET_REPORT:
            stateCopy.loading = true;
            return stateCopy;
        case GET_REPORT:
            const data = getDetailReport(action.payload.riasec);
            stateCopy.report = data.arrayOfObjects;
            stateCopy.testReport = { ...action.payload, chart: data.chart.filter(el => el.name !== '_id'), lastThree: data.last3, top3: data.arrayOfObjects };
            stateCopy.loading = false;
            return stateCopy;
        case GET_ONE_REPORT:
            const obj = stateCopy.reportList.data.filter(el => el._id === action.payload)[0];

            const data1 = getDetailReport(obj.riasec);
            stateCopy.report = data1.arrayOfObjects;
            stateCopy.testReport = { ...obj, chart: data1.chart.filter(el => el.name !== '_id'), lastThree: data1.last3, top3: data1?.arrayOfObjects, name: stateCopy.reportList.name };
            stateCopy.loading = false;
            return stateCopy;
        case GET_INTERESTS:
            const uniqueInterests = {};
            action.payload.data?.forEach(item => {
                uniqueInterests[item.interest] = item;
            });
            const uniqueInterestObjects = Object.values(uniqueInterests);
            stateCopy.interests = uniqueInterestObjects;
            stateCopy.match = action.payload.match;
            return stateCopy;
        case RESET_LIST_LOADER:
            stateCopy.isLoading = true;
            return stateCopy;
        case GET_SLOT_LIST:
            stateCopy.slots = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case CHANGE_SLOT_STATUS:
            stateCopy.slots = updateActiveStatus(stateCopy.slots, action.payload._id, action.payload.month, action.payload.active);
            return stateCopy;
        case GET_ALL_BOOKINGS:
            stateCopy.allBookings = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_BOOKINGS:
            stateCopy.bookingList = action.payload;
            return stateCopy;
        case GET_BOOKING_SLOTS:
            stateCopy.bookingSlots = action.payload;
            return stateCopy;
        case RESET_ABILITY_QUESTIONS:
            stateCopy.loadingAbilityQues = true;
            return stateCopy;
        case GET_ABILITY_LIST:
            stateCopy.abilities = action.payload;
            stateCopy.loadingAbility = false;
            return stateCopy;
        case GET_ABILITY_QUESTIONS:
            if (action.payload.type === 'response') {
                stateCopy.questions = action.payload.data;
            } else {
                stateCopy.questions = action.payload.data.map(el => [{ ...el, answer: null }][0]);
            }
            stateCopy.loadingAbilityQues = false;
            return stateCopy;
        case CHANGE_VALUE:
            stateCopy.questions.forEach(el => {
                if (el._id === action.payload.id) {
                    el.answer = action.payload.ansId
                }
            });
            return stateCopy;
        case SAVE_TEST:
            return stateCopy;
        case GET_ABILITY_DETAILS:
            stateCopy.abilityDetails = action.payload.data;
            return stateCopy;
        case GET_TEST_GRAPH:
            console.log(action.payload)
            if (action.payload.type === 'riasec') {
                const obj = stateCopy.reportList.data.filter(el => el._id === action.payload)[0];

                const data1 = getDetailReport(obj.riasec);
                stateCopy.report = data1.arrayOfObjects;
                stateCopy.testReport = { ...obj, chart: data1.chart.filter(el => el.name !== '_id'), lastThree: data1.last3, top3: data1?.arrayOfObjects, name: stateCopy.reportList.name };
                stateCopy.loading = false;
            } else {
                stateCopy.abilityTrends = action.payload.data;
            }

            console.log(stateCopy.abilityTrends)
            return stateCopy;
        default:
            return stateCopy;
    }
}

const getDetailReport = (obj) => {
    const skillsArray = Object.entries(obj);
    skillsArray.sort((a, b) => b[1] - a[1]);
    const chart = skillsArray.map(([name, y], index) => ({
        name,
        y,
        color: name === "realistic" ? '#35996D' : name === 'investigative' ? '#72B7D3' : name === 'artistic' ? '#1D86A3' :
            name === 'social' ? "#DC5E5F" : name === "enterprising" ? '#EE824B' : "#DEC02E"
    }));

    // Step 3: Take the top three elements
    const topThree = skillsArray.slice(0, 3);
    const lastThree = skillsArray.slice(3, 6);
    const arrayOfObjects = topThree.map(entry => ({
        type: entry[0].charAt(0).toUpperCase() + entry[0].slice(1),
        value: entry[1]
    }));
    const last3 = lastThree.map(entry => ({
        type: entry[0].charAt(0).toUpperCase() + entry[0].slice(1),
        value: entry[1]
    }));
    return { arrayOfObjects, chart, last3 };
}



