import React, { Component, Fragment, useEffect, useState } from 'react';
import './notes.scss';
import { DropdownButton } from 'react-bootstrap';
import Notespopup from './notespopup';
import { getNotes, closePopup, getNotesList, clearFilter, onLikeClick, getFilters, onProgramFilterClick, onSubjectFilterClick, onDateFilterClick, getFilteredResult, getSearchResult, savedNote } from '../../Redux/ActionCreators/notesAction';
import Pagination from "react-js-pagination";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { openShare } from '../../Redux/ActionCreators/shareAction';
import SharePopup from '../Share-popup/sharePopup'



function NotesModule() {
    const [query, setQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [id, setId] = useState('');

    const dispatch = useDispatch();

    const { isLoading, notes, note, isNote, subjectFilter, dateFilter, notesPerPage, notesTotalPage, fromDate, toDate, subjectSelected, } = useSelector(state => state.notes);

    useEffect(() => {
        dispatch(getFilters());
        dispatch(getNotesList(pageNumber, query, subjectFilter, fromDate, toDate));
    }, [])


    const openNotes = async (el) => {
        await dispatch(getNotes(el));
    }

    const closeDetails = () => {
        dispatch(closePopup());
    }


    const getSubject = (id) => {
        const prg = subjectFilter?.filter(el => el._id === id)[0];
        return prg !== undefined && prg.name;
    }

    useEffect(() => {
        dispatch(getNotesList(pageNumber, query, subjectFilter, fromDate, toDate));
    }, [id])

    const notesPagination = (page) => {
        setPageNumber(page);
        dispatch(getNotesList(page, query, subjectFilter, fromDate, toDate));
    }



    const subjectFilterClick = async (id, value, filter) => {
        setId(filter ? id : '');
        await dispatch(onSubjectFilterClick(id, value));
    }

    const dateFilterClick = async (code, value) => {
        await dispatch(onDateFilterClick(code, value));
    }

    useEffect(() => {
        dispatch(getNotesList(pageNumber, query, subjectFilter, fromDate, toDate));
    }, [fromDate])

    const timerRef1 = React.useRef(null);

    const searchQuery = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPageNumber(1)
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }


    const delaySearch = async (val) => {
        await dispatch(getNotesList(1, val, subjectFilter, fromDate, toDate));
    }


    const saveNote = async (id, type, chapter, subject, note) => {
        const save = {
            resource_id: id,
            type: type,
            subject_id: subject,
            chapter: chapter,
            text: note
        }
        await dispatch(savedNote(save));
    }

    const clearFilters = async () => {
        setPageNumber(1);
        await dispatch(clearFilter());
        await dispatch(getNotesList(1, '', [], '', ''));
    }

    const likeClick = (id, type, liked) => {
        dispatch(onLikeClick(id, type, liked));
    }

    return (
        <div className='notes-holder'>

            <div className='notes-top-holder'>
                <h3>My Notes</h3>
                <div className="has-search"  >
                    <span className="form-control-feedback">
                        <img src="/images/search.svg" width="14" alt='' />
                    </span>
                    <input placeholder='Search for topic/title/content or notes' id='search' value={query} onChange={(e) => searchQuery(e.target.value)} autoComplete='off' className='form-control' />
                </div>
                <div style={{ marginRight: window.innerWidth > 767 ? 25 : -15 }}>
                    {/* <DropdownButton id="dropdown-basic-button" title="Program">
                        <div>
                            <ul className="subscribe">
                                {
                                    programFilter !== undefined ?
                                        programFilter.map((items, i) => {
                                            return (
                                                <li onClick={() => programFilterClick(items._id, items.value, true)}>
                                                    <div className="checkbox-square">
                                                        {
                                                            items.value ? <img src="/images/tick1.svg" alt='' /> : null
                                                        }
                                                    </div>
                                                    <h5 style={{ minWidth: `${180 + items.name.length}px` }}  >{items.name}</h5>
                                                </li>
                                            )
                                        }) : null
                                }


                            </ul>
                        </div>
                    </DropdownButton> */}
                    <DropdownButton id="dropdown-basic-button" title="Subject">
                        <div>
                            <ul className="type">
                                {
                                    subjectFilter !== undefined ?
                                        subjectFilter.map((items, i4) => {
                                            return (
                                                <li onClick={() => subjectFilterClick(items._id, items.value, true)}>
                                                    <div className="checkbox-square">
                                                        {
                                                            items.value ? <img src="/images/tick1.svg" alt='' /> : null
                                                        }
                                                    </div>
                                                    <h5>{items.name}</h5>
                                                </li>
                                            )
                                        }) : null
                                }


                            </ul>
                        </div>
                    </DropdownButton>
                    <DropdownButton id="dropdown-basic-button" title="Date">
                        <div>
                            <ul className="activity">
                                {
                                    dateFilter !== undefined ?
                                        dateFilter.map((items, i) => {
                                            return (
                                                <li onClick={() => dateFilterClick(items.code, items.value)}>
                                                    <div className="checkbox-square">
                                                        {
                                                            items.value ? <img src="/images/tick1.svg" alt='' /> : null
                                                        }

                                                    </div>
                                                    <h5>{items.name}</h5>
                                                </li>
                                            )
                                        }) : null
                                }


                            </ul>
                        </div>
                    </DropdownButton>
                </div>
            </div >
            {
                (subjectSelected !== undefined && subjectSelected.length > 0) ?
                    <div className='clear-filter' style={{ padding: window.innerWidth > 767 ? '' : '3px' }}>
                        <div className='clear'>
                            <p onClick={clearFilters}>Clear Filters</p>
                            <div className='select-list'>
                                <ul>

                                    {
                                        subjectSelected !== undefined && subjectSelected.map((el, index) => {
                                            return (
                                                <li key={index}>{el.name}
                                                    <img src="/images/close1.svg" alt='' onClick={() => subjectFilterClick(el._id, el.value, false)} />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className='notes-list'>
                <ul>
                    {
                        isLoading ?
                            '' :
                            notes && notes.length > 0 ?
                                notes !== undefined && notes.map((el, index) => {
                                    return (
                                        <li onClick={() => openNotes(el)}>
                                            <div className='carusole-card'>
                                                <div className='row'>
                                                    <div className='col-md-11 col-10 d-flex'>
                                                        <img src={el.type === 'Document' ? '/images/error-image.jpg' : el.image} onError={(e) => {
                                                            if (e.target.src !== "/images/error-image.jpg") {
                                                                e.target.onerror = "/images/error-image.jpg";
                                                                e.target.src = "/images/error-image.jpg";
                                                            }
                                                        }} width='80px' height='60px' alt='' />&nbsp;&nbsp;
                                                        <div style={{ width: window.innerWidth > 767 ? '65%' : '65%' }}>
                                                            <h4>{el.title}</h4>
                                                            <p>{el.createdAt?.split('T')[0]}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-1 col-2' style={{ marginLeft: window.innerWidth > 767 ? -25 : 0 }}>
                                                        {
                                                            el.type === "Article" ?
                                                                <img
                                                                    src="/images/article-icon.svg" alt='' /> : el.type === "Document" ?
                                                                    <img
                                                                        src="/images/document-icon.svg" alt='' /> : el.type === "Video" ?
                                                                        <img
                                                                            src="/images/video-icon.svg" alt='' /> : el.type === "Course" ?
                                                                            <img
                                                                                src="/images/course-icon.svg" alt='' /> : el.type === "Webinar" ?
                                                                                <img
                                                                                    src="/images/webinar-icon.svg" alt='' /> : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className='notes-description mt-2'>
                                                    {el.text}
                                                </div>
                                                <small className='mt-2'>{getSubject(el.subject_id) + '/' + el.chapter}</small>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <Fragment>
                                    <div className="no-notes">
                                        <div className="">
                                            {/* <img src="/images/no-results.png" alt='' /> */}
                                            <p>Access all your notes related to topics,subjects anytime you want. <br /> Start adding notes to the content you have accessed.</p>
                                        </div>
                                    </div>
                                </Fragment>
                    }
                </ul>

            </div>
            {
                notesTotalPage > 9 ?
                    <div className="pagination-holder">
                        <Pagination
                            activePage={pageNumber}
                            itemsCountPerPage={notesPerPage}
                            totalItemsCount={notesTotalPage}
                            hideFirstLastPages={true}
                            // pageRangeDisplayed={5}
                            onChange={notesPagination}
                        />
                    </div>
                    : null

            }
            {/* ######################## Notes Popup ########################### */}
            <div className="feeds-details" style={{ display: isNote ? 'block' : 'none' }}>
                <Notespopup
                    activeFeedData={note}
                    saveNote={saveNote}
                    likeClick={likeClick}
                    closeNotes={closeDetails}
                    shareFeed={(data) => dispatch(openShare(data))}
                />

                <SharePopup />
            </div>

        </div >
    )
}



export default NotesModule;