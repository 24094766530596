import React, { Fragment, useEffect, useState } from 'react';
import './sidebarLayout.scss';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
// import { getColorCodes } from '../../Redux/ActionCreators/accountAction';
import { getMenus, getNotifications, trackUserLogin } from '../../Redux/ActionCreators/layoutAction';
import { markedRead } from '../../Redux/ActionCreators/notificationAction';
import { getProfileDetails, updateLanguage, } from '../../Redux/ActionCreators/profileAction';
import { logOutUser } from '../../Redux/ActionCreators/layoutAction';
import { useTranslation } from 'react-i18next';
import NotificationPopover from './notificationPopover';

function SidebarLayout() {

    const [activeTab, setActiveTab] = useState('users');
    const [searchSkill, setSearchSkill] = useState('');
    // const [profile_image, setProfileImage] = useState('/images/enoch.png');
    const [showPopover, setShowPopover] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isHide } = useSelector(state => state.manageContent);
    const { menuList, notifications, unread } = useSelector(state => state.layout);
    const { profileDetails } = useSelector(state => state.profile);
    const error = useSelector(state => state.error);

    const isTabletOrBigger = window.matchMedia("(min-width: 1200px)").matches;

    useEffect(() => {
        const firstRender = async () => {
            await dispatch(updateLanguage(localStorage.getItem('i18nextLng')))
            await dispatch(getMenus());
            dispatch(trackUserLogin());
            dispatch(getProfileDetails());
            updateDimensions();
            setActiveTab(window.location.pathname.split('/')[1]);
            // localStorage.getItem('role') !== 'student' && await dispatch(getColorCodes());

            const root = document.documentElement;

            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
        }
        firstRender();
    }, [])


    useEffect(() => {
        dispatch(getNotifications())

        if (window.innerWidth < 767 && !window.location.href?.includes('search')) {
            $("#search-icon").hide();
            $("#filter-btn").hide();
            $("#misc-div").hide();
            setSearchSkill('');
        }
    }, [activeTab]);


    const resetPopover = () => {
        setShowPopover(false);
    };


    const goToNotification = () => {
        navigate('/notifications');
    }

    const goToClassroom = () => {
        // dispatch(showSideBar());
        navigate('/library');
    }

    const goToClassroom1 = () => {
        window.location.href = '/library';
    }


    const redirectToResources = (id, r_id, type, read, action) => {
        if (read) {
            (localStorage.getItem('role') === 'student') ?
                window.location.href = `/my-learning?_id=${r_id}&type=${type}` : window.location.href = `/my-content?_id=${r_id}&type=${type}`
        }
        else {
            (localStorage.getItem('role') === 'student') ?
                dispatch(markedRead(id, `/my-learning?_id=${r_id}&type=${type}`)) : dispatch(markedRead(id, `/my-content?_id=${r_id}&type=${type}`));
        }
    }

    const redirectToLibrary = (id, r_id, type, read, action) => {
        if (read) {
            window.location.href = `/skill-pods/id=${r_id}`;
        }
        else {
            dispatch(markedRead(id, `/skill-pods/id=${r_id}`))
        }
    }

    const redirectToConnect = (id, read) => {
        if (read) {
            window.location.href = '/connect?tab=conversation'
        }
        else {
            dispatch(markedRead(id, '/connect?tab=conversation'))
        }
    }

    const redirectToAchievers = (id, read, action) => {
        if (read) {
            window.location.href = '/achievers'
        }
        else {
            dispatch(markedRead(id, '/achievers'))
        }
    }

    const redirectNoticeBoard = (id, read, action) => {
        if (read) {
            window.location.href = '/notice-board'
        }
        else {
            dispatch(markedRead(id, '/notice-board'))
        }
    }


    const logout = async () => {
        const res = await dispatch(logOutUser());
        if (res?.type === 'LOGOUT_USER') {
            localStorage.clear();
            window.location.href = '/login';
        }
    }

    const isSidebar = () => {
        if ($("body").hasClass("BodyCloseNav")) {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        } else {
            document.body.classList.add("BodyCloseNav");
            document.body.classList.remove("scrollhidden");
        }
    };

    const updateDimensions = () => {
        let w = window.screen.width;
        if (w <= 1024) {
            document.body.classList.add("BodyCloseNav");
        } else {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        }
    };

    const isTabActive = (pathname) => {
        setActiveTab(pathname);
        if (!isTabletOrBigger) {
            document.body.classList.add("BodyCloseNav");
            document.body.classList.remove("scrollhidden");
        }
    }


    const getIntial = (fname, lname) => {
        const i1 = fname?.split('')[0]
        const i2 = lname?.split('')[0]
        return i1 + i2
    }






    const redirectProfile = () => {
        navigate('/profile');
    }

    const redirectFaq = () => {
        window.open("https://elitelearning-nh.freshdesk.com/support/home", '_blank');
    }





    const searchOpen = () => {
        $("#misc-div").toggle();
        if (window.location.href.split("/")[3] === "search") {
            $("#filter-btn").toggle();
            $(".suggestion-container").hide();
        } else {
            $("#search-icon").toggle();
            focusMethod();
            $(".suggestion-container").hide();
        }
    };



    const showFilter = () => {
        $("#mobileFilter").slideDown("slow");
    }

    const closeFilters = () => {
        $("#mobileFilter").slideUp();
    }


    const focusMethod = function getFocus() {
        document.getElementById("myTextField").focus();
    };

    const onChangeSearchSkills = async (val) => {
        setSearchSkill(val);
        if (val === '') {
            closeSuggestion();
        }
        else {
            // await props.getSuggestions(val);
            // await props.openSuggestion();
        }
    };

    const onkeypressSearchSkills = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setSearchSkill(e.target.value);
            // $(".suggestion-container").hide();
            navigate(`/search?query=${e.target.value}`)
        }
    };

    const closeSuggestion = () => {
        // props.closeSuggestions()
    }


    const snackClosed = () => {
        dispatch(clearErrors());
    }


    return (
        <Fragment >
            {/* {
                (localStorage.getItem('role') === 'student' || localStorage.getItem('role') === 'trainee' || localStorage.getItem('role') === 'corporate') ?
                    window.location.pathname === '/' && (
                        navigate('/library')
                    ) :
                    window.location.pathname === '/' && (
                        navigate('/my-syllabus')
                    )
            } */}
            {/* {
                isIntroJsAndMobileView === false &&
                    isIntroJsComplete === false
                    ? (
                        <div>
                            <Steps
                                enabled={true}
                                steps={localStorage.getItem('role') === 'admin' ? adminSteps : (localStorage.getItem('role') === 'student') ? steps : (localStorage.getItem('role') === 'trainee' || localStorage.getItem('role') === 'corporate') ? traineeSteps : localStorage.getItem('role') === 'faculty' ? facultySteps : localStorage.getItem('role') === 'training manager' ? tmSteps : smeSteps}
                                initialStep={initialStep}
                                onExit={() => onExit()}
                                options={options}
                                // tooltipClass="intro-comp"
                                onAfterChange={() => changeGet()}
                            />
                        </div>
                    ) : null
            } */}
            {
                // isHide || isShow || isShow1 || isShowSideBar || (window.innerWidth > 767 && window.location.pathname === '/notifications') || window.location.pathname === '/start-test' || (window.innerWidth > 767 && window.location.pathname === '/search') || 
                (isTabletOrBigger && window.location.pathname === '/profile') || isHide ? '' :
                    <div className="side-bar">

                        <div className="logo-dashboard">
                            <div className='logo'  >
                                <div style={{ backgroundImage: `url("${localStorage.getItem('role') === 'master-admin' ? 'https://elite-logos.s3.amazonaws.com/Edsanta%20Education_1655277805123.jpeg' : localStorage.getItem('logo')}")`, backgroundPosition: "center", backgroundRepeat: 'no-repeat', padding: 32, backgroundSize: 'contain', width: 110 }}>

                                </div>

                            </div>
                        </div>
                        <div className="menu-close">
                            <button
                                type="button"
                                className="close"
                                onClick={() => isSidebar()}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='profile-overflow'>
                            {(
                                <div className="profile-side-bar">
                                    {profileDetails?.profile_image ?
                                        <div className="profile-side-bar-avatar" style={{
                                            backgroundImage: `url('${profileDetails?.profile_image}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: 55,
                                            width: 55, borderRadius: 50
                                        }} >
                                        </div> :
                                        <div className="profile-side-bar-avatar"  >
                                            {profileDetails?.initial !== null ? (
                                                <div style={{ background: '#5855d6' }} className="initial-circle">{profileDetails?.initial}</div>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    }


                                    <div className="profile-details">
                                        <h3>{profileDetails?.firstname} {profileDetails?.lastname}</h3>
                                        {
                                            (localStorage.getItem('role') === 'student') ?
                                                <h4 >
                                                    Class {profileDetails !== undefined && profileDetails?.class}
                                                </h4> :
                                                <h4 className='text-uppercase'>
                                                    {localStorage.getItem('role')?.includes('master') ? 'Master Admin' : localStorage.getItem('role') === 'coordinator' ? 'Coordinator' : localStorage.getItem('role') === 'admin' ? 'Admin' : localStorage.getItem('role') === 'faculty' ? 'Teacher' : localStorage.getItem('role')}
                                                </h4>
                                        }

                                        {/* {subscription && subscription !== "Free" ?
                                    <h4 style={{color: '#c90037'}}>{subscription} Plan
                                        - {subscription_days_left} days left</h4> : null} */}
                                    </div>
                                </div>
                            )}
                            <div></div>

                            {/* {
                                profile && profile?.section_rank > 0 ?
                                    (localStorage.getItem('role') === 'student') && (
                                        <div className="profile-score">
                                            <div className="engagement-layout-holder">
                                                <h4>Engagement Score: </h4>
                                                <h5>
                                                    {profile?.engagement_score}
                                                </h5>
                                                <div className="engagement-logo">
                                                    <img src="/images/increase.svg" alt='' />
                                                </div>
                                            </div>profileDetails
                                            <div className="engagement-layout-holder">
                                                <h4>Ranking </h4>
                                                <h5>
                                                    {profile?.section_rank}
                                                </h5>
                                                <div className="engagement-logo" style={{ paddingTop: 5 }}>
                                                    <img src="/images/engagement-logo.svg" alt='' />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : null
                            } */}

                            {/* {profileDetails !== undefined && profileDetails.section_rank == null ? (
                                <div className="profile-score1">
                                    <div className="engagement-layout-holder">
                                        <h4>Engagement Score </h4>
                                        <h5>
                                            {profileDetails?.engagement_score}
                                        </h5>
                                    </div>
                                    <div className="engagement-logo">
                                        <img src="/images/increase.svg" alt='' />
                                    </div>
                                </div>
                            ) : null} */}

                            <div className="dashboard-links">
                                <ul>
                                    {menuList.map((items, i) => {
                                        if ((items.action === 'career-assessment') && ((profileDetails?.class !== 8) && (profileDetails?.class !== 9) && (profileDetails?.class !== 10))) {
                                            return null
                                        }
                                        else if (window.innerWidth < 767 && items.action === 'contests') {
                                            return null
                                        }
                                        return (
                                            <Link
                                                to={`/${items.action}`}
                                                onClick={() => isTabActive(items.action)}
                                            >
                                                <li
                                                    id={items.id}
                                                    key={i}
                                                    className={
                                                        activeTab === items.action
                                                            ? "dashboard-active"
                                                            : null
                                                    }
                                                >

                                                    <img
                                                        src={activeTab === items.action ? `/images/${items.active_icon}` : `/images/${items.icon}`}
                                                        alt=''
                                                    />
                                                    {t(`${items.text}`)}
                                                </li>
                                            </Link>
                                        );
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
            }
            {
                <div className="dashboard-header">
                    <div className="Colapselink">
                        <button
                            className="btn rounded-circle  d-block"
                            onClick={() => isSidebar()}
                        >
                            <img src="/images/ham.svg" alt='' />
                        </button>
                    </div>

                    <div className="dashboard-header-heading">
                        <div
                            className="mobile-logo d-block d-md-none"

                        >
                            <div className='logo' style={{ marginTop: -5, marginBottom: 0 }} >
                                <div
                                    style={{ backgroundImage: `url("${localStorage.getItem('logo')}")`, backgroundPosition: "center", backgroundRepeat: 'no-repeat', padding: 22, backgroundSize: 'contain', width: 130, cursor: 'pointer' }}>
                                </div>
                                {/* <img src={localStorage.getItem("logo")} className="logo" /> */}
                            </div>
                        </div>
                        <div className="searchIcon " onClick={() => searchOpen()}>
                            <img
                                src="/images/search-icon1.svg"
                                width="37px"
                                height="37px"
                                alt=''
                            />
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        {
                            window.innerWidth > 767 &&
                            <div className="dashboard-profile-holder" style={{ display: isHide ? 'none' : '' }}>
                                <a href='https://elitelearning-nh.freshdesk.com/support/home' target='_blank' rel='noreferrer'>
                                    <div className="dropmain-holder1 d-inline-block cursor-pointer" style={{ marginTop: 12 }} >
                                        <img src='/images/help-icon.svg' alt='' width={25} />
                                    </div>
                                </a>
                            </div>
                        }
                        {
                            <NotificationPopover
                                isHide={isHide}
                                myData={notifications}
                                redirectToAchievers={redirectToAchievers}
                                redirectNoticeBoard={redirectNoticeBoard}
                                redirectToResources={redirectToResources}
                                redirectToConnect={redirectToConnect}
                                goToNotification={goToNotification}
                                redirectToLibrary={redirectToLibrary}
                                resetPopover={resetPopover}
                                unread={unread}
                            />

                        }


                        <div className="dashboard-profile-holder" style={{ visibility: isHide ? 'hidden' : '' }}>
                            <div className="dropmain-holder d-inline-block">
                                <div
                                    className="drop-profile-hold initial-circle-custom"
                                    style={{ background: profileDetails?.profile_image ? '' : '#5855d6' }}
                                >
                                    {profileDetails?.profile_image ? (
                                        <img
                                            src={profileDetails?.profile_image} alt=''
                                        />
                                    ) : profileDetails?.initial ? (
                                        profileDetails?.initial
                                    ) : null
                                    }
                                </div>
                                <div className="drop-inst">
                                    <div className="drop-banner">
                                        <div
                                            className="profile-banner-drop"
                                            style={{ background: profileDetails?.profile_image ? '' : '#5855d6' }}

                                        >
                                            {profileDetails?.profile_image !== null &&
                                                profileDetails?.profile_image !== "" ? (
                                                <img src={profileDetails?.profile_image} alt='' />
                                            ) : profileDetails?.initial !== undefined ? (
                                                <div style={{ color: '#fff', fontSize: 18 }} >{profileDetails?.initial}</div>
                                            ) : null}
                                            {/* {profile.firstName.split('')[0]}{profile.lastName.split('')[0]} */}

                                        </div>
                                        <div className="drop-profile-name">
                                            {profileDetails?.firstname} {profileDetails?.lastname}
                                        </div>
                                    </div>
                                    <ul>
                                        <li onClick={() => redirectProfile()}>
                                            <div className="icons-holder">
                                                <i className="fa fa-address-book-o"></i>
                                            </div>
                                            <div className="list-text">
                                                <h4>{t('My Profile')}</h4>
                                                {/* <p>Account settings and more</p> */}
                                            </div>
                                        </li>
                                        {
                                            window.innerWidth > 767 ? null :
                                                <li onClick={() => redirectFaq()}>
                                                    <div className="icons-holder">
                                                        <img src='/images/faq.svg' alt='' />&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    <div className="list-text">
                                                        <h4>{t('Faq')}</h4>
                                                        <p>{t('How can we help you?')}</p>
                                                    </div>
                                                </li>
                                        }
                                        {/* {
                                            window.innerWidth > 767 ? null :
                                                <li>
                                                    <div className="icons-holder">
                                                        <img src='/images/video.svg' alt='' />&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    <div className="list-text">
                                                        <h4>Product video</h4>
                                                    </div>
                                                </li>
                                        } */}

                                    </ul>

                                    {/* // : null} */}

                                    <div className="logout" onClick={() => logout()}>
                                        {t('Sign Out')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='search1' style={{ visibility: 'hidden' }}>
                        <div className="has-search none" id="search-icon" style={{ width: '100%' }} >
                            <span className="form-control-feedback">
                                <img src="/images/search.svg" width="18" height='18px' alt='' />
                            </span>
                            <input
                                type="search"
                                className="form-control"
                                id="myTextField"
                                placeholder="Search for Subjects and Topics"
                                autoComplete="off"
                                value={searchSkill}
                            // debounceTimeout={800}
                            // onChange={(e) => onChangeSearchSkills(e.target.value)}
                            // onKeyPress={(e) => onkeypressSearchSkills(e)}
                            />
                        </div>

                    </div>
                    <div className="logo1"  >
                        <div className='logo' style={{ pointerEvents: isHide ? 'none' : 'auto', marginBottom: -2 }} onClick={() => navigate(-1)} >
                            <div style={{ backgroundImage: `url("${localStorage.getItem('role') === 'master-admin' ? 'https://elite-logos.s3.amazonaws.com/Edsanta%20Education_1655277805123.jpeg' : localStorage.getItem('logo')}")`, backgroundPosition: "center", backgroundRepeat: 'no-repeat', padding: 26, backgroundSize: 'contain', width: 130 }}>
                            </div>
                        </div>
                    </div>
                    {/* <div className='search1'>
                        <div className="input-fixed d-md-none d-lg-none" id="filter-btn" style={{ display: searchOpen ? 'flex' : 'none' }}>
                            <div className="left has-search">
                                <span className="form-control-feedback">
                                    <img src="/images/search.svg" width="18" height='18px' alt='' />
                                </span>

                                <input
                                    type="search"
                                    className="form-control"
                                    id="myTextField"
                                    autoComplete="off"
                                    placeholder="Search for subejct and topics"
                                    debounceTimeout={300}
                                    value={searchSkill}
                                // onChange={(e) => onChangeSearchSkills(e.target.value)}
                                // onKeyPress={(e) => onkeypressSearchSkills(e)}
                                />
                            </div>
                            <button
                                onClick={() => showFilter()}
                                className="btn btn-outline-primary"
                                id="filter"
                            >
                                <img
                                    src="/images/filter-icon.svg"
                                    width="40px"
                                    height="28px"
                                    alt=''
                                />
                            </button>
                        </div>
                       
                    </div> */}
                </div>
            }
            <div
                style={{
                    width: "100%",
                    height: 46,
                    display: "none",
                    top: 71,
                    backgroundColor: "white",
                }}
                id="misc-div"
            ></div>
            <div className='layout-container' style={{ paddingLeft: ((window.innerWidth < 767) || window.location.pathname === '/my-learning/topics' || window.location.pathname === '/my-subjects/topics' || window.location.pathname === '/content-library/topics') ? 0 : 321 }}  >
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successMessage}
                    autoHideDuration={3000}
                    onClose={snackClosed}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <React.Suspense fallback={''}>
                    <Outlet />
                </React.Suspense>
            </div>
            {
                // (props.showQuiz || props.showQuiz1) ? null :
                <div className="mobilefooter-links d-block d-md-none">
                    {
                        (localStorage.getItem('role')?.includes('master') || localStorage.getItem('role') === 'admin') ?
                            <ul>
                                <Link
                                    to='/content-library'
                                    onClick={() => isTabActive('content-library')}

                                >
                                    <li

                                        id="content-library"
                                        className={
                                            activeTab === "content-library"
                                                ? "dashboard-active"
                                                : null
                                        }
                                    >

                                        <img
                                            src="/images/content-layout-icon.svg"
                                            alt=''
                                        />
                                        <small>Content Library</small>
                                    </li>
                                </Link>
                                <Link
                                    to='/users'
                                    onClick={() => isTabActive("users")}
                                >
                                    <li
                                        id="users"
                                        className={
                                            activeTab === "users"
                                                ? "dashboard-active"
                                                : null
                                        }
                                    >
                                        <img
                                            src="/images/classroom-layout-icon.svg"
                                            alt=''
                                        />
                                        <small>Users</small>
                                    </li>
                                </Link>

                                <Link
                                    to='/account'
                                    onClick={() => isTabActive("account")}
                                >
                                    <li
                                        onClick={() => isTabActive("account")}
                                        id="account"
                                        className={
                                            activeTab === "account" ? "dashboard-active" : null
                                        }
                                    >
                                        <img
                                            src="/images/account-layout-icon.svg"
                                            alt=''
                                        />
                                        <small>Account</small>
                                    </li>
                                </Link>
                                <Link
                                    to='/manage-content'
                                    onClick={() => isTabActive("manage-content")}
                                >
                                    <li
                                        onClick={() => isTabActive("manage-content")}
                                        id="manage-content"
                                        className={
                                            activeTab === "manage-content" ? "dashboard-active" : null
                                        }
                                    >
                                        <img
                                            src="/images/manage-content-layout-icon.svg"
                                            alt=''
                                        />
                                        <small>Manage Content</small>
                                    </li>
                                </Link>
                            </ul> :
                            (localStorage.getItem('role')?.includes('faculty') || localStorage.getItem('role')?.includes('coordinator')) ?
                                <ul>
                                    <Link
                                        to='/my-subjects'
                                        onClick={() => isTabActive("my-subjects")}
                                    >
                                        <li
                                            id="my-subjects"
                                            className={
                                                activeTab === "my-subjects"
                                                    ? "dashboard-active"
                                                    : null
                                            }
                                            style={{ width: '25%' }}
                                        >
                                            <img
                                                src="/images/classroom-layout-icon.svg"
                                                alt=''
                                            />
                                            <small>My Subjects</small>
                                        </li>
                                    </Link>
                                    <Link
                                        to='/content-library'
                                        onClick={() => isTabActive('content-library')}

                                    >
                                        <li

                                            id="library"
                                            className={
                                                activeTab === "content-library"
                                                    ? "dashboard-active"
                                                    : null
                                            }
                                            style={{ width: '25%' }}
                                        >

                                            <img
                                                src="/images/content-layout-icon.svg"
                                                alt=''
                                            />
                                            <small>Content Library</small>
                                        </li>
                                    </Link>

                                    <Link
                                        to='/manage-content'
                                        onClick={() => isTabActive("manage-content")}
                                    >
                                        <li
                                            onClick={() => isTabActive("manage-content")}
                                            id="manage-content"
                                            className={
                                                activeTab === "manage-content" ? "dashboard-active" : null
                                            }
                                            style={{ width: '25%' }}
                                        >
                                            <img
                                                src="/images/manage-content-layout-icon.svg"
                                                alt=''
                                            />
                                            <small>Manage Content</small>
                                        </li>
                                    </Link>
                                    <Link
                                        to='/notice-board'
                                        onClick={() => isTabActive("notice-board")}
                                    >
                                        <li
                                            onClick={() => isTabActive("notice-board")}
                                            id="notice-board"
                                            className={
                                                activeTab === "notice-board" ? "dashboard-active" : null
                                            }
                                            style={{ width: '25%' }}
                                        >
                                            <img
                                                src="/images/notice-board-layout-icon.svg"
                                                alt=''
                                            />
                                            <small>Notice Board</small>
                                        </li>
                                    </Link>
                                </ul>
                                :
                                (localStorage.getItem('role') === 'sme' || localStorage.getItem('role') === 'program manager') ?
                                    <ul>
                                        <Link
                                            to='/content-library'
                                            onClick={() => isTabActive('content-library')}

                                        >
                                            <li

                                                id="content-library"
                                                className={
                                                    activeTab === "content-library"
                                                        ? "dashboard-active"
                                                        : null
                                                }
                                            >

                                                <img
                                                    src="/images/content-layout-icon.svg"
                                                    alt=''
                                                />
                                                <small>Content Library</small>
                                            </li>
                                        </Link>
                                        {/* <Link
                                            to='/my-syllabus'
                                            onClick={() => isTabActive("my-syllabus")}
                                        >
                                            <li
                                                id="my-syllabus"
                                                className={
                                                    activeTab === "my-syllabus"
                                                        ? "dashboard-active"
                                                        : null
                                                }
                                            >
                                                <img
                                                    src="/images/syllabus-layout-icon.svg"
                                                    alt=''
                                                />
                                                <small>My Syllabus</small>
                                            </li>
                                        </Link> */}
                                        <Link
                                            to='/manage-content'
                                            onClick={() => isTabActive("manage-content")}
                                        >
                                            <li style={{ width: '22%' }}
                                                id="manage-content"
                                                className={
                                                    activeTab === "manage-content" ? "dashboard-active" : null
                                                }
                                            >
                                                <img
                                                    src="/images/manage-content-layout-icon.svg"
                                                    alt=''
                                                />
                                                <small>Manage Content</small>
                                            </li>
                                        </Link>
                                        {/* <Link
                                            to='/my-content'
                                            onClick={() => isTabActive("my-content")}
                                        >
                                            <li
                                                id="my-content"
                                                className={
                                                    activeTab === "my-content" ? "dashboard-active" : null
                                                }
                                            >
                                                <img
                                                    src="/images/my-content-layout-icon.svg"
                                                    alt=''
                                                />
                                                <small>My Content</small>
                                            </li>
                                        </Link> */}
                                    </ul>
                                    : <ul>
                                        <Link
                                            to='/user-dashboard'
                                            onClick={() => isTabActive('user-dashboard')}

                                        >
                                            <li

                                                id="dashboard"
                                            >
                                                <div className={
                                                    activeTab === "user-dashboard" ?
                                                        "menu-active"
                                                        : null
                                                }>
                                                    <img
                                                        src="/images/dashboard-layout-icon.svg"
                                                        alt=''
                                                    />
                                                </div>
                                                <small>Dashboard</small>
                                            </li>
                                        </Link>
                                        <Link
                                            to='/my-learning'
                                            onClick={() => isTabActive("my-learning")}
                                        >
                                            <li
                                                onClick={() => isTabActive("my-learning")}
                                                id="my-learning"

                                            >
                                                <div className={
                                                    activeTab === "my-learning" ?
                                                        "menu-active"
                                                        : null
                                                }>
                                                    <img
                                                        src="/images/classroom-layout-icon.svg"
                                                        alt=''
                                                    />
                                                </div>
                                                <small>My Learning</small>
                                            </li>
                                        </Link>

                                        <Link
                                            to='/content-library'
                                            onClick={() => isTabActive("content-library")}
                                        >
                                            <li
                                                id="content-library"
                                            >
                                                <div className={
                                                    activeTab === "content-library" ?
                                                        "menu-active"
                                                        : null
                                                }>
                                                    <img
                                                        src="/images/content-layout-icon.svg"
                                                        alt=''
                                                    />
                                                </div>
                                                <small>Content Library</small>
                                            </li>
                                        </Link>
                                        <Link
                                            to='/practice-tests'
                                            onClick={() => isTabActive("practice-tests")}
                                        >
                                            <li
                                                id="notes"

                                            >
                                                <div className={
                                                    activeTab === "practice-tests" ?
                                                        "menu-active"
                                                        : null
                                                }>
                                                    <img
                                                        src="/images/practise-layout-icon.svg"
                                                        alt=''
                                                    />

                                                </div>
                                                <small>Practice Tests</small>
                                            </li>
                                        </Link>
                                        {/* <Link
                                            to='/counselling'
                                            onClick={() => isTabActive("counselling")}
                                        >
                                            <li
                                                id="notes"

                                            >
                                                <div className={
                                                    activeTab === "counselling" ?
                                                        "menu-active"
                                                        : null
                                                }>
                                                    <img
                                                        src="/images/counselling-layout-icon.svg"
                                                        alt=''
                                                    />

                                                </div>
                                                <small>Counselling</small>
                                            </li>
                                        </Link> */}

                                    </ul>
                    }
                </div>
            }


        </Fragment >


    )
}


export default SidebarLayout
