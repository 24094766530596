import React from 'react';


import Profile from '../Components/Profile/profile';
import Account from '../Components/Account/account';
import Users from '../Components/Users/users';
import ManageContent from '../Components/Manage-Content/manageContent';
import ManageTopic from '../Components/Manage-Content/Manage-Topic/manageTopic';
import ManageChapter from '../Components/Manage-Content/Manage-Chapter/manageChapter';
import ManageSubTopic from '../Components/Manage-Content/Manage-Subtopic/manageSubtopic';
import ManageResource from '../Components/Manage-Content/Manage-Resources/manageResource';
import ManageSubject from '../Components/Manage-Content/Manage-Subject/manageSubject';
import AddResources from '../Components/Manage-Content/Manage-Add-Content/addResources';
import MentorConnect from '../Components/Mentor-connect/mentorConnect';
import AddQuery from '../Components/Mentor-connect/addQuery';
import AddUsers from '../Components/Users/addUsers';

import MyLearning from '../Components/My-Learning/myLearning';
import Chapters from '../Components/My-Learning/Chapters/chapters';
import Topics from '../Components/My-Learning/Topics/topics';
import NotesModule from '../Components/My-notes/notes';
import Counselling from '../Components/Counselling/counselling';
import CareerAssessment from '../Components/Career-assessment/careerAssessment';
import CareerPath from '../Components/Career-Path/careerPath';
import CareerAfter from '../Components/Career-Path/careerAfter';


import SkillPods from '../Components/Content-Library/Skill-pods/skillPod';
import PodTopics from '../Components/Content-Library/PodResources/podResource';
import Skills from '../Components/Content-Library/Skills/skills';
import NoticeBoard from '../Components/Notice-board/noticeBoard';
import Notification from '../Components/Notification/notification';
import Contests from '../Components/Reward/contests';
import AdminRewards from '../Components/AdminRewards/adminRewards';
import AdminContests from '../Components/AdminContests/contests';
import RedeemReward from '../Components/AdminRewards/redeemRewards';
import Achievers from '../Components/Achievers/achievers';
import MyContent from '../Components/My-Content/myContent';
import Dashboard from '../Components/Dashboard/dashboard';
import Reports from '../Components/Reports/reports';
import Ability from '../Components/Career-assessment/ability';

// import TestReport from '../Components/User/Counselling/test-report';
// import Certificate from '../utils/certificate';
const ContentLibrary = React.lazy(() => import('../Components/Content-Library/contentLibrary'));
const PracticeTest = React.lazy(() => import('../Components/Practice-Test/practiceTest'));



const RouteItems = [
    { path: '/profile', component: <Profile />, layout: 'mainLayout' },
    { path: '/user-dashboard', component: <Dashboard />, layout: 'mainLayout' },
    { path: '/notifications', component: <Notification />, layout: 'mainLayout' },
    { path: '/account', component: <Account />, layout: 'mainLayout' },
    { path: '/users', component: <Users />, layout: 'mainLayout' },
    { path: '/users/add', component: <AddUsers />, layout: 'mainLayout' },
    { path: '/manage-content', component: <ManageContent />, layout: 'mainLayout' },
    { path: '/manage-content/subject', component: <ManageSubject />, layout: 'mainLayout' },
    { path: '/manage-content/chapter', component: <ManageChapter />, layout: 'mainLayout' },
    { path: '/manage-content/topic', component: <ManageTopic />, layout: 'mainLayout' },
    { path: '/manage-content/subtopic', component: <ManageSubTopic />, layout: 'mainLayout' },
    { path: '/manage-content/topic/resources', component: <ManageResource />, layout: 'mainLayout' },
    { path: '/manage-content/topic/add-resources', component: <AddResources />, layout: 'mainLayout' },
    { path: '/mentor-connect', component: <MentorConnect />, layout: 'mainLayout' },
    { path: '/mentor-connect/query', component: <AddQuery />, layout: 'mainLayout' },
    { path: '/my-content', component: <MyContent />, layout: 'mainLayout' },
    { path: '/my-content/add-resources', component: <AddResources />, layout: 'mainLayout' },
    { path: '/notice-board', component: <NoticeBoard />, layout: 'mainLayout' },
    { path: '/achievers', component: <Achievers />, layout: 'mainLayout' },
    { path: '/my-subjects', component: <MyLearning />, layout: 'mainLayout' },
    { path: '/my-subjects/chapters', component: <Chapters />, layout: 'mainLayout' },
    { path: '/my-subjects/topics', component: <Topics />, layout: 'mainLayout' },
    { path: '/my-learning', component: <MyLearning />, layout: 'mainLayout' },
    { path: '/my-learning/chapters', component: <Chapters />, layout: 'mainLayout' },
    { path: '/my-learning/topics', component: <Topics />, layout: 'mainLayout' },
    { path: '/content-library', component: <ContentLibrary />, layout: 'mainLayout' },
    { path: '/content-library/chapters', component: <SkillPods />, layout: 'mainLayout' },
    { path: '/content-library/topics', component: <PodTopics />, layout: 'mainLayout' },
    { path: '/skill-library', component: <Skills />, layout: 'mainLayout' },
    { path: '/counselling', component: <Counselling />, layout: 'mainLayout' },
    { path: '/career-assessment', component: <CareerAssessment />, layout: 'mainLayout' },
    { path: '/career-ability', component: <Ability />, layout: 'mainLayout' },
    { path: '/practice-tests', component: <PracticeTest />, layout: 'mainLayout' },
    { path: '/career-path', component: <CareerPath />, layout: 'mainLayout' },
    { path: '/career-path/:after', component: <CareerAfter />, layout: 'mainLayout' },
    { path: '/my-notes', component: <NotesModule />, layout: 'mainLayout' },
    { path: '/contests', component: <Contests />, layout: 'mainLayout' },
    { path: '/admin-rewards', component: <AdminRewards />, layout: 'mainLayout' },
    { path: '/reports', component: <Reports />, layout: 'mainLayout' },
    { path: '/admin-contest', component: <AdminContests />, layout: 'mainLayout' },
    { path: '/redeem-rewards', component: <RedeemReward />, layout: 'mainLayout' },

    // { path: '/certificate', component: <Certificate />, layout: 'mainLayout' },
    // { path: '/reports', component: <Reports />, layout: 'mainLayout' },
]

export default RouteItems