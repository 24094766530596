import axios from 'axios';
import { getApiCall, postApiCall, putApiCall } from "../../utils/request";
import { returnErrors } from "./errorAction";
import { GET_MY_CONTENT, GET_NEXT_MY_CONTENT, CHANGE_CONTENT_STATUS, SEARCH_MY_CONTENT, Add_CONTENT, EDIT_CONTENT, GET_CONTENT_STATE, ADD_QUESTION_MY_CONTENT, EDIT_QUESTION_MY_CONTENT, GET_QUESTION_DATA, ADD_RESOURCE_TO_ATTACHMENT } from '../Types/types';


export const getMyContents = (page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/mycontent/content/list?page=${page}`);
        dispatch({ type: GET_MY_CONTENT, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getNextMyContents = (page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/mycontent/content/list?page=${page}`);
        dispatch({ type: GET_NEXT_MY_CONTENT, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data?.message, error?.response?.status);
    }
};

export const changeContentStatus = (id, type, checked) => async (dispatch) => {
    let obj = {
        type: type,
        _id: id
    }
    try {
        const response = await putApiCall(`admin/mycontent/content/toggle`, obj);
        if (response.data.success) {
            dispatch({ type: CHANGE_CONTENT_STATUS, payload: { id, checked } });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSearchContent = (val) => async (dispatch) => {

    try {
        const response = await getApiCall(`admin/mycontent/content/search?query=${val}`);
        if (response.data.success) {
            dispatch({ type: SEARCH_MY_CONTENT, payload: response.data.data });
        } else {
            // returnErrors(
            //     dispatch, response.data !== undefined && response.data.message, response.data !== undefined && response.status);
        }
    } catch (error) {
        // returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const addArticles = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/mycontent/add/resource/link`, obj);
        if (response.data.success) {
            returnErrors(dispatch, `${obj.type} added`, response?.status);
            return dispatch({ type: Add_CONTENT, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data?.message, error?.response?.status);
    }
}

export const uploadVideoContent = (obj) => async (dispatch) => {
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    dispatch({ type: GET_CONTENT_STATE, payload: true });
    try {
        var percent = 0;
        const config = {
            headers: { Authorization: AuthStr },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                percent = Math.round((loaded * 100) / total);
                if (percent < 98) {
                    return dispatch({ type: "Get_Documents_Percent", payload: percent })
                }
                else {
                    return dispatch({ type: "Get_Documents_Percent", payload: 98 })
                }
            }
        }
        await axios.post(process.env.REACT_APP_API_KEY + 'admin/mycontent/upload/video', obj, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: "Get_Documents_Percent", payload: 100 })
                    dispatch({ type: GET_CONTENT_STATE, payload: false });
                    dispatch({ type: "Get_Video_Content", payload: response.data.data });
                    returnErrors(dispatch, 'Video  uploaded', response?.status);
                } else {
                    dispatch({ type: GET_CONTENT_STATE, payload: false });
                    returnErrors(dispatch, response?.data?.message, response?.status);
                }
            });
    }
    catch (error) {
        dispatch({ type: GET_CONTENT_STATE, payload: false });
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


export const uploadDocumentContent = (obj) => async (dispatch) => {
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    dispatch({ type: GET_CONTENT_STATE, payload: true });
    try {
        var percent = 0;
        const config = {
            headers: { Authorization: AuthStr },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                percent = Math.round((loaded * 100) / total);
                if (percent < 98) {
                    return dispatch({ type: "Get_Documents_Percent", payload: percent })
                }
                else {
                    return dispatch({ type: "Get_Documents_Percent", payload: 98 })
                }
            }
        }
        await axios.post(process.env.REACT_APP_API_KEY + 'admin/mycontent/upload/document', obj, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: "Get_Documents_Percent", payload: 100 })
                    dispatch({ type: GET_CONTENT_STATE, payload: false });
                    dispatch({ type: "Get_Document_Content", payload: response.data.data });
                    returnErrors(dispatch, 'Document  uploaded', response?.status);
                } else {
                    dispatch({ type: GET_CONTENT_STATE, payload: false });
                    returnErrors(dispatch, response?.data?.message, response?.status);
                }
            });
    }
    catch (error) {
        dispatch({ type: GET_CONTENT_STATE, payload: false });
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updatedArticle = (obj) => async (dispatch) => {
    if (obj.get('type') === 'Article') {
        try {
            const response = await putApiCall(`admin/mycontent/content/update`, obj);
            if (response.data.success) {
                dispatch(getMyContents(1))
                dispatch({ type: EDIT_CONTENT, payload: response.data.data });
                returnErrors(dispatch, 'Article is updated', response?.status);
            } else {
                returnErrors(dispatch, response?.data?.message, response?.status);
            }
        } catch (error) {
            returnErrors(dispatch, error.response?.data?.message, error?.response?.status);
        }
    }
    else {
        const AuthStr = "Bearer " + localStorage.getItem("loginToken");
        dispatch({ type: GET_CONTENT_STATE, payload: true });
        try {
            var percent = 0;
            const config = {
                headers: { Authorization: AuthStr },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent
                    percent = Math.round((loaded * 100) / total);
                    if (percent < 98) {
                        return dispatch({ type: "Get_Documents_Percent", payload: percent })
                    }
                    else {
                        return dispatch({ type: "Get_Documents_Percent", payload: 98 })
                    }
                }
            }
            await axios.put(process.env.REACT_APP_API_KEY + 'admin/mycontent/content/update', obj, config)
                .then((response) => {
                    if (response.data.success) {
                        dispatch({ type: "Get_Documents_Percent", payload: 100 })
                        dispatch({ type: GET_CONTENT_STATE, payload: false });
                        dispatch({ type: GET_CONTENT_STATE, payload: response.data.data });
                        returnErrors(dispatch, 'Content is updated', response?.status);
                    } else {
                        dispatch({ type: GET_CONTENT_STATE, payload: false });
                        returnErrors(dispatch, response?.data?.message, response?.status);
                    }
                });
        }
        catch (error) {
            dispatch({ type: GET_CONTENT_STATE, payload: false });
            returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
        }
    }
}


export const savedContentQuestion = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/mycontent/question/add`, obj);
        if (response.data.success) {
            dispatch({ type: ADD_QUESTION_MY_CONTENT, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const editContentQuestion = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/mycontent/question/update`, obj);
        if (response.data.success) {
            dispatch({ type: EDIT_QUESTION_MY_CONTENT, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQuestionData = (type, id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/mycontent/resources/list?type=${type}&_id=${id}`);
        console.log(response.data.data)
        return dispatch({ type: GET_QUESTION_DATA, payload: response.data.data?.type === 'essay' ? response.data.data : response.data.data[0] });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getResource = (type, id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/mycontent/resources/list?type=${type}&_id=${id}`);
        return dispatch({ type: GET_QUESTION_DATA, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const addResourceToAttachment = (id) => async (dispatch) => {
    try {
        dispatch({ type: ADD_RESOURCE_TO_ATTACHMENT, payload: id });
        returnErrors(dispatch, 'Resource added to attachments', 200);
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};