import { GET_MENUS, GET_POPUP_NOTIFICATION, LOGOUT_USER } from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';



export const trackUserLogin = () => async (dispatch) => {
    try {
        await getApiCall(`track/login`);

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getMenus = () => async (dispatch) => {
    try {
        const response = await getApiCall(`menu`);
        dispatch({ type: GET_MENUS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getNotifications = () => async (dispatch) => {

    try {
        const response = await getApiCall(`notification?page=1&unread=true`);
        if (response.data.unread === 0) {
            const response2 = await getApiCall(`notification?page=1&unread=false`);
            if (response2.data.success) {
                dispatch({ type: GET_POPUP_NOTIFICATION, payload: response2.data });
            }
        }
        else {
            if (response.data.success) {
                dispatch({ type: GET_POPUP_NOTIFICATION, payload: response.data });
            }
        }
    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}



export const logOutUser = () => async (dispatch) => {
    try {
        const response = await postApiCall('auth/logout');
        return dispatch({ type: LOGOUT_USER });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}