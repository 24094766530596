import React, { Fragment, useEffect, useState } from 'react';
import './myContent.scss';
import Modal from "react-bootstrap/Modal";
import $ from 'jquery';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { TopicLoader } from '../../Loader/loader';
// import Notespopup from '../My-notes/notespopup';
import SharePopup from '../Share-popup/sharePopup';
import PreviewLink from "../Manage-Content/Manage-Add-Content/previewLink";
import { getMyContents, getNextMyContents, getQuestionData, addArticles, updatedArticle, changeContentStatus, getSearchContent, getResource } from '../../Redux/ActionCreators/myContentAction';
import { googleLinkPreviewSetNull, googleLinkPreview, getQuestionForEdit, getAllSubjects, getChapters, getTopics } from '../../Redux/ActionCreators/manageContentAction';
import { renameProperties, renameArrProperties } from '../../utils/miscelleneous';
// import AddPodModal from '../Library/addPodModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openShare } from '../../Redux/ActionCreators/shareAction';
import ViewResource from '../../utils/viewResource';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function MyContent() {

    const [page, setPage] = useState(1);
    const [isView, setIsView] = useState(false);
    const [resource, setResource] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isAddArticle, setIsAddArticle] = useState(false);
    const [edit, setEdit] = useState(false);
    const [articleLink, setArticleLink] = useState('');
    const [articleError, setArticleError] = useState('');
    const [subject, setSubject] = useState(null);
    const [contentTags, setContentTags] = useState([]);
    const [topic, setTopic] = useState(null)
    const [subjectError, setsubjectError] = useState('');
    const [topicError, setTopicError] = useState('');
    const [id, setId] = useState('');
    const [query, setQuery] = useState('');
    const [addToBubble, setAddToBubble] = useState(false);
    const [rType, setrType] = useState('');
    const [rId, setrId] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { contents, isResourceLoading, isLoading, questionData } = useSelector(state => state.myContent);
    const { previewData, selectFromSubject, topicsList, chapterList, editQuestionData } = useSelector(state => state.manageContent);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            setPage(1)
            dispatch(getMyContents(1));

            if (location.search) {
                const id = window.location.search.split('=')[1].split('&')[0]
                const type = window.location.search.split('=')[2]
                if (type === 'Question') {
                    const res = await dispatch(getQuestionData(type, id));
                    if (res?.type === 'GET_QUESTION_DATA') {
                        await setResource({ ...res?.payload, type1: 'Question' });
                        await setIsView(true);
                    }
                }
                else {
                    const res = await dispatch(getResource(type, id));
                    if (res?.type === 'GET_QUESTION_DATA') {
                        await setResource({ ...res?.payload, type: type });
                        await setIsView(true);
                    }
                }
            }
        }
        firstRender();
    }, [])


    const checkFocus = () => {
        // dummy element
        var dummyEl = document.getElementById('express-form-typeahead');
        // check for focus
        var isFocused = (document.activeElement === dummyEl);
        if (isFocused) {
            setIsFocused(isFocused);
        }
        else {
            setIsFocused(false);
        }
    }

    const addArticle = () => {
        setIsAddArticle(true);
        setEdit(false);
        setTopic(null);
        setSubject(null);

    }

    const addResource = (type) => {
        if (type === 'Question') {
            dispatch(getAllSubjects());
        }
        navigate("/my-content/add-resources", {
            state: {
                type: type,
                edit: false,
            }
        });
    }


    const handleModal1 = () => {
        setIsAddArticle(false);
        setArticleError('');
        setArticleLink('');
        setContentTags([]);
        setSubject(null);
        setTopic(null);
        dispatch(googleLinkPreviewSetNull());
    }

    const handleChangeArticle = async (evt) => {
        var regex =
            /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
        if (!regex.test(evt.target.value)) {
            setArticleError("Please enter valid link")
            dispatch(googleLinkPreviewSetNull());
            return false;
        } else {
            dispatch(googleLinkPreview(evt.target.value));
            setArticleError('');
            setArticleLink(evt.target.value);
        }
    }

    const selectSubject = (e) => {
        setSubject(e);
        setTopic(null);
        setTopicError('');
        setsubjectError('');
        dispatch(getChapters(e.value));
    }

    const selectTopic = (e) => {
        setTopic(e)
        setTopicError('')
        dispatch(getTopics(e.value));
    }

    const selectTags = (e) => {
        setContentTags(e);
    }

    const articleLinkASave = async () => {
        if (articleLink === "" || null) {
            setArticleError("Link cannot be empty")
        } else if (!previewData) {
            setArticleError("Please enter the link")
        }
        else if (!subject?.value) {
            setsubjectError("Please select subject");
        }
        else if (!topic?.value) {
            setTopicError("Please select unit")
        }
        else {
            let obj = {
                title: previewData.title,
                type: 'Article',
                description: previewData.description,
                url: previewData.url,
                image: previewData.image,
                subject_id: subject?.value,
                chapter_id: topic?.value,
                tags: contentTags.map(el => [el.label][0]),
            }
            await dispatch(addArticles(obj));
            setPage(1);
            await dispatch(getMyContents(1));
            await setIsAddArticle(false);
            setArticleLink('');
            setSubject(null);
            setTopic(null);
            setContentTags([]);
            dispatch(googleLinkPreviewSetNull());
        }
    };

    const getEditInfo = async (details) => {
        console.log(details)
        if (details?.type === "Article") {
            dispatch(googleLinkPreview(details.url));
            setIsAddArticle(true);
            setEdit(true);
            setArticleLink(details?.url);
            setId(details?._id);
            setSubject(details?.subject && renameProperties(details?.subject, { _id: 'value', name: 'label' }));
            setTopic(details?.chapter && renameProperties(details?.chapter, { _id: 'value', name: 'label' }));
            setContentTags(details.tags && renameArrProperties(details?.tags));
            dispatch(getAllSubjects());
            dispatch(getChapters(details?.subject?._id));
            dispatch(getTopics(details?.chapter?._id));
        } else {
            if (details?.type === 'Question') {
                const res = await dispatch(getQuestionForEdit('Question', details));
                console.log(res)
                if (res?.type === 'GET_QUESTION_DETAILS') {
                    dispatch(getAllSubjects());
                    navigate("/my-content/add-resources",
                        {
                            state: {
                                type: details?.type,
                                details: res?.payload,
                                edit: true,
                            }
                        }
                    );
                }

            }
            else {
                navigate(
                    "/my-content/add-resources",
                    {
                        state: {
                            type: details?.type,
                            details: details,
                            edit: true,
                        }
                    }
                );
            }

        }
    }

    const updateArticle = async () => {
        if (articleLink === "" || null) {
            articleError("Link cannot be empty");
        } else if (!previewData) {
            articleError("Please enter the link");
        } else {
            var data = new FormData()
            data.append("_id", id);
            data.append("description", previewData?.description);
            data.append("title", previewData?.title);
            data.append("image", previewData?.image);
            data.append("type", "Article");
            data.append("url", previewData?.url);
            data.append('subject_id', subject?.value)
            data.append('topic_id', topic?.value)
            data.append('tags', JSON.stringify(contentTags.map(el => [el.label][0])))
            await dispatch(updatedArticle(data));
            setIsAddArticle(false);
            setArticleLink('');
            dispatch(googleLinkPreviewSetNull());
        }
    }


    const openView = async (el) => {
        if (el.type === 'Question') {
            const res = await dispatch(getQuestionData(el.type, el._id));
            if (res?.type === 'GET_QUESTION_DATA') {
                await setResource({ ...res.payload, type1: 'Question' });
                await setIsView(true);
            }
        }
        else {
            setIsView(true);
            setResource(el);
        }
        $("body").css("overflow", "hidden");
    }

    const closeDetails = () => {
        setIsView(false);
        setResource(null);
        $("body").css("overflow", "visible");
    }

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextMyContents(page + 1));
    }

    const timerRef = React.useRef(null);


    const getSearchResult = (e) => {
        const val = e.target.value
        setQuery(val);
        setPage(1);
        if (val.length === 1) {
            dispatch(getMyContents(1));
        }
        else {
            clearTimeout(timerRef.current);
            setPage(1);
            timerRef.current = setTimeout(() => getquerySearch(val), 500);
        }
    }

    const getquerySearch = (val) => {
        dispatch(getSearchContent(val));
    }



    return (
        <div className='manage-subtopics' style={{ marginLeft: 5 }}>

            {<div className="mobile-coming">
                <div className="mobile-inner-hide">
                    <img src="/images/mobile-error.svg" alt='' />
                    <h4>Please visit the website on your Desktop/Laptop</h4>
                    <p>Mobile view coming soon</p>
                </div>

            </div>}
            <div className='d-flex' style={{ fontSize: 20 }}>
                <h1>My Content</h1>
            </div>

            <div className='subject-header mt-2'>
                <ul className='subject-btn'>
                    <li><button onClick={() => addResource('Video')}><img src='/images/video-icon-white.svg' alt='' /> &nbsp; Add Video</button></li>
                    <li><button onClick={() => addArticle('Article')}><img src='/images/article-icon-white.svg' alt='' /> &nbsp; Add Article</button></li>
                    <li><button onClick={() => addResource('Document')}><img src='/images/document-icon-white.png' alt='' /> &nbsp; Add Document</button></li>
                    <li><button onClick={() => addResource('Question')}><img src='/images/quiz-icon-white.png' alt='' /> &nbsp; Add Question</button></li>
                </ul>
                <div class="animated-search" style={{ top: 60 }} >
                    <input id="express-form-typeahead" autoComplete="off" onBlur={checkFocus} onChange={(e) => getSearchResult(e)} onFocus={checkFocus} spellCheck="false" type="text" />
                    <div class="symbol" >
                        <img src='/images/search1.svg' alt='' />
                    </div>
                    {
                        isFocused ?
                            <img src='/images/close2.png' alt='' className='close-input' onClick={() => setIsFocused(false)} />
                            : ''
                    }
                </div>
                <div className='content-heading'>
                    <ul>
                        <li>
                            <div>No.</div>
                            <div style={{ width: '46%' }}>Item</div>
                            <div style={{ width: '25%' }} >Item Type</div>
                            <div style={{ width: '20%' }}>Actions</div>
                        </li>
                    </ul>
                </div>
            </div>

            {
                isResourceLoading ? (
                    <div>
                        <TopicLoader />
                    </div>
                ) :

                    <InfiniteScroll
                        dataLength={contents.length} //This is important field to render the next data
                        next={fetchData}
                        hasMore={true}
                        loader={<div >{isLoading ?
                            <Fragment><TopicLoader /></Fragment> : null}</div>}
                    >
                        {contents !== undefined && contents.length > 0 ?
                            <ul className='subject-list'>
                                {
                                    contents !== undefined && contents.map((el, index) => {
                                        return (
                                            <li >
                                                <div className='subject-num' style={{ paddingLeft: 15 }}>
                                                    <p>{index + 1}</p>
                                                </div>
                                                <div className='subject-name ml-0' style={{ width: '60%' }} >
                                                    <div className="d-flex align-items-center">
                                                        <article style={{ width: '56px' }}>
                                                            <img
                                                                src={el.type === 'Question' ? '/images/quiz.svg' : el.image !== null && el.image ? el.image : '/images/error-image.jpg'}
                                                                alt=""
                                                                className="rounded"
                                                                width="56px "
                                                                height="32px"
                                                                onError={(e) => {
                                                                    if (e.target.src !== "/images/empty-image.png") {
                                                                        e.target.onerror = null;
                                                                        e.target.src = "/images/empty-image.png";
                                                                    }
                                                                }
                                                                }
                                                            />
                                                        </article> &nbsp;
                                                        <p style={{ marginLeft: el.type === 'Question' ? '10px' : "17px", lineHeight: "normal" }} dangerouslySetInnerHTML={{ __html: el.title || el.description }} />

                                                    </div>
                                                </div>
                                                <div className='text-center' style={{ width: '20%' }} >
                                                    <h3 className='d-flex justify-content-start align-items-center'>
                                                        {el.type === "Video" ?
                                                            <img
                                                                src="/images/video-icon.svg" alt='' /> : el.type === "Article" ?
                                                                <img
                                                                    src="/images/article-icon.svg" alt='' /> : el.type === "Quiz" ? <img
                                                                        src="/images/quiz-icon.svg" alt='' /> : el.type === "Document" ? <img
                                                                            src="/images/document-icon.svg" alt='' /> : el.type === "Question" ? <img src='/images/quiz-icon.svg' alt='' /> : null}
                                                        &nbsp; &nbsp;
                                                        {el.type}</h3>
                                                </div>
                                                <div className='text-center cursor-pointer'>
                                                    {<img src="/images/view_grey.svg" alt="" width='25px' onClick={() => openView(el)} />}
                                                    <img src="/images/edit.svg" onClick={() => getEditInfo(el)} alt="" />
                                                    <img src='/images/share.png' alt='' onClick={() => dispatch(openShare(el))} />&nbsp;&nbsp;
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            :
                            <div className="empty-list">
                                <img src="/images/empty.png" alt="" />
                                <p>You have not added any content yet.</p>
                            </div>}
                    </InfiniteScroll>
            }


            <SharePopup />

            <div className="resource-details" style={{ display: isView ? 'block' : 'none', }}>
                <ViewResource
                    activeFeedData={resource}
                    closeNotes={closeDetails}
                />
            </div>

            <Modal
                show={isAddArticle}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-topic-modal"}
            >
                <div class="modal-header" style={{ background: "#1D8BCB" }}>
                    <h5 class="modal-title">
                        {edit ? 'Edit Article' : 'Add Article'}
                        <br />
                    </h5>

                    <div className="sticky-close" style={{ top: 14 }} onClick={() => handleModal1()}>
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "22px 40px 22px 40px" }}>
                    <div>
                        <label>Article Link*</label>
                        <input
                            className="form-control mt-2"
                            value={articleLink}
                            onChange={(e) => handleChangeArticle(e)}
                        />
                        {previewData ? (
                            <PreviewLink previewData={previewData} />
                        ) : null}
                    </div>
                    <small>{articleError}</small>
                    <div >
                        <div className="mt-2">
                            <label>Subject*</label>
                            <div className="mt-2">
                                <Select
                                    components={{ DropdownIndicator }}
                                    placeholder='Select Subject'
                                    value={subject}
                                    onChange={selectSubject}
                                    options={selectFromSubject}
                                />
                            </div>
                            <small>{subjectError}</small>
                        </div>
                        <div className="mt-2" style={{ marginLeft: -3 }}>
                            <label>Unit*</label>
                            <div className="mt-2">
                                <Select
                                    components={{ DropdownIndicator }}
                                    placeholder='Select Unit'
                                    value={topic}
                                    onChange={selectTopic}
                                    options={chapterList?.map(el => [{ label: el.name, value: el._id }][0])}
                                />
                            </div>
                            <small>{topicError}</small>
                        </div>
                    </div>
                    <div className="mt-2">
                        <label>Tags</label>
                        <p className='mt-0' style={{ fontSize: 12 }}>Add search tags like unit , sub unit to help you easily find this Article</p>
                        <div className="mt-2">
                            <CreatableSelect
                                components={{ DropdownIndicator }}
                                isSearchable
                                isMulti
                                placeholder='Select Tags'
                                value={contentTags}
                                onChange={selectTags}
                                options={topicsList?.map(el => [{ label: el.name, value: el._id }][0])}
                            />
                        </div>
                    </div>
                    <div className="save-btn">
                        {
                            edit ?
                                <button
                                    onClick={updateArticle}
                                >
                                    Save
                                </button> :
                                <button
                                    // disabled={!previewData}
                                    onClick={() => articleLinkASave()}
                                >
                                    Save
                                </button>
                        }

                        <button onClick={handleModal1}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div >

    )
}


export default (MyContent);