import { EMPTY_PROFILE, GET_PROFILE, UPDATE_PROFILE, UPDATE_LANGUAGE } from '../Types/types';
import { getApiCall, putApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';


export const getProfileDetails = () => async (dispatch) => {

    try {
        const response = await getApiCall(`auth/profile`);
        return dispatch({ type: GET_PROFILE, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const updateProfile = (data) => async (dispatch) => {

    try {
        await putApiCall('auth/profile', data);
        dispatch({ type: UPDATE_PROFILE });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const emptyData = () => async (dispatch) => {
    dispatch({ type: EMPTY_PROFILE });
}

export const updateLanguage = (val) => async (dispatch) => {
    const obj = {
        language: val
    }
    try {
        await putApiCall('auth/language', obj);
        dispatch({ type: UPDATE_LANGUAGE, payload: val });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

