import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../manageContent.scss';
import { clearErrors } from '../../../Redux/ActionCreators/errorAction';
import {
    getSubjectFromClassroom, addToClassroom, backToSubject, getSubjectList, getNextSubjectList, getCheckedIds, checkOne, addSubjectToClassroom,
    openAddSubject, handleModalClose, savedSubject,
    updateSubject, cloneSubject
} from '../../../Redux/ActionCreators/manageContentAction';
import AddSubjectModal from '../Manage-Add-Content/addSubjectModal';
import Modal from 'react-bootstrap/Modal';
import { TopicLoader } from '../../../Loader/loader';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';



function ManageSubject(props) {
    const [page, setPage] = useState(1);
    const [id, setId] = useState('');
    const [search, setSearch] = useState('');
    const [checkedId, setCheckedId] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [title, setTitle] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [detail, setDetail] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { subjectFromClassroom, editObj, editSubject, isSubjectLoading, addSubject, subjectList, classroomId, isAddSubject } = useSelector(state => state.manageContent);
    useEffect(() => {
        const firstRender = async () => {
            window.scrollTo(0, 0);
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            };
            setId(location.state.id);
            setTitle(location.state.classroom);
            await dispatch(clearErrors());
            await dispatch(getSubjectFromClassroom(location.state.id));
            await dispatch(getSubjectList(1, search));
        }

        firstRender();
    }, [])


    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextSubjectList(page + 1, search))
    }

    const backToMain = () => {
        navigate(-1);
    }


    const addSubToProgram = () => {
        dispatch(addToClassroom());
        setPage(1);
        // setSearch('');
        dispatch(getCheckedIds(subjectFromClassroom));
    }

    const timerRef = React.useRef(null);

    const searchQuery = (val) => {
        clearInterval(timerRef.current);
        setSearch(val);
        setPage(1);
        timerRef.current = setTimeout(() => delaySearch(val), 800);

    }

    const delaySearch = (val) => {
        dispatch(getSubjectList(page, val));

    }

    const goToChapter = (id, title, details) => {
        navigate("/manage-content/chapter", {
            state: { id: id, title: title, details: details, subject: true },
        });
    };


    const getCheckedId = async (e) => {
        dispatch(checkOne(e.target.value, e.target.checked));
        if (e.target.checked) {
            setCheckedId([...checkedId, e.target.value]);
        }
        else {
            setCheckedId(checkedId.filter(el => el !== e.target.value));
        }
    }

    const saveSubjectToClassroom = async () => {
        const obj = {
            course_id: location?.state?.id,
            subject_ids: subjectList.filter(el => el.isChecked).map(el => el._id)
        }
        await dispatch(addSubjectToClassroom(obj));
        await dispatch(getSubjectFromClassroom(location?.state?.id));
    }

    const handleModal = async () => {
        await dispatch(handleModalClose());
    }

    const goToSubject = (el) => {
        navigate('/my-learning/chapters', {
            state: {
                id: el._id,
                detail: el,
                type: 'main',
                title: el.name
            }
        })
    }

    const openConfirm = (el) => {
        if (!el.cloned) {
            setIsConfirm(true);
            setDetail(el);
        } else {
            navigate("/manage-content/chapter", {
                state: { id: el._id, title: el.name, details: el, subject: false },
            });
        }
    }

    const confirmClone = async () => {
        if (!detail?.cloned) {
            const res = await dispatch(cloneSubject(detail._id));
            if (res?.type === 'SUBJECT_CLONE') {
                setIsConfirm(false);
                setDetail(null);
                navigate("/manage-content/chapter", {
                    state: { id: detail._id, title: detail.name, details: detail, subject: false },
                });
            }
        } else {
            navigate("/manage-content/chapter", {
                state: { id: detail._id, title: detail.name, details: detail, subject: true },
            });
        }

    }

    const checkFocus = () => {
        // dummy element
        var dummyEl = document.getElementById('express-form-typeahead');
        // check for focus
        var isFocused = (document.activeElement === dummyEl);
        if (isFocused) {
            setIsFocused(isFocused)
        }
        else {
            setIsFocused(false);
        }
    }

    return (
        <Fragment>
            {
                !addSubject ?
                    <div className='manage-content-holder'>
                        <h2 className='text-capitalize d-flex' onClick={backToMain}><p style={{ marginTop: -2 }}><img src='/images/back.svg' width='20px' alt='' /></p>
                            &nbsp;&nbsp;{title}</h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li className="breadcrumb-item" onClick={() => navigate('/manage-content')} >Manage Content</li>
                                <li className="breadcrumb-item" onClick={() => navigate(-1)} >Classrooms</li>
                                <li class="breadcrumb-item b-active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                        <div>
                            <div className='content-header position-relative'>
                                <div className='d-flex justify-content-between w-50'>
                                    {localStorage.getItem('role').includes('state-admin') ? null : <button onClick={addSubToProgram}><img src='/images/plus-white.svg' alt='' /> &nbsp; Add Subjects to Course</button>}
                                    {/* <select className='form-control' style={{ width: '15%' }} onChange={(e) => getSemsfilter(e.target.value)} >
                            <option hidden>All Semsester</option>
                            <option value='all'>All</option>
                            {semesters !== undefined && semesters.map((el, index) => {
                                return (
                                    <option>{el.title}</option>
                                )
                            })}
                        </select> */}

                                </div>
                                <div class="animated-search d-none" >
                                    <input id="express-form-typeahead" autoComplete="off" onBlur={checkFocus} onFocus={checkFocus} spellCheck="false" type="text" />
                                    <div class="symbol" >
                                        <img src='/images/search1.svg' alt='' />
                                    </div>
                                    {
                                        isFocused ?
                                            <img src='/images/close2.png' alt='' className='close-input' onClick={() => setIsFocused(false)} />
                                            : ''
                                    }
                                </div>
                                <div className='content-heading'>
                                    <ul>
                                        <li>
                                            <div style={{ width: '35%' }}>Subjects</div>
                                            <div>Chapters</div>
                                            {/* <div>Units</div> */}

                                            <div>Items</div>
                                            <div>Status</div>
                                            <div>Actions</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {isSubjectLoading ?
                                <div>
                                    <TopicLoader />
                                </div> :
                                subjectFromClassroom !== undefined && subjectFromClassroom?.length > 0 ?
                                    <ul className='program-list'>
                                        {
                                            subjectFromClassroom !== undefined && subjectFromClassroom.map((el, index) => {
                                                return (
                                                    <li key={index} onClick={() => (localStorage.getItem('role').includes('state-admin')) ? '' : goToChapter(el._id, el.name, el)}>
                                                        <div className='program-name d-flex align-items-center' style={{ width: '35%' }} >
                                                            <div style={{ width: '56px' }}>
                                                                <img src={el.image} className='rounded' alt='' width='56px' height='32px' />
                                                            </div> &nbsp;&nbsp;&nbsp;
                                                            <p>{el.name}</p>
                                                        </div>
                                                        <div className='text-center' >
                                                            <h4>{el.chapters}</h4>
                                                        </div>
                                                        {/* <div className='text-center' >
                                                            <h3>{el.topics}</h3>
                                                        </div> */}
                                                        {/* <div className='text-center' >
                                                            <h3>{el.subtopic}</h3>
                                                        </div> */}
                                                        <div className='text-center' >
                                                            <h3>{el.articles + el.videos + el.documents + el.quizzes}</h3>
                                                        </div>
                                                        <div className='text-center'>
                                                            <p>
                                                                {el.active ? 'Active' : 'Inactive'}
                                                            </p>
                                                        </div>
                                                        <div className='text-center'>
                                                            {el.cloned ? null :
                                                                (localStorage.getItem('role').includes('state-admin')) &&
                                                                <img src='/images/view_grey.svg' width={24} alt='' onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation();
                                                                    goToSubject(el)
                                                                }
                                                                } />}
                                                            &nbsp;&nbsp;
                                                            <img src='/images/edit.svg' alt='' onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation();
                                                                (localStorage.getItem('role').includes('state-admin')) ? openConfirm(el) : dispatch(openAddSubject(el, true));
                                                            }
                                                            } /> &nbsp;&nbsp;
                                                            {/* <img src='/images/delete.svg' alt='' onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }
                                                            } />&nbsp;&nbsp;
                                                            <img src='/images/menu.svg' alt='' onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }
                                                            } /> */}
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                    :
                                    <div className='empty-list'>
                                        <img src='/images/empty.png' alt='' />
                                        <p>You have not added any subjects  yet.</p>
                                    </div>
                            }
                        </div>
                    </div>
                    : <div className='add-subject-to-classroom'>
                        <div className='add-header'>
                            {/* <img src='/images/back.svg' onClick={() => dispatch(backToSubject())} alt='' /> */}
                            <div style={{ display: 'inline-block', marginLeft: '35%' }}>
                                <h2>Add Subject to Course</h2>
                                <p className='mt-1'>{title}</p>
                            </div>
                        </div>
                        <div className='d-flex mt-2'>
                            <div className='left-section'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h1>Subjects<br />  </h1>
                                    <p onClick={() => dispatch(openAddSubject(null, false))}>Add new Subjects</p>
                                </div>
                                <div className="has-search"  >
                                    <span className="form-control-feedback">
                                        <img src="/images/search.svg" width="14" alt='' />
                                    </span>
                                    <input placeholder='Search for Subject' id='search' value={search} onChange={(e) => searchQuery(e.target.value)} autoComplete='off' className='form-control' />
                                </div>
                                <div className='lists-of-subjects mt-3'>
                                    <div className='add-contest' style={{ maxHeight: 450, overflow: 'auto' }}>
                                        <div className='mt-0'>
                                            {subjectList && subjectList?.length ?
                                                <InfiniteScroll
                                                    dataLength={subjectList && subjectList?.length} //This is important field to render the next data
                                                    next={fetchData}
                                                    hasMore={true}
                                                >
                                                    <div className='tableFixHead'>
                                                        <Table responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {/* <input type='checkbox' style={{ width: 16, height: 16 }} checked={checked} onChange={(e) => checkAll(e)} /> */}
                                                                    </th>
                                                                    <th>Subject</th>
                                                                    <th>Affiliation</th>
                                                                    <th>Chapters</th>
                                                                    <th>Items</th>
                                                                    {/* <th>Action</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    subjectList && subjectList?.map(el => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <input type='checkbox' onChange={getCheckedId} style={{ width: 16, height: 16 }} value={el._id}
                                                                                        checked={el.isChecked} />
                                                                                </td>
                                                                                <td>{el.name}</td>
                                                                                <td>{el.affiliation?.name}</td>
                                                                                <td>{el.chapters}</td>
                                                                                <td>{el.articles + el.videos + el.documents + el.quizzes}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </InfiniteScroll>
                                                :
                                                <div className='empty-list'>
                                                    <img src='/images/empty.png' alt='' />
                                                    <p>No subjects found.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='save-btn mt-2'>
                            <button onClick={saveSubjectToClassroom} >Save</button>
                            <button onClick={() => dispatch(backToSubject())}>Cancel</button>
                        </div>

                    </div>
            }

            {/* ################## Add subject modal ############################ */}
            <Modal
                show={isAddSubject}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-subject-modal"}
            >
                <AddSubjectModal
                    handleModal={handleModal}
                    savedSubject={(obj) => dispatch(savedSubject(obj))}
                    updateSubject={(obj) => dispatch(updateSubject(obj))}
                    items={editObj}
                    edit={editSubject}
                />
            </Modal>

            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4 text-center">
                    <h1 className='mb-2'>{`Are you sure you want to edit this ${detail?.name}?`}</h1>
                    <div className="save-btn mt-4">
                        <button onClick={confirmClone}>Yes</button> &nbsp;&nbsp;
                        <button onClick={() => setIsConfirm(false)}>No</button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export default (ManageSubject);