import React, { Fragment, useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import AddQuestions from "./addQuestion";
import Select, { components } from 'react-select';
import QuizQuestionList from "./quizQuestionList";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};


export default function AddPracticeTest(props) {
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [testTitle, setTestTitle] = useState('');
    const [duration, setDuration] = useState('');
    const [subject, setSubject] = useState('');
    const [quesType, setQuesType] = useState('');
    const [testId, setTestId] = useState('');
    const [isShowList, setIsShowList] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [durationError, setDurationError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [isQuestionVisible, setIsQuestionVisible] = useState(false);
    const [isQuestionListVisble, setIsQuestionListVisble] = useState(false);
    const [questionError, setQuestionError] = useState('');
    const times = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100]
    const typeForSelect = [
        { value: 'mcq', label: 'Multiple choice question' },
        // { value: 'Subjective', label: 'Long answer' }
    ]
    useEffect(() => {
        const { test } = props
        if (props.test !== undefined && props.edit) {
            setTestTitle(test.title);
            setDuration(test.time);
            setSubject(test.subjects?.map(el => [{ label: el.name, value: el._id }][0]));
            setTestId(test._id);
            setQuesType(test.type === 'mcq' ? { label: 'Multiple choice question', value: 'mcq' } : { label: 'Long answer', value: 'essay' });
            setIsShowList(true);
        }
        return (() => {
            props.deleteAllQuestion();
        })

    }, [])



    const onChangeQuiZTitle = (val) => {
        setTestTitle(val);
        setTitleError('');
    };

    const onChangeSubject = (e) => {
        setSubject(e);
        setSubjectError('');
    };

    const onChangeType = (e) => {
        setQuesType(e);
        setTypeError('');
    }



    const onChangeDuration = (val) => {
        setDuration(val);
        setDurationError('');
    };

    const savePractiseTest = () => {
        if (testTitle.trim() === "" || null) {
            setTitleError(`title can't empty`);
        }
        else if (subject === "" || null) {
            setSubjectError(`subjects can't be empty`);
        }
        else if (duration.trim() === "" || null) {
            setDurationError(`duration can't be empty`);
        }
        else if (props.questionListForQuiz?.length === 0 || null) {
            setQuestionError("please select the questions");
        } else {
            setTitleError('');
            setSubjectError('');
            setDurationError('');
            setQuestionError('');
            const obj = {
                type: quesType?.value,
                title: testTitle,
                time: duration,
                subject_ids: subject?.map(el => el.value),
                q_ids: props.questionListForQuiz.map(el => el._id),
            };
            props.savedNewQuiz(obj, 'practice-test');
            props.backToMain();
        }
    };

    const editPracticeTests = async () => {
        if (testTitle === "") {
            setTitleError(`title can't empty`)
        }
        else if (subject === "") {
            setSubjectError(`subject can't be empty`);
        }
        else if (duration === '') {
            setDurationError(`duration can't be empty`);
        }
        else if (props.questionListForQuiz?.length === 0 || null) {
            setQuestionError("please select the questions");
        }
        else {
            setTitleError('');
            setSubjectError('');
            setDurationError('');
            setQuestionError('');
            const obj = {
                _id: testId,
                title: testTitle,
                time: duration,
                subject_id: subject?.value,
                q_ids: props.questionListForQuiz?.map(el => [{ _id: el._id, index: el.index }][0])
            };
            await props.editPracticeTest(obj, 'practice');
            await props.backToMain();
        }
    }

    const showQuizList = () => {
        if (testTitle.trim() === "" || null) {
            setTitleError(`title can't empty`)
        }
        else if (subject === "" || null) {
            setSubjectError(`subject can't be empty`);
        }
        else if (duration.trim() === "" || null) {
            setDurationError(`duration can't be empty`);
        }
        else if (quesType === "" || undefined) {
            setTypeError("type cannot be empty");
        }
        else {
            setIsShowList(true);
        }
    }

    const backToMainList = () => {
        setIsQuestionVisible(false);
    };

    const backToList = () => {
        setIsQuestionListVisble(false);
    };

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    const { edit } = props
    return (
        <Fragment>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />

            {isQuestionVisible === true ? (
                <AddQuestions
                    type={"Question Bank"}
                    backToMain={() => backToMainList()}
                    successEnable={props.successEnable}
                    successMessageText={props.successMessageText}
                    snackClosed={snackClosed}
                    isAddfromQuiz={true}
                    savedQuestions={props.savedQuestions}
                    resetPercentage={props.resetPercentage}
                    selectFromSubject={props.selectFromSubject}
                    getTopicsOfSub={props.getTopicsOfSub}
                    subjectTopicList={props.subjectTopicList}
                    qType={quesType?.value}
                    subjectChapters={props.subjectChapters}
                    getChapterOfSub={props.getChapterOfSub}
                    getQuizQuestion={props.getQuizQuestion}
                    getSubTopics={props.getSubTopics}
                    subTopicsContent={props.subTopicsContent}
                    getClubbedQuestionList={props.getClubbedQuestionList}
                />
            ) : isQuestionListVisble ? (
                < QuizQuestionList
                    backToMain={() => backToList()}
                    type="selectQuestion"
                    isnavRender={false}
                    subjectSelect={subject?.map(el => el.value)[0]}
                    selectType={quesType?.value}
                    selectFromSubject={props.selectFromSubject}
                    subject={props.selectFromSubject?.filter(el => el.value === subject?.map(el => el.value)[0])[0]}
                />
            ) : (
                <>
                    <div div className="practise">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 onClick={props.backToMain} style={{ marginLeft: 0 }}>
                                {isShowList && !edit ? null : <img src="/images/back.svg" width="20px" alt="" />}&nbsp;&nbsp;
                                {edit ? 'Edit Practice Test' : 'Add Practice Test'}
                            </h2>
                            {
                                isShowList ?
                                    <button onClick={() => edit ? editPracticeTests() : savePractiseTest()}>
                                        Save test<span class="arrow-right"></span>{" "}
                                    </button>
                                    : null
                            }
                        </div>
                        <>
                            <div className="form-group mt-2 w-100 practice-form">
                                <label>Test Title</label>
                                <input
                                    className="form-control mt-2  w-100"
                                    value={testTitle}
                                    onChange={(e) => onChangeQuiZTitle(e.target.value)}
                                />
                                <small>{titleError}</small>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-group mt-2  me-2 w-100">
                                    <label>Subject</label>
                                    <Select
                                        isMulti
                                        components={{ DropdownIndicator }}
                                        options={props.selectFromSubject}
                                        onChange={onChangeSubject}
                                        className='selectSub'
                                        placeholder='Select Subject'
                                        value={subject}
                                        isDisabled={isShowList ? true : false}
                                    >
                                    </Select>
                                    <small>{subjectError}</small>
                                </div>
                                <div className="form-group mt-2  ml-3 w-100">
                                    <label>Test Duration(mins)</label>
                                    <select
                                        className="form-control mt-2 w-100"
                                        // style={{ width: "85%" }}
                                        id='subjects'
                                        value={duration}
                                        onChange={(e) => onChangeDuration(e.target.value)} >
                                        <option hidden>Select duration</option>

                                        {
                                            times !== undefined && times.map(el => {
                                                return (
                                                    <option>{el}</option>
                                                )
                                            })
                                        }

                                    </select>
                                    <small>{durationError}</small>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-md-6 form-group mt-2  pl-0 ">
                                    <label>Question type</label>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={typeForSelect}
                                        onChange={onChangeType}
                                        className='selectSub'
                                        placeholder='Select Type'
                                        value={quesType}
                                        isDisabled={isShowList ? true : false}
                                    >
                                    </Select>

                                    <small>{typeError}</small>
                                </div>
                            </div>
                            {!isShowList ?
                                <button className="mt-2" onClick={showQuizList}>
                                    Save
                                </button>
                                :
                                <div className="form-group mt-2">
                                    <label className="pb-2">Test Questions</label>
                                    <div className="addpointer  d-flex align-items-center">
                                        <button
                                            className=""
                                            onClick={() => setIsQuestionListVisble(true)}
                                        >
                                            Select from list
                                        </button> &nbsp; &nbsp;
                                        <button
                                            style={{ background: 'none', border: '1px solid #5855d6', color: '#5855d6' }}
                                            onClick={() => setIsQuestionVisible(true)}
                                        >
                                            Add new question
                                        </button>
                                    </div>
                                    <small>{props.questionListForQuiz?.length ? '' : questionError}</small>
                                </div>
                            }

                        </>
                    </div>
                    {
                        !isShowList ? null :
                            <Fragment>
                                <div className="selected-count">
                                    {" "}
                                    <h4>
                                        Selected Questions ({props.questionListForQuiz?.length})
                                    </h4>
                                </div>
                                <div div className="add-subject1">
                                    <QuizQuestionList />
                                </div>
                            </Fragment>
                    }

                </>
            )}
        </Fragment>
    );
}