import React, { Fragment, useEffect, useState } from "react";
import "./../manageContent.scss";
import { getQuestionList, getSubtopicQuizList } from "../../../Redux/ActionCreators/manageContentAction";
import Select, { components } from 'react-select';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};


function QuizList(props) {
    const [subject, setSubject] = useState(null);
    const [quizId, setQuizId] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { questionList, allQuizList } = useSelector(state => state.manageContent);

    useEffect(() => {
        if (window.location.pathname.split('/')[1] === 'manage-content') {
            setSubject(props.selectFromSubject?.filter(el => el.value === props.subjectId)[0]);

            dispatch(getSubtopicQuizList(props.subjectId, 'quiz'));
        }
    }, [])

    const selectQuiz = (detail) => {
        setQuizId(detail?._id);
    }

    const addQuiz = async () => {
        await props.quizData(quizId);
        navigate(-1);
    }

    const getSubject = (e) => {
        setSubject(e)
        dispatch(getSubtopicQuizList(subject?.value, 'quiz'));
    }



    return (
        <Fragment>


            <div className="manage-content-holder managequiz">
                <Fragment>
                    <div style={{ fontSize: 20, cursor: "pointer" }}>
                        <div className="d-flex justify-content-between ">

                            <div className="d-flex ml-2">
                                <p
                                    style={{ marginTop: 5 }}
                                    onClick={() => navigate(-1)}
                                >
                                    <img src="/images/back.svg" alt="" width="20px" />
                                </p>
                                <h1>Add Quiz</h1>
                            </div>

                            <button
                                className="ml-auto quizButton"
                                onClick={() => addQuiz()}
                            >
                                Done <span className="arrow-right"></span>
                            </button>
                        </div>
                    </div>
                    <div className="content-heading">
                        <div className="content-header">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p style={{ paddingLeft: 0 }}>
                                    Select quiz from the list below or{" "}
                                    <b className='cursor-pointer'
                                        onClick={() => {
                                            props.isQuiz();
                                        }}
                                    >
                                        add new quiz
                                    </b>
                                </p>
                                <div style={{ width: '30%', marginTop: -20 }}>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={props.selectFromSubject}
                                        onChange={getSubject}
                                        placeholder='Select Subject'
                                        value={subject}
                                    />
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <div className="first-row">Select</div>
                                    <div>Title</div>
                                    <div>Subject</div>
                                    <div>Questions</div>
                                    <div></div>
                                </li>
                            </ul>
                        </div>
                        <ul className="program-list">
                            {allQuizList !== undefined && allQuizList.length ?
                                allQuizList.map((el, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="program-name first-row">
                                                <td>
                                                    <input
                                                        type="radio"
                                                        className="mr-3"
                                                        name="radio1"
                                                        style={{ width: 18, height: 18 }}
                                                        onClick={(e) => {
                                                            selectQuiz(el);
                                                        }}
                                                    />
                                                </td>
                                            </div>
                                            <div className="program-name text-center">
                                                <p>{el.title}</p>
                                            </div>
                                            <div className="text-center">
                                                <p>{el.subject}</p>
                                            </div>
                                            <div className="text-center">
                                                <h3>{el.questions}</h3>
                                            </div>
                                            <div className="text-center">
                                                {/* View questions &nbsp;&nbsp; */}
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <div className="empty-list">
                                    <img src="/images/empty.png" alt="" />
                                    <p>You have not added any Quiz yet.</p>
                                </div>
                            }
                        </ul>
                    </div>
                    {/* ) : (
            <div className="empty-list">
              <img src="/images/empty.png" alt="" />
              <p>You have not added any Questions yet.</p>
            </div>
            )} */}
                </Fragment>
            </div>
        </Fragment>
    );
}






export default (QuizList);
