/* eslint-disable no-unused-vars */
import { getApiCall, postApiCall } from "../../utils/request";
import { GET_NOTIFICATIONS, GET_NEXT_NOTIFICATIONS, NOTIFICATION_MARKED_READ } from "../Types/types";
import { returnErrors } from "./errorAction";


export const getNotifications = (page) => async (dispatch) => {

    try {
        const response = await getApiCall(`notification?page=${page}&unread=false`);
        dispatch({
            type: GET_NOTIFICATIONS, payload: response.data.data
        })
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const markedRead = (id, action) => async (dispatch) => {

    try {
        const response = await getApiCall(`notification/mark?_id=${id}`);
        dispatch({ type: NOTIFICATION_MARKED_READ, payload: action });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getNextNotifications = (page) => async (dispatch) => {

    try {
        const response = await getApiCall(`notification?page=${page}&unread=false`);
        dispatch({ type: GET_NEXT_NOTIFICATIONS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}