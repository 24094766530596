/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import '../AdminRewards/adminRewards.scss';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import { getContests, addContest, handleStatus, updateContest, } from '../../Redux/ActionCreators/adminContestAction';
import { getRewards, checkOne, getCheckedIds } from '../../Redux/ActionCreators/adminRewardsAction';
import { getSchools } from '../../Redux/ActionCreators/accountAction';
import Select, { components } from 'react-select';
import { convertDate } from '../../utils/miscelleneous';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function AdminContests() {

    const [isOpen, setIsOpen] = useState(false);
    const [contestDetail, setContestDetails] = useState({
        title: '', start: '', end: '', school_id: '', redemption: ''
    });
    const [errors, setErrors] = useState({ title: '', start: '', end: '', school_id: '', redemption: '' });
    const [checkedId, setCheckedId] = useState([]);
    const [edit, setEdit] = useState(false);
    const [school, setSchool] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [contestRewards, setContestRewards] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const { contests, companies } = useSelector(state => state.contest);
    const { rewards } = useSelector(state => state.adminReward);
    const { schools } = useSelector(state => state.account);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContests());
        dispatch(getRewards());
    }, [])

    const openAddForm = async (data, edit) => {
        setIsOpen(true);
        setEdit(edit);
        dispatch(getSchools());
        if (data !== null) {
            setSchool([{ label: data?.school_name, value: data?.school_id }]);
            dispatch(getCheckedIds(data.rewards));
            setContestDetails({ title: data.title, start: moment(data.start).format('YYYY-MM-DD'), end: moment(data.end).format('YYYY-MM-DD'), redemption: moment(data.redemption).format('YYYY-MM-DD'), school_id: [data?.school_id], _id: data._id });
        } else {
            setSchool(null);
            const rewardId = rewards.map(el => [el._id][0])
            setCheckedId(rewardId);
            setContestDetails({ title: '', start: '', end: '', school_id: '', redemption: '' });
        }
    }

    const selectSchool = (e) => {
        setSchool(e);
        const schoolIds = e?.map(el => [el.value][0]);
        setContestDetails(prev => ({ ...prev, school_id: schoolIds }));
        setErrors(prev => ({ ...prev, school_id: '' }));
    }



    function contestFormChange(e) {
        const { name, value } = e.target
        const newErrors = { ...errors };
        if (name === 'title' && value === '') {
            newErrors.title = 'Contest title is required';
        } else {
            newErrors.title = '';
        }
        if (name === 'start' && value === '') {
            newErrors.start = 'Start date is required';
        } else {
            // checkTime(contestDetail?.end, value);
            newErrors.start = '';
        }
        if (name === 'end' && value === '') {
            newErrors.end = 'End date is required';
        }
        else {
            checkTime(value, contestDetail?.start);
            newErrors.end = '';
        }
        if (name === 'redemption' && value === '') {
            newErrors.redemption = 'Redemption date is required';
        } else {
            newErrors.redemption = '';
        }
        setErrors(newErrors)
        setContestDetails(prev => ({ ...prev, [name]: value }));
    }

    const checkTime = (end, start) => {
        console.log(end, start)
        if (start !== null) {
            if (new Date(end) < new Date(start)) {
                setSuccessMsg(true);
                setSuccessMessageText("End date should exceed the start date");
            }
            else if (end === start?.split('T')[0]) {
                setSuccessMsg(true);
                setSuccessMessageText("End date should exceed the start date");
            }
            else {
                return true
            }
        } else {
            setSuccessMsg(true);
            setSuccessMessageText("Please select start date first");
            return false;
        }

    }



    const getCheckedId = async (e) => {
        console.log(e.target.checked)
        dispatch(checkOne(e.target.value, e.target.checked));
        if (e.target.checked) {
            setCheckedId([...checkedId, e.target.value]);
        }
        else {
            setCheckedId(checkedId.filter(el => el !== e.target.value));
            console.log(checkedId)
        }
    }

    const saveContest = () => {
        const errors = {}
        setLoading(true);
        if (!contestDetail?.school_id) {
            errors.school_id = 'Please select company';
            setErrors(errors);
            setLoading(false);
        }
        else if (!contestDetail?.title) {
            errors.title = 'Contest title is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!contestDetail?.start) {
            console.log('hhh')
            errors.start = 'Start Date is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!contestDetail?.end) {
            errors.end = 'End Date is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!contestDetail?.redemption) {
            errors.end = 'Redemption date is required';
            setErrors(errors);
            setLoading(false);
        }
        else if (!checkTime(contestDetail?.end, contestDetail?.start)) {
            setSuccessMsg(true);
            setSuccessMessageText("Please check dates");
        }
        else {
            setErrors({ title: '', start: '', end: '', school_id: '', redemption: '' });
            const rewardIds = rewards.filter(el => (el.isChecked && el.active)).map(el1 => [el1._id][0])
            if (edit) {
                dispatch(updateContest({ ...contestDetail, reward_id: rewardIds }));
            } else {
                dispatch(addContest({ ...contestDetail, reward_id: rewardIds }));
            }
            dispatch(getCheckedIds(null));
            setIsOpen(false);
            setIsSave(false);
            setSchool(null);
            setContestRewards([]);
            setContestDetails({ title: '', start: '', end: '', reward_id: '', school_id: '', _id: '', redemption: '' })
            setLoading(false);
            setCheckedId([]);
        }
    }


    const closeAdd = () => {
        setIsOpen(false);
        dispatch(getCheckedIds(null));
        setContestDetails({ title: '', start: '', end: '', reward_id: '', _id: '', redemption: '' });
        setSchool(null);
    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                onClose={() => snackClosed()}
                message={successMessageText}
                autoHideDuration={4000}
            />


            {
                !isOpen ?
                    <div className="user-module">
                        <div className="engaged-users">
                            <div className="users-heading d-flex justify-content-between align-items-center">
                                <h3>Contests</h3>
                                <div className="users-filters form-group mb-0">
                                    {/* <label>Search BY</label> */}
                                    {/* <input
                                placeholder="Search by Company Name"
                                className='form-control'
                                value={search}
                                onChange={(event) => getSearchList(event.target.value)} 
                                /> */}

                                </div>
                                <button className='add-btn' onClick={() => openAddForm(null, false)}>Add Contest</button>
                            </div>
                            <div className="table-admin">
                                <Table responsive>
                                    <thead>
                                        <tr className="header-table">
                                            <th>Contest title</th>
                                            <th>Start Date</th>
                                            <th>End date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            (contests !== undefined && contests?.length) ?
                                                contests.map((contest) => {
                                                    return (
                                                        <tr key={contest.title}>
                                                            <td>{contest.title}</td>
                                                            <td>{convertDate(contest.start)}</td>
                                                            <td>{convertDate(contest.end)}</td>

                                                            <td className=''>
                                                                {
                                                                    contest.active === true || contest.active === false ?
                                                                        <Switch
                                                                            width={30}
                                                                            height={16}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            onColor='#00B24B'
                                                                            onChange={() => {
                                                                                dispatch(handleStatus(contest.active, contest._id))
                                                                            }}
                                                                            checked={contest.active === true ? true : false}
                                                                        /> : <span className="status-tags">{contest?.active}</span>
                                                                }<br />
                                                                {/* {contest.active === true ? 'Active' : 'Inactive'} */}
                                                            </td>

                                                            <td>
                                                                <img src='/images/edit.svg' alt='' className='cursor-pointer' onClick={() => openAddForm(contest, true)} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='empty-list'>
                                                            <img src='/images/empty.png' alt='' />
                                                            <p>You have not added any contests yet.</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='add-contest-modal'>
                        <div className="header">
                            <img src='/images/back.svg' onClick={() => closeAdd()} alt='' />&nbsp;
                            <h5 className="modal-label">{edit ? 'Edit Contest' : 'Add Contest'}</h5>


                        </div>
                        {contestDetail !== undefined &&
                            <div className='add-contest'>
                                <Fragment>
                                    <div className='mt-0'>
                                        <label>Company*
                                        </label>
                                        <div className='mt-2'>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={schools}
                                                onChange={selectSchool}
                                                className='selectSub'
                                                placeholder='Select School'
                                                value={school}
                                            // isMulti
                                            />
                                        </div>

                                        <small>{errors.school_id}</small>
                                    </div>
                                    <div className='form-group mt-2'>
                                        <label>Contest title*
                                        </label>
                                        <div className='mt-2'>
                                            <input type='text' name='title' className='form-control' value={contestDetail?.title} onChange={(e) => contestFormChange(e)} />
                                        </div>
                                        <span style={{ color: 'red' }}>{errors?.title}</span>
                                    </div>

                                    <div className='row pl-0 pr-0'>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-0'>
                                                <label>Start Date*
                                                </label>
                                                <div className='mt-2'>
                                                    <input type='date' name='start' className='form-control' value={contestDetail?.start} onChange={(e) => contestFormChange(e)} />
                                                </div>
                                                <span style={{ color: 'red' }}>{errors?.start}</span>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-0'>
                                                <label>End Date*
                                                </label>
                                                <div className='mt-2'>
                                                    <input type='date' name='end' className='form-control' value={contestDetail?.end} onChange={(e) => contestFormChange(e)} />
                                                </div>
                                                <span style={{ color: 'red' }}>{errors?.end}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pl-0 pr-0'>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-0'>
                                                <label>Redemption Date*
                                                </label>
                                                <div className='mt-2'>
                                                    <input type='date' name='redemption' className='form-control' value={contestDetail?.redemption} onChange={(e) => contestFormChange(e)} />
                                                </div>
                                                <span style={{ color: 'red' }}>{errors?.redemption}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {rewards && rewards?.length ?
                                        <div className='tableFixHead'>
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {/* <input type='checkbox' style={{ width: 16, height: 16 }} checked={checked} onChange={(e) => checkAll(e)} /> */}
                                                        </th>
                                                        <th>Reward</th>
                                                        <th>Cost</th>
                                                        <th>Value</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        rewards && rewards.filter(el => el.active)?.map(el => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <input type='checkbox' onChange={getCheckedId} style={{ width: 16, height: 16 }} value={el._id}
                                                                            checked={el.isChecked} />
                                                                    </td>
                                                                    <td>{el.title}</td>
                                                                    <td>{el.cost}</td>
                                                                    <td>{el?.value}</td>
                                                                    {/* <td><img onClick={() => removeReward(el._id)} src='/images/delete.svg' className='cursor-pointer' alt='' /></td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        : null
                                    }
                                    <div>
                                        <button onClick={() => setIsSave(true)} disabled={loading ? true : false} className="add-btn">Save</button>
                                    </div>
                                </Fragment>
                            </div>
                        }
                    </div>
            }

            {/* ########## Complete mark modal ############### */}
            <Modal
                show={isSave}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"confirm-modal"}
            >
                <div className="p-3 text-center">
                    <h1 className='mb-2'>{'Are you sure you want to save this contest?'}</h1>
                    <div className="save-btn">
                        <button onClick={saveContest}>Yes</button> &nbsp;&nbsp;
                        <button onClick={() => setIsSave(false)}>No</button>
                    </div>
                </div>
            </Modal>
        </Fragment >
    );
}



export default AdminContests;
