import { GET_STUDENT_LOGIN_TREND, GET_COMPLETIONS, GET_COUNT_SUBJECT, GET_ENGAGEMENT_SCORE, GET_TOPIC_MATRICS, GET_SCORE_HISTORY, GET_NEXT_SCORE_HISTORY, RESET_HISTORY } from '../Types/types';
import { getApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';

export const getTopicMatrics = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/dashboard/chapter/metrics')
        dispatch({ type: GET_TOPIC_MATRICS, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getStudentLogin = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/dashboard/login/trend');
        dispatch({ type: GET_STUDENT_LOGIN_TREND, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getCompletions = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/dashboard/subject/completion');
        const response1 = await getApiCall('student/dashboard/practise/test/completion');
        const data = response.data
        const data1 = response1.data
        dispatch({ type: GET_COMPLETIONS, payload: { data, data1 } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getEngagementScore = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`campus/user/score/engagement?_id=${id}`)
        dispatch({ type: GET_ENGAGEMENT_SCORE, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getCountsSubject = () => async (dispatch) => {
    try {
        const response = await getApiCall('campus/dashboard/graphs/subjects')
        dispatch({ type: GET_COUNT_SUBJECT, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getScoresHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/dashboard/score/history?_id=${id}&page=${page}`);
        const data = response.data.data;
        dispatch({ type: GET_SCORE_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getNextScoresHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/dashboard/score/history?_id=${id}&page=${page}`);
        const data = response.data.data;
        dispatch({ type: GET_NEXT_SCORE_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetHistory = (type) => async (dispatch) => {
    return dispatch({
        type: RESET_HISTORY, payload: type
    })
}



