import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails, updateProfile, emptyData, updateLanguage } from '../../Redux/ActionCreators/profileAction';
import './profile.scss';
import Modal from "react-bootstrap/Modal";
import Avatar from 'react-avatar-edit'
import { BallBeat } from '../../Loader/loader';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { compressImage } from '../../utils/miscelleneous';

function Profile() {
    const [avatar, setAvatar] = useState('');
    const [initial, setInitial] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [image, setImage] = useState('')
    const [city, setCity] = useState(null);
    const [avatarModal, setavatarModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [afterPreview, setAfterPreview] = useState(false)
    const [successMessageText, setSuccessMessageText] = useState('')
    const [updateLoading, setUpdateLoading] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng'))
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();



    useEffect(() => {
        const getFirstrender = async () => {
            await dispatch(getProfileDetails());
        }
        getFirstrender();
    }, [])

    const { profileDetails } = useSelector(state => state.profile);

    useEffect(() => {
        if (profileDetails !== null) {
            // setTimeout(() => {
            setFirstName(profileDetails?.firstname);
            setLastName(profileDetails?.lastname);
            setEmail(profileDetails?.email);
            setMobile(profileDetails?.mobile === null ? '' : profileDetails?.mobile);
            setAvatar(!profileDetails?.profile_image ? null : profileDetails?.profile_image);
            setImage(!profileDetails?.profile_image ? null : profileDetails?.profile_image);
            setInitial(profileDetails?.initial);
            setCity(profileDetails?.city === 'null' ? null : profileDetails?.city)

        }
    }, [profileDetails?.firstname])

    const handleChangeLanguage = (e) => {
        setLang(e.target.value);
        i18n.changeLanguage(e.target.value);
        dispatch(updateLanguage(e.target.value));
        localStorage.setItem('i18nextLang', e.target.value);
    }


    const getInitial = (f, l) => {
        return f.split('')[0] + l.split('')[0]
    }

    const onBeforeFileLoad = async (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB")

        } else {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
            };
            if (file) {
                reader.readAsDataURL(file);
                // setAvatar(reader.result);
                setAfterPreview(true);
                setImage(file);
            } else {
                setAfterPreview(false)
            }
        }
    };

    const saveAvatar = async () => {
        if (image === null) {
            setAfterPreview(false);
            setavatarModal(true);
        }
        else {
            setAfterPreview(false);
            setavatarModal(false);
            const img = await compressImage(image);
            let data = new FormData()
            data.append('firstname', firstName)
            data.append('lastname', lastName)
            data.append('email', email)
            data.append('mobile', mobile)
            data.append('city', city)
            image !== undefined && data.append('image', img)
            await dispatch(emptyData());
            await dispatch(updateProfile(data));
            await dispatch(getProfileDetails());
            setSuccessMsg(true);
            setSuccessMessageText("Profile picture has been successfully uploaded");
            // if (profileDetails !== null) {
            //     setFirstName(profileDetails?.firstname)
            //     setInitial(profileDetails.initial);
            // }
        }
    }

    const closeModal = () => {
        setavatarModal(false);
    }

    const savePersonalChange = async () => {
        setUpdateLoading(true);
        if (firstName?.trim() === "" || null) {
            setSuccessMsg(true);
            setSuccessMessageText("First name cannot be empty");
            setUpdateLoading(false);
        } else if (lastName?.trim() === "" || null) {
            setSuccessMsg(true);
            setSuccessMessageText("Last name cannot be empty");
            setUpdateLoading(false);
        }
        else if (mobile === "") {
            setSuccessMsg(true);
            setSuccessMessageText("Please enter 10 digit mobile number");
            setUpdateLoading(false);
        }
        else {
            let data = new FormData()
            data.append('firstname', firstName)
            data.append('lastname', lastName)
            data.append('email', email)
            data.append('mobile', mobile)
            data.append('city', city)
            image !== undefined && data.append('image', image);
            await dispatch(emptyData());
            await dispatch(updateProfile(data));
            await dispatch(getProfileDetails());
            setUpdateLoading(false);
            setSuccessMsg(true);
            setSuccessMessageText('Personal information has been successfully updated');
        }
    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    const onClosed = (e) => {
        setAfterPreview(false);
    }

    return (
        <div className='user-details-holder' style={{ marginLeft: window.innerWidth > 767 ? -100 : 0 }}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <h2 className='d-flex justify-content-between' style={{ width: '70%' }}>{t('My Account')}
                {/* <div className='d-flex  align-items-center' style={{ width: '30%' }} >
                    <p style={{ fontSize: 12, width: '60%' }}> {t('App Language', { ns: 'profile' })}</p>&nbsp;
                    <select className='form-control' value={lang} onChange={handleChangeLanguage}>
                        <option value='en'>English</option>
                        <option value='hi'>Hindi</option>
                        <option value='kn'>Kannada</option>
                        <option value='tn'>Tamil</option>
                        <option value='od'>Odia</option>
                        <option value='ml'>Malyalam</option>
                    </select>
                </div> */}
            </h2>
            <div className='profile-holder'>
                <h3>{t('Profile Details')}
                </h3>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='form-group mt-2'>
                            <label>{t('First Name')}
                            </label>
                            <input className='form-control mt-2' autoComplete='off' name='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        {
                            window.innerWidth < 767 ?
                                <div className='form-group mt-2'>
                                    <label>{t('Last Name')}
                                    </label>
                                    <input className='form-control mt-2' autoComplete='off' name='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div> : null
                        }
                        <div className='form-group mt-2'>
                            <label>{t('Email')}
                            </label>
                            <input className='form-control mt-2' disabled autoComplete='off' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='form-group mt-2'>
                            <label>{t('City')}
                            </label>
                            <input className='form-control mt-2' autoComplete='off' name='city' value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        {window.innerWidth > 767 ? <div className='form-group mt-2'>
                            <label>{t('Last Name')}
                            </label>
                            <input className='form-control mt-2' autoComplete='off' name='lastname' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div> : null}
                        <div className='form-group mt-2'>
                            <label>{t('Mobile Number')}
                            </label>
                            <input className='form-control mt-2' autoComplete='off' name='mobile' value={mobile} onChange={(e) => setMobile(e.target.value)} />
                        </div>
                    </div>
                    <div className={window.innerWidth > 767 ? 'col-md-4' : 'col-md-4 order-first'} >
                        <div className="profile-container">
                            <div className="profile-info-avatar initial-circle-custom"
                                style={{ background: avatar !== null ? null : '#F5821F' }}
                            >
                                {
                                    (avatar !== "" && avatar !== null) ?
                                        <img src={avatar} alt='' /> :
                                        initial
                                }
                            </div>
                            <p onClick={() => setavatarModal(true)}>{t('Change Photo')}</p>
                        </div>

                    </div>
                </div>
                <div className="save-btn position-relative">
                    <button
                        onClick={() => savePersonalChange()}>
                        {updateLoading ? (
                            <BallBeat color={"#fff"} />
                        ) : (
                            `${t("Update & Save")}`
                        )}
                    </button>
                </div>
            </div>
            {/* <div className='subscription-details'>
                <h3>Subscription Details</h3>
                {
                    paymentDetails !== undefined && paymentDetails.paid ?
                        <Fragment>
                            <p>You have subscribed plan</p>
                            <p>Expiry Date :{convertDate(paymentDetails.end_date)}</p>
                        </Fragment>
                        :
                        <Fragment>
                            <p>You have not subscribed any plan</p>
                            <button onClick={clickPayment}>Pay now</button>
                        </Fragment>
                }

            </div> */}
            <Modal
                show={avatarModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"alumani-modal"}
            >
                <div className="edit-educational-container">
                    <div
                        className="cross-holder"
                        onClick={() => closeModal()}
                    >
                        <img src="/images/close1.svg" width='18px' alt='' />
                    </div>

                    <div className="educational-details-main">
                        <h2>{t('Upload Profile Picture')}</h2>
                        <div className="profile-avatar-edit-holder">
                            {profileDetails !== undefined &&
                                profileDetails !== "" ? (
                                <Avatar
                                    width={200}
                                    height={200}
                                    imageWidth={200}
                                    imageHeight={200}
                                    minCropRadius={60}
                                    // onCrop={props.onCrop}
                                    cropRadius={60}
                                    onClose={onClosed}
                                    onBeforeFileLoad={onBeforeFileLoad}
                                />
                            ) : null}
                        </div>

                        <div className="save-buttons-education">
                            <button
                                disabled={!afterPreview}
                                style={{ opacity: afterPreview ? 1 : 0.6 }}
                                onClick={() => saveAvatar()}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


export default Profile;